
// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { size, fontWeightRegular } = typography;
const { white } = colors;

const stepLabel = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} `,
      fontWeight: fontWeightRegular,
      fontSize: size.md,
      color: "#9fc9ff",
      textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} `,
        color: `${rgba(white.main, 0.8)} `,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} `,
        color: `${rgba(white.main, 0.8)} `,
      },
    },
  },
};

export default stepLabel;
