import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { AppointmentTimes, NewAppointment, ReasonsForVisit } from 'models/AppointmentModels';
import { NewPatient, Patient } from 'models/PatientModels';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDBox from 'components/atoms/MDBox/MDBox';
import { Checkbox, FormControlLabel, FormGroup, InputAdornment, Modal } from '@mui/material';
import borders from 'assets/theme/base/borders';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import colors from 'assets/theme/base/colors';
import { containerStyles, getAppointmentTimeFromISOString, validateDateFormat } from 'helpers/helpers';
import Moment from 'moment';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDInput from 'components/atoms/MDInput/MDInput';
import SearchIcon from '@mui/icons-material/Search';
import { usePatientData } from 'hooks/usePatientData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCircleNotch, faX } from '@fortawesome/free-solid-svg-icons';
import DropdownSelect from 'components/molecules/DropdownSelect/DropdownSelect';
import useKeyPress, { KeyValues } from 'hooks/useKeyPress';
import { usePatientMutations } from 'hooks/usePatientMutations';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import {
  defaultHour,
  formatDateForDB,
  todayFormattedForISO,
  getCurrentISOTimestamp,
} from 'services/dateService';
import { SurveyTypes } from 'models/SurveyModels';
import { OrgDashboardModes } from 'components/pages/OrganizationDashboardPage/OrganizationDashboardPage.types';
import { useNavigation } from 'hooks/useNavigation';
import { RouteKeys } from 'models/RouteModels';

interface PatientIntakeModalProps {
  closeModal(): void;
  handleShowAlert?(): void;
  selectedDateISOString?: string;
  mode: OrgDashboardModes | 'quickstart';
}

interface SearchItemProps {
  label: string;
  handleClick(): void;
  isFirst: boolean;
  isLast: boolean;
  isFocused: boolean;
}

const SearchItem: React.FC<SearchItemProps> = ({
  label,
  handleClick,
  isFirst,
  isLast,
  isFocused,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isFocused || !ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [isFocused]);

  return (
    <MDBox
      ref={ref}
      onClick={handleClick}
      sx={{
        width: '100%',
        transition: 'background-color 250ms ease',
        cursor: 'pointer',
        paddingTop: !isFirst ? '.1rem' : '0',
        paddingBottom: !isLast ? '.1rem' : '0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        backgroundColor: isFocused ? colors.disabled.main : 'unset',
        '&:hover': {
          backgroundColor: colors.disabled.main,
        },
      }}
    >
      <MDTypography variant='body2' sx={{ textTransform: 'capitalize' }}>
        {label}
      </MDTypography>
    </MDBox>
  )
};

const PatientIntakeModal: React.FC<PatientIntakeModalProps> = ({
  closeModal,
  handleShowAlert,
  selectedDateISOString,
  mode,
}) => {
  // if the user opened the modal via the Quickstart Exam button in the app header,
  // we show a simplified version of the modal
  const isQuickstartMode = mode === 'quickstart';
  const isAppointmentMode = mode === OrgDashboardModes.APPOINTMENT_SCHEDULE;

  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });

  const { user } = useAuth();

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const {
    createPatient,
    createPatientError,
    createPatientLoading,
  } = usePatientMutations();

  const {
    createAppointment,
    createAppointmentError,
    createAppointmentLoading,
  } = useAppointmentMutations();

  const requestsLoading = createPatientLoading || createAppointmentLoading;
  const isError = createPatientError || createAppointmentError;

  const { allAppointments } = useAppointmentData({ selectedDateISOString, preventRefetch: true });

  const [screen, setScreen] = useState<'modeSelect' | 'intakeForm' | 'patientSearch'>(
    isAppointmentMode || isQuickstartMode ? 'modeSelect' : 'intakeForm'
  );
  const [patientSearch, setPatientSearch] = useState<string>('');
  const [patientSearchFocusedIndex, setPatientSearchFocusedIndex] = useState<number>(0);
  const [existingPatient, setExistingPatient] = useState<Patient | null>(null);

  const { allPatients } = usePatientData({ preventRefetch: true });

  const patientsBySearch = allPatients?.filter((patient) => (
    patient.first_name.toLowerCase().includes(patientSearch.toLowerCase())
    || patient.last_name.toLowerCase().includes(patientSearch.toLowerCase())
    || patient.external_id?.toString().includes(patientSearch.toLowerCase())
  ));

  useEffect(() => {
    if (!patientsBySearch || !patientsBySearch.length || patientSearchFocusedIndex === 0) return;
    if (patientsBySearch.length - 1 < patientSearchFocusedIndex) {
      setPatientSearchFocusedIndex(0);
    }
  }, [patientsBySearch, patientSearchFocusedIndex]);

  const newPatientDataDefaults = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    external_id: '',
    provider: '',
    org_id: user!.orgs[0].org_id,
  };

  const newAppointmentDataDefaults = {
    patient_id: '',
    appointment_date: null,
    org_id: user!.orgs[0].org_id,
    survey_type: SurveyTypes.HOOS_KOOS,
    reason_for_visit: undefined,
  };

  const [newPatientData, setNewPatientData] = useState<NewPatient>(newPatientDataDefaults);
  const [newAppointmentData, setNewAppointmentData] = useState<NewAppointment>(newAppointmentDataDefaults);

  const updateAppointmentDataPerExistingPatient = (patient?: Patient | null) => {
    setNewAppointmentData({
      ...newAppointmentData,
      patient_id: patient?.patient_id || '',
    });
  };

  useEffect(() => {
    updateAppointmentDataPerExistingPatient(existingPatient);
    // eslint-disable-next-line
  }, [existingPatient]);

  const updateNewPatientData = (key: keyof NewPatient, value: string) => setNewPatientData({
    ...newPatientData,
    [key]: value,
  });
  const updateNewAppointmentData = (key: keyof NewAppointment, value: string | null | undefined) => setNewAppointmentData({
    ...newAppointmentData,
    [key]: value,
  });

  const resetNewPatientData = () => setNewPatientData(newPatientDataDefaults);
  const resetNewAppointmentData = () => setNewAppointmentData(newAppointmentDataDefaults);

  const isIncomplete = (data: Record<any, any>): boolean => !!Object.keys(data).find((key) => !data[key] ? true : false);

  const handleSuccess = () => {
    if (handleShowAlert) handleShowAlert();
    closeModal();
  };

  const handleSubmit = async ({ isNewPatient, quickstartPatient }: { isNewPatient: boolean, quickstartPatient?: Patient }) => {
    if (!user) return;

    if (isNewPatient) {
      try {
        if (isQuickstartMode) {
          // if creating a new patient and in quickstart mode, first we post a new patient to
          // the DB, then we post a new appointment to the DB for that patient with several auto
          // populated fields.

          const resp = await createPatient(newPatientData);
          const newPatient = resp.data.patient;

          const newExamData = await createAppointment({
            patient_id: newPatient.patient_id,
            appointment_date: getCurrentISOTimestamp(),
            org_id: user.orgs[0].org_id,
            survey_type: SurveyTypes.HOOS_KOOS, // this data is auto populated in quickstart mode
            reason_for_visit: ReasonsForVisit.EVALUATION, // this data is auto populated in quickstart mode
          });

          const newExam = newExamData.data.appointment;

          // then we navigate to that appointment's overview page.
          navigateToBaseRouteWithAptmtDetails({
            routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
            appointmentDateISOString: newExam.appointment_date,
            appointmentId: newExam.appointment_id,
          });

          handleSuccess();

          return;
        }

        if (isIncomplete(newPatientData)) return;

        // otherwise, we create the patient and appointment entirely based upon the user's input
        // with no fields auto populated
        const resp = await createPatient(newPatientData);
        const newPatient = resp.data.patient;
        if (createPatientError || !newPatient) return;

        if (isAppointmentMode) {
          await createAppointment({
            ...newAppointmentData,
            patient_id: newPatient.patient_id,
          });
        }

        handleSuccess();
      } catch (e) {
        console.error(`Something went wrong: ${e}`);
      }
    } else {
      // otherwise, if instead creating an appointment for an existing patient
      // and not a new one...
      try {
        if (isQuickstartMode) {
          if (!existingPatient && !quickstartPatient) return;

          // in quickstart mode, create a new appointment for the existing patient
          // with the autopopulated fields
          const newExamData = await createAppointment({
            patient_id: existingPatient?.patient_id || quickstartPatient?.patient_id || '',
            appointment_date: getCurrentISOTimestamp(),
            org_id: user.orgs[0].org_id,
            survey_type: SurveyTypes.HOOS_KOOS,
            reason_for_visit: ReasonsForVisit.EVALUATION,
          });

          const newExam = newExamData.data.appointment;

          // then we navigate to that appointment's overview page.
          navigateToBaseRouteWithAptmtDetails({
            routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
            appointmentDateISOString: newExam.appointment_date,
            appointmentId: newExam.appointment_id,
          });

          handleSuccess();

          return;
        }

        if (isIncomplete(newAppointmentData)) return;

        // otherwise, we create the patient and appointment entirely based upon the user's input
        // with no fields auto populated
        await createAppointment(newAppointmentData);
        handleSuccess();
      } catch (e) {
        console.error(`Something went wrong: ${e}`);
      }
    }
  };

  // keyboard controls for operating the patient search screen in the modal
  useKeyPress(KeyValues.Enter, async () => {
    switch (screen) {
      case 'patientSearch': {
        if (patientsBySearch && patientsBySearch.length) {
          if (isQuickstartMode) {
            await handleSubmit({
              isNewPatient: false,
              quickstartPatient: patientsBySearch[patientSearchFocusedIndex] || patientsBySearch[0],
            });
            return;
          }

          setExistingPatient(patientsBySearch[patientSearchFocusedIndex] || patientsBySearch[0] || null);
          setPatientSearch('');

          setScreen('intakeForm');
        }
        return;
      }
      default: return null;
    }
  });

  // keyboard controls for operating the patient search screen in the modal
  useKeyPress(KeyValues.ArrowDown, () => {
    switch (screen) {
      case 'patientSearch': {
        if (!patientsBySearch || !patientSearch.length || patientSearchFocusedIndex === patientsBySearch.length - 1) return;
        setPatientSearchFocusedIndex(patientSearchFocusedIndex + 1);
        break;
      }
      default: return null;
    }
  });

  // keyboard controls for operating the patient search screen in the modal
  useKeyPress(KeyValues.ArrowUp, () => {
    switch (screen) {
      case 'patientSearch': {
        if (!patientsBySearch || !patientSearch.length || patientSearchFocusedIndex === 0) return;
        setPatientSearchFocusedIndex(patientSearchFocusedIndex - 1);
        break;
      }
      default: return null;
    }
  });

  const appointmentIsTaken = (time: AppointmentTimes): boolean => {
    return !!allAppointments.find((aptmt) => getAppointmentTimeFromISOString(aptmt.appointment_date) === time);
  };

  const updateAppointmentTime = ({
    appointmentDateISOString,
    selectedTime,
  }: {
    appointmentDateISOString: string | null,
    selectedTime: AppointmentTimes,
  }): string | null => {
    let dateObj: Date;

    if (appointmentDateISOString) {
      dateObj = new Date(appointmentDateISOString);
    } else {
      dateObj = new Date(todayFormattedForISO);
    }

    const timePeriod = selectedTime.split(' ')[1];
    let hours = parseInt(selectedTime.split(':')[0]);
    const mins = parseInt(selectedTime.split(':')[1]);
    if (timePeriod === 'PM') {
      hours = 12 + hours;
    }
    dateObj.setUTCHours(hours, mins);
    return formatDateForDB(dateObj);
  };

  const generateAppointmentButtons = (timePeriod: 'AM' | 'PM') => Object.values(AppointmentTimes)
    .filter((time) => time.split(' ')[1] === timePeriod)
    .map((time) => (
      <div key={time} style={{ padding: '.25rem', width: '50%' }}>
        <MDButton
          fullWidth
          color={
            getAppointmentTimeFromISOString(newAppointmentData.appointment_date) === time
              ? MUIColors.PRIMARY
              : MUIColors.SECONDARY
          }
          onClick={() => updateNewAppointmentData(
            'appointment_date',
            updateAppointmentTime({
              appointmentDateISOString: selectedDateISOString || getCurrentISOTimestamp(),
              selectedTime: time,
            }))}
          disabled={appointmentIsTaken(time)}
          variant={
            appointmentIsTaken(time)
              ? 'outlined'
              : 'contained'
          }
        >
          {time}
        </MDButton>
      </div>
    ));

  // autoformatting for the date of birth input field when the user types
  const handleDOBInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const latestChar = value.split('')[value.length - 1];

    // handle backspace
    if (value.length < newPatientData.date_of_birth.length) {
      updateNewPatientData('date_of_birth', value);
      return;
    }

    if (isNaN(parseInt(latestChar)) || value.length === 11) return;

    if (value.length === 4 || value.length === 7) {
      updateNewPatientData('date_of_birth', value + '-');
      return;
    }

    updateNewPatientData('date_of_birth', value);
  };

  // TODO: if pre-op testing is selected, will have to show "surgery info" dropdowns
  // from figma. confirm how designs should work with shannon --> Not MVP
  const reasonForVisitCheckboxes = (
    <FormGroup
      {...containerStyles({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
      })}
    >
      {Object.values(ReasonsForVisit).map((reason) => (
        <FormControlLabel
          key={reason}
          {...containerStyles({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          })}
          onChange={() => updateNewAppointmentData('reason_for_visit', reason)}
          control={<Checkbox />}
          label={(
            <MDTypography variant='body2' sx={{ textTransform: 'capitalize' }}>
              {reason}
            </MDTypography>
          )}
        />
      ))}
    </FormGroup>
  );

  // TODO: this rendering logic could use some refactoring and streamlining
  return (
    <Modal
      {...containerStyles({
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
      open={true}
      onClose={closeModal}
    >
      <MDBox
        shadow={Shadows.SMALL}
        borderRadius={borders.borderRadius.lg}
        sx={{
          padding: '3rem',
          backgroundColor: colors.white.main,
          width: breakpointBreached ? '90%' : 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxHeight: '95vh',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <FontAwesomeIcon
          icon={faX}
          color={colors.primary.main}
          cursor='pointer'
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '1rem',
          }}
        />
        {screen === 'modeSelect' && (
          <>
            <MDTypography
              variant='h2'
              color={MUIColors.PRIMARY}
              sx={{ marginBottom: isQuickstartMode ? '1.5rem' : '1rem' }}
              textAlign='center'
            >
              {isQuickstartMode ? 'Quickstart Exam' : 'Add Patient to Schedule'}
            </MDTypography>
            {!isQuickstartMode && (
              <MDTypography
                variant='h3'
                fontWeight='regular'
                color={MUIColors.SECONDARY}
                sx={{ marginBottom: '1.5rem' }}
                textAlign='center'
              >
                {Moment(selectedDateISOString).format('MMMM Do YYYY')}
              </MDTypography>
            )}
            <MDBox
              {...containerStyles({
                width: breakpointBreached ? '100%' : '25rem',
                height: '10rem',
                display: 'flex',
                justifyContent: 'center',
              })}
            >
              <MDButton
                fullWidth
                variant='contained'
                color={MUIColors.SECONDARY}
                style={{ height: '100%', margin: '.25rem' }}
                onClick={() => setScreen('patientSearch')}
              >
                <MDTypography fontWeight='bold'>
                  Search for a Patient
                </MDTypography>
              </MDButton>
              <MDButton
                fullWidth
                variant='contained'
                color={MUIColors.SECONDARY}
                style={{ height: '100%', margin: '.25rem' }}
                onClick={() => setScreen('intakeForm')}
              >
                <MDTypography fontWeight='bold'>
                  New<br />Patient
                </MDTypography>
              </MDButton>
            </MDBox>
          </>
        )}
        {screen === 'patientSearch' && (
          <MDBox sx={{ minWidth: '20rem', position: 'relative' }}>
            {!isQuickstartMode && (
              <MDTypography
                variant='h2'
                color={MUIColors.PRIMARY}
                sx={{ marginBottom: '2rem' }}
                textAlign='center'
              >
                Add Existing Patient
              </MDTypography>
            )}
            <MDInput
              dark
              fullWidth
              placeholder='Patient name or ID'
              value={patientSearch}
              onChange={(e) => setPatientSearch(e.target.value)}
              sx={{ marginBottom: '1rem' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="medium" />
                  </InputAdornment>
                ),
              }}
            />
            <MDBox
              {...containerStyles({
                width: '100%',
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                marginBottom: '1rem',
                padding: '.5rem 0',
                backgroundColor: colors.grey[200],
                border: `1px solid ${colors.grey[400]}`,
                borderRadius: '6px',
              })}
            >
              {patientSearch === '' && (
                <MDTypography
                  variant='body2'
                  sx={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.grey[600],
                  }}
                >
                  No query entered.
                </MDTypography>
              )}
              {patientsBySearch?.length === 0 && (
                <MDTypography
                  variant='body2'
                  sx={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.grey[600],
                  }}
                >
                  No matches found.
                </MDTypography>
              )}
              {patientSearch !== '' && patientsBySearch?.map((patient, i, a) => (
                <SearchItem
                  key={patient.patient_id}
                  label={`${patient.last_name}, ${patient.first_name[0]}.`}
                  isFirst={i === 0}
                  isLast={i === a.length - 1}
                  isFocused={patientSearchFocusedIndex === i}
                  handleClick={async () => {
                    if (isQuickstartMode) {
                      await handleSubmit({ isNewPatient: false, quickstartPatient: patient });
                      return;
                    }

                    setPatientSearch('');
                    setExistingPatient(patient);
                    setScreen('intakeForm');
                  }}
                />
              ))}
            </MDBox>
            <MDButton
              style={{ padding: 0 }}
              onClick={() => {
                setPatientSearch('');
                setScreen('modeSelect');
              }
              }>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '.5rem' }} />
              Back
            </MDButton>
            {isQuickstartMode && createAppointmentLoading && (
              <FontAwesomeIcon
                spin
                icon={faCircleNotch}
                color={colors.grey[400]}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              />
            )}
          </MDBox>
        )}
        {screen === 'intakeForm' && (
          <>
            <MDTypography
              variant='h3'
              color={MUIColors.SECONDARY}
              sx={{
                textTransform: 'capitalize',
                marginBottom: existingPatient ? '0' : '1rem'
              }}
              textAlign='center'
            >
              {existingPatient
                ? `${existingPatient.first_name} ${existingPatient.last_name}`
                : 'Enroll new patient'
              }
            </MDTypography>
            {existingPatient && (
              <MDTypography
                variant='h4'
                fontWeight='regular'
                marginBottom='1rem'
              >
                ID number: {existingPatient.external_id || 'N/A'}
              </MDTypography>
            )}
            <MDBox
              {...containerStyles({
                width: breakpointBreached ? '100%' : '40rem',
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: '1rem',
              })}
            >
              {isQuickstartMode && (
                <MDBox
                  {...containerStyles({
                    width: '100%',
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  })}
                >
                  <MDInput
                    dark
                    label='First name'
                    value={newPatientData.first_name}
                    onChange={(e) => updateNewPatientData('first_name', e.target.value)}
                    sx={{ marginBottom: '.50rem', width: breakpointBreached ? '100%' : '60%' }}
                  />
                  <MDInput
                    dark
                    label='Last name'
                    value={newPatientData.last_name}
                    onChange={(e) => updateNewPatientData('last_name', e.target.value)}
                    sx={{ marginBottom: '.50rem', width: breakpointBreached ? '100%' : '60%' }}
                  />
                  <MDInput
                    dark
                    label="Date of birth"
                    placeholder='YYYY-MM-DD'
                    value={newPatientData.date_of_birth}
                    onChange={handleDOBInputChange}
                    sx={{ marginBottom: '1rem', width: breakpointBreached ? '100%' : '60%' }}
                    error={
                      newPatientData.date_of_birth === ''
                        ? false
                        : !validateDateFormat(newPatientData.date_of_birth)
                    }
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      padding: '1rem',
                    }}
                  >
                    <MDButton
                      onClick={() => {
                        setExistingPatient(null);
                        resetNewAppointmentData();
                        resetNewPatientData();
                        setScreen('modeSelect');
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '.5rem' }} />
                      Back
                    </MDButton>
                  </div>
                </MDBox>
              )}
              {!isQuickstartMode && (
                <>
                  <MDBox
                    {...containerStyles({
                      width: breakpointBreached ? '100%' : '50%',
                      padding: breakpointBreached ? '0 1rem' : '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    })}
                  >
                    {isAppointmentMode && (
                      <>
                        <MDTypography variant='body2' fontWeight='bold'>
                          1. Appointment Date
                        </MDTypography>
                        <MDInput
                          dark
                          disabled
                          value={Moment(new Date(selectedDateISOString || getCurrentISOTimestamp())).format('YYYY-MM-DD')}
                          sx={{ marginBottom: '1rem' }}
                        />
                      </>
                    )}
                    <MDTypography variant='body2' fontWeight='bold'>
                      {isAppointmentMode && '2.'}
                      {' '}
                      {existingPatient ? 'Reason for Visit' : 'Patient Details'}
                    </MDTypography>
                    {existingPatient && (
                      reasonForVisitCheckboxes
                    )}
                    {!existingPatient && (
                      <>
                        <MDInput
                          dark
                          fullWidth
                          label='First name'
                          value={newPatientData.first_name}
                          onChange={(e) => updateNewPatientData('first_name', e.target.value)}
                          sx={{ marginBottom: '.50rem' }}
                        />
                        <MDInput
                          dark
                          fullWidth
                          label='Last name'
                          value={newPatientData.last_name}
                          onChange={(e) => updateNewPatientData('last_name', e.target.value)}
                          sx={{ marginBottom: '.50rem' }}
                        />
                        <MDInput
                          dark
                          fullWidth
                          label="Date of birth"
                          placeholder='YYYY-MM-DD'
                          value={newPatientData.date_of_birth}
                          onChange={handleDOBInputChange}
                          sx={{ marginBottom: '1rem' }}
                          error={
                            newPatientData.date_of_birth === ''
                              ? false
                              : !validateDateFormat(newPatientData.date_of_birth)}
                        />
                      </>
                    )}
                    {isAppointmentMode && (
                      <>
                        <MDTypography variant='body2' fontWeight='bold'>
                          3. {existingPatient ? 'Provider' : 'Enter Patient ID Number'}
                        </MDTypography>
                        {!existingPatient && (
                          <MDInput
                            dark
                            fullWidth
                            placeholder='ID'
                            type='number'
                            value={newPatientData.external_id}
                            onChange={(e) => updateNewPatientData('external_id', e.target.value)}
                          />
                        )}
                        {existingPatient && (
                          // TODO: if this changes it will have to patch to the user object in the DB
                          <MDInput
                            disabled
                            dark
                            fullWidth
                            value={existingPatient.provider || ''}
                            onChange={(e) => updateNewPatientData('provider', e.target.value)}
                          />
                        )}
                      </>
                    )}
                  </MDBox>
                  <MDBox
                    {...containerStyles({
                      width: breakpointBreached ? '100%' : '50%',
                      padding: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    })}
                  >
                    {isAppointmentMode && (
                      <>
                        <MDTypography variant='body2' fontWeight='bold'>
                          4. Assign a Survey
                        </MDTypography>
                        <DropdownSelect
                          style={{ marginBottom: '1rem' }}
                          value={newAppointmentData.survey_type}
                          options={
                            Object.values(SurveyTypes).map((st) => ({
                              value: st,
                              handleClick: () => updateNewAppointmentData('survey_type', st),
                            }))
                          }
                        />
                      </>
                    )}
                    {!existingPatient && (
                      <>
                        <MDTypography variant='body2' fontWeight='bold'>
                          {isAppointmentMode && '5.'}
                          {' '}
                          Provider
                        </MDTypography>
                        <MDInput
                          dark
                          fullWidth
                          value={newPatientData.provider}
                          sx={{ marginBottom: '1rem' }}
                          onChange={(e) => updateNewPatientData('provider', e.target.value)}
                        />
                        {isAppointmentMode && (
                          <>
                            <MDTypography variant='body2' fontWeight='bold'>
                              6. Reason for Visit
                            </MDTypography>
                            {reasonForVisitCheckboxes}
                          </>
                        )}
                      </>
                    )}
                    {!isAppointmentMode && (
                      <>
                        <MDTypography variant='body2' fontWeight='bold'>
                          Enter Patient ID Number
                        </MDTypography>
                        <MDInput
                          dark
                          fullWidth
                          placeholder='ID'
                          type='number'
                          value={newPatientData.external_id}
                          onChange={(e) => updateNewPatientData('external_id', e.target.value)}
                        />
                      </>
                    )}
                  </MDBox>
                </>
              )}
            </MDBox>
            {isAppointmentMode && (
              <>
                <MDTypography
                  variant='body2'
                  fontWeight='bold'
                  marginBottom={breakpointBreached ? '1rem' : 0}
                >
                  Select appointment time:
                </MDTypography>
                <MDBox
                  {...containerStyles({
                    width: breakpointBreached ? '100%' : '40rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: '1rem',
                  })}
                >
                  <MDBox
                    {...containerStyles({
                      width: breakpointBreached ? '100%' : '50%',
                      padding: breakpointBreached ? '0 1rem' : '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      marginBottom: breakpointBreached ? '2rem' : '0',
                    })}
                  >
                    <MDTypography
                      variant='body2'
                      sx={{ marginBottom: '1rem', textDecoration: 'underline' }}
                    >
                      Morning:
                    </MDTypography>
                    <MDBox
                      {...containerStyles({
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      })}
                    >
                      {generateAppointmentButtons('AM')}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    {...containerStyles({
                      width: breakpointBreached ? '100%' : '50%',
                      padding: breakpointBreached ? '0 1rem' : '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      marginBottom: breakpointBreached ? '2rem' : '0',
                    })}
                  >
                    <MDTypography
                      variant='body2'
                      sx={{ marginBottom: '1rem', textDecoration: 'underline' }}
                    >
                      Afternoon:
                    </MDTypography>
                    <MDBox
                      {...containerStyles({
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      })}
                    >
                      {generateAppointmentButtons('PM')}
                    </MDBox>
                  </MDBox>
                </MDBox>
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    padding: '1rem',
                  }}
                >
                  <MDButton
                    onClick={() => {
                      setExistingPatient(null);
                      resetNewAppointmentData();
                      resetNewPatientData();
                      setScreen('modeSelect');
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '.5rem' }} />
                    Back
                  </MDButton>
                </div>
              </>
            )}
            <MDButton
              color={MUIColors.SECONDARY}
              variant='contained'
              onClick={() => handleSubmit({ isNewPatient: !existingPatient })}
              disabled={
                // TODO: abstract into a function
                requestsLoading
                  || isQuickstartMode
                  ? (
                    !existingPatient
                    && (
                      !newPatientData.first_name
                      || !newPatientData.last_name
                      || !newPatientData.date_of_birth
                      || !validateDateFormat(newPatientData.date_of_birth)
                    )
                  )
                  : isAppointmentMode
                    ? (
                      !existingPatient
                        ? !validateDateFormat(newPatientData.date_of_birth) || isIncomplete(newPatientData)
                        : isIncomplete(newAppointmentData)
                    )
                    || !newAppointmentData.appointment_date
                    || (
                      newAppointmentData.appointment_date.split('T')[1] === defaultHour
                    )
                    : !validateDateFormat(newPatientData.date_of_birth) || isIncomplete(newPatientData)
              }
            >
              {requestsLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Add'}
            </MDButton>
            {isError && (
              <MDTypography
                variant='body2'
                color={MUIColors.ERROR}
                sx={{ marginTop: '1rem' }}
              >
                Something went wrong. Please try again.
              </MDTypography>
            )}
          </>
        )}
      </MDBox>
    </Modal>
  );
};

export default PatientIntakeModal;
