import UserPool from 'services/cognitoUserPoolService';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { AxiosError } from 'axios';
import { routesMap } from 'data/routes';
import { ENDPOINT_BASE_URL } from 'hooks/constants';
import { OrgRole } from 'models/OrgModels';
import { RouteKeys } from 'models/RouteModels';
import { useState } from 'react';
import { setUnauthorizedInviteWarning } from 'services/checkUnauthorizedInviteService';
import { useAuth } from 'hooks/useAuth';
import HttpService from 'services/httpService';
import { useNavigate } from 'react-router-dom';
import { FormValues } from '../SignUpPage';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDInput from 'components/atoms/MDInput/MDInput';
import MDButton, { MDButtonVariants } from 'components/atoms/MDButton/MDButton';
import { MUIBaseColors, MUIBaseVariants, MUIColors } from 'models/StyleModels';

interface EmailCodeVerifyStepProps {
    formValues: FormValues
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    inviteEmail: string | null
    inviteId: string | null
    fromInvite: boolean
}

const EmailCodeVerifyStep: React.FC<EmailCodeVerifyStepProps> = ({
    formValues,
    handleChange,
    inviteEmail,
    inviteId,
    fromInvite
}) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [codeResent, setCodeResent] = useState(false);
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState('');

    const navigate = useNavigate();

    // Resend Verification Code
    const resendCode = () => {
        setCodeResent(true);

        const cognitoUser = new CognitoUser({ Username: fromInvite ? inviteEmail! : formValues.email, Pool: UserPool });
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            } else {
                console.log(result);
            }
        })
    };

    const {
        signIn,
        signOut,
        toggleLoadingOn: toggleAuthLoadingOn,
        toggleLoadingOff: toggleAuthLoadingOff,
    } = useAuth();

    const confirmEmail = async (event: React.FormEvent) => {
        const { email: formEmail, password } = formValues
        const email = fromInvite && inviteEmail ? inviteEmail : formEmail

        event.preventDefault();
        setRequestLoading(true);

        const cognitoUser = new CognitoUser({ Username: email, Pool: UserPool });

        cognitoUser.confirmRegistration(verificationCode, true, async (err, result) => {
            if (err) {
                setRequestLoading(false);
                setValidationErrors('Verification Failed. Please try again');
                return;
            }
            if (result === 'SUCCESS') {
                // after the user confirms their email and is successfully registered with cognito,
                // if they've signed up to a specific org via an invite...
                if (fromInvite) {
                    toggleAuthLoadingOn();

                    const authData = {
                        Username: email,
                        Password: password,
                    };

                    const authDetails = new AuthenticationDetails(authData);

                    cognitoUser.authenticateUser(authDetails, {
                        onSuccess: async () => {
                            cognitoUser.getSession(async (err: Error | null, session: any) => {
                                if (err) {
                                    toggleAuthLoadingOff();
                                    setRequestLoading(false);
                                    setValidationErrors('Something went wrong.');
                                    throw new Error(`Error getting user session: ${err.message || JSON.stringify(err)}`);
                                }

                                const token = session?.accessToken.jwtToken;

                                // we attempt to create a role for them using the invite information, with validation
                                // run under the hood in the accept-invite endpoint
                                const roleData = await HttpService.get<{ role: OrgRole }>({
                                    url: `${ENDPOINT_BASE_URL}/accept-invite/?invite_id=${inviteId}&email=${inviteEmail}`,
                                    token: token,
                                }).catch(async (e: AxiosError) => {
                                    switch (e.response?.status) {
                                        case 403:
                                            // if the crosscheck on the server does not match an actual invite on the org,
                                            // then the user is attempting to spoof the invite in order to gain unauthorized
                                            // access to an organization. if that's the case, we set the unauthorized invite
                                            // warning flag in session storage, which will cause an alert to render on the sign
                                            // in page after being redirected, and then delete the cognito user so that they
                                            // can sign up again in case this was somehow done in error
                                            console.error('invalid invite credentials');
                                            setUnauthorizedInviteWarning();
                                            cognitoUser.deleteUser(() => { });
                                            break;
                                        default:
                                            setValidationErrors('Something went wrong. Please try again.');
                                            console.error('something went wrong')
                                            break;
                                    }
                                });

                                const role = roleData?.data.role;

                                if (!role) {
                                    cognitoUser.signOut();
                                    signOut();

                                    navigate(`${routesMap[RouteKeys.SIGN_IN].route}?unauthorized=true`);
                                } else {
                                    // if the validation crosscheck passes and a new role is created on the requested org
                                    // for the user via the invite, we prompt the user to sign in with a sign up successful
                                    // message

                                    await signIn({ username: email, password })
                                }
                            });
                        },
                        onFailure: (err) => {
                            setRequestLoading(false);
                            setValidationErrors('Something went wrong.');
                            throw new Error(`Error authenticating user: ${err}`);
                        },
                    });
                } else {
                    // if the sign up was not via an invite, we don't create a role for the user on any org, and instead
                    // prompt them to sign in with a success message. users who sign in without a role on an org will be
                    // taken through the onboarding process to set up a new organization.

                    await signIn({ username: email, password })
                }
            }
        });
    };

    return (
        <>
            <MDBox textAlign='center' id='verifyAccount' component='form' role='form' onSubmit={confirmEmail}>
                {fromInvite && !validationErrors && (
                    <MDTypography sx={{ textTransform: 'unset' }} variant='button' color={MUIColors.WHITE}>
                        A verification code was sent to the email provided by your organization administrator.
                    </MDTypography>
                )}

                <>
                    <MDBox lineHeight={0} pb={3} px={3} textAlign='center'
                        display={validationErrors === '' ? 'none' : 'block'}
                    >
                        <MDTypography variant='button' color={MUIColors.ERROR}>
                            {validationErrors}
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={2} sx={{ marginTop: '2rem' }}>
                        <MDInput
                            className='verification-code'
                            label='Verification Code'
                            value={verificationCode || ''}
                            fullWidth
                            placeholder='######'
                            onChange={(event) => setVerificationCode(event.target.value)} />
                    </MDBox>
                    <MDBox mt={4} mb={1} type='submit'>
                        <MDButton
                            variant={MDButtonVariants.GRADIENT as unknown as MUIBaseVariants}
                            color={MUIColors.LIGHT as unknown as MUIBaseColors}
                            type='submit'
                            disabled={requestLoading}
                            fullWidth
                        >
                            Confirm Email
                        </MDButton>
                    </MDBox>

                    {!codeResent ?

                        <MDBox mt={3} mb={1} textAlign='center'>
                            <MDTypography variant='button' color={MUIColors.WHITE}>
                                Didn't get a code?{' '}
                                <MDButton
                                    onClick={resendCode}
                                    variant='text'
                                    sx={{ cursor: 'pointer' }}
                                >
                                    Resend
                                </MDButton>
                            </MDTypography>
                        </MDBox>

                        :

                        <MDBox lineHeight={0} px={2} mt={3} textAlign='center'>
                            <MDTypography variant='button' color={MUIColors.WHITE}>
                                Verification code sent
                            </MDTypography>
                        </MDBox>
                    }
                </>
            </MDBox>
        </>
    )
}

export default EmailCodeVerifyStep 