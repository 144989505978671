import { ENDPOINT_BASE_URL } from 'hooks/constants';
import httpService from './httpService';
import { ViewTypes, Xray } from 'models/XrayModels';
import { User } from 'models/UserModels';

// requests specifically interacting with the Accion API, formatted per their requirements

export type ViewsObject = {
  [VT in ViewTypes]?: Xray | null
};

const sendXraysForAnalysis = async (xraysByView: ViewsObject, token: string | null, user: User | null) => {
  const firstNonNullXray = Object.values(xraysByView).find((xray) => !!xray);
  if (!firstNonNullXray) return;
  await fetch(`${ENDPOINT_BASE_URL}/xrays/analyze?appointment_id=${firstNonNullXray.appointment_id}&org_id=${user?.orgs[0].org_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(xraysByView),
    keepalive: true, // this request can take some time, so we keep it alive to ensure that navigating away doesn't cut it off early
  });
};

const getRoiImage = async (sectionImgId: string, token: string | null, user: User | null) => {
  const resp = await httpService.get({
    url: `${ENDPOINT_BASE_URL}/xrays/roi-img?accion_img_id=${sectionImgId}&org_id=${user?.orgs[0].org_id}`,
    token,
  });
  return resp;
};

export {
  sendXraysForAnalysis,
  getRoiImage,
};
