import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { PRO } from 'models/SurveyModels';
import { Appointment } from 'models/AppointmentModels';
import { FullJoints } from 'models/XrayModels';
import { useMemo } from 'react';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const proQueryKeys = {
  allProsKey: (appointment: Appointment | null | undefined) => ['allProsByAppointment', { activeAppointment: appointment }],
  getAllProsByPatientKey: (patientId: string | undefined) => ['allProsByPatient', { activePatientId: patientId }],
  getProKey: (appointment: Appointment | null | undefined, proId: string | null | undefined) => ['pro', { activeAppointment: appointment, activeProId: proId }],
};

export type PROsQueryCache = {
  data: {
    pros: PRO[];
  };
  ts: number;
} | undefined;

export type PROQueryCache = {
  data: {
    pro: PRO;
  };
  ts: number;
} | undefined;

// TODO: refactor to match useAppointmentData options structure
interface UsePRODataOptions {
  appointment?: Appointment | null;
  joint?: string;
  patientId?: string;
  proId?: string | null;
  options?: { preventRefetch: boolean; };
}

export const useProData = ({
  appointment,
  joint,
  patientId,
  proId,
  options,
}: UsePRODataOptions) => {
  const { accessToken, user } = useAuth();

  const getProsByAppointment = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activeAppointment }] = queryKey;
    if (!activeAppointment) return;
    const data = await HttpService.get<{ pros: PRO[] }>({
      url: `${ENDPOINT_BASE_URL}/pros/?appointment_id=${activeAppointment.appointment_id}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };

  const getAllProsByPatient = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activePatientId }] = queryKey;
    if (!activePatientId) return;
    const data = await HttpService.get<{ pros: PRO[] }>({
      url: `${ENDPOINT_BASE_URL}/pros/from-patient/${activePatientId}?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };

  const getPro = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activeAppointment, activeProId }] = queryKey;
    if (!activeAppointment || !activeProId) return;
    const data = await HttpService.get<{ pro: PRO }>({
      url: `${ENDPOINT_BASE_URL}/pros/${activeProId}?appointment_id=${activeAppointment.appointment_id}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };
  
  const {
    data: allAppointmentProsData,
    error: allAppointmentProsError,
    isFetching: allAppointmentProsLoading,
    refetch: refetchAllAppointmentPros,
  } = useQuery(
    proQueryKeys.allProsKey(appointment),
    getProsByAppointment,
    {
      enabled: !!appointment,
      refetchOnMount: !options?.preventRefetch,
    },
  );

  const {
    data: allProsByPatientData,
    error: allProsByPatientError,
    isFetching: allProsByPatientLoading,
    refetch: refetchAllProsByPatient,
  } = useQuery(
    proQueryKeys.getAllProsByPatientKey(patientId),
    getAllProsByPatient,
    {
      refetchOnMount: !options?.preventRefetch,
      enabled: !!patientId,
    },
  );

  const {
    data: proData,
    error: proError,
    isFetching: proLoading,
    refetch: refetchPro,
  } = useQuery(
    proQueryKeys.getProKey(appointment, proId),
    getPro,
    {
      refetchOnMount: !options?.preventRefetch,
      enabled: !!appointment && !!proId,
    },
  );

  // .replace() below is a fix for how old versions of safari parse date objects.
  const allAppointmentPros = useMemo(() => allAppointmentProsData?.data.pros.sort((a, b) => (new Date(b.created_at.replace(/ /g, "T")) as any) - (new Date(a.created_at.replace(/ /g, "T")) as any)), [allAppointmentProsData]);
  const allProsByPatient = useMemo(() => allProsByPatientData?.data.pros || [], [allProsByPatientData]);

  interface PatientProsByApptIdType {
    [appointment_id: string]: PRO[];
  }

  // returns an object where each entry is an array of PROs mapped to a key of the appointment ID that those PROs belong to
  const allProsByPatientMappedToAppointmentIds = useMemo(() => allProsByPatient.reduce((obj, pro) => {
    if (obj[pro.appointment_id]) {
      obj[pro.appointment_id].push(pro);
    } else {
      obj[pro.appointment_id] = [pro];
    }
    return obj;
  }, {} as PatientProsByApptIdType), [allProsByPatient]);

  // returns an object where each entry is a PRO mapped to the FullJoint member it was filled out for as the key
  // .replace() below is a fix for how old versions of safari parse date objects.
  const latestProsMappedToJoints = useMemo(() => allAppointmentPros?.filter((pro) => !pro?.hide)
    .sort((a, b) => (new Date(b?.created_at.replace(/ /g, "T")) as any) - (new Date(a?.created_at.replace(/ /g, "T")) as any))
    .reduce((obj, pro) => {
      if (!obj[pro.joint || pro.survey.demographics.joint]) obj[pro.joint || pro.survey.demographics.joint] = pro;
      return obj;
    }, {} as { [FJ in FullJoints]?: PRO }) || {}, [allAppointmentPros]);

  const latestPro = allAppointmentPros ? allAppointmentPros[0] : null;
  const latestProByJoint = allAppointmentPros && joint ? allAppointmentPros.filter((pro) => pro.survey.demographics.joint === joint)[0] : null;

  return {
    latestPro,
    latestProByJoint,
    latestProsMappedToJoints,
    allAppointmentPros,
    allAppointmentProsError,
    allAppointmentProsLoading,
    refetchAllAppointmentPros,
    allProsByPatient,
    allProsByPatientMappedToAppointmentIds,
    allProsByPatientError,
    allProsByPatientLoading,
    refetchAllProsByPatient,
    pro: proData?.data.pro,
    proError,
    proLoading,
    refetchPro,
  };
};
