import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { NewPatient, Patient, UpdatePatient } from 'models/PatientModels';
import { PatientQueryCache, PatientsQueryCache, patientQueryKeys } from './usePatientData';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const usePatientMutations = () => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();

  const postPatient = async (newPatient: NewPatient): Promise<{ data: { patient: Patient } }> => {
    const resp = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/patients/?org_id=${user?.orgs[0].org_id}`,
      data: newPatient,
      token: accessToken,
    });
    return resp as unknown as { data: { patient: Patient } };
  };

  const patchPatient = async (patient: UpdatePatient): Promise<{ data: { patient: Patient } }> => {
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/patients/${patient.patient_id}?org_id=${user?.orgs[0].org_id}`,
      data: patient,
      token: accessToken,
    });
    return resp as unknown as { data: { patient: Patient } };
  };

  // this mutation is not being used anywhere on the app yet, but will definitely need to be inovked
  // when adding patient update functionality to the Patient Overview page. You'll need to update the
  // args passed to useMutation here to also include an object containing the onSuccess method to shovel
  // the updated patient into client state, the way that the createPatient mutation does below. you can
  // check the update function of other use{Model}Mutation hooks to see how it works.

  const {
    mutateAsync: updatePatient,
    data: updatedPatientData,
    error: updatePatientError,
    isLoading: updatePatientLoading,
    isSuccess: updatePatientSuccessful,
  } = useMutation((patient: UpdatePatient) => patchPatient(patient),
    {
      onSuccess: (resp) => {
        const updatedPatient = resp.data.patient;
        const prevPatients: PatientsQueryCache = queryClient
          .getQueryData<PatientsQueryCache>(patientQueryKeys.allPatientsKey);

        if (prevPatients) {
          queryClient.setQueryData<PatientsQueryCache>(patientQueryKeys.allPatientsKey, {
            ...prevPatients,
            data: {
              ...prevPatients.data,
              patients: prevPatients.data.patients.map((p) => { return p.patient_id === updatedPatient.patient_id ? { ...p, ...updatedPatient } : p; }),
            },
          });
        }

        const prevCurrPatient: PatientQueryCache = queryClient
          .getQueryData<PatientQueryCache>(patientQueryKeys.getSinglePatientKey(updatedPatient.patient_id));

        if (prevCurrPatient) {
          queryClient.setQueryData<PatientQueryCache>(patientQueryKeys.getSinglePatientKey(updatedPatient.patient_id), {
            ...prevCurrPatient,
            data: {
              ...prevCurrPatient.data,
              patient: {
                ...prevCurrPatient.data.patient,
                ...updatedPatient
              },
            },
          });
        }

      },
    });

  const updatedPatient = updatedPatientData?.data.patient;

  const {
    mutateAsync: createPatient,
    data: createPatientData,
    isLoading: createPatientLoading,
    isSuccess: createPatientSuccessful,
    isError: createPatientError,
  } = useMutation(
    (newPatient: NewPatient) => postPatient(newPatient),
    {
      onSuccess: (resp) => {
        const newPatient = resp.data.patient;
        const prevPatients: PatientsQueryCache = queryClient
          .getQueryData<PatientsQueryCache>(patientQueryKeys.allPatientsKey);

        if (prevPatients) {
          queryClient.setQueryData<PatientsQueryCache>(patientQueryKeys.allPatientsKey, {
            ...prevPatients,
            data: {
              ...prevPatients.data,
              patients: [newPatient, ...prevPatients.data.patients],
            },
          });
        }
      },
    },
  );

  return {
    createPatient,
    newPatient: createPatientData?.data.patient,
    createPatientLoading,
    createPatientSuccessful,
    createPatientError,
    updatePatient,
    updatedPatient,
    updatePatientError,
    updatePatientLoading,
    updatePatientSuccessful,
  };
};
