import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from '@mui/material/Container';
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import Footer from "components/molecules/Footer/Footer";
import DataTable, {
  TableColumnType,
  TableSecondaryAction,
  TableToggleAction,
} from "components/organisms/DataTable/DataTable";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import { Breakpoints, MUIColors } from 'models/StyleModels';
import PatientIntakeModal from 'components/organisms/PatientIntakeModal/PatientIntakeModal';
import Alert from 'components/atoms/Alert/Alert';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { getAppointmentTimeFromISOString } from 'helpers/helpers';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import { useBreakpoints } from 'hooks/useBreakpoints';
import colors from 'assets/theme/base/colors';
import { useNavigation } from 'hooks/useNavigation';
import { Appointment } from 'models/AppointmentModels';
import { RouteKeys } from 'models/RouteModels';
import { usePatientData } from 'hooks/usePatientData';
import { formatDateForDB, todayFormattedForISO } from 'services/dateService';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AxiosResponse } from 'axios';
import { Patient } from 'models/PatientModels';
import { useNavigate } from 'react-router-dom';
import { routesMap } from 'data/routes';
import { OrgDashboardModes } from './OrganizationDashboardPage.types';
import { useAuth } from 'hooks/useAuth';
import ActionSlugs from 'services/PermissionsService';
import "./OrganizationDashboardPage.css";
import "react-datepicker/dist/react-datepicker.css";

interface OrganizationDashboardProps {
  mode: OrgDashboardModes;
}

// the mode here is denoted by the active route. the routesMap object from the data/routes.tsx file
// is used to index each route at the top level of the app, and contains the component for each route.
// in that object you'll see that this component is supplied the corresponding route prop based upon if
// the user is on the appointment-schedule subroute or the patient-list subroute

const OrganizationDashboardPage: React.FC<OrganizationDashboardProps> = ({ mode }) => {
  const { user } = useAuth();

  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();
  const navigate = useNavigate();

  const [patientModalOpen, setPatientModalOpen] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [selectedDateISOString, setSelectedDateISOString] = useState<string>(todayFormattedForISO);

  const {
    allAppointments,
    allAppointmentsError,
    allAppointmentsLoading,
    refetchAllAppointments,
  } = useAppointmentData({ selectedDateISOString });

  const {
    allPatients,
    patientsMappedToIds,
    allPatientsLoading,
    allPatientsError,
    refetchAllPatients,
  } = usePatientData({ preventRefetch: true });

  const appointmentTableData = allAppointments?.map((appointment) => ({
    json: appointment,
    patient_name: patientsMappedToIds[appointment.patient_id]
      ? `${patientsMappedToIds[appointment.patient_id].first_name} ${patientsMappedToIds[appointment.patient_id].last_name}`
      : '-',
    date_of_birth: patientsMappedToIds[appointment.patient_id]?.date_of_birth || '-',
    id_number: patientsMappedToIds[appointment.patient_id]?.external_id || '-',
    appointment_type: <span style={{ textTransform: 'capitalize' }}>{appointment.reason_for_visit || '-'}</span>,
    physician: appointment.physician || '-',
    appointment_time: getAppointmentTimeFromISOString(appointment.appointment_date) || '-',
  })) || [];

  const appointmentColumns = [
    { Header: "Patient Name", accessor: "patient_name", width: "20%", align: "left", isSorted: false, canSort: true },
    { Header: "DOB", accessor: "date_of_birth", align: "left", isSorted: false, canSort: true },
    { Header: "ID Number", accessor: "id_number", align: "left", isSorted: false, canSort: true },
    { Header: "Appointment Time", accessor: "appointment_time", align: "left", isSorted: false, canSort: false },
    { Header: "Appointment Type", accessor: "appointment_type", align: "left", isSorted: false, canSort: false },
    { Header: "Physician", accessor: "physician", align: "left", isSorted: false, canSort: false },
  ];

  // TODO: the sorting here is a bandaid fix because default sorting in the DataTable
  // component is broken. if the existence of too many patients causes this to take too long,
  // look deeper into the setSortedValue function in DataTable.tsx. Quiet honestly the entire
  // component should probably be ripped out in favor of something like tanstack-table.
  const patientTableData = allPatients?.sort((a, b) => a.last_name.localeCompare(b.last_name)).map((patient) => ({
    json: patient,
    patient_name: `${patient.last_name}, ${patient.first_name}`,
    date_of_birth: patient?.date_of_birth || '-',
    id_number: patient?.external_id || '-',
  })) || [];

  const patientColumns = [
    { Header: "Patient Name", accessor: "patient_name", width: "20%", align: "left", isSorted: true, canSort: true },
    { Header: "DOB", accessor: "date_of_birth", width: "20%", align: "left", isSorted: false, canSort: true },
    { Header: "ID Number", accessor: "id_number", align: "left", isSorted: false, canSort: true },
  ];

  const handleShowAlert = () => {
    setTimeout(() => setShowAlert(false), 10000);
    setShowAlert(true);
  };

  const handleAppointmentRowClick = (row: { original: { json: Appointment } }) => navigateToBaseRouteWithAptmtDetails({
    routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
    appointmentId: row.original.json.appointment_id,
    appointmentDateISOString: row.original.json.appointment_date,
  });

  const handlePatientRowClick = (row: { original: { json: Patient } }) => navigate(`${routesMap[RouteKeys.PATIENT_OVERVIEW].baseRoute}/${row.original.json.patient_id}`);

  type RefetchAppointmentsFnType = <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<AxiosResponse<{
    appointments: Appointment[];
  }, any> | undefined, unknown>>;

  type RefetchPatientsFnType = <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<AxiosResponse<{
    patients: Patient[];
  }, any> | undefined, unknown>>;

  interface ModeData {
    tableData: Record<any, any>[];
    tableDataLoading: boolean;
    tableDataError: boolean;
    refetchQueryFn: RefetchAppointmentsFnType | RefetchPatientsFnType;
    columns: TableColumnType[];
    handleRowClick(args?: any): void;
    tableSecondaryAction: TableSecondaryAction | null,
    tableEmptyMsg?: string;
    header: string;
    headerAction?: React.ReactNode;
    toggleActionData: TableToggleAction;
    createSuccessAlertText: string;
  }

  // denotes which data to feed to the table based on the active mode - Appointment Schedule
  // or Patient List
  const activeModeData: { [K in OrgDashboardModes]: ModeData } = {
    [OrgDashboardModes.APPOINTMENT_SCHEDULE]: {
      tableData: appointmentTableData,
      tableDataLoading: allAppointmentsLoading,
      tableDataError: !!allAppointmentsError,
      refetchQueryFn: refetchAllAppointments,
      columns: appointmentColumns,
      handleRowClick: handleAppointmentRowClick,
      tableSecondaryAction: user?.can(ActionSlugs.CREATE$APPOINTMENT)
        ? {
          label: 'Schedule Appointment',
          action: () => setPatientModalOpen(true),
        }
        : null,
      tableEmptyMsg: 'No appointments scheduled.',
      header: 'Appointment Schedule',
      toggleActionData: {
        label: 'View Full Patient List',
        action: () => navigate(routesMap[RouteKeys.PATIENT_LIST].route),
      },
      createSuccessAlertText: 'Appointment created successfully.',
      headerAction: (
        <DatePicker
          selected={new Date(selectedDateISOString)}
          onChange={(date) => setSelectedDateISOString(date ? formatDateForDB(date) : todayFormattedForISO)}
          customInput={(
            <MDBox sx={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colors.primary.focus,
              borderRadius: '4px',
              transition: 'background-color 250ms ease',
              paddingBottom: '2px',
              '&:hover': {
                backgroundColor: colors.secondary.focus,
              },
            }}>
              <h5
                style={{
                  backgroundColor: MUIColors.WHITE,
                  borderRadius: '4px',
                  padding: '.1rem 1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: colors.primary.main,
                  width: breakpointBreached ? 'auto' : '20rem',
                }}
              >
                {Moment(selectedDateISOString).format('MMMM Do YYYY - dddd')}
              </h5>
              <FontAwesomeIcon icon={faChevronDown} color='white' />
            </MDBox>
          )}
        />
      ),
    },
    [OrgDashboardModes.PATIENT_LIST]: {
      tableData: patientTableData,
      tableDataLoading: allPatientsLoading,
      tableDataError: !!allPatientsError,
      refetchQueryFn: refetchAllPatients,
      columns: patientColumns,
      createSuccessAlertText: 'Patient successfully added.',
      handleRowClick: handlePatientRowClick,
      header: 'Patient List',
      tableSecondaryAction: user?.can(ActionSlugs.CREATE$PATIENT)
        ? {
          label: 'Add Patient',
          action: () => setPatientModalOpen(true),
        }
        : null,
      tableEmptyMsg: 'No patients found.',
      toggleActionData: {
        label: 'View Appointment Schedule',
        action: () => navigate(routesMap[RouteKeys.APPOINTMENT_SCHEDULE].route),
      },
    },
  };

  const activeData = activeModeData[mode];

  return (
    <>
      <Alert show={showAlert} handleClose={() => setShowAlert(false)}>
        {activeData.createSuccessAlertText}
      </Alert>
      {patientModalOpen && (
        <PatientIntakeModal
          selectedDateISOString={selectedDateISOString}
          closeModal={() => setPatientModalOpen(false)}
          handleShowAlert={handleShowAlert}
          mode={mode}
        />
      )}
      <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
        <MDAppBar />
        <MDBox pb={3}>
          <Grid item xs={12} pt={2}>
            <Card>
              <MDBox
                mx={1}
                mt={-3}
                py={2}
                px={3}
                bgColor={MUIColors.SECONDARY}
                borderRadius="lg"
                coloredShadow="secondary"
                display="flex"
                flexDirection={breakpointBreached ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                height={breakpointBreached ? "fit-content" : "5rem"}
              >
                <MDTypography
                  sx={{ marginBottom: breakpointBreached ? '1rem' : '0' }}
                  variant="h5"
                  color={MUIColors.WHITE}
                >
                  {activeData.header}
                </MDTypography>
                {activeData.headerAction && (
                  <div style={{
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                    {activeData.headerAction}
                  </div>
                )}
              </MDBox>
              <MDBox pt={2}>
                <DataTable
                  table={{ columns: activeData.columns, rows: activeData.tableData }}
                  canSearch={true}
                  isSorted={[true, true, true, false, false, false]}
                  showTotalEntries={!activeData.tableDataLoading && !activeData.tableDataError}
                  pagination={{ variant: "contained", color: "secondary" }}
                  sx={{ padding: "0 2.25rem", float: "left" }}
                  dataLoading={allAppointmentsLoading || allPatientsLoading}
                  dataError={!!allAppointmentsError || !!allPatientsError}
                  handleRetry={activeData.refetchQueryFn}
                  handleRowClick={activeData.handleRowClick}
                  secondaryAction={activeData.tableSecondaryAction}
                  emptyMessage={activeData.tableEmptyMsg}
                  toggle={activeData.toggleActionData}
                />
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </Container>
    </>
  );
};

export default OrganizationDashboardPage;
