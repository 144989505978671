
// @mui material components
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";

interface MDBoxRootProps {
  theme?: any,
  ownerState: any,
}

export default styled(Avatar)(({ theme, ownerState }: MDBoxRootProps) => {
  const { functions, typography, boxShadows } = theme;
  const { shadow, size } = ownerState;

  const { pxToRem } = functions;
  const { size: fontSize, fontWeightRegular } = typography;

  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: fontSize.xs,
      };
      break;
    case "sm":
      sizeValue = {
        width: pxToRem(36),
        height: pxToRem(36),
        fontSize: fontSize.sm,
      };
      break;
    case "lg":
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: fontSize.sm,
      };
      break;
    case "xl":
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: fontSize.md,
      };
      break;
    case "xxl":
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: fontSize.md,
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: fontSize.md,
      };
    }
  }

  return {
    background: 'none',
    color: colors.secondary.main,
    fontWeight: fontWeightRegular,
    boxShadow: boxShadows[shadow],
    ...sizeValue,
  };
});
