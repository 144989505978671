import { Card } from "@mui/material"
import MDBox from "components/atoms/MDBox/MDBox"

// Images
import bgImage from 'assets/images/stock/stretching.png';
import { ReactComponent as Logo } from '../../../assets/images/logo/JointAILogo2023.svg';
import BasicLayout from "components/templates/BasicLayout/BasicLayout";
import { useState } from "react";
import ClinicNameStep from "./steps/ClinicNameStep";
import ClinicInfoStep from "./steps/ClinicInfoStep";
import GetStartedStep from "./steps/GetStartedStep";
import OnboardingCompleteStep from "./steps/OnboardingCompleteStep";
import OrgLogoStep from "./steps/OrgLogoStep";
import { useNavigate } from "react-router-dom";
import { routesMap } from "data/routes";
import { RouteKeys } from "models/RouteModels";
import useForm from "hooks/useForm";
import colors from "assets/theme/base/colors";
import { useAuth } from "hooks/useAuth";

enum Steps {
    GetStartedStep = 0,
    ClinicNameStep = 1,
    ClinicInfoStep = 2,
    OrgLogoStep = 3,
    OnboardingCompleteStep = 4,
}

export type FormValues = {
    clinicName: string;

    clinicSize: string;
    clinicEmail: string;
    clinicPhone: string;
    clinicCountry: string;
    clinicState: string;
    clinicCity: string;
}

const OnboardingPage: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(
        !user?.orgs.length
            ? Steps.GetStartedStep
            : Steps.OnboardingCompleteStep);

    const {
        values,
        handleChange,
        setFormValue,
    } = useForm<FormValues>({
        clinicName: '',
        clinicEmail: '',
        clinicPhone: '',
        clinicSize: '',
        clinicCountry: '',
        clinicState: '',
        clinicCity: '',
    });

    const renderStep = () => {
        switch (currentStep) {
            case Steps.GetStartedStep:
                return <GetStartedStep
                    next={() => setCurrentStep(Steps.ClinicNameStep)}
                    back={() => { navigate(routesMap[RouteKeys.SIGN_UP].route) }} />
            case Steps.ClinicNameStep:
                return <ClinicNameStep
                    formValues={values}
                    handleChange={handleChange}
                    next={() => setCurrentStep(Steps.ClinicInfoStep)}
                    back={() => setCurrentStep(Steps.GetStartedStep)} />
            case Steps.ClinicInfoStep:
                return <ClinicInfoStep
                    formValues={values}
                    handleChange={handleChange}
                    setFormValue={setFormValue}
                    next={() => setCurrentStep(Steps.OrgLogoStep)}
                    back={() => setCurrentStep(Steps.ClinicNameStep)} />
            case Steps.OrgLogoStep:
                return <OrgLogoStep
                    next={() => setCurrentStep(Steps.OnboardingCompleteStep)} />
            case Steps.OnboardingCompleteStep:
                return <OnboardingCompleteStep />
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card style={{
                backgroundColor: '#D6E2EF',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: "translate(-50%, -50%)",
                minWidth: '350px',
                maxWidth: '425px',
                overflowY: 'auto',
                maxHeight: '85vh',
            }}>
                <MDBox p={3}>
                    <Logo style={{ height: 'fit-content', width: '100%' }} />
                </MDBox>

                <MDBox py={3} px={2}>

                    {user ? renderStep() : 'loading...'}

                    <MDBox display="flex" gap="1rem" justifyContent='center' mt="2rem">
                        {![Steps.OnboardingCompleteStep].includes(currentStep) && (
                            Object.values(Steps)
                                .filter((val) =>
                                    typeof val === 'number'
                                    && ![
                                        Steps.OnboardingCompleteStep
                                    ].includes(val)
                                )
                                .map((step, i) => (
                                    <div key={`breadcrumb-${i}`}
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '100px',
                                            backgroundColor: colors.text.main,
                                            opacity: i === currentStep ? 1 : .4,
                                        }}>
                                    </div>
                                ))
                        )}
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    )
}

export default OnboardingPage 