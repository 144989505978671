import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { OrgRole } from 'models/OrgModels';
import { DBUser, UserWithRole } from 'models/UserModels';
import { useOrgRoleData } from './useOrgRoleData';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const userQueryKeys = {
  userById: (userId: string) => ['usersById', { userId: userId }],
  usersWithRoles: (orgRoles: OrgRole[]) => ['usersWithRoles', { roles: orgRoles }],
};

export type UserQueryCache = {
  user: DBUser;
  ts: number;
} | undefined;

interface UseUserDataOptions {
  preventRefetch?: boolean;
}

export const useUserData = (options?: UseUserDataOptions) => {
  const { accessToken, user } = useAuth();
  const { orgRolesFromOrg } = useOrgRoleData(options);

  const getUsersWithRoles = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { roles }] = queryKey;
    if (roles.length === 0) return;
    const usersWithRolesResp = await HttpService.post<UserWithRole[]>({
      url: `${ENDPOINT_BASE_URL}/users/with-roles?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
      data: roles,
    });
    return usersWithRolesResp.data;
  };

  const {
    data: usersWithRoles,
    error: usersWithRolesError,
    isFetching: usersWithRolesLoading,
    refetch: refetchUsersWithRoles,
  } = useQuery(
    userQueryKeys.usersWithRoles(orgRolesFromOrg || []),
    getUsersWithRoles,
    {
      enabled: !!orgRolesFromOrg,
      refetchOnMount: !options?.preventRefetch,
    },
  );


  const getUserById = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { userId }] = queryKey;
    if (!userId) return;
    const usersWithRolesResp = await HttpService.get<{ user: DBUser }>({
      url: `${ENDPOINT_BASE_URL}/users/${userId}?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });

    return usersWithRolesResp.data;
  };

  const {
    data: userById,
    error: userByIdError,
    isFetching: userByIdLoading,
    refetch: refetchUserById,
  } = useQuery(
    userQueryKeys.userById(user!.details.sub as string),
    getUserById,
    {
      enabled: !!user?.details.sub,
      refetchOnMount: !options?.preventRefetch,
    },
  );

  return {
    usersWithRoles: usersWithRoles,
    usersWithRolesError,
    usersWithRolesLoading,
    refetchUsersWithRoles,
    userById,
    userByIdError,
    userByIdLoading,
    refetchUserById
  };
};
