import React from 'react';
import Grid from "@mui/material/Grid";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDBox from "components/atoms/MDBox/MDBox";
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { useBreakpoints } from 'hooks/useBreakpoints';

interface HeaderItems {
  headline: string;
  subItems?: { [key: string]: string }[];
  secondaryContent?: React.ReactNode;
  pageTitle?: string;
}

interface PatientDashboardHeaderProps {
  headerItems: HeaderItems;
}

const PatientDashboardHeader: React.FC<PatientDashboardHeaderProps> = ({ headerItems }) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  return (
    <MDBox
      mx={1}
      mt={-3}
      py={2.5}
      px={2}
      bgColor={MUIColors.SECONDARY}
      borderRadius="lg"
      coloredShadow="secondary"
      alignItems={"center"}
      justifyContent={breakpointBreached ? 'center' : 'center'}
      sx={{
        display: 'grid',
        gridTemplate: breakpointBreached
          ? `"pageTitle" "headline" "secondaryContent" / 1fr`
          : `"headline pageTitle secondaryContent" / 1fr 1fr 1fr`
      }}


    >
      {headerItems.pageTitle && (
        <MDTypography
          variant='h2'
          color={MUIColors.WHITE}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            gridArea: "pageTitle",
          }}
        >
          {headerItems.pageTitle}
        </MDTypography>
      )}
      <Grid
        container
        spacing={2}
        justifyContent={breakpointBreached ? 'center' : 'left'}
        sx={{ gridArea: 'headline' }}>
        <Grid item xs={12} lg={9}>
          {/* TODO: whitespace nowrap is a quickfix and will break with longer names */}
          <MDTypography whiteSpace="nowrap" variant="h5" lineHeight="1.5" color={MUIColors.WHITE} textAlign={breakpointBreached ? 'center' : 'left'}>
            {headerItems.headline}
          </MDTypography>
          {headerItems.subItems?.map((item) => {
            const [label, value] = Object.entries(item)[0];
            return (
              <MDTypography
                whiteSpace="nowrap"
                key={label}
                variant="h6"
                lineHeight="1"
                color={MUIColors.WHITE}
                fontWeight="light"
                textAlign={breakpointBreached ? 'center' : 'left'}
              >
                <span style={{ fontWeight: '400' }}>{label}:</span> {value || 'Pending'}
              </MDTypography>
            )
          })}
        </Grid>
      </Grid>
      {
        headerItems.secondaryContent && (
          <div style={{
            marginTop: breakpointBreached ? '1rem' : 0,
            gridArea: 'secondaryContent',
            display: 'flex',
            justifyContent: breakpointBreached ? 'center' : 'flex-end',
          }}>
            {headerItems.secondaryContent}
          </div>
        )
      }
    </MDBox >
  );
};

export default PatientDashboardHeader;
