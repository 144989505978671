import React from 'react';
import {
  DegenerationLevels,
  JointSections,
  ViewTypes,
  compartmentOrder,
  normalizedDegenerationLevels,
  normalizedJointSections,
  normalizedViewTypes,
} from 'models/XrayModels';
import MDBox from 'components/atoms/MDBox/MDBox';
import { containerStyles, getDegenColor, getWorstCaseDegeneration } from 'helpers/helpers';
import colors from 'assets/theme/base/colors';
import { MUIColors, Shadows } from 'models/StyleModels';
import { Card, Tooltip, Typography } from '@mui/material';
import { CardCover } from '@mui/joy';
import { faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDButton from 'components/atoms/MDButton/MDButton';
import { loadingAnimation } from 'assets/animations/animationStyles';

interface TriageXrayDetailCardLoaderProps {
  displayVertical: boolean;
}

export const TriageXrayDetailCardLoader: React.FC<TriageXrayDetailCardLoaderProps> = ({ displayVertical }) => ((
  <MDBox
    shadow={Shadows.MEDIUM}
    {...containerStyles({
      width: '100%',
      maxWidth: '25rem',
      justifySelf: 'center',
      minHeight: '13rem',
      flexDirection: displayVertical ? 'column' : 'row',
      padding: '1rem',
      borderRadius: '8px',
      position: 'relative',
      ...loadingAnimation('backgroundColor'),
    })}
  >
    {displayVertical && (
      <MDBox
        sx={{
          width: '60%',
          height: '2rem',
          borderRadius: '4px',
          marginBottom: '.5rem',
          ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
        }}
      />
    )}
    <Card {...containerStyles({
      width: '100%',
      height: displayVertical ? '15rem' : '100%',
      borderRadius: '8px',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: displayVertical ? 0 : '.5rem',
      marginBottom: displayVertical ? '.5rem' : 0,
      ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
    })} />
    <MDBox
      {...containerStyles({
        width: '100%',
        height: displayVertical ? 'fit-content' : '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      })}
    >
      {!displayVertical && (
        <MDBox
          sx={{
            width: '60%',
            height: '1.5rem',
            borderRadius: '4px',
            ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
          }}
        />
      )}
      <MDBox
        sx={{
          width: '100%',
          height: '2.75rem',
          borderRadius: '8px',
          ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
        }}
      />
    </MDBox>
  </MDBox>
));

interface TriageXrayDetailCardProps {
  imgSrc: string | undefined;
  imgLoading: boolean;
  label: string;
  compartmentOutcomes: { [JS in JointSections]?: DegenerationLevels };
  handleClickReview(): void;
  confirmed: boolean;
  displayVertical?: boolean;
}

const XrayDetailCard: React.FC<TriageXrayDetailCardProps> = ({
  imgSrc,
  imgLoading,
  label,
  compartmentOutcomes,
  handleClickReview,
  confirmed,
  displayVertical,
}) => {
  return (
    <MDBox
      shadow={Shadows.MEDIUM}
      {...containerStyles({
        width: '100%',
        justifySelf: 'center',
        minHeight: '13rem',
        border: `3px solid ${getDegenColor(getWorstCaseDegeneration(Object.values(compartmentOutcomes)))}`,
        padding: '1rem',
        borderRadius: '8px',
        backgroundColor: colors.grey[100],
        position: 'relative',
        display: 'grid',
        gap: '1rem',
      })}
    >
      {confirmed && (
        <Tooltip
          title={<MDTypography sx={{ fontSize: '13px' }} color={MUIColors.WHITE}>Analysis confirmed</MDTypography>}
          placement='right'
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color={colors.success.focus}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '.5rem',
            }}
          />
        </Tooltip>
      )}

      {displayVertical && (
        <MDTypography variant='h5' textTransform='capitalize' sx={{ marginBottom: '.5rem' }}>
          {label}
        </MDTypography>
      )}

      <MDBox display="grid" style={{ gridTemplateColumns: displayVertical ? '1fr' : '1fr 1fr' }} gap="1rem;">
        <div>
          <Card {...containerStyles({
            width: displayVertical ? '100%' : '9rem',
            height: displayVertical ? 'auto' : '9.2rem',
            aspectRatio: '1 / 1',
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: colors.grey[300],
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: displayVertical ? 0 : '.5rem',
            marginBottom: displayVertical ? '.5rem' : 0,
          })}>
            {!imgLoading && imgSrc && (
              <CardCover>
                <img src={imgSrc} alt="X-ray" />
              </CardCover>
            )}
            {imgLoading && <FontAwesomeIcon icon={faCircleNotch} color={colors.grey[500]} spin />}
          </Card>
        </div>

        <div>
          <MDBox
            {...containerStyles({
              width: '100%',
              height: displayVertical ? 'fit-content' : '100%',
              flexDirection: 'column',
            })}
          >
            {!displayVertical && (
              <MDTypography sx={{ color: colors.grey[800] }} variant='h5' textTransform='capitalize'>
                {label}
              </MDTypography>
            )}
            <MDBox sx={{ marginBottom: '.25rem', gap: '1rem', display: 'grid', gridTemplateColumns: displayVertical ? '1fr 1fr' : '1fr' }}>
              {Object.entries(compartmentOutcomes)
                .sort(([compartmentA], [compartmentB]) => (
                  compartmentOrder[normalizedJointSections[compartmentA as JointSections]] - compartmentOrder[normalizedJointSections[compartmentB as JointSections]]
                ))
                .map(([compartment, degenLvl], i, a) => (
                  a.length === 1
                    ? (
                      <MDTypography
                        key={compartment}
                        fontWeight='bold'
                        variant='body2'
                        textTransform='capitalize'
                        sx={{
                          color: getDegenColor(degenLvl),
                          marginBottom: displayVertical ? '.5rem' : 0,
                        }}
                      >
                        {normalizedDegenerationLevels[degenLvl]}
                      </MDTypography>
                    )
                    : (
                      <div key={compartment} style={{ marginBottom: displayVertical ? '.5rem' : 0 }}>
                        <MDTypography variant='body2' textTransform='capitalize'>
                          {normalizedJointSections[compartment as JointSections]}:
                        </MDTypography>
                        <MDTypography
                          variant='body2'
                          fontWeight='bold'
                          textTransform='capitalize'
                          sx={{
                            lineHeight: '8px',
                            color: getDegenColor(degenLvl),
                            marginBottom: i === a.length - 1 ? 0 : '.5rem',
                          }}
                        >
                          {normalizedDegenerationLevels[degenLvl]}
                        </MDTypography>
                      </div>
                    )
                ))
              }
            </MDBox>
          </MDBox>
        </div>
      </MDBox>
      <MDButton
        onClick={handleClickReview}
        variant='outlined'
        fullWidth
        color={MUIColors.SECONDARY}
        style={{ fontSize: '.8rem' }}
      >
        Review compartments
      </MDButton>
    </MDBox>
  )
};

export interface ITriageMissingXrayDetailCardProps {
  displayVertical: boolean;
  viewLabel: string;
  viewKey: string;
}

export const TriageMissingXrayDetailCard: React.FC<ITriageMissingXrayDetailCardProps> = ({ displayVertical, viewLabel, viewKey }) => (
  <MDBox
    shadow={Shadows.MEDIUM}
    {...containerStyles({
      width: '100%',

      justifySelf: 'center',
      minHeight: '13rem',
      border: `3px solid`,
      padding: '1rem',
      borderRadius: '8px',
      backgroundColor: colors.grey[100],
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: displayVertical ? '1fr' : '1fr 1fr',
      gap: '1rem',
    })}
  >

    <div style={{
      border: `3px dashed ${colors.error.main}`,
      borderRadius: '8px',
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '.2rem',
      width: displayVertical ? '100%' : '9rem',
      height: displayVertical ? 'auto' : '9.2rem',
      aspectRatio: '1 / 1',
    }}>
      <Typography color={colors.error.main} textAlign='center'>MISSING</Typography>
      <Typography color={colors.error.main} textAlign='center' variant='body2'>{viewLabel}</Typography>
    </div>


    <div>
      <div>
        <MDTypography sx={{ color: colors.grey[800] }} variant='h5' textTransform='capitalize'>
          {normalizedViewTypes[viewKey as ViewTypes]}
        </MDTypography>
      </div>


      <MDBox display="grid" gridTemplateColumns={displayVertical ? '1fr 1fr' : '1fr'}>
        <div>
          <MDTypography variant='body2' textTransform='capitalize'>
            Medial:
          </MDTypography>
        </div>
        <div>
          <MDTypography variant='body2' textTransform='capitalize'>
            Lateral:
          </MDTypography>
        </div>
      </MDBox>
    </div>
  </MDBox >
);

export default XrayDetailCard;
