/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";
import Button from '@mui/material/Button';
// Custom styles for MDButton
import MDButtonRoot from "components/atoms/MDButton/MDButtonRoot";

import { ButtonProps } from "@mui/material";

// Material Dashboard 2 React contexts
import { GreyValues, MUIColors } from "models/StyleModels";

export enum MDButtonVariants {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  GRADIENT = 'gradient'
}

export type MDButtonProps = {
  circular?: boolean;
  iconOnly?: boolean;
  variant?: MDButtonVariants;
  color?: MUIColors | GreyValues;
} & ButtonProps;

const MDButton: React.FC<MDButtonProps> = forwardRef<HTMLButtonElement, MDButtonProps>(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => (
    <MDButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
      sx={{ fontWeight:"normal" }}
    >
      {children}
    </MDButtonRoot>
  )
);

export default MDButton as typeof Button;
