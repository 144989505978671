import React from 'react';
import { containerStyles } from "helpers/helpers";
import colors from 'assets/theme/base/colors';
import MDBox from "components/atoms/MDBox/MDBox";
import { useDropzone } from 'react-dropzone';
import { Card } from '@mui/material';
import { CardCover } from '@mui/joy';
import { Org } from 'models/OrgModels';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { useOrgMutations } from 'hooks/useOrgMutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface XrayUploadPortalProps {
  org: Org | undefined;
  handleSuccess?(): void;
  handleError?(): void;
}

const XrayUploadPortal: React.FC<XrayUploadPortalProps> = ({
  org,
  handleSuccess,
  handleError,
}) => {
  const {
    uploadLogo,
    uploadLogoLoading,
  } = useOrgMutations();

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDropAccepted: async (acceptedFiles) => {
      if (!org) throw new Error('Error gathering org details');
      await uploadLogo({ file: acceptedFiles[0], org }).catch(() => {
        if (handleError) handleError();
      });
      if (handleSuccess) handleSuccess();
    },
  });

  return (
    <MDBox {...containerStyles({ flexDirection: 'column', width: 'fit-content', cursor: 'pointer' })}>
      <Card {...containerStyles({
        width: '20rem',
        height: '20rem',
        aspectRatio: '1 / 1',
        border: uploadLogoLoading
          ? '3px solid transparent'
          : isDragAccept
            ? `3px solid ${colors.success.main}`
            : isDragReject
              ? `3px solid ${colors.error.main}`
              : '3px solid transparent',
        backgroundColor: colors.grey[200],
        objectFit: 'cover',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '10px',
        position: 'relative',
        '&:hover': {
          border: `3px solid ${colors.info.main}`
        }
      })}
        {...getRootProps()}
      >
        {!org?.logo_url && !uploadLogoLoading && <input {...getInputProps()} />}
        {uploadLogoLoading && (
          <MDBox {...containerStyles({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: colors.grey[600],
            opacity: .75,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          })}>
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              color={colors.grey[800]}
              size="4x"
            />
          </MDBox>
        )}
        <div>
          {org?.logo_url && (
            <CardCover>
              <img src={org.logo_url} alt={`${org.name} Logo`} />
            </CardCover>
          )}
          {!org?.logo_url && (
            <MDTypography sx={{ color: colors.grey[600] }}>
              Drag & Drop or click to browse
            </MDTypography>
          )}
        </div>
      </Card>
    </MDBox>
  );
};

export default XrayUploadPortal;
