import colors from "assets/theme/base/colors";
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { containerStyles, getXrayViewTypeValidationStatus } from "helpers/helpers";
import { Shadows, MUIColors } from "models/StyleModels";
import { FullJoints, ViewTypes, Xray, ViewTypeData } from "models/XrayModels";
import { CSSProperties } from "react";

interface ElementProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

export const Column: React.FC<ElementProps> = ({ children, style }) => (
  <MDBox {...containerStyles({
    display: 'flex',
    flexDirection: 'column',
    padding: '.5rem',
    width: '50%',
    ...style,
  })}>
    {children}
  </MDBox>
);

export const ColumnContent: React.FC<ElementProps> = ({ children, style }) => (
  <MDBox {...containerStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    ...style,
  })}>
    {children}
  </MDBox>
);

export const Surface: React.FC<ElementProps> = ({ children, style }) => (
  <MDBox
    shadow={Shadows.MEDIUM}
    sx={{
      width: '100%',
      borderRadius: '8px',
      backgroundColor: colors.grey[100],
      padding: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      ...style,
    }}
  >
    {children}
  </MDBox>
);

export const DataCardHeader: React.FC<ElementProps> = ({ children, style }) => (
  <MDTypography
    variant='h3'
    color={MUIColors.SECONDARY}
    sx={{ marginBottom: '.5rem', ...style }}
  >
    {children}
  </MDTypography>
);

const col1Width = '30%';
const col2Width = '50%';
const col3Width = '20%';
const colPadding = '.1rem';

interface DataRowHeaderProps {
  col1Header: string;
  col2Header: string;
}

export const DataRowHeader: React.FC<DataRowHeaderProps> = ({ col1Header, col2Header }) => (
  <MDBox {...containerStyles({ flexDirection: 'row', borderBottom: `1px solid ${colors.grey[500]}`, marginBottom: '.5rem' })}>
    <MDBox {...containerStyles({ flexDirection: 'column', width: col1Width })}>
      <MDTypography sx={{ paddingRight: colPadding }} fontWeight='bold' variant='body2'>
        {col1Header}
      </MDTypography>
    </MDBox>
    <MDBox {...containerStyles({ flexDirection: 'column', width: col2Width })}>
      <MDTypography sx={{ paddingRight: colPadding }} fontWeight='bold' variant='body2'>
        {col2Header}
      </MDTypography>
    </MDBox>
    <MDBox {...containerStyles({ flexDirection: 'column', width: col3Width })} />
  </MDBox>
);

export interface ProRowItem {
  type: 'pro';
  id: string,
  joint: FullJoints,
  status: string,
  action: React.ReactNode,
}

export type UploadedXraysMappedToViewsObjType = {
  [VT in ViewTypes]?: Xray;
};

export interface XrayRowItem {
  type: 'xray';
  id: string,
  seriesType: string,
  requiredViews: { [VT in ViewTypes]?: ViewTypeData<VT> },
  uploadedXraysMappedToViewTypes: UploadedXraysMappedToViewsObjType;
  action: React.ReactNode | null,
}

interface StaticRowItem {
  type: 'static',
  content: React.ReactNode;
}

type RowItem = ProRowItem | XrayRowItem | StaticRowItem;

interface DataRowProps {
  item: RowItem;
  isFirst?: boolean;
  isLast?: boolean;
  showRowDivider?: boolean;
  style?: CSSProperties;
}

export const DataRow: React.FC<DataRowProps> = ({
  style,
  item,
  isFirst,
  isLast,
  showRowDivider,
}) => (
  <MDBox {...containerStyles({
    flexDirection: 'row',
    marginTop: isFirst ? '0' : '.1rem',
    marginBottom: isLast ? '0' : showRowDivider ? '.5rem' : '.1rem',
    paddingBottom: isLast ? '0' : showRowDivider ? '.5rem' : '0',
    borderBottom: !isLast && showRowDivider ? `1px solid ${colors.grey[300]}` : 'unset',
    gap: '1rem',
    ...style,
  })}>
    {
      item.type === 'static'
        ? <>{item.content}</>
        : (
          <>
            <MDBox {...containerStyles({ flexDirection: 'column', width: col1Width })}>
              <MDTypography sx={{ paddingRight: colPadding }} textTransform='capitalize' variant='body2'>
                {item.type === 'pro' ? item.joint : item.seriesType}
              </MDTypography>
            </MDBox>
            <MDBox {...containerStyles({ flexDirection: 'column', width: col2Width })}>
              <MDBox sx={{ paddingRight: colPadding }}>
                {item.type === 'pro' && (
                  <MDTypography color={item.status === 'Complete' ? MUIColors.SUCCESS : item.status === 'Not Started' ? MUIColors.ERROR : MUIColors.WARNING} variant='body2'>
                    {item.status}
                  </MDTypography>
                )}
                {item.type === 'xray' && Object.entries(item.requiredViews).map(([vt, data], idx, arr) => (
                  <div
                    key={`${item.id}-${vt}`}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingRight: `calc(${colPadding} * 3)`,
                      borderBottom: idx === arr.length - 1 ? 'none' : `1px dashed ${colors.grey[300]}`,
                    }}
                  >
                    <MDTypography
                      sx={{ paddingRight: colPadding }}
                      textTransform='capitalize'
                      variant='body2'
                    >
                      {data.label}
                    </MDTypography>
                    <MDTypography
                      textAlign='right'
                      textTransform='capitalize'
                      variant='body2'
                      color={
                        item.uploadedXraysMappedToViewTypes[vt as ViewTypes]
                          ? getXrayViewTypeValidationStatus({ xray: item.uploadedXraysMappedToViewTypes[vt as ViewTypes], viewTypeDataObj: data })
                            ? MUIColors.SUCCESS
                            : data.isOptional ? MUIColors.DULL : MUIColors.WARNING
                          : !data.isOptional ? MUIColors.ERROR : MUIColors.DULL
                      }
                    >
                      {
                        item.uploadedXraysMappedToViewTypes[vt as ViewTypes]
                          ? getXrayViewTypeValidationStatus({ xray: item.uploadedXraysMappedToViewTypes[vt as ViewTypes], viewTypeDataObj: data })
                            ? 'X-ray assigned'
                            : data.isOptional ? 'Optional' : 'View type mismatch'
                          : data.isOptional
                            ? 'Optional'
                            : 'Required'
                      }
                    </MDTypography>
                  </div>
                ))}
              </MDBox>
            </MDBox>
            <MDBox {...containerStyles({
              display: 'grid',
              flexDirection: 'column',
              width: col3Width,
              alignSelf: 'center'
            })}>
              {item.action}
            </MDBox>
          </>
        )
    }
  </MDBox>
);
