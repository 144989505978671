import { useMutation } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { NewOrgInvite, OrgInvite } from 'models/OrgModels';
import { User } from 'models/UserModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const postInvite = async (invite: NewOrgInvite, token: string | null, user: User | null): Promise<{ data: { invite: OrgInvite } }> => {
  const newInviteResp = await HttpService.post<{ invite: OrgInvite }>({
    url: `${ENDPOINT_BASE_URL}/invites/?org_id=${user?.orgs[0].org_id}`,
    data: invite,
    token,
  });
  return newInviteResp;
};

export const useInviteMutations = () => {
  const { accessToken, user } = useAuth();

  const {
    mutate: createInvite,
    data: newInviteResp,
    error: createInviteError,
    isLoading: createInviteLoading,
    isSuccess: createInviteSuccessful,
  } = useMutation((invite: NewOrgInvite) => postInvite(invite, accessToken, user));

  return {
    createInvite,
    newOrg: newInviteResp?.data.invite,
    createInviteError,
    createInviteLoading,
    createInviteSuccessful,
  };
};
