import { FullJoints, ViewTypes, ViewTypeData, ExamTypes } from "models/XrayModels";

export type ReqViewsByJointType = {
  [FJ in FullJoints]: {
    [VT in ViewTypes]?: ViewTypeData<VT>;
  };
};

// this object is used to access the required view types for an exam
// given the joints present. it is used by the isMissingXrayData helper function
export const reqViewsByJoint: ReqViewsByJointType = {
  [FullJoints.LEFT_KNEE]: {
    [ViewTypes.FLEX]: {
      key: ViewTypes.FLEX,
      label: 'Flexion',
      value: 'ap_knee', // the field here represents how Accion labels the view type
    },
    [ViewTypes.NON_FLEX]: {
      key: ViewTypes.NON_FLEX,
      label: 'Non-Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.KNEECAP]: {
      key: ViewTypes.KNEECAP,
      label: 'Left Kneecap',
      value: ['unilateral_kneecap', 'bilateral_kneecap'],
    },
  },
  [FullJoints.RIGHT_KNEE]: {
    [ViewTypes.FLEX]: {
      key: ViewTypes.FLEX,
      label: 'Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.NON_FLEX]: {
      key: ViewTypes.NON_FLEX,
      label: 'Non-Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.KNEECAP]: {
      key: ViewTypes.KNEECAP,
      label: 'Right Kneecap',
      value: ['unilateral_kneecap', 'bilateral_kneecap'],
    },
  },
  [FullJoints.LEFT_HIP]: {
    [ViewTypes.AP_PELVIS]: {
      key: ViewTypes.AP_PELVIS,
      label: 'AP Pelvis',
      value: 'ap_pelvis',
    },
    [ViewTypes.LEFT_FROG]: {
      key: ViewTypes.LEFT_FROG,
      label: 'Left Lateral',
      value: 'left_frog',
    },
  },
  [FullJoints.RIGHT_HIP]: {
    [ViewTypes.AP_PELVIS]: {
      key: ViewTypes.AP_PELVIS,
      label: 'AP Pelvis',
      value: 'ap_pelvis',
    },
    [ViewTypes.RIGHT_FROG]: {
      key: ViewTypes.RIGHT_FROG,
      label: 'Right Lateral',
      value: 'right_frog',
    },
  },
};

export type ReqViewsByExamType = {
  [ET in ExamTypes]: {
    [VT in ViewTypes]?: ViewTypeData<VT>;
  };
};

// this object is used to access the required xray view types given an exam type. it is
// used on the Appointment Overview Page as well as by the getMissingXraysByActiveExams
// helper function
export const reqViewsByExam: ReqViewsByExamType = {
  [ExamTypes.SINGLE_KNEE_RIGHT]: {
    [ViewTypes.FLEX]: {
      key: ViewTypes.FLEX,
      label: 'PA Flexion',
      value: 'ap_knee', // the field here represents how Accion labels the view type
    },
    [ViewTypes.NON_FLEX]: {
      key: ViewTypes.NON_FLEX,
      label: 'PA No Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.KNEECAP]: {
      key: ViewTypes.KNEECAP,
      label: 'Right Kneecap',
      value: ['unilateral_kneecap'],
    },
    [ViewTypes.RIGHT_LATERAL_KNEE]: {
      key: ViewTypes.RIGHT_LATERAL_KNEE,
      label: 'Right Lateral',
      value: 'lateral_knee',
      isOptional: true,
    },
  },
  [ExamTypes.SINGLE_KNEE_LEFT]: {
    [ViewTypes.FLEX]: {
      key: ViewTypes.FLEX,
      label: 'PA Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.NON_FLEX]: {
      key: ViewTypes.NON_FLEX,
      label: 'PA No Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.KNEECAP]: {
      key: ViewTypes.KNEECAP,
      label: 'Left Kneecap',
      value: ['unilateral_kneecap'],
    },
    [ViewTypes.LEFT_LATERAL_KNEE]: {
      key: ViewTypes.LEFT_LATERAL_KNEE,
      label: 'Left Lateral',
      value: 'lateral_knee',
      isOptional: true,
    },
  },
  [ExamTypes.BILAT_KNEE]: {
    [ViewTypes.FLEX]: {
      key: ViewTypes.FLEX,
      label: 'PA Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.NON_FLEX]: {
      key: ViewTypes.NON_FLEX,
      label: 'PA No Flexion',
      value: 'ap_knee',
    },
    [ViewTypes.KNEECAP]: {
      key: ViewTypes.KNEECAP,
      label: 'Bilateral Kneecap',
      value: ['bilateral_kneecap'],
    },
    [ViewTypes.LEFT_LATERAL_KNEE]: {
      key: ViewTypes.LEFT_LATERAL_KNEE,
      label: 'Left Lateral',
      value: 'lateral_knee',
      isOptional: true,
    },
    [ViewTypes.RIGHT_LATERAL_KNEE]: {
      key: ViewTypes.RIGHT_LATERAL_KNEE,
      label: 'Right Lateral',
      value: 'lateral_knee',
      isOptional: true,
    },
  },
  [ExamTypes.SINGLE_HIP_RIGHT]: {
    [ViewTypes.AP_PELVIS]: {
      key: ViewTypes.AP_PELVIS,
      label: 'AP Pelvis',
      value: 'ap_pelvis',
    },
    [ViewTypes.RIGHT_FROG]: {
      key: ViewTypes.RIGHT_FROG,
      label: 'Right Lateral',
      value: 'right_frog',
    },
  },
  [ExamTypes.SINGLE_HIP_LEFT]: {
    [ViewTypes.AP_PELVIS]: {
      key: ViewTypes.AP_PELVIS,
      label: 'AP Pelvis',
      value: 'ap_pelvis',
    },
    [ViewTypes.LEFT_FROG]: {
      key: ViewTypes.LEFT_FROG,
      label: 'Left Lateral',
      value: 'left_frog',
    },
  },
  [ExamTypes.BILAT_HIP]: {
    [ViewTypes.AP_PELVIS]: {
      key: ViewTypes.AP_PELVIS,
      label: 'AP Pelvis',
      value: 'ap_pelvis',
    },
    [ViewTypes.LEFT_FROG]: {
      key: ViewTypes.LEFT_FROG,
      label: 'Left Lateral',
      value: 'left_frog',
    },
    [ViewTypes.RIGHT_FROG]: {
      key: ViewTypes.RIGHT_FROG,
      label: 'Right Lateral',
      value: 'right_frog',
    },
  },
};

type FullJointsByExamTypesType = {
  [ET in ExamTypes]: {
    [FJ in FullJoints | string]?: FullJoints | string;
  };
};

// this object is used to confirm which FullJoint values are present in a given exam type. it is
// used on the Appointment Overview Page as well as by the getMissingXraysByActiveExams helper function
export const fullJointsByExamTypes: FullJointsByExamTypesType = {
  [ExamTypes.SINGLE_KNEE_RIGHT]: {
    [FullJoints.RIGHT_KNEE]: FullJoints.RIGHT_KNEE,
    'bilateral knee': 'bilateral knee',
  },
  [ExamTypes.SINGLE_KNEE_LEFT]: {
    [FullJoints.LEFT_KNEE]: FullJoints.LEFT_KNEE,
    'bilateral knee': 'bilateral knee',
  },
  [ExamTypes.BILAT_KNEE]: {
    [FullJoints.RIGHT_KNEE]: FullJoints.RIGHT_KNEE,
    [FullJoints.LEFT_KNEE]: FullJoints.LEFT_KNEE,
    'bilateral knee': 'bilateral knee',
  },
  [ExamTypes.SINGLE_HIP_RIGHT]: {
    [FullJoints.RIGHT_HIP]: FullJoints.RIGHT_HIP,
    'bilateral hip': 'bilateral hip',
  },
  [ExamTypes.SINGLE_HIP_LEFT]: {
    [FullJoints.LEFT_HIP]: FullJoints.LEFT_HIP,
    'bilateral hip': 'bilateral hip',
  },
  [ExamTypes.BILAT_HIP]: {
    [FullJoints.RIGHT_HIP]: FullJoints.RIGHT_HIP,
    [FullJoints.LEFT_HIP]: FullJoints.LEFT_HIP,
    'bilateral hip': 'bilateral hip',
  },
};

export enum ViewTypeValues {
  // view types that need joint or other label
  AP_KNEE = 'ap_knee',
  UNILATERAL_KNEECAP = 'unilateral_kneecap',
  LATERAL_KNEE = 'lateral_knee',

  // view types that don't need extra annotation
  BILATERAL_KNEE = 'bilateral_knee',
  BILATERAL_KNEECAP = 'bilateral_kneecap',
  AP_PELVIS = 'ap_pelvis',
  LEFT_FROG = 'left_frog',
  RIGHT_FROG = 'right_frog',
}