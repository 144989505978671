import colors from "assets/theme/base/colors"
import MDBox from "components/atoms/MDBox/MDBox"
import MDButton from "components/atoms/MDButton/MDButton"

interface ConfirmLinkOrgStepProps {
    affirmative: () => void
    negative: () => void
}

const ConfirmLinkOrgStep: React.FC<ConfirmLinkOrgStepProps> = ({
    affirmative,
    negative,
}) => {
    return (
        <MDBox display="grid" justifyContent="center">
            <div style={{
                marginBottom: "2rem",
                maxWidth: "15rem",
                fontSize: "1.4rem",
                textAlign: "center"
            }}>
                <span style={{ color: colors.white.main, fontWeight: 400, }}>
                    You have been invited to join an organization on JointDX. Continue to join jointDX with your organization.
                </span>
            </div>
            <MDBox display="grid" marginTop="1.5rem">
                <MDButton
                    variant='contained'
                    style={{ color: colors.text.main }}
                    onClick={affirmative}>
                    Continue
                </MDButton>
            </MDBox>
        </MDBox>
    )
}

export default ConfirmLinkOrgStep 