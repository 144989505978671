import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Portal: React.FC<PortalProps> = ({ children, ...props }) => {
  const portalElement = document.getElementById('portal');

  const ele = (
    <div
      {...props}
      style={{
        ...props.style,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '50000000000',
        width:'100vw',
        height:'fit-content',
        overflow:'hidden',
      }}
    >
      {children}
    </div>
  );

  return portalElement
    ? ReactDOM.createPortal(ele, portalElement) : null;
};

export default Portal;
