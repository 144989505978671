import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox/MDBox";
import TriageDataTable, {
  TableColumnType,
  TableSecondaryAction,
} from "components/organisms/TriageDataTable/TriageDataTable";
import { MUIColors } from 'models/StyleModels';
import Alert from 'components/atoms/Alert/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'assets/theme/base/colors';
import { usePatientData } from 'hooks/usePatientData';
import { Patient } from 'models/PatientModels';
import { useAuth } from 'hooks/useAuth';
import ActionSlugs from 'services/PermissionsService';
import "./TriageListPage.css";
import "react-datepicker/dist/react-datepicker.css";
import AddPatientModal from 'components/organisms/AddPatientModal/AddPatientModal';
import MDButton from 'components/atoms/MDButton/MDButton';
import { faEye, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RouteKeys } from 'models/RouteModels';
import { TriageAppointmentStatuses, useAppointmentData } from 'hooks/useAppointmentData';
import { useNavigation } from 'hooks/useNavigation';
import Spinner from 'components/molecules/Spinner/Spinner';
import { Typography } from '@mui/material';
import { User } from 'models/UserModels';
import { TriageProStatus, TriageXrayStatus } from 'models/AppointmentModels';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AxiosResponse } from 'axios';
import Moment from 'moment';
import TriageFlowLayout from 'components/templates/TriageFlowLayout/TriageFlowLayout';
import { useOrgData } from 'hooks/useOrgData';
import { Org } from 'models/OrgModels';
import { NameCellValue } from './TriagePage.styles';


interface ITriageListContentProps {
  allPatients: Patient[];
  triageAppointmentStatuses: TriageAppointmentStatuses;
  user: User;
  org: Org;
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<AxiosResponse<{
    appointmentStatuses: TriageAppointmentStatuses;
  }, any> | undefined, unknown>>
}

const TriageListContent: React.FC<ITriageListContentProps> = ({
  allPatients,
  triageAppointmentStatuses,
  user,
  org,
  refetch
}) => {
  const [addPatientModalOpen, setAddPatientModalOpen] = useState<boolean>(false);
  const [editPatientModalOpen, setEditPatientModalOpen] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const formatXrayStatus = (status: TriageXrayStatus) => {
    const commonStyles = { fontSize: '1rem' }
    switch (status) {
      case TriageXrayStatus.NO_XRAYS:
        return <Typography style={{ color: colors.error.main, ...commonStyles }}>No X-Rays</Typography>
      case TriageXrayStatus.XRAYS_MISSING:
        return <Typography style={{ color: colors.error.main, ...commonStyles }}>X-Rays Missing</Typography>
      case TriageXrayStatus.READY_FOR_REVIEW:
        return <Typography style={{ color: colors.warning.main, ...commonStyles }}>Ready for Review</Typography>
      case TriageXrayStatus.COMPLETED:
        return <Typography style={{ color: colors.success.main, ...commonStyles }}>Completed</Typography>
      case TriageXrayStatus.ANALYZING:
        return <Typography style={{ color: colors.warning.main, ...commonStyles }}>Analyzing...</Typography>
      case TriageXrayStatus.ANALYSIS_FAILED:
        return <Typography style={{ color: colors.error.main, ...commonStyles }}>Analysis Failed</Typography>
      default:
        return <Typography style={{ color: colors.error.main, ...commonStyles }}>No X-Rays</Typography>
    }
  }

  const formatPROStatus = (status: TriageProStatus) => {
    const commonStyles = { fontSize: '1rem' }
    switch (status) {
      case TriageProStatus.COMPLETED:
        return <Typography style={{ color: colors.success.main, ...commonStyles }}>Complete</Typography>
      default:
        return <Typography style={{ color: colors.error.main, ...commonStyles }}>Incomplete</Typography>
    }
  }

  const patientTableData = allPatients?.sort((a, b) => (new Date(b.created_at.replace(/ /g, "T")) as any) - (new Date(a.created_at.replace(/ /g, "T")) as any)).map((patient) => ({
    json: patient,
    patient_name: `${patient.last_name}, ${patient.first_name}`,
    id_number: patient?.external_id || '-',
    data: 'x-ray',
    status: TriageXrayStatus.NO_XRAYS,
  })) || [];

  const patientColumns = [
    {
      Header: "Created At", accessor: "created_at", width: "10%", align: "left",
      Cell: ({ row }: any) => { return <div>{Moment(new Date(row.original.json.created_at.replace(/ /g, "T"))).format('MM/DD/YYYY')}</div> },
      isSorted: false,
      canSort: false,
    },
    {
      Header: "Patient Name",
      accessor: "patient_name",
      width: "20%",
      align: "left",
      isSorted: false,
      canSort: false,
      Cell: ({ row }: any) => {
        const isDisabled = !triageAppointmentStatuses[row.original.json.patient_id];
        return <NameCellValue
          disabled={isDisabled}
          onClick={() => {
            if (triageAppointmentStatuses[row.original.json.patient_id]) {
              setSelectedPatient(row.original.json)
              setEditPatientModalOpen(true)
            }
          }}>{row.values.patient_name}</NameCellValue>
      }
    },
    {
      Header: "ID Number", accessor: "id_number", align: "left", isSorted: false, canSort: false,
    },
    {
      Header: "Data", accessor: "data", align: "left",
      Cell: () => (
        <MDBox display="grid" gap="1rem" gridAutoRows="2.5rem 2.5rem" alignItems="center">
          <MDBox>
            X-Rays
          </MDBox >

          {org.use_pros && <MDBox>
            PROs
          </MDBox >}
        </MDBox >
      )
    },
    {
      Header: "Status", accessor: "status", align: "left",
      Cell: ({ row }: any) => (
        <MDBox display="grid" gap="1rem" gridAutoRows="2.5rem 2.5rem" alignItems="center">
          <MDBox style={{ color: colors.error.main }}>
            {formatXrayStatus(triageAppointmentStatuses[row.original.json.patient_id]?.xray_status)}
          </MDBox >

          {org.use_pros &&
            <MDBox style={{ color: colors.error.main }}>
              {formatPROStatus(triageAppointmentStatuses[row.original.json.patient_id]?.pro_status)}
            </MDBox >
          }
        </MDBox >)
    },
    {
      Header: "", accessor: "a", align: "right",
      Cell: ({ row }: any) => {
        const appointmentStatus = triageAppointmentStatuses[row.original.json.patient_id];
        return (<MDBox display="grid" gap="1rem" gridAutoRows="2.5rem 2.5rem">
          <div>
            {appointmentStatus?.xray_status && appointmentStatus?.xray_status !== TriageXrayStatus.NO_XRAYS && (<MDButton
              variant='outlined'
              onClick={() => { }}
              color={MUIColors.SECONDARY}
              onClickCapture={(e) => {
                e.preventDefault(); e.stopPropagation()

                navigateToBaseRouteWithAptmtDetails({
                  routeKey: RouteKeys.MATCH_XRAYS,
                  appointmentId: appointmentStatus.appointment_id!,
                  appointmentDateISOString: appointmentStatus.appointment_date!,
                })
              }}
            >
              <FontAwesomeIcon color={colors.secondary.main} icon={faPen} style={{ marginRight: '10px' }} />
              Edit
            </MDButton>)}
          </div>

          {org.use_pros &&
            <div>
              <MDButton
                variant='outlined'
                onClick={() => { }}
                color={MUIColors.SECONDARY}

                onClickCapture={(e) => {
                  e.preventDefault(); e.stopPropagation()

                  navigateToBaseRouteWithAptmtDetails({
                    routeKey: RouteKeys.TRIAGE_SURVEY,
                    appointmentId: appointmentStatus.appointment_id,
                    appointmentDateISOString: appointmentStatus.appointment_date,
                    addtlQueryParams: {
                      view: 'desktop',
                    },
                  })
                }}
              >
                <FontAwesomeIcon color={colors.secondary.main} icon={faPen} style={{ marginRight: '10px' }} />
                Edit
              </MDButton>
            </div>
          }

        </MDBox>)
      }
    },
    {
      Header: "", accessor: "b", align: "right", Cell: ({ row }: any) => {
        const appointmentStatus = triageAppointmentStatuses[row.original.json.patient_id];

        if (!appointmentStatus) {
          return null
        }

        if (appointmentStatus.xray_status === TriageXrayStatus.NO_XRAYS || appointmentStatus.xray_status === TriageXrayStatus.XRAYS_MISSING) {
          return (<MDButton
            variant='contained'
            onClick={(e) => {
              e.preventDefault(); e.stopPropagation()

              navigateToBaseRouteWithAptmtDetails({
                routeKey: RouteKeys.BATCH_XRAY_UPLOAD,
                appointmentId: appointmentStatus.appointment_id,
                appointmentDateISOString: appointmentStatus.appointment_date,
              })

            }}
            color={MUIColors.SECONDARY}
          >
            <FontAwesomeIcon color={colors.white.focus} icon={faPlus} style={{ marginRight: '10px' }} />
            Add
          </MDButton>
          )
        }

        if (appointmentStatus?.xray_status === TriageXrayStatus.READY_FOR_REVIEW || appointmentStatus?.xray_status === TriageXrayStatus.COMPLETED) {
          return (
            <MDButton
              variant='contained'
              onClick={() => {
                navigateToBaseRouteWithAptmtDetails({
                  routeKey: RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS,
                  appointmentId: appointmentStatus.appointment_id,
                  appointmentDateISOString: appointmentStatus.appointment_date,
                })
              }}
              color={MUIColors.SECONDARY}
            >
              <FontAwesomeIcon color={colors.white.focus} icon={faEye} style={{ marginRight: '10px' }} />
              Exam Room
            </MDButton>
          )
        }

        return null
      }
    },
  ];

  interface ModeData {
    tableData: Record<any, any>[];
    columns: TableColumnType[];
    handleRowClick(args?: any): void;
    tableSecondaryAction: TableSecondaryAction | null,
    header: string;
    headerAction?: React.ReactNode;
    createSuccessAlertText: string;
  }

  const activeData: ModeData = {
    tableData: patientTableData,
    columns: patientColumns,
    createSuccessAlertText: 'Patient successfully added.',
    handleRowClick: (row) => { },
    header: 'Triage List',
    tableSecondaryAction: user?.can(ActionSlugs.CREATE$PATIENT)
      ? {
        label: 'Add Patient',
        action: () => setAddPatientModalOpen(true),
      }
      : null
  }

  return (
    <>
      <Alert show={showAlert} handleClose={() => setShowAlert(false)}>
        {activeData.createSuccessAlertText}
      </Alert>

      {addPatientModalOpen && (
        <AddPatientModal
          onSuccess={async () => {
            setAddPatientModalOpen(false)
          }}
          closeModal={() => setAddPatientModalOpen(false)} />
      )}

      {editPatientModalOpen && (
        <AddPatientModal
          isEdit
          onSuccess={async () => {
            await refetch()
            setEditPatientModalOpen(false)
          }}
          appointmentDate={triageAppointmentStatuses[selectedPatient?.patient_id!]?.appointment_date}
          appointmentId={triageAppointmentStatuses[selectedPatient?.patient_id!]?.appointment_id}
          patient={selectedPatient}
          closeModal={() => setEditPatientModalOpen(false)} />
      )}

      <TriageFlowLayout title="Triage List" singlePatient={null}>
        <MDBox pb={3}>
          <Grid item xs={12} pt={2}>

            <TriageDataTable
              table={{ columns: activeData.columns, rows: activeData.tableData }}
              canSearch={true}
              isSorted={[false, false, false, true, false, false]}
              pagination={{ variant: "contained", color: "secondary" }}
              sx={{ padding: "0 2.25rem", float: "left" }}
              handleRowClick={activeData.handleRowClick}
              secondaryAction={activeData.tableSecondaryAction}
            />
          </Grid>
        </MDBox>
      </TriageFlowLayout >
    </>
  );
};

const BatchXrayUploadFallback: React.FC = () => {
  return (
    <TriageFlowLayout title="Triage List" singlePatient={null}>
      <MDBox>
        <Spinner />
      </MDBox>
    </TriageFlowLayout>
  )
}

const TriageListPage: React.FC = () => {
  const [appointmentsHaveLoaded, setAppointmentsHaveLoaded] = useState<boolean>(false);
  const { user } = useAuth();
  const { org } = useOrgData();
  const {
    allPatients,
    allPatientsLoading,
  } = usePatientData({ preventRefetch: true });

  const {
    triageAppointmentStatuses,
    triageAppointmentStatusesLoading,
    refetchTriageAppointmentStatuses
  } = useAppointmentData({ patientIds: allPatients?.map((p) => p.patient_id), polling: true });

  useEffect(() => {
    if (!appointmentsHaveLoaded && Object.keys(triageAppointmentStatuses).length > 0) {
      setAppointmentsHaveLoaded(true)
    }
  }, [appointmentsHaveLoaded, triageAppointmentStatuses])

  return (
    !(allPatientsLoading
      || (!appointmentsHaveLoaded && triageAppointmentStatusesLoading)
    ) &&
    allPatients && triageAppointmentStatuses && user && org

  ) ? <TriageListContent
    refetch={refetchTriageAppointmentStatuses}
    allPatients={allPatients}
    triageAppointmentStatuses={triageAppointmentStatuses}
    user={user}
    org={org}
  /> : <BatchXrayUploadFallback />
}

export default TriageListPage;
