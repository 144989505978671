import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ENDPOINT_BASE_URL } from './constants';
import { generateQueryKeyFromAppt } from 'helpers/helpers';
import { useAuth } from './useAuth';
import HttpService from 'services/httpService';
import { Appointment } from 'models/AppointmentModels';
import { FullJoints, ViewTypes, Xray } from 'models/XrayModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const xrayQueryKeys = {
  allXraysKey: (appointment: Appointment) => generateQueryKeyFromAppt('allXrays', appointment),
  getAllXraysByPatientKey: (patientId: string | undefined) => ['allXraysByPatient', { activePatientId: patientId }],
};

export type XraysQueryCache = {
  data: {
    xrays: Xray[];
  };
  ts: number;
} | undefined;

// TODO: refactor to match useAppointmentData options structure

interface UseXrayDataOptions {
  appointment?: Appointment | null;
  patientId?: string;
  options?: { preventRefetch: boolean; };
}

export const useXrayData = ({
  appointment,
  patientId,
  options,
}: UseXrayDataOptions) => {
  const { accessToken, user } = useAuth();

  const getXraysByAppointment = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activeAppointment }] = queryKey;
    if (!activeAppointment) return;
    const resp = await HttpService.get<{ xrays: Xray[] }>({
      url: `${ENDPOINT_BASE_URL}/xrays/?appointment_id=${activeAppointment.appointment_id}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return resp;
  };

  const getAllXraysByPatient = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activePatientId }] = queryKey;
    if (!activePatientId) return;
    const data = await HttpService.get<{ xrays: Xray[] }>({
      url: `${ENDPOINT_BASE_URL}/xrays/from-patient/${activePatientId}?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };

  const {
    data: allAppointmentXraysData,
    error: allAppointmentXraysError,
    isFetching: allAppointmentXraysLoading,
    refetch: refetchAllAppointmentXrays,
  } = useQuery(
    xrayQueryKeys.allXraysKey(appointment!),
    getXraysByAppointment,
    {
      enabled: !!appointment,
      refetchOnMount: !options?.preventRefetch
    },
  );

  const {
    data: allXraysByPatientData,
    error: allXraysByPatientError,
    isFetching: allXraysByPatientLoading,
    refetch: refetchAllXraysByPatient,
  } = useQuery(
    xrayQueryKeys.getAllXraysByPatientKey(patientId),
    getAllXraysByPatient,
    {
      refetchOnMount: !options?.preventRefetch,
      enabled: !!patientId,
    },
  );

  const allAppointmentXrays = useMemo(() => allAppointmentXraysData?.data.xrays.sort((a, b) => (new Date(b.created_at) as any) - (new Date(a.created_at) as any)), [allAppointmentXraysData]);
  const evaluatedXrays = useMemo(() => allAppointmentXraysData?.data.xrays.filter((xray) => xray.prediction_results), [allAppointmentXraysData]);

  const allXraysByPatient = useMemo(() => allXraysByPatientData?.data.xrays || [], [allXraysByPatientData]);
  interface PatientXraysByApptIdType {
    [appointment_id: string]: Xray[];
  }

  // generates an object where each entry is an array of xrays belonging to the given patient
  // mapped to a key of the appointment id that those xrays belong to.
  const allXraysByPatientMappedToAppointmentIds = useMemo(() => allXraysByPatient.reduce((obj, xray) => {
    if (obj[xray.appointment_id]) {
      obj[xray.appointment_id].push(xray);
    } else {
      obj[xray.appointment_id] = [xray];
    }
    return obj;
  }, {} as PatientXraysByApptIdType), [allXraysByPatient]);

  // generates an object where each entry is an array of xrays mapped to the FullJoint for which they
  // are for
  const xraysMappedToJoints = useMemo(() => allAppointmentXrays?.filter((xray) => !!xray.prediction_results)
    .reduce((obj, xray) => {
      const hasRightKneeSection = !!xray.prediction_results.sections.find((sect) => sect.knee === 'right')
        || xray.view_type_results.viewType === 'bilateral_kneecap'
        || (xray.joint === FullJoints.RIGHT_KNEE && xray.view_type_results.viewType === 'unilateral_kneecap')
        || (xray.joint === FullJoints.RIGHT_KNEE && xray.client_provided_view_type === ViewTypes.KNEECAP);
      const hasLeftKneeSection = !!xray.prediction_results.sections.find((sect) => sect.knee === 'left')
        || xray.view_type_results.viewType === 'bilateral_kneecap'
        || (xray.joint === FullJoints.LEFT_KNEE && xray.view_type_results.viewType === 'unilateral_kneecap')
        || (xray.joint === FullJoints.LEFT_KNEE && xray.client_provided_view_type === ViewTypes.KNEECAP);
      const hasRightHipSection = !!xray.prediction_results.sections.find((sect) => sect.hip === 'right');
      const hasLeftHipSection = !!xray.prediction_results.sections.find((sect) => sect.hip === 'left');

      if (hasRightKneeSection) {
        if (!obj[FullJoints.RIGHT_KNEE]) {
          obj[FullJoints.RIGHT_KNEE] = [xray];
        } else {
          obj[FullJoints.RIGHT_KNEE] = [...obj[FullJoints.RIGHT_KNEE], xray];
        }
      }

      if (hasLeftKneeSection) {
        if (!obj[FullJoints.LEFT_KNEE]) {
          obj[FullJoints.LEFT_KNEE] = [xray];
        } else {
          obj[FullJoints.LEFT_KNEE] = [...obj[FullJoints.LEFT_KNEE], xray];
        }
      }

      if (hasRightHipSection) {
        if (!obj[FullJoints.RIGHT_HIP]) {
          obj[FullJoints.RIGHT_HIP] = [xray];
        } else {
          obj[FullJoints.RIGHT_HIP] = [...obj[FullJoints.RIGHT_HIP], xray];
        }
      }

      if (hasLeftHipSection) {
        if (!obj[FullJoints.LEFT_HIP]) {
          obj[FullJoints.LEFT_HIP] = [xray];
        } else {
          obj[FullJoints.LEFT_HIP] = [...obj[FullJoints.LEFT_HIP], xray];
        }
      }

      return obj;
    }, {} as { [FJ in FullJoints]?: Xray[] }) || {}, [allAppointmentXrays]);

  const xrayRequestsLoading = allAppointmentXraysLoading || allXraysByPatientLoading;

  return {
    evaluatedXrays,
    xraysMappedToJoints,
    allAppointmentXrays,
    allAppointmentXraysError,
    allAppointmentXraysLoading,
    refetchAllAppointmentXrays,
    allXraysByPatient,
    allXraysByPatientMappedToAppointmentIds,
    allXraysByPatientError,
    allXraysByPatientLoading,
    refetchAllXraysByPatient,
    xrayRequestsLoading,
  };
};
