import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, Typography } from "@mui/material"
import borders from "assets/theme/base/borders"
import colors from "assets/theme/base/colors"
import MDBox from "components/atoms/MDBox/MDBox"
import MDButton from "components/atoms/MDButton/MDButton"
import MDInput from "components/atoms/MDInput/MDInput"
import Spinner from "components/molecules/Spinner/Spinner"
import { containerStyles, snakeToTitleCase } from "helpers/helpers"
import { useAppointmentData } from "hooks/useAppointmentData"
import { useAppointmentMutations } from "hooks/useAppointmentMutations"
import { useAuth } from "hooks/useAuth"
import { useBreakpoints } from "hooks/useBreakpoints"
import useForm from "hooks/useForm"
import { useOrgData } from "hooks/useOrgData"
import { usePatientMutations } from "hooks/usePatientMutations"
import { Appointment } from "models/AppointmentModels"
import { Patient } from "models/PatientModels"
import { Breakpoints, MUIColors, Shadows } from "models/StyleModels"
import { FullJoints } from "models/XrayModels"
import { getCurrentISOTimestamp } from "services/dateService"

export enum FormKeys {
    PATIENT_FIRST_NAME = 'PATIENT_FIRST_NAME',
    PATIENT_LAST_NAME = 'PATIENT_LAST_NAME',
    PATIENT_ID_NUMBER = 'PATIENT_ID_NUMBER',
    RIGHT_KNEE = 'RIGHT_KNEE',
    LEFT_KNEE = 'LEFT_KNEE',
    RIGHT_HIP = 'RIGHT_HIP',
    LEFT_HIP = 'LEFT_HIP'
}

export type FormValues = {
    [FormKeys.PATIENT_FIRST_NAME]: string;
    [FormKeys.PATIENT_LAST_NAME]: string;
    [FormKeys.PATIENT_ID_NUMBER]: string;
    [FormKeys.RIGHT_KNEE]: boolean;
    [FormKeys.LEFT_KNEE]: boolean;
    [FormKeys.RIGHT_HIP]: boolean;
    [FormKeys.LEFT_HIP]: boolean;
}

interface AddPatientModalContentProps {
    closeModal(): void;
    onSuccess(): void;
    isEdit?: boolean;
    patient?: Patient | null;
    appointment?: Appointment | null;
}

const Content: React.FC<AddPatientModalContentProps> = ({ closeModal, onSuccess, isEdit, patient, appointment }) => {
    const { user } = useAuth();

    const {
        org,
    } = useOrgData({ preventRefetch: true });

    const {
        createPatient,
        createPatientError,
        createPatientLoading,
        updatePatient,
        updatePatientError,
        updatePatientLoading,
    } = usePatientMutations();

    const {
        createAppointment,
        updateAppointment,
        createAppointmentError,
        createAppointmentLoading,
        updateAppointmentError,
        updateAppointmentLoading,
    } = useAppointmentMutations();

    const {
        values: formValues,
        handleChange,
        setFormValue,
    } = useForm<FormValues>({
        [FormKeys.PATIENT_FIRST_NAME]: patient?.first_name || '',
        [FormKeys.PATIENT_LAST_NAME]: patient?.last_name || '',
        [FormKeys.PATIENT_ID_NUMBER]: patient?.external_id || '',
        [FormKeys.RIGHT_KNEE]: isEdit && appointment?.joints ? appointment.joints[FullJoints.RIGHT_KNEE] === 1 : false,
        [FormKeys.LEFT_KNEE]: isEdit && appointment?.joints ? appointment.joints[FullJoints.LEFT_KNEE] === 1 : false,
        [FormKeys.RIGHT_HIP]: isEdit && appointment?.joints ? appointment.joints[FullJoints.RIGHT_HIP] === 1 : false,
        [FormKeys.LEFT_HIP]: isEdit && appointment?.joints ? appointment.joints[FullJoints.LEFT_HIP] === 1 : false,
    });

    const createPatientHandler = async () => {
        try {
            const resp = await createPatient({
                org_id: org!.org_id,
                first_name: formValues[FormKeys.PATIENT_FIRST_NAME],
                last_name: formValues[FormKeys.PATIENT_LAST_NAME],
                external_id: formValues[FormKeys.PATIENT_ID_NUMBER],
                // TODO: determine whether this is actually a required input
                date_of_birth: ''
            });

            const newPatient = resp.data.patient;
            await createAppointment({
                patient_id: newPatient.patient_id,
                appointment_date: getCurrentISOTimestamp(),
                org_id: user!.orgs[0].org_id,
                joints: {
                    ...(formValues[FormKeys.RIGHT_KNEE] ? { [FullJoints.RIGHT_KNEE]: 1 } : null),
                    ...(formValues[FormKeys.LEFT_KNEE] ? { [FullJoints.LEFT_KNEE]: 1 } : null),
                    ...(formValues[FormKeys.RIGHT_HIP] ? { [FullJoints.RIGHT_HIP]: 1 } : null),
                    ...(formValues[FormKeys.LEFT_HIP] ? { [FullJoints.LEFT_HIP]: 1 } : null),
                },
                isTriage: true
            });

            onSuccess()

        } catch (e) {
            console.error(`Something went wrong: ${e}`);
        }
    }

    const updatePatientHandler = async () => {

        if (!patient || !appointment) {
            return
        }

        try {
            await updatePatient({
                org_id: org!.org_id,
                patient_id: patient.patient_id,
                first_name: formValues[FormKeys.PATIENT_FIRST_NAME],
                last_name: formValues[FormKeys.PATIENT_LAST_NAME],
                external_id: formValues[FormKeys.PATIENT_ID_NUMBER],
            });

            await updateAppointment({
                appointmentDate: appointment.appointment_date,
                appointmentId: appointment.appointment_id,
                newValues: {
                    joints: {
                        ...(formValues[FormKeys.RIGHT_KNEE] ? { [FullJoints.RIGHT_KNEE]: 1 } : null),
                        ...(formValues[FormKeys.LEFT_KNEE] ? { [FullJoints.LEFT_KNEE]: 1 } : null),
                        ...(formValues[FormKeys.RIGHT_HIP] ? { [FullJoints.RIGHT_HIP]: 1 } : null),
                        ...(formValues[FormKeys.LEFT_HIP] ? { [FullJoints.LEFT_HIP]: 1 } : null),
                    },
                }
            });

            onSuccess()
        }
        catch (e) {

            console.error(`Something went wrong: ${e}`);
        }
    }

    return (
        <>
            <Typography style={{ color: colors.text.focus, textAlign: 'center' }} fontWeight={700} fontSize={"1.8rem"} >{isEdit ? 'Edit' : 'Enter'} Patient Information</Typography>

            {(createPatientError || updatePatientError || updateAppointmentError || createAppointmentError) && <div style={{ marginBottom: "1rem" }}>
                <span style={{ textAlign: 'center', display: 'block', color: colors.error.focus, fontWeight: 400, fontSize: '1rem' }}>
                    Something went wrong. Please try again.
                </span>
            </div>}

            <FontAwesomeIcon
                icon={faX}
                color={colors.primary.main}
                cursor='pointer'
                onClick={closeModal}
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '1rem',
                }}
            />

            <MDBox display='grid' gridTemplateColumns="1fr 1fr" gap="1rem">
                <MDInput
                    dark
                    label='First name'
                    name={FormKeys.PATIENT_FIRST_NAME}
                    value={formValues[FormKeys.PATIENT_FIRST_NAME]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '.50rem' }}
                />
                <MDInput
                    dark
                    label='Last Name'
                    name={FormKeys.PATIENT_LAST_NAME}
                    value={formValues[FormKeys.PATIENT_LAST_NAME]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '.50rem' }}
                />
                <MDInput
                    dark
                    fullWidth
                    placeholder='ID'
                    name={FormKeys.PATIENT_ID_NUMBER}
                    type='number'
                    value={formValues[FormKeys.PATIENT_ID_NUMBER]}
                    onChange={handleChange}
                />
                <MDBox display='flex' flexDirection='column'>
                    <Typography style={{ color: colors.text.focus, paddingBottom: '.5rem' }} fontWeight={600} fontSize={'1rem'}> Select Joint with X-Rays </Typography>
                    {[
                        FormKeys.RIGHT_KNEE,
                        FormKeys.LEFT_KNEE,
                        FormKeys.RIGHT_HIP,
                        FormKeys.LEFT_HIP,
                    ].map(key => (
                        <label key={key}>
                            <div style={{ display: "flex" }}>
                                <input
                                    style={{ marginRight: "1rem" }}
                                    type="checkbox"
                                    name={key}
                                    checked={!!formValues[key]}
                                    onChange={() => setFormValue(key, !formValues[key])}
                                />
                                <Typography fontWeight={600} fontSize={'1rem'}> {snakeToTitleCase(key)}</Typography>
                            </div>
                        </label>
                    ))}
                </MDBox>
            </MDBox>

            <MDButton
                color={MUIColors.SECONDARY}
                variant='contained'
                disabled={
                    createPatientLoading || updatePatientLoading || createAppointmentLoading || updateAppointmentLoading ||
                    !formValues[FormKeys.PATIENT_FIRST_NAME] ||
                    !formValues[FormKeys.PATIENT_LAST_NAME] ||
                    !formValues[FormKeys.PATIENT_ID_NUMBER] ||
                    (
                        !formValues[FormKeys.RIGHT_KNEE] &&
                        !formValues[FormKeys.LEFT_KNEE] &&
                        !formValues[FormKeys.RIGHT_HIP] &&
                        !formValues[FormKeys.LEFT_HIP]
                    )
                }
                onClick={async () => {
                    isEdit ? updatePatientHandler() : createPatientHandler()
                }}
            >
                {isEdit ? 'Save' : 'Create'}
            </MDButton>
        </>
    )
}

const ContentFallback: React.FC<AddPatientModalContentProps> = ({ closeModal, onSuccess, isEdit }) => {
    return (<>
        <Typography style={{ color: colors.text.focus }} fontWeight={700} fontSize={"1.8rem"}>Edit Patient Information</Typography>

        <FontAwesomeIcon
            icon={faX}
            color={colors.primary.main}
            cursor='pointer'
            onClick={closeModal}
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '1rem',
            }}
        />

        <Spinner />
    </>)
}

interface AddPatientModalLoaderProps {
    closeModal(): void;
    onSuccess(): void;
    isEdit?: boolean;
    patient?: Patient | null;
    appointmentId?: string;
    appointmentDate?: string;
}

const Loader: React.FC<AddPatientModalLoaderProps> = ({ closeModal, onSuccess, isEdit, patient, appointmentId, appointmentDate }) => {
    const {
        appointment,
        appointmentLoading,
    } = useAppointmentData({ appointmentId: appointmentId, selectedDateISOString: appointmentDate, enabled: !isEdit });

    const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });
    return <Modal
        {...containerStyles({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        })}
        open={true}
        onClose={closeModal}
    >
        <MDBox
            shadow={Shadows.SMALL}
            borderRadius={borders.borderRadius.lg}
            sx={{
                padding: '3rem',
                backgroundColor: colors.white.main,
                width: breakpointBreached ? '80%' : '60vw',
                display: 'grid',
                gap: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '95vh',
                maxWidth: '700px',
                overflow: 'auto',
                position: 'relative',
            }}
        >
            {
                appointmentLoading
                    ? <ContentFallback closeModal={closeModal} onSuccess={onSuccess} isEdit={isEdit} />
                    : <Content closeModal={closeModal} onSuccess={onSuccess} isEdit={isEdit} patient={patient} appointment={appointment} />
            }
        </MDBox>
    </Modal >
}

export default Loader