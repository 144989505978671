import {
  useEffect,
  useRef,
  useCallback,
} from 'react';

export enum KeyValues {
  Key0 = "0",
  Key1 = "1",
  Key2 = "2",
  Key3 = "3",
  Key4 = "4",
  Key5 = "5",
  Key6 = "6",
  Key7 = "7",
  Key8 = "8",
  Key9 = "9",
  KeyA = "A",
  KeyB = "B",
  KeyC = "C",
  KeyD = "D",
  KeyE = "E",
  KeyF = "F",
  KeyG = "G",
  KeyH = "H",
  KeyI = "I",
  KeyJ = "J",
  KeyK = "K",
  KeyL = "L",
  KeyM = "M",
  KeyN = "N",
  KeyO = "O",
  KeyP = "P",
  KeyQ = "Q",
  KeyR = "R",
  KeyS = "S",
  KeyT = "T",
  KeyU = "U",
  KeyV = "V",
  KeyW = "W",
  KeyX = "X",
  KeyY = "Y",
  KeyZ = "Z",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Enter = "Enter",
  Tab = "Tab",
  Escape = "Escape",
  Backspace = "Backspace",
  Delete = "Delete",
  Home = "Home",
  End = "End",
  PageUp = "PageUp",
  PageDown = "PageDown",
  Insert = "Insert",
  Spacebar = " ",
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  F8 = "F8",
  F9 = "F9",
  F10 = "F10",
  F11 = "F11",
  F12 = "F12",
  Control = "Control",
  Alt = "Alt",
  Shift = "Shift",
  Meta = "Meta",
  Numpad0 = "0", // when Num Lock is active
  Numpad1 = "1",
  Numpad2 = "2",
  Numpad3 = "3",
  Numpad4 = "4",
  Numpad5 = "5",
  Numpad6 = "6",
  Numpad7 = "7",
  Numpad8 = "8",
  Numpad9 = "9",
  NumpadAdd = "+", // Add
  NumpadSubtract = "-", // Subtract
  NumpadMultiply = "*", // Multiply
  NumpadDivide = "/", // Divide
  NumpadEnter = "Enter", // on the numeric keypad
  NumpadDecimal = ".", // Decimal point on the numeric keypad
  CapsLock = "CapsLock",
  ScrollLock = "ScrollLock",
  Pause = "Pause",
  PrintScreen = "PrintScreen",
  ContextMenu = "ContextMenu",
}

// takes a target key from the above enum along with a callback function, and binds
// an event listener for the callback to the supplied key press. returns a ref
// which can optionally be attached to a DOM node to be interacted with when the 
// provided key is pressed.

const useKeyPress = function (targetKey: KeyValues, fn?: () => any) {
  const ref = useRef<HTMLElement>();

  const downHandler = useCallback(({ key }: { key: string }) => {
    if (ref.current) return ref.current.click();
    if (key === targetKey && fn) fn();
  }, [targetKey, fn]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);

  return { ref };
};

export default useKeyPress;
