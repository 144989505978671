import React from 'react';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Container, Grid, Card } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import Footer from 'components/molecules/Footer/Footer';
import MDAppBar from 'components/organisms/MDAppBar/MDAppBar';

enum SupportedErrors {
  _404 = 404,
}

interface ErrorPageProps {
  code?: SupportedErrors,
}

const ErrorPage: React.FC<ErrorPageProps> = ({ code }) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  const descriptions: {[E in SupportedErrors]: string} = {
    [SupportedErrors._404]: "We couldn't find what you were looking for."
  };
  
  return (
    <Container maxWidth="sm" sx={{ minWidth:"350px" }}>
      <MDAppBar />
      <MDBox pb={3}>
        <Grid item xs={12} pt={2}>
          <Card>
            <MDBox m={3} pt={1}>
            <div style={{
              width: '100%',
              padding: '5%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}>
              <MDTypography sx={{ marginBottom: '2%' }} variant='h1' color={MUIColors.ERROR}>
                {code && `${code} Error`}
                {!code && 'Something went wrong.'}
              </MDTypography>
              <MDTypography fontWeight='regular' sx={{ width: breakpointBreached ? '100%' : '30%' }} variant='h4' color={MUIColors.PRIMARY}>
                {code ? descriptions[code] : 'Internal server error. Please try again later.'}
              </MDTypography>
            </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      <Footer />
    </Container>
  );
};

export default ErrorPage;
