import { ENDPOINT_BASE_URL } from "./constants";
import { useEffect, useState } from 'react';
import axios from 'axios';

type FeatureFlags = {
    triageFlow?: boolean
}

const useFetchFeatureFlags = () => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});
    const [featureFlagsLoading, setFeatureFlagsLoadingLoading] = useState<boolean>(true);
    const [featureFlagsError, setFeatureFlagsError] = useState<boolean>(false);

    useEffect(() => {
        const fetchFeatureFlags = async () => {
            try {
                const response = await axios.get<FeatureFlags>(`${ENDPOINT_BASE_URL}/feature-flags/`);
                setFeatureFlags(response.data);
            } catch (err) {
                setFeatureFlagsError(true);
            } finally {
                setFeatureFlagsLoadingLoading(false);
            }
        };

        fetchFeatureFlags();
    }, []);

    return {
        featureFlags,
        featureFlagsLoading,
        featureFlagsError
    }
}

export default useFetchFeatureFlags