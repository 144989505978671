import colors from 'assets/theme/base/colors';
import styled from 'styled-components';
import { PainLevels } from 'models/SurveyModels';
import {
    DegenerationLevels,
} from 'models/XrayModels';

export const SectionContainer = styled.div`
    border-radius: .5rem;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
`;

export const DetailCardContainer = styled.div<{ $displayVertical?: boolean }>`
    border-radius: 0 0 .5rem .5rem;
    display: grid;
    justify-content: center;
    grid-template-columns: ${({ $displayVertical }) => $displayVertical ? '1fr' : '1fr 1fr 1fr'};
    padding: 1rem;
    background-color: #F3F6FC;
    justify-content: space-between;
    gap: 1rem;
`;

export const HeaderContainer = styled.div<{ $displayVertical?: boolean }>`
    border-radius: .5rem .5rem 0rem 0rem;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: ${colors.secondary.focus};
    padding: 1rem;
    position: relative;
    display: grid;
    grid-template-columns: ${({ $displayVertical }) => $displayVertical ? '1fr' : '.5fr 1fr'};
    gap: 1rem;
`

export const TreatmentReco = styled.div<{ $candidate: boolean }>`
    padding: .5rem;
    border: 1px solid ${({ $candidate }) => $candidate ? colors.error.focus : '#000'};
    color: ${({ $candidate }) => $candidate ? colors.error.focus : '#000'};
    background-color: #fff;
    font-size: 1.2rem;
    width: fit-content
`

export const SecondaryHeaderLabels = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    display: grid;
    align-items: center;
`

export const HeaderWorstCaseValue = styled.div<{ $degen?: DegenerationLevels | null }>`  
    font-size: 1.2rem;
    font-weight: 700;
    padding: 1rem;
    border-radius: .5rem;
    
    display: flex;
    gap: .5rem;
    align-items: center;
    height: 3rem;

    color: #fff;
    padding: .5rem 1rem;

    ${({ $degen }) => {
        switch ($degen) {
            case DegenerationLevels.NORMAL:
                return `
                    background-color: ${colors.success.focus};
                `;
            case DegenerationLevels.MODERATE:
                return `
                    background-color: ${colors.warning.focus};
                `;
            case DegenerationLevels.ENDSTAGE:
            case DegenerationLevels.NEAR_ENDSTAGE:
                return `
                    background-color: ${colors.error.focus};
                `;
            default:
                return '';
        }
    }}
`;

export const HeaderProValue = styled.div<{ $proScore?: PainLevels | null }>`  
    font-size: 1rem;
    height: 3rem;
    padding: .5rem 1rem;
    border-radius: .5rem;
    
    display: flex;
    gap: .5rem;
    align-items: center;

    color: #fff;

    ${({ $proScore }) => {
        switch ($proScore) {
            case PainLevels.NONE:
            case PainLevels.MILD:
                return `
                    background-color: ${colors.success.focus};
                `;
            case PainLevels.MODERATE:
                return `
                    background-color: ${colors.warning.focus};
                `;
            case PainLevels.SEVERE:
            case PainLevels.EXTREME:
                return `
                    background-color: ${colors.error.focus};
                `;
            default:
                return '';
        }
    }}
`;

export const ProScore = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
`