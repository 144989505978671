export enum MUIBaseColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INHERIT = 'inherit',
}

export enum MUIBaseVariants {
  TEXT = 'text',
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
}

export enum MUIColors {
  TRANSPARENT = 'transparent',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LIGHT = 'light',
  DARK = 'dark',
  WHITE = 'white',
  DULL = 'dull',
  TEXT = 'text',
  RETURN = 'return',
}

export enum GreyValues {
  _100 = 'grey-100',
  _200 = 'grey-200',
  _300 = 'grey-300',
  _400 = 'grey-400',
  _500 = 'grey-500',
  _600 = 'grey-600',
  _700 = 'grey-700',
  _800 = 'grey-800',
  _900 = 'grey-900',
}

export enum Sizes {
  X_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  X_LARGE = 'xl',
  XX_LARGE = 'xxl',
}

export enum Shadows {
  NONE = 'none',
  INSET = 'inset',
  X_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  X_LARGE = 'xl',
  XX_LARGE = 'xxl',
}

export enum Breakpoints {
  X_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  X_LARGE = 'xl',
  XX_LARGE = 'xxl',
}
