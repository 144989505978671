
import colors from "assets/theme/base/colors"
import MDButton from "components/atoms/MDButton/MDButton"

interface RequestLinkStepProps {
    back: () => void
}

const RequestLinkStep: React.FC<RequestLinkStepProps> = ({ back }) => {
    return (
        <>
            <div style={{ marginBottom: "2rem", textAlign: "center" }}>
                <span style={{ color: colors.white.main, fontWeight: 400, fontSize: "1.4rem", textAlign: "center" }}>
                    Reach out to your clinic’s administrator to be invited to join Joint Dx through an unique invite link.
                </span>
            </div>

            <MDButton
                variant='contained'
                fullWidth
                style={{ color: colors.text.main }}
                onClick={back}>
                Done
            </MDButton>
        </>
    )
}

export default RequestLinkStep 