import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { Org, UpdateOrgBody } from 'models/OrgModels';
import { OrgQueryCache, orgQueryKeys } from './useOrgData';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const useOrgMutations = () => {
  const { user, accessToken } = useAuth();
  const queryClient = useQueryClient();

  interface UpdateOrgRequest {
    orgId: string | undefined;
    newValues: UpdateOrgBody,
  }

  interface UploadLogoRequest { file: File, org: Org }

  const patchOrg = async (requestData: UpdateOrgRequest): Promise<void | { data: { org: Org; }; }> => {
    if (!requestData.orgId) return;
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/orgs/${requestData.orgId}?org_id=${requestData.orgId}`,
      data: requestData.newValues,
      token: accessToken,
    });
    return resp as unknown as { data: { org: Org } };
  };

  // media files must be uploaded as File type objects with the Content-Type: multipart/form-data headers.
  // additional metadata required for the request must be stringified in this format, as the org metadata
  // is here.
  const updateLogo = async ({ file, org }: UploadLogoRequest): Promise<{ data: { org: Org } }> => {
    const org_metadata = JSON.stringify(org);
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/orgs/${user?.orgs[0].org_id}/logo?org_id=${user?.orgs[0].org_id}`,
      data: {
        file: file,
        org_metadata: org_metadata,
      },
      token: accessToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp as unknown as { data: { org: Org } };
  };

  const {
    mutateAsync: updateOrg,
    data: updateOrgData,
    error: updateOrgError,
    isLoading: updateOrgLoading,
    isSuccess: updateOrgSuccessful,
  } = useMutation(
    (req: UpdateOrgRequest) => patchOrg(req),
    {
      onSuccess: (resp) => {
        const updatedOrg = resp?.data?.org;
        if (!updatedOrg) return;
        const prevOrg: OrgQueryCache = queryClient
          .getQueryData<OrgQueryCache>(orgQueryKeys.getOrgKey(user, accessToken));

        if (prevOrg) {
          queryClient.setQueryData<OrgQueryCache>(orgQueryKeys.getOrgKey(user, accessToken), {
            ...prevOrg,
            data: {
              ...prevOrg.data,
              org: updatedOrg,
            },
          });
        }
      },
    },
  );

  const {
    mutateAsync: uploadLogo,
    data: updatedOrg,
    error: uploadLogoError,
    isLoading: uploadLogoLoading,
    isSuccess: uploadLogoSuccessful,
  } = useMutation(
    (req: { file: File, org: Org }) => updateLogo(req),
    {
      onSuccess: (resp) => {
        const updatedOrg = resp?.data?.org;
        if (!updatedOrg) return;
        const prevOrg: OrgQueryCache = queryClient
          .getQueryData<OrgQueryCache>(orgQueryKeys.getOrgKey(user, accessToken));

        if (prevOrg) {
          queryClient.setQueryData<OrgQueryCache>(orgQueryKeys.getOrgKey(user, accessToken), {
            ...prevOrg,
            data: {
              ...prevOrg.data,
              org: updatedOrg,
            },
          });
        }
      },
    },
  );

  return {
    updateOrg,
    updateOrgData,
    updateOrgError,
    updateOrgLoading,
    updateOrgSuccessful,
    uploadLogo,
    updatedOrg,
    uploadLogoError,
    uploadLogoLoading,
    uploadLogoSuccessful,
  };
};
