import React, { CSSProperties } from 'react';
import { ReactComponent as NoSelection } from 'assets/images/joint_selection_svgs/joint_select_none_cropped.svg';
import { ReactComponent as LHipSelected } from 'assets/images/joint_selection_svgs/joint_select_lhip_cropped.svg';
import { ReactComponent as RHipSelected } from 'assets/images/joint_selection_svgs/joint_select_rhip_cropped.svg';
import { ReactComponent as LKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_lknee_cropped.svg';
import { ReactComponent as RKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_rknee_cropped.svg';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import Moment from "moment";
import { PRO } from 'models/SurveyModels';
import { FullJoints } from 'models/XrayModels';
import { getJointFromFullJoint, getJointSideFromFullJoint } from 'helpers/helpers';

export type JointDataType = {
  [FJ in FullJoints]?: {
    pro?: PRO | null;
    priority?: number | null;
    mark?: boolean;
  } | null;
};

interface JointInfoVisualizerProps {
  mode: 'jointPriority' | 'proScore' | 'hybrid' | 'marker';
  jointData: JointDataType;
  fadeOutBottomToColor?: CSSProperties["color"];
  dataLoading?: boolean;
}

const JointInfoVisualizer: React.FC<JointInfoVisualizerProps> = ({
  mode,
  jointData,
  fadeOutBottomToColor,
  dataLoading,
}) => {
  const jointsToMark = Object.entries(jointData).filter(([, jointData]) => !!jointData && (
    !!jointData?.mark
    || ((mode === 'proScore') && jointData?.pro)
    || ((mode === 'jointPriority' || mode === 'hybrid') && jointData?.priority)
  ));

  const transitionTiming = '1000ms ease';
  const commonStyles = { justifySelf: 'center', height: '100%' };
  const opacityTransition = `opacity ${transitionTiming}`;
  const allStyles = { opacity: dataLoading ? 0 : 1, transition: opacityTransition, ...commonStyles };

  type JointAttributesType = {
    [FJ in FullJoints]: {
      graphic: React.ReactNode;
      mainLabelStyles: CSSProperties;
      proLabelStyles: CSSProperties;
    }
  };

  const jointAttributes: JointAttributesType = {
    [FullJoints.LEFT_HIP]: {
      graphic: <LHipSelected style={{ gridArea: '1 / 1 / 2 / 2', ...allStyles }} />,
      mainLabelStyles: {
        position: 'absolute',
        top: '22px',
        right: '10px',
      },
      proLabelStyles: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        transform: 'translate(.75rem, -1rem)',
        opacity: dataLoading ? 0 : 1,
        transition: `all ${transitionTiming}`,
      },
    },
    [FullJoints.RIGHT_HIP]: {
      graphic: <RHipSelected style={{ gridArea: '1 / 1 / 2 / 2', ...allStyles }} />,
      mainLabelStyles: {
        position: 'absolute',
        top: '22px',
        left: '22px',
      },
      proLabelStyles: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        transform: 'translate(-1rem, -1rem)',
        opacity: dataLoading ? 0 : 1,
        transition: `all ${transitionTiming}`,
      },
    },
    [FullJoints.LEFT_KNEE]: {
      graphic: <LKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', ...allStyles }} />,
      mainLabelStyles: {
        position: 'absolute',
        bottom: mode === 'hybrid' ? '30px' : '45px',
        right: '18px',
        zIndex: 3,
      },
      proLabelStyles: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        transform: 'translate(1rem, -1rem)',
        opacity: dataLoading ? 0 : 1,
        transition: `all ${transitionTiming}`,
      },
    },
    [FullJoints.RIGHT_KNEE]: {
      graphic: <RKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', ...allStyles }} />,
      mainLabelStyles: {
        position: 'absolute',
        bottom: mode === 'hybrid' ? '30px' : '45px',
        left: '22px',
        zIndex: 3,
      },
      proLabelStyles: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        transform: 'translate(-1rem, -1rem)',
        opacity: dataLoading ? 0 : 1,
        transition: `all ${transitionTiming}`,
      },
    },
  };

  const getLoadingStyles = (fullJoint: FullJoints): CSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    opacity: dataLoading ? 0 : 1,
    transition: `all ${transitionTiming}`,
    transform: getJointSideFromFullJoint(fullJoint) === 'left'
      ? `translate(${getJointFromFullJoint(fullJoint) === 'hip' ? `${dataLoading ? 'calc(2rem + -1rem), -1rem' : '-1rem, -1rem' }` : `${dataLoading ? 'calc(2rem + -0.5rem), -1rem' : '-.5rem, -1rem' }`})`
      : `translate(${getJointFromFullJoint(fullJoint) === 'hip' ? `${dataLoading ? '-2rem, -1rem' : '0.75rem, -1rem' }` : `${dataLoading ? '-2rem, -1rem' : '0, -1rem' }`})`,
  });

  const activeSelections = jointsToMark.map(([fullJoint, jointData]) => (
    <React.Fragment key={fullJoint}>
      <MDTypography
        variant='body2'
        textAlign={getJointSideFromFullJoint(fullJoint as FullJoints)}
        sx={jointAttributes[fullJoint as FullJoints].mainLabelStyles}
      >
        {(mode === 'jointPriority' || mode === 'hybrid') && (
          <span 
            style={
              mode === 'hybrid' && jointData?.pro?.scores
                // if a PRO exists for the joint, grab the PRO label styles from the obj above.
                ? jointAttributes[fullJoint as FullJoints].proLabelStyles
                // if not, use this style. this will trigger both if a PRO doesnt exist as well
                // as if the data is loading.
                : getLoadingStyles(fullJoint as FullJoints)
            }
          >
            <span style={{ fontWeight: 600 }}>Priority {jointData?.priority}</span>
            <span style={{ textTransform: 'capitalize' }}>{fullJoint}</span>
            {mode === 'hybrid' && !dataLoading && jointData?.pro && jointData.pro.scores && (
              <>
                <span>{getJointFromFullJoint(fullJoint as FullJoints) === 'hip' ? 'HOOs' : 'KOOs'} JR - {Math.round(jointData.pro.scores.overall)}</span>
                {/* .replace() below is a fix for how old versions of safari parse date objects. TODO: fix all 
                generated timestamps on the backend to use datestring format to prevent this issue */}
                <span style={{ fontSize: '12px', lineHeight: 0 }}>Date: {Moment(new Date(jointData.pro.created_at.replace(/ /g, "T"))).format('MM/DD/YYYY')}</span>
              </>
            )}
          </span>
        )}
        {mode === 'proScore' && (
          <span
            style={
              dataLoading
                ? getLoadingStyles(fullJoint as FullJoints)
                : jointAttributes[fullJoint as FullJoints].proLabelStyles
            }
          >
            {jointData?.pro && jointData.pro.scores && (
              <>
                <span style={{ fontWeight: 600, textTransform: 'capitalize' }}>{fullJoint}</span>
                <span>{getJointFromFullJoint(fullJoint as FullJoints) === 'hip' ? 'HOOs' : 'KOOs'} JR - {Math.round(jointData?.pro?.scores.overall || 0)}</span>
              </>
            )}
            {jointData?.pro && (
              <span style={{ fontSize: '12px', lineHeight: 0 }}>Date: {Moment(new Date(jointData?.pro?.created_at.replace(/ /g, "T"))).format('MM/DD/YYYY')}</span>
            )}
          </span>
        )}
      </MDTypography>
      {jointAttributes[fullJoint as FullJoints].graphic}
    </React.Fragment>
  ));

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        justifySelf: 'center',
        alignSelf: 'center',
        height: '100%',
        maxHeight: '20rem',
        position: 'relative',
      }}
    >
      <NoSelection style={{
        gridArea: '1 / 1 / 2 / 2',
        justifySelf: 'center',
        height: '100%',
        opacity: dataLoading ? 1 : 0,
        transition: opacityTransition,
      }} />
      <NoSelection style={{ gridArea: '1 / 1 / 2 / 2', ...commonStyles }} />
      {activeSelections}
      {fadeOutBottomToColor && (
        <div
          style={{
            width: '100%',
            height: '3rem',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 2,
            background: `linear-gradient(0deg, ${fadeOutBottomToColor} 20%, rgba(255,0,0,0) 100%)`,
          }}
        />
      )}
    </div>
  );
};

export default JointInfoVisualizer;
