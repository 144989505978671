import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDF.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

interface PDFProps {
  src: File | Blob;
  fadeOutBottom?: boolean;
}

const PDF: React.FC<PDFProps> = ({ src, fadeOutBottom }) => {
  const [numPages, setNumPages] = useState<number>();
  const [hide, setHide] = useState<boolean>(true);
  
  return (
    <div style={{ opacity: hide ? 0 : 1}} className={`PDF__container ${fadeOutBottom ? 'fade-out-btm' : ''}`}>
      <div className='PDF__container__document'>
        <Document
          file={src}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            setHide(false);
          }}
          options={options}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PDF;
