import React, { SyntheticEvent, useEffect, useState } from 'react';
import { CardCover } from '@mui/joy';
import {
  Modal,
  Card,
  Grid,
  AppBar,
  Tabs,
  Tab,
  CardContent,
} from '@mui/material';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { useActivePatient } from 'hooks/useActivePatient';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import {
  DegenerationLevels,
  FullJoints,
  PredictionSection,
  ViewTypes,
  Xray,
  compartmentOrder,
  normalizedDegenerationLevels,
  normalizedJointSections,
} from 'models/XrayModels';
import colors from 'assets/theme/base/colors';
import {
  formatSectionLabel,
  generateCardDetails,
  getJointFromFullJoint,
  getJointSideFromFullJoint,
} from 'helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faX } from '@fortawesome/free-solid-svg-icons';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Appointment } from 'models/AppointmentModels';
import { useXrayMutations } from 'hooks/useXrayMutations';

import RightKneecapNormal from "assets/images/comparison_xrays/right_kneecap_normal.png";
import RightKneecapModerate from "assets/images/comparison_xrays/right_kneecap_mod.png";
import RightKneecapNES from "assets/images/comparison_xrays/right_kneecap_nes.png";
import RightKneecapES from "assets/images/comparison_xrays/right_kneecap_end_stage.png";

import LeftKneecapNormal from "assets/images/comparison_xrays/left_kneecap_normal.png";
import LeftKneecapModerate from "assets/images/comparison_xrays/left_kneecap_mod.png";
import LeftKneecapNES from "assets/images/comparison_xrays/left_kneecap_nes.png";
import LeftKneecapES from "assets/images/comparison_xrays/left_kneecap_end_stage.png";

import BLRightMedialSlight from "assets/images/comparison_xrays/BL_right_normal_medial.png";
import BLRightMedialModerate from "assets/images/comparison_xrays/BL_right_mod_medial.png";
import BLRightMedialNES from "assets/images/comparison_xrays/BL_right_nes_medial.png";
import BLRightMedialES from "assets/images/comparison_xrays/BL_right_end_stage_medial.png";

import BLLeftMedialSlight from "assets/images/comparison_xrays/BL_left_normal_medial.png";
import BLLeftMedialModerate from "assets/images/comparison_xrays/BL_left_mod_medial.png";
import BLLeftMedialNES from "assets/images/comparison_xrays/BL_left_nes_medial.png";
import BLLeftMedialES from "assets/images/comparison_xrays/BL_left_end_stage_medial.png";

import BLRightLateralSlight from "assets/images/comparison_xrays/BL_right_normal_lateral.png";
import BLRightLateralModerate from "assets/images/comparison_xrays/BL_right_mod_lateral.png";
import BLRightLateralNES from "assets/images/comparison_xrays/BL_right_nes_lateral.png";
import BLRightLateralES from "assets/images/comparison_xrays/BL_right_end_stage_lateral.png";

import BLLeftLateralSlight from "assets/images/comparison_xrays/BL_left_normal_lateral.png";
import BLLeftLateralModerate from "assets/images/comparison_xrays/BL_left_mod_lateral.png";
import BLLeftLateralNES from "assets/images/comparison_xrays/BL_left_nes_lateral.png";
import BLLeftLateralES from "assets/images/comparison_xrays/BL_left_end_stage_lateral.png";

import ApPelvisZ1Normal from "assets/images/comparison_xrays/hip_AP_Normal_Z1.png";
import ApPelvisZ1Moderate from "assets/images/comparison_xrays/hip_AP_Moderate_Z1.png";
import ApPelvisZ1NES from "assets/images/comparison_xrays/hip_AP_NES_Z1.png";
import ApPelvisZ1ES from "assets/images/comparison_xrays/hip_AP_ES_Z1.png";

import ApPelvisZ2Normal from "assets/images/comparison_xrays/hip_AP_Normal_Z2.png";
import ApPelvisZ2Moderate from "assets/images/comparison_xrays/hip_AP_Moderate_Z2.png";
import ApPelvisZ2NES from "assets/images/comparison_xrays/hip_AP_NES_Z2.png";
import ApPelvisZ2ES from "assets/images/comparison_xrays/hip_AP_ES_Z2.png";

import LateralHipZ1Normal from "assets/images/comparison_xrays/hip_Lateral_Normal_Z1.png";
import LateralHipZ1Moderate from "assets/images/comparison_xrays/hip_Lateral_Moderate_Z1.png";
import LateralHipZ1NES from "assets/images/comparison_xrays/hip_Lateral_NES_Z1.png";
import LateralHipZ1ES from "assets/images/comparison_xrays/hip_Lateral_End Stage_Z1.png";

import LateralHipZ2Normal from "assets/images/comparison_xrays/hip_Lateral_Normal_Z2.png";
import LateralHipZ2Moderate from "assets/images/comparison_xrays/hip_Lateral_Moderate_Z2.png";
import LateralHipZ2NES from "assets/images/comparison_xrays/hip_Lateral_NES_Z2.png";
import LateralHipZ2ES from "assets/images/comparison_xrays/hip_Lateral_End Stage_Z2.png";
import ActionSlugs, { IfUserIsAllowed } from 'services/PermissionsService';

interface XrayReviewModalProps {
  xray: Xray;
  appointment: Appointment | null;
  fullJoint: FullJoints;
  modalOpen: boolean;
  handleClose(): void;
  roiImgUrls: { [key: string]: string; };
}

const XrayReviewModal: React.FC<XrayReviewModalProps> = ({
  xray,
  appointment,
  fullJoint,
  modalOpen,
  handleClose,
  roiImgUrls,
}) => {
  const joint = getJointFromFullJoint(fullJoint);
  const jointSide = getJointSideFromFullJoint(fullJoint);

  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const { updateXray, updateXrayLoading } = useXrayMutations({ appointment });

  const { UPDATE$XRAY } = ActionSlugs;

  const isKneecapXray = xray.view_type_results.viewType === 'bilateral_kneecap'
    || xray.view_type_results.viewType === 'unilateral_kneecap';

  const title = generateCardDetails(xray, jointSide).title;

  const xrayImg = roiImgUrls[jointSide === 'right' ? xray.prediction_results.rightROI! : xray.prediction_results.leftROI!]
    || roiImgUrls[xray.prediction_results.singleROI!]
    || roiImgUrls[xray.prediction_results.sections[0].imgId];

  const outcomes = xray.prediction_results.sections
    .filter((sect) => {
      if (xray.prediction_results.sections.length === 1) return sect;
      return sect.joint === jointSide || sect.joint === 'single';
    })
    .sort((sectA, sectB) => compartmentOrder[normalizedJointSections[sectA.section]] - compartmentOrder[normalizedJointSections[sectB.section]])
    .map((sect) => ({
      section: sect.section,
      result: sect.prediction,
      clientSelectedDegen: sect.clientSelectedPrediction,
      label: formatSectionLabel(sect.section),
      imgUrl: roiImgUrls[xray.prediction_results.sections[xray.prediction_results.sections.indexOf(sect)]?.imgId],
    }));

  const { activePatient } = useActivePatient();

  // state of compartment comparison tab
  const [activeModalTabIndex, setActiveModalTabIndex] = useState<number>(0);
  const handlesetActiveModalTabIndex = (event: SyntheticEvent<Element, Event>, newValue: number) => setActiveModalTabIndex(newValue);

  type PredictionDataType = {
    [DL in DegenerationLevels]: {
      accionLabel: DL;
      label: string;
      comparisonIndex: number;
      descriptions: string[];
      color: string;
    };
  };

  // comparisonIndex here refers to the corresponding `activeComparisonIndex` value which renders
  // the appropriate example xray when selected
  const predictionData: PredictionDataType = {
    [DegenerationLevels.NORMAL]: {
      accionLabel: DegenerationLevels.NORMAL,
      label: 'Normal',
      comparisonIndex: 0,
      descriptions: [
        'Slight to no degredation',
        'Joint space thick',
        'No significant loss of cartilage',
      ],
      color: colors.success.main,
    },
    [DegenerationLevels.MODERATE]: {
      accionLabel: DegenerationLevels.MODERATE,
      label: 'Moderate',
      comparisonIndex: 1,
      descriptions: [
        'Moderate degredation',
        'Joint space narrowing parts of the joint surface',
        'Cartilage present but showing signs of wear',
      ],
      color: colors.warning.main,
    },
    [DegenerationLevels.NEAR_ENDSTAGE]: {
      accionLabel: DegenerationLevels.NEAR_ENDSTAGE,
      label: 'Near-End Stage',
      comparisonIndex: 2,
      descriptions: [
        'Near End Stage degredation',
        'Joint space significantly narrowed',
        'Significant loss of cartilage',
      ],
      color: colors.error.main,
    },
    [DegenerationLevels.ENDSTAGE]: {
      accionLabel: DegenerationLevels.ENDSTAGE,
      label: 'End Stage',
      comparisonIndex: 3,
      descriptions: [
        'End Stage degredation',
        'No joint space remaining, bone is contacting bone',
        'Cartilage has been compromised and worn away',
      ],
      color: colors.error.main,
    },
  };

  const analysisOutcome = isKneecapXray
    ? predictionData[outcomes[0]?.result]
    : predictionData[outcomes[activeModalTabIndex]?.result];

  // points to the section within xray.prediction_results.sections that is currently
  // active per which top level tab in the modal is selected (medial, zone 2, etc)
  const activeSectionInXrayObj = xray.prediction_results.sections.find((sect) => sect.section === outcomes[activeModalTabIndex]?.section);

  const clientSelectedDegen = activeSectionInXrayObj?.clientSelectedPrediction;

  // state of degeneration comparison tabs. defaults to manually selected degeneration first,
  // if it exists, and then to accion selection
  const [activeComparisonIndex, setActiveComparisonIndex] = useState<number>(
    clientSelectedDegen
      ? predictionData[clientSelectedDegen].comparisonIndex
      : analysisOutcome?.comparisonIndex || 0
  );
  const handleSetActiveComparisonIndex = (event: SyntheticEvent<Element, Event>, newValue: number) => setActiveComparisonIndex(newValue);

  const selectedDegenValue = Object.values(predictionData).find((data) => data.comparisonIndex === activeComparisonIndex)?.accionLabel;
  const selectedDegenMatchesXray = (activeSectionInXrayObj?.clientSelectedPrediction
    ? activeSectionInXrayObj.clientSelectedPrediction === selectedDegenValue
    : activeSectionInXrayObj?.prediction === selectedDegenValue)

  useEffect(() => {
    setActiveComparisonIndex(
      clientSelectedDegen
        ? predictionData[clientSelectedDegen].comparisonIndex
        : analysisOutcome?.comparisonIndex
    );
    // eslint-disable-next-line
  }, [activeModalTabIndex]);

  type ComparisonXraysType = {
    [key: string]: {
      [key: number]: string | { [key: number]: string };
    };
  };

  const comparisonXrays: ComparisonXraysType = {
    kneeCap: {
      // keys here coincide with the value of `activeComparisonIndex`
      // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
      0: jointSide === 'right' ? RightKneecapNormal : LeftKneecapNormal,
      1: jointSide === 'right' ? RightKneecapModerate : LeftKneecapModerate,
      2: jointSide === 'right' ? RightKneecapNES : LeftKneecapNES,
      3: jointSide === 'right' ? RightKneecapES : LeftKneecapES,
    },
    standingKnee: {
      // keys here coincide with the value of `activeModalTabIndex` 
      // 0 -> Lateral Comparison, 2 -> Medial Comparison
      0: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: jointSide === 'right' ? BLRightMedialSlight : BLLeftMedialSlight,
        1: jointSide === 'right' ? BLRightMedialModerate : BLLeftMedialModerate,
        2: jointSide === 'right' ? BLRightMedialNES : BLLeftMedialNES,
        3: jointSide === 'right' ? BLRightMedialES : BLLeftMedialES,
      },
      1: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: jointSide === 'right' ? BLRightLateralSlight : BLLeftLateralSlight,
        1: jointSide === 'right' ? BLRightLateralModerate : BLLeftLateralModerate,
        2: jointSide === 'right' ? BLRightLateralNES : BLLeftLateralNES,
        3: jointSide === 'right' ? BLRightLateralES : BLLeftLateralES,
      },
    },
    hipLateral: {
      // keys here coincide with the value of `activeModalTabIndex` 
      // 0 -> Zone 1 Comparison, 2 -> Zone 2 Comparison
      0: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: LateralHipZ1Normal,
        1: LateralHipZ1Moderate,
        2: LateralHipZ1NES,
        3: LateralHipZ1ES,
      },
      1: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: LateralHipZ2Normal,
        1: LateralHipZ2Moderate,
        2: LateralHipZ2NES,
        3: LateralHipZ2ES,
      },
    },
    apPelvis: {
      // keys here coincide with the value of `activeModalTabIndex` 
      // 0 -> Zone 1 Comparison, 2 -> Zone 2 Comparison
      0: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: ApPelvisZ1Normal,
        1: ApPelvisZ1Moderate,
        2: ApPelvisZ1NES,
        3: ApPelvisZ1ES,
      },
      1: {
        // keys here coincide with the value of `activeComparisonIndex`
        // 0 -> Normal, 1 -> Moderate, 2 -> Near-End Stage, 3 -> End Stage
        0: ApPelvisZ2Normal,
        1: ApPelvisZ2Moderate,
        2: ApPelvisZ2NES,
        3: ApPelvisZ2ES,
      },
    },
  };

  const getComparisonXrays = () => {
    if (isKneecapXray) return comparisonXrays.kneeCap;
    if (xray.client_provided_view_type === ViewTypes.AP_PELVIS) return comparisonXrays.apPelvis;
    if (xray.client_provided_view_type === ViewTypes.LEFT_FROG || xray.client_provided_view_type === ViewTypes.RIGHT_FROG) return comparisonXrays.hipLateral;
    return comparisonXrays.standingKnee;
  };

  const confirmEvaluation = async () => {
    if (!activeSectionInXrayObj || !selectedDegenValue) return;
    let newSectionValues: PredictionSection | null = null;

    if (
      activeSectionInXrayObj.clientSelectedPrediction
        ? selectedDegenValue !== activeSectionInXrayObj.clientSelectedPrediction
        : selectedDegenValue !== activeSectionInXrayObj.prediction
    ) {
      newSectionValues = {
        ...activeSectionInXrayObj,
        clientConfirmed: true,
        clientSelectedPrediction: selectedDegenValue,
      };
    } else {
      newSectionValues = {
        ...activeSectionInXrayObj,
        clientConfirmed: true,
      };
    }
    if (!newSectionValues) return;
    await updateXray({
      xray,
      newValues: {
        prediction_results: {
          ...xray.prediction_results,
          sections: xray.prediction_results.sections.map((sect) => {
            if (sect.section === newSectionValues?.section) return newSectionValues;
            return sect;
          }),
        },
      },
    });

    if (!isKneecapXray && activeModalTabIndex === 0) setActiveModalTabIndex(1);
    if ((!isKneecapXray && activeModalTabIndex === 1) || isKneecapXray) handleClose();
  };

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1rem',
    width: '50rem',
    maxWidth: 'calc(100vw - 20px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    minWidth: '350px',
    maxHeight: '95vh',
  };

  const compartmentTabs = (
    <Grid item xs={12} sm={6.75}>
      <AppBar position="static">
        {!isKneecapXray && (
          <Tabs
            TabIndicatorProps={{ sx: { background: colors.secondary.main } } as React.HTMLAttributes<HTMLDivElement>}
            value={activeModalTabIndex || 0}
            onChange={handlesetActiveModalTabIndex}
          >
            <Tab key='section 1' style={{ fontSize: "12px", color: activeModalTabIndex === 0 ? colors.white.main : colors.primary.main }} label={`${outcomes[0].label} Comparison`} />,
            <Tab key='section 2' style={{ fontSize: "12px", color: activeModalTabIndex === 1 ? colors.white.main : colors.primary.main }} label={`${outcomes[1].label} Comparison`} />,
          </Tabs>
        )}
      </AppBar>
    </Grid>
  );

  return (
    <Modal sx={{ minWidth: "350px" }} open={modalOpen} onClose={handleClose}>
      <Card sx={{ boxShadow: 24, m: -1, ...boxStyle }}>
        <Grid container sx={{ paddingBottom: '1rem' }}>
          <Grid item mt={2} xs={12}>
            <Grid container sx={{ position: 'relative', display: 'flex', flexDirection: breakpointBreached ? 'column' : 'row', justifyContent: 'space-between' }}>
              <Grid item xs={4.75}>
                <MDTypography variant="h5" lineHeight="1" color={MUIColors.DARK}>
                  {activePatient?.first_name} {activePatient?.last_name}
                </MDTypography>
              </Grid>
              <Grid
                item
                xs={0.50}
                onClick={handleClose}
                sx={
                  breakpointBreached
                    ? {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }
                    : {}
                }
              >
                <MDButton
                  variant="text"
                  color={MUIColors.PRIMARY}
                >
                  <FontAwesomeIcon icon={faX} />
                </MDButton>
              </Grid>
              <Grid container mt={1} >
                <Grid item xs={12}>
                  <MDTypography variant="h6" lineHeight="1" color={MUIColors.DARK} fontWeight="light">
                    <span style={{ fontWeight: '400' }}>Date of Birth:</span> {activePatient?.date_of_birth.replaceAll('-', '/')}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: breakpointBreached ? '1rem' : 0 }}>
                  <MDTypography
                    variant="h6"
                    lineHeight="1"
                    color={MUIColors.DARK}
                    fontWeight="light"
                  >
                    <span style={{ fontWeight: '400' }}>X-Ray Type:</span> {title}
                  </MDTypography>
                </Grid>
                <Grid container justifyContent='center'>
                  {compartmentTabs}
                </Grid>
              </Grid>
            </Grid>
            {/* degeneration tabs  */}
            <Grid item xs={12}>
              <Grid item mb={3} mt={3} xs={12}>
                <AppBar position="static">
                  <Tabs
                    sx={{ overflow: 'unset' }}
                    value={activeComparisonIndex || 0}
                    onChange={handleSetActiveComparisonIndex}
                    TabIndicatorProps={{ sx: { background: colors.secondary.main } } as React.HTMLAttributes<HTMLDivElement>}
                  >
                    {Object.values(DegenerationLevels).map((degLvl, i) => (
                      <Tab
                        key={degLvl}
                        style={{ fontSize: "12px", color: activeComparisonIndex === i ? colors.white.main : colors.primary.main }}
                        label={normalizedDegenerationLevels[degLvl]}
                        sx={{ overflow: 'unset' }}
                        icon={
                          analysisOutcome.accionLabel === degLvl
                            ? (
                              <div
                                style={{
                                  backgroundColor: colors.grey[100],
                                  borderBottomRightRadius: '4px',
                                  borderBottomLeftRadius: '4px',
                                  padding: breakpointBreached ? '4px' : '4px 15px',
                                  position: 'absolute',
                                  transform: 'translateY(30px)',
                                  border: `solid ${colors.secondary.main} 2px`
                                }}
                              >
                                <MDTypography
                                  fontSize='10px'
                                  fontWeight='bold'
                                  color={MUIColors.SECONDARY}
                                  textAlign='center'
                                  textTransform='uppercase'
                                  whiteSpace='nowrap'
                                >
                                  {breakpointBreached ? 'jdx selection' : 'jointdx selection'}
                                </MDTypography>
                              </div>
                            )
                            : ''
                        }
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid container spacing={0.75} textAlign='center'>
                {/* Patient X-Ray Image */}
                <Grid item xs={12} sm={6}>
                  <Card sx={{
                    width: "100%",
                    height: 'auto',
                    aspectRatio: "1 / 1",
                    overflow: "hidden",
                    borderRadius: "1rem",
                    margin: "1rem 0",
                    position: 'relative',
                  }}>
                    <CardCover>
                      <img src={outcomes[activeModalTabIndex]?.imgUrl || xrayImg} alt="" />
                    </CardCover>
                    <CardCover sx={{ borderRadius: '1rem', background: 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 100px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 50px)' }} />
                    <CardContent
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <MDTypography
                        textAlign="left"
                        variant="h6"
                        sx={{ color: colors.white.main, lineHeight: 0 }}
                      >
                        Patient's X-ray
                      </MDTypography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Example X-Ray Images */}
                <Grid item xs={12} sm={6} mb={2}>
                  <Card sx={{
                    width: "100%",
                    height: 'auto',
                    aspectRatio: "1 / 1",
                    overflow: "hidden",
                    borderRadius: "1rem",
                    margin: "1rem 0",
                    position: 'relative',
                  }}>
                    <CardCover >
                      {isKneecapXray
                        ? <img src={getComparisonXrays()[activeComparisonIndex] as string} alt="Comparison X-ray" />
                        : <img style={{ transform: joint === 'hip' && jointSide === 'left' ? 'scaleX(-1)' : 'unset' }} src={getComparisonXrays()[activeModalTabIndex][activeComparisonIndex]} alt="Comparison X-ray" />
                      }
                    </CardCover>
                    <CardCover sx={{ borderRadius: '1rem', background: 'linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0) 100px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 50px)' }} />
                    {(analysisOutcome.accionLabel === selectedDegenValue || activeSectionInXrayObj?.clientSelectedPrediction === selectedDegenValue)
                      && (
                        <>
                          <CardCover
                            sx={{
                              borderRadius: '1rem',
                              background: `linear-gradient(to top, rgb(255 255 255 / 0%), rgb(255 255 255 / 0%) 100px),linear-gradient(to bottom, ${colors.secondary.main}, rgb(255 255 255 / 0%) 50px)`,
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                position: 'relative',
                                textAlign: 'center',
                              }}
                            >
                              <CardContent
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                }}
                              >
                                <MDTypography
                                  textAlign="center"
                                  variant="h6"
                                  fontWeight="light"
                                  sx={{ color: colors.white.main }}
                                >
                                  {analysisOutcome.accionLabel === selectedDegenValue ? 'JointDx Selection' : 'Manual Selection'}
                                </MDTypography>
                              </CardContent>
                            </div>
                          </CardCover>
                        </>
                      )
                    }
                    <CardContent
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <MDTypography
                        textAlign="left"
                        variant="h6"
                        sx={{ color: colors.white.main, lineHeight: 0 }}
                      >
                        Example X-ray
                      </MDTypography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <IfUserIsAllowed to={UPDATE$XRAY}>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <MDButton
                variant='contained'
                color={MUIColors.SECONDARY}
                onClick={confirmEvaluation}
                disabled={
                  updateXrayLoading
                    || !activeSectionInXrayObj?.clientConfirmed
                    ? false
                    : selectedDegenMatchesXray
                }
              >
                {
                  updateXrayLoading
                    ? <FontAwesomeIcon icon={faCircleNotch} color={colors.white.main} spin />
                    : (
                      <>
                        {
                          selectedDegenMatchesXray && activeSectionInXrayObj?.clientConfirmed
                            ? 'Evaluation Confirmed'
                            : <>{(activeSectionInXrayObj?.clientSelectedPrediction || activeSectionInXrayObj?.prediction) !== selectedDegenValue && 'Update &'} Confirm {!isKneecapXray && outcomes[activeModalTabIndex].label} Evaluation</>
                        }
                      </>
                    )
                }
              </MDButton>
            </div>
          </IfUserIsAllowed>
        </Grid>
      </Card>
    </Modal >
  );
};

export default XrayReviewModal;
