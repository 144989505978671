import React, { HTMLAttributes, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'assets/theme/base/colors';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import useOutsideClick from 'hooks/useOutsideClick';
import { MUIColors, Shadows } from 'models/StyleModels';

interface DropdownSelectOpt {
  value: any;
  handleClick(args?: any): void;
}

type DropdownSelectProps = {
  options: DropdownSelectOpt[];
  value: any;
  label?: string;
} & HTMLAttributes<HTMLDivElement>;

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  value,
  label,
  ...props
}) => {
  const transitionSpeed = '250ms';
  const elementHeightRem = 2.75;
  const borderRadiusValue = '6px';
  const disallowTextSelect = {
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  };

  const [open, setOpen] = useState<boolean>(false);
  const closeMenu = () => setOpen(false);
  const openMenu = () => setOpen(true);

  const { elementRef } = useOutsideClick(closeMenu);

  return (
    <div
      {...props}
      style={{
        width: '100%',
        position: 'relative',
        zIndex: open ? 2001 : 1,
        transition: `background-color ${transitionSpeed} ease`,
        height: 'fit-content',
        ...props.style,
        backgroundColor: open ? props.style?.backgroundColor || 'transparent' : 'transparent',
      }}
    >
      {label && (
        <label
          aria-label={`${label}-dropdown-label`}
          style={{ alignSelf: 'flex-start' }}
        >
          <MDTypography sx={{ fontSize: '16px', textAlign: 'left' }}>
            {label}
          </MDTypography>
        </label>
      )}
      <MDBox
        aria-labelledby={label ? `${label}-dropdown-label` : undefined}
        ref={elementRef}
        sx={{
          borderRadius: borderRadiusValue,
          width: '100%',
          height: `${elementHeightRem}rem`,
          cursor: 'pointer',
          padding: '.5rem 1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: `1px solid ${colors.primary.main}`,
          position: 'relative'
        }}
        onClick={() => open ? closeMenu() : openMenu()}
      >
        <MDTypography
          color={MUIColors.PRIMARY}
          sx={{ ...disallowTextSelect, textTransform: 'capitalize' }}
          variant='button'
        >
          {value}
        </MDTypography>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            transition: `all ${transitionSpeed} ease`,
            transform: `rotate(${open ? '180deg' : '0'})`
          }}
          color={colors.primary.main}
        />
        <MDBox
          shadow={Shadows.SMALL}
          sx={{
            transition: `height ${transitionSpeed} ease`,
            overflow: 'hidden',
            width: '100%',
            position: 'absolute',
            zIndex: 200,
            left: 0,
            top: `${elementHeightRem}rem`,
            height: open ? `${elementHeightRem * options.length}rem` : '0rem',
            backgroundColor: colors.white.main,
            borderBottomLeftRadius: borderRadiusValue,
            borderBottomRightRadius: borderRadiusValue,
          }}
        >
          {options.map((option, i, arr) => (
            <MDBox
              key={option.value}
              sx={{
                width: '100%',
                height: `${elementHeightRem}rem`,
                transition: `background-color ${transitionSpeed} ease`,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '.5rem 1rem',
                overflow: 'hidden',
                borderTopLeftRadius: i === 0 ? borderRadiusValue : 'unset',
                borderTopRightRadius: i === 0 ? borderRadiusValue : 'unset',
                borderBottomLeftRadius: i === arr.length - 1 ? borderRadiusValue : 'unset',
                borderBottomRightRadius: i === arr.length - 1 ? borderRadiusValue : 'unset',
                '&:hover': {
                  backgroundColor: colors.disabled.main,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                option.handleClick();
                closeMenu();
              }}
            >
              <MDTypography
                color={MUIColors.PRIMARY}
                sx={{ ...disallowTextSelect, textTransform: 'capitalize' }}
                variant='button'
              >
                {option.value}
              </MDTypography>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </div>
  );
};

export default DropdownSelect;
