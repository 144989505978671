import React, { HTMLAttributes, useState } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardCover } from '@mui/joy';
import { Card } from '@mui/material';
import colors from 'assets/theme/base/colors';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import {
  containerStyles,
  getDegenColor,
  getJointFromFullJoint,
  getJointSideFromFullJoint,
  getPROColorAndLabel,
  getTreatmentRecco,
  getWorstCaseDegeneration,
} from 'helpers/helpers';
import { TreatmentOutcomes } from 'models/AppointmentModels';
import { MUIColors, Shadows } from 'models/StyleModels';
import { PRO } from 'models/SurveyModels';
import {
  DegenerationLevels,
  FullJoints,
  Xray,
  normalizedViewTypes,
} from 'models/XrayModels';
import { loadingAnimation } from 'assets/animations/animationStyles';

interface JointOutcomesCardLoaderProps {
  displayVertical: boolean;
}

export const JointOutcomesCardLoader: React.FC<JointOutcomesCardLoaderProps> = ({ displayVertical }) => (
  <MDBox
    shadow={Shadows.SMALL}
    {...containerStyles({
      flexDirection: 'column',
      height: 'fit-content',
      borderRadius: '8px',
      overflow: 'hidden',
    })}
  >
    <MDBox {...containerStyles({
      width: '100%',
      height: '3.1rem',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.secondary.focus,
      padding: '.5rem 1rem',
      ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
    })} />
    <MDBox
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '1rem',
      }}
    >
      <MDBox
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: displayVertical ? 'column' : 'row',
          justifyContent: 'center',
          padding: '.5rem',
          textAlign: 'center',
          height: '3.06rem',
          ...loadingAnimation('backgroundColor'),
        }}
      />
    </MDBox>
    <MDBox
      sx={{
        width: '100%',
        backgroundColor: colors.white.main,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        gap: '.5rem',
      }}
    >
      <MDBox
        shadow={Shadows.MEDIUM}
        {...containerStyles({
          height: '2.5rem',
          padding: '.25rem',
          display: 'flex',
          flexDirection: displayVertical ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          position: 'relative',
          backgroundColor: colors.grey[100],
          overflow: 'hidden',
          borderRadius: '8px',
          textAlign: 'center',
          ...loadingAnimation('backgroundColor'),
        })}
      />
      <MDBox {...containerStyles({
        width: '100%',
        display: 'flex',
        gap: '.5rem',
        flexDirection: displayVertical ? 'column' : 'row',
        alignItems: displayVertical ? 'flex-start' : 'center',
      })}>
        <Card
          {...containerStyles({
            width: displayVertical ? ' 100%' : '50%',
            height: '15rem',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            color: colors.white.main,
            ...loadingAnimation('backgroundColor'),
          })}
        />
        <Card
          {...containerStyles({
            width: displayVertical ? ' 100%' : '50%',
            height: '15rem',
            display: 'flex',
            borderRadius: '8px',
            border: '5px solid transparent',
            overflow: 'hidden',
            backgroundColor: colors.grey[300],
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            ...loadingAnimation('backgroundColor'),
          })}
        />
      </MDBox>
    </MDBox>
    <MDBox {...containerStyles({
      width: '100%',
      height: '3.75rem',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.disabled.focus,
      padding: '.75rem 1rem',
      ...loadingAnimation('backgroundColor'),
    })} />
  </MDBox>
);

type JointOutcomesCardProps = {
  fullJoint: FullJoints;
  priority: number;
  pro: PRO;
  xrays: Xray[];
  thumbnailXray: Xray | undefined;
  jointDegenOutcomes: DegenerationLevels[];
  displayVertical?: boolean;
  allXraysConfirmed: boolean;
  handleConfirmXrays(): void; // not in use, holdover from prev functionality
  handleClickReview(): void;
  confirmRequestLoading?: boolean;
  roiImgUrls: { [key: string]: string } | undefined;
  roiImagesLoading: boolean;
  hideCTA?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const JointOutcomesCard: React.FC<JointOutcomesCardProps> = ({
  fullJoint,
  priority,
  pro,
  xrays,
  thumbnailXray,
  jointDegenOutcomes,
  displayVertical,
  allXraysConfirmed,
  handleConfirmXrays, // not in use, holdover from prev functionality
  handleClickReview,
  confirmRequestLoading,
  roiImgUrls,
  roiImagesLoading,
  hideCTA,
  ...props
}) => {
  // at this point in the current flow all PROs should have scores. consider
  // refactoring this to be more typesafe though in case card is eventually used elsewhere
  const overallProScore = parseInt(pro.scores?.overall.toFixed(0) || '0');

  const joint = getJointFromFullJoint(fullJoint);
  const jointSide = getJointSideFromFullJoint(fullJoint);

  interface TreatmentLabelsType {
    knee: { [TO in TreatmentOutcomes]: string };
    hip: { [TO in TreatmentOutcomes]: string };
  }

  const treatmentLabels: TreatmentLabelsType = {
    knee: {
      [TreatmentOutcomes.TOTAL]: 'Operative - TKR',
      [TreatmentOutcomes.PARTIAL]: 'Operative - TKR,  Consider Partial Replacement',
      [TreatmentOutcomes.NON_OP]: 'Non-Operative',
      [TreatmentOutcomes.OP_IF_WORSENS]: 'Surgery if Symptoms Worsen',
    },
    hip: {
      [TreatmentOutcomes.TOTAL]: 'Operative', // hips can only receive total replacements.
      [TreatmentOutcomes.PARTIAL]: 'Operative',
      [TreatmentOutcomes.NON_OP]: 'Non-Operative',
      [TreatmentOutcomes.OP_IF_WORSENS]: 'Surgery if Symptoms Worsen',
    },
  };

  const treatmentRecco = getTreatmentRecco({ xrays, pro });

  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);

  const [xrayThumbHovered, setXrayThumbHovered] = useState<boolean>(false);

  return (
    <MDBox
      shadow={Shadows.SMALL}
      {...containerStyles({
        flexDirection: 'column',
        height: 'fit-content',
        borderRadius: '8px',
        overflow: 'hidden',
        ...props.style,
      })}
    >
      <MDBox {...containerStyles({
        width: '100%',
        height: 'fit-content',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.secondary.focus,
        padding: '.5rem 1rem',
      })}>
        <MDTypography
          textTransform='capitalize'
          variant='h4'
          color={MUIColors.WHITE}
        >
          {fullJoint}
        </MDTypography>
        <MDTypography fontWeight='bold' variant='body1' color={MUIColors.WHITE}>
          Priority {priority}
        </MDTypography>
      </MDBox>
      <MDBox
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '1rem',
        }}
      >
        <MDBox
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: displayVertical ? 'column' : 'row',
            justifyContent: 'center',
            backgroundColor: !allXraysConfirmed ? colors.error.focus : colors.badgeColors.success.background,
            padding: '.5rem',
            color: !allXraysConfirmed ? colors.white.main : colors.success.dark,
            textAlign: 'center',
            height: 'fit-content',
          }}
        >
          <MDTypography variant='body1' fontWeight={!allXraysConfirmed ? 'bold' : 'regular'}>
            {!allXraysConfirmed ? hideCTA ? 'Analysis Unconfirmed' : 'ACTION REQUIRED - Confirm X-ray Analysis' : 'Analysis Confirmed'}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          width: '100%',
          backgroundColor: colors.white.main,
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '.5rem',
        }}
      >
        <MDBox
          shadow={Shadows.MEDIUM}
          {...containerStyles({
            height: 'fit-content',
            padding: '.25rem',
            display: 'flex',
            flexDirection: displayVertical ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            position: 'relative',
            backgroundColor: colors.grey[100],
            overflow: 'hidden',
            borderRadius: '8px',
            textAlign: 'center',
          })}
        >
          <div
            style={{
              width: '200%',
              height: '100%',
              position: 'absolute',
              top: '-50%',
              left: '-50%',
              borderRadius: '50%',
              opacity: '.1',
              zIndex: 3,
              backgroundColor: 'white',
            }}
          />
          <div
            style={{
              width: displayVertical ? '100%' : '50%',
              display: 'flex',
              flexDirection: displayVertical ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography textTransform='uppercase' sx={{ color: colors.secondary.focus }} fontWeight='bold' variant='body2'>
              Treatment Pathway:
            </MDTypography>
          </div>
          <div
            style={{
              width: displayVertical ? '100%' : '50%',
              display: 'flex',
              flexDirection: displayVertical ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '5rem',
            }}
          >
            <MDTypography
              variant='body1'
              color={MUIColors.PRIMARY}
            >
              {treatmentRecco ? treatmentLabels[getJointFromFullJoint(fullJoint)][treatmentRecco] : null}
            </MDTypography>
          </div>
        </MDBox>
        <MDBox {...containerStyles({
          width: '100%',
          display: 'flex',
          gap: '.5rem',
          flexDirection: displayVertical ? 'column' : 'row',
          alignItems: displayVertical ? 'flex-start' : 'center',
        })}>
          <Card
            {...containerStyles({
              width: displayVertical ? ' 100%' : '50%',
              height: '15rem',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              overflow: 'hidden',
              backgroundColor: getPROColorAndLabel(overallProScore).color,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              color: colors.white.main,
            })}
          >
            <div
              style={{
                width: '200%',
                height: '100%',
                position: 'absolute',
                top: '-50%',
                left: '-50%',
                borderRadius: '50%',
                opacity: '.1',
                zIndex: 3,
                backgroundColor: 'white',
              }}
            />
            <MDTypography variant='h5' textTransform='capitalize' textAlign='center'>
              Overall <br /> {joint} Health
            </MDTypography>
            <MDTypography fontWeight='regular' sx={{ fontSize: '80px', lineHeight: '104px' }}>
              {overallProScore}
            </MDTypography>
            <MDTypography variant='h5' textTransform='capitalize' fontWeight='regular'>
              {getPROColorAndLabel(overallProScore).label}
            </MDTypography>
          </Card>
          <Card
            {...containerStyles({
              width: displayVertical ? ' 100%' : '50%',
              height: '15rem',
              display: 'flex',
              borderRadius: '8px',
              border: worstCaseDegen ? `5px solid ${getDegenColor(worstCaseDegen)}` : 'none',
              overflow: 'hidden',
              backgroundColor: colors.grey[300],
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            })}
            onMouseOver={() => setXrayThumbHovered(true)}
            onMouseLeave={() => setXrayThumbHovered(false)}
          >
            {thumbnailXray && (
              <MDBox
                sx={{
                  width: '100%',
                  height: 'fit-content',
                  padding: '.25rem',
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.275)',
                  position: 'absolute',
                  bottom: xrayThumbHovered ? '0' : '-100%',
                  left: 0,
                  zIndex: 2,
                  transition: 'bottom 250ms ease',
                }}
              >
                <MDTypography color={MUIColors.WHITE} sx={{ textTransform: 'capitalize', fontSize: '14px' }}>
                  {normalizedViewTypes[thumbnailXray.client_provided_view_type]} View
                </MDTypography>
              </MDBox>
            )}
            {!roiImagesLoading && roiImgUrls && thumbnailXray && (
              <CardCover>
                <img src={roiImgUrls[thumbnailXray.prediction_results![`${jointSide}ROI`]!] || roiImgUrls[thumbnailXray.prediction_results!.singleROI!]} alt="X-ray" />
              </CardCover>
            )}
            {(roiImagesLoading || !roiImgUrls || !thumbnailXray) && (
              <FontAwesomeIcon icon={faCircleNotch} color={colors.grey[500]} spin />
            )}
          </Card>
        </MDBox>
      </MDBox>
      <MDBox {...containerStyles({
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.disabled.focus,
        padding: '.75rem 1rem',
      })}>
        <MDBox {...containerStyles({
          width: '100%',
          display: 'flex',
          flexDirection: displayVertical ? 'column' : 'row',
          alignItems: displayVertical ? 'flex-start' : 'center',
          justifyContent: 'flex-end',
          gap: '.5rem',
        })}>
          <MDBox {...containerStyles({
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
          })}>
            <MDButton
              fullWidth
              variant='outlined'
              color={MUIColors.SECONDARY}
              style={{ backgroundColor: colors.white.main }}
              onClick={handleClickReview}
            >
              {allXraysConfirmed || hideCTA ? 'Review Analysis Details' : 'Review and Confirm Analysis'}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default JointOutcomesCard;
