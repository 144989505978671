// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";

// Material Dashboard 2 React contexts
import { MUIColors } from "models/StyleModels";

interface DataTableHeadCellProps {
  width?: string | number;
  children: React.ReactNode;
  sorted?:  'none' | 'asce' | 'desc';
  align: 'left' | 'right' | 'center';
}

const DataTableHeadCell: React.FC<DataTableHeadCellProps> = ({
  width,
  children,
  sorted,
  align,
  ...rest
}) => (
  <MDBox
    component="th"
    width={width}
    py={1.5}
    px={3}
    sx={({ palette: { light }, borders: { borderWidth } }: any) => ({
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    })}
  >
    <MDBox
      {...rest}
      position="relative"
      textAlign={align}
      color={MUIColors.SECONDARY}
      opacity={0.7}
      sx={({ typography: { size, fontWeightBold } }: any) => ({
        fontSize: size.xs,
        fontWeight: fontWeightBold,
        textTransform: "capitalize",
        cursor: sorted && "pointer",
        userSelect: sorted && "none",
      })}
    >
      {children}
      {sorted && (
        <MDBox
          position="absolute"
          top={0}
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
          })}
        >
          <MDBox
            position="absolute"
            top={-6}
            color={sorted === "asce" ? MUIColors.TEXT : MUIColors.SECONDARY}
            opacity={sorted === "asce" ? 1 : 0.5}
          >
            <Icon>arrow_drop_up</Icon>
          </MDBox>
          <MDBox
            position="absolute"
            top={0}
            color={sorted === "desc" ? MUIColors.TEXT : MUIColors.SECONDARY}
            opacity={sorted === "desc" ? 1 : 0.5}
          >
            <Icon>arrow_drop_down</Icon>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  </MDBox>
);

export default DataTableHeadCell;
