// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";

// Material Dashboard 2 React context
import { MUIColors } from "models/StyleModels";

interface PageLayoutProps {
  background?: MUIColors;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ background, children }) => (
  <MDBox
    width="100vw"
    height="100%"
    minHeight="100vh"
    bgColor={background}
    sx={{ overflowX: "hidden" }}
  >
    {children}
  </MDBox>
);

export default PageLayout;
