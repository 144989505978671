import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Card } from "@mui/material";

// MD components
import MDBox from "components/atoms/MDBox/MDBox";
import Footer from "components/molecules/Footer/Footer";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import { Breakpoints, MUIColors } from "models/StyleModels";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { Patient } from "models/PatientModels";
import { useBreakpoints } from "hooks/useBreakpoints";

export interface TriageFlowLayouProps {
  children?: React.ReactNode;
  singlePatient?: Patient | null;
  title: string
}

const TriageFlowLayout: React.FC<TriageFlowLayouProps> = ({
  children,
  singlePatient,
  title,
}) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });
  const { breakpointBreached: lgBreakpointBreachd } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  return (
    <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
      <MDAppBar hideQuickStartButtons />
      <MDBox pb={3}>
        <Grid item xs={12} pt={2}>
          <Card>
            <MDBox
              mt={-3}
              py={2}
              px={3}
              bgColor={MUIColors.SECONDARY}
              borderRadius="lg"
              coloredShadow="secondary"
              display="flex"
              flexDirection={lgBreakpointBreachd ? 'column' : 'row'}
              justifyContent="center"
              alignItems="center"
              height={lgBreakpointBreachd ? "fit-content" : "5rem"}
              position="relative"
              zIndex={3}
            >
              {singlePatient && <MDBox
                position={lgBreakpointBreachd ? 'inherit' : 'absolute'}
                paddingBottom={lgBreakpointBreachd ? '2rem' : 'absolute'}
                top='.8rem'
                left='1rem'
              >
                <MDTypography
                  sx={{ marginBottom: '0' }}
                  variant='h5'
                  color={MUIColors.WHITE}
                >
                  {singlePatient?.first_name} {singlePatient?.last_name}
                </MDTypography>
                <MDTypography
                  fontSize='1rem'
                  color={MUIColors.WHITE}
                >
                  Patient ID: {singlePatient?.external_id}
                </MDTypography>
              </MDBox>}

              <MDTypography
                sx={{ marginBottom: breakpointBreached ? '1rem' : '0' }}
                variant="h2"
                color={MUIColors.WHITE}
              >
                {title}
              </MDTypography>
            </MDBox>
            <MDBox pb={3}>
              {children}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      <Footer />
    </Container>
  );
};

export default TriageFlowLayout;
