import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDAvatar from "components/atoms/MDAvatar/MDAvatar";

// Images
import colors from "assets/theme/base/colors";
import { Breakpoints, MUIColors, Shadows, Sizes } from "models/StyleModels";
import borders from "assets/theme/base/borders";
import { useBreakpoints } from "hooks/useBreakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faX } from "@fortawesome/free-solid-svg-icons";
import AvatarUploadPortal from "../AvatarUploadPortal/AvatarUploadPortal";
import { DBUser } from "models/UserModels";
import { Modal } from "@mui/material";
import { containerStyles } from "helpers/helpers";

interface ProfileContainerProps {
  children: React.ReactNode;
  header: string;
  subHeader?: string;
  icon?: boolean;
  shiftHeaderUp?: boolean;
  user?: DBUser
}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  children,
  header,
  subHeader,
  icon,
  shiftHeaderUp,
  user
}) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });
  const modalContainerStyles = {
    padding: '3rem',
    backgroundColor: colors.white.main,
    width: breakpointBreached ? '90%' : 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [logoHovered, setLogoHovered] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="11.75rem"
        borderRadius="xl"
        sx={{
          backgroundColor: colors.secondary.main,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        {shiftHeaderUp && (
          <Grid
            container
            spacing={icon ? 3 : 2}
            alignItems="center"
            justifyContent="center"
            m={icon ? 1 : undefined}
            sx={{ transform: 'translateY(-4rem)' }}
          >
            {icon && (
              <Grid item>
                <MDAvatar src={user?.avatar_url ?? ''} alt="profile-image" size={Sizes.LARGE} shadow={Shadows.SMALL} />
              </Grid>
            )}
            <Grid item sx={!icon ? { marginTop: '1rem', marginBottom: '1rem' } : {}}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography
                  color={MUIColors.WHITE}
                  variant="h4"
                  fontWeight="medium"
                  sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                >
                  {header}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -16,
          mx: 2,
          py: 2,
          px: 2,
        }}
      >
        {!shiftHeaderUp && (
          <Grid
            container
            spacing={icon ? 3 : 2}
            alignItems="center"
            m={icon ? 1 : undefined}
            onMouseOver={() => setLogoHovered(true)}
            onMouseLeave={() => setLogoHovered(false)}>
            {icon && (
              <Grid item position="relative" style={{ cursor: 'pointer' }}>
                <MDAvatar
                  src={user?.avatar_url ?? ''}
                  alt="profile-image"
                  size={Sizes.X_LARGE}
                  shadow={Shadows.SMALL} />

                {logoHovered && (
                  <FontAwesomeIcon
                    style={{
                      position: 'absolute',
                      top: '1rem',
                      right: '-.5rem',
                      opacity: .8,
                    }}
                    onClick={() => setOpenModal(true)}
                    icon={faPenToSquare}
                    color={colors.grey[500]} />)}
              </Grid>
            )}

            <Grid item sx={!icon ? { marginTop: '1rem', marginBottom: '1rem' } : {}}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {header}
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {subHeader}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
        {children}
      </Card>

      <Modal
        {...containerStyles({
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
        open={!!openModal}
        onClose={closeModal}
      >
        <MDBox
          shadow={Shadows.SMALL}
          borderRadius={borders.borderRadius.lg}
          sx={modalContainerStyles}
          position='relative'
        >
          <FontAwesomeIcon
            role="button"
            tabIndex={0}
            onClick={() => setOpenModal(false)}
            style={{ position: 'absolute', top: '1rem', right: '1rem', cursor: 'pointer' }}
            icon={faX}
          />
          <MDTypography sx={{ marginBottom: '2rem' }} color={MUIColors.SECONDARY} fontWeight="medium">
            Upload Profile Avatar
          </MDTypography>
          <AvatarUploadPortal
            user={user}
            handleSuccess={closeModal}
            handleError={closeModal}
          />
        </MDBox>
      </Modal>
    </MDBox>
  );
}

export default ProfileContainer;
