const breakpoints = {
  values: {
    xs: 1,
    sm: 576,
    md: 768,
    lg: 968,
    xl: 1024,
    xxl: 1200,
  },
};

export default breakpoints;
