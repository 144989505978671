import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { NewPRO, PRO } from 'models/SurveyModels';
import { PROsQueryCache, proQueryKeys } from './useProData';
import { Appointment } from 'models/AppointmentModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

interface UseProMutationsArgs {
  appointment: Appointment | null;
}

export const useProMutations = ({ appointment }: UseProMutationsArgs) => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();

  const postPro = async (pro: NewPRO): Promise<{ data: { pro: PRO } }> => {
    const newPRO = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/pros/?org_id=${user?.orgs[0].org_id}`,
      data: pro,
      token: accessToken,
    });
    return newPRO as unknown as { data: { pro: PRO } };
  };

  const postMultiplePros = async (pros: NewPRO[]): Promise<{ data: { pros: PRO[] } }> => {
    const newPROs = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/pros/multi?org_id=${user?.orgs[0].org_id}`,
      data: pros,
      token: accessToken,
    });
    return newPROs as unknown as { data: { pros: PRO[] } };
  };

  const patchPro = async (updatedPro: PRO): Promise<{ data: { pro: PRO } }> => {
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/pros/${updatedPro.pro_id}?org_id=${user?.orgs[0].org_id}`,
      data: updatedPro,
      token: accessToken,
    });
    return resp as unknown as { data: { pro: PRO } };
  };

  const {
    mutateAsync: createPro,
    data: newPro,
    error: createProError,
    isLoading: createProLoading,
    isSuccess: createProSuccessful,
  } = useMutation(
    (pro: NewPRO) => postPro(pro),
    {
      onSuccess: (resp) => {
        const newPro = resp.data.pro;
        const prevPros: PROsQueryCache = queryClient
          .getQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment));

        if (prevPros) {
          queryClient.setQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment), {
            ...prevPros,
            data: {
              ...prevPros.data,
              pros: [...prevPros.data.pros, newPro],
            },
          });
        }
      },
    },
  );

  const {
    mutateAsync: createMultiplePros,
    data: newPros,
    error: createMultipleProsError,
    isLoading: createMultipleProsLoading,
    isSuccess: createMultipleProsSuccessful,
  } = useMutation(
    (pros: NewPRO[]) => postMultiplePros(pros),
    {
      onSuccess: (resp) => {
        const newPros = resp.data.pros;
        const prevPros: PROsQueryCache = queryClient
          .getQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment));

        if (prevPros) {
          queryClient.setQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment), {
            ...prevPros,
            data: {
              ...prevPros.data,
              pros: [...prevPros.data.pros, ...newPros],
            },
          });
        }
      },
    },
  );

  const {
    mutateAsync: updatePro,
    data: updatedPro,
    error: updateProError,
    isLoading: updateProLoading,
    isSuccess: updateProSuccessful,
  } = useMutation(
    (pro: PRO) => patchPro(pro),
    {
      onSuccess: (resp) => {
        const updatedPro = resp.data.pro;
        const prevPros: PROsQueryCache = queryClient
          .getQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment));

        if (prevPros) {
          queryClient.setQueryData<PROsQueryCache>(proQueryKeys.allProsKey(appointment), {
            ...prevPros,
            data: {
              ...prevPros.data,
              pros: prevPros.data.pros.map((pro) => pro.pro_id === updatedPro.pro_id ? updatedPro : pro),
            },
          });
        }
      },
    },
  );

  const patchUserCommittedPros = async (appointmentId: string): Promise<{ data: { success: boolean } }> => {
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/pros/commit-appointment-pros/${appointmentId}?org_id=${user?.orgs[0].org_id}`,
      data: updatedPro,
      token: accessToken,
    });
    return resp as unknown as { data: { success: boolean } };
  };

  const {
    mutateAsync: setUserCommittedPros,
    // data: updatedPro,
    error: setUserCommittedProsError,
    isLoading: setUserCommittedProsLoading,
    isSuccess: setUserCommittedProsProSuccessful,
  } = useMutation(
    (appointmentId: string) => patchUserCommittedPros(appointmentId)
  );

  return {
    createPro,
    newPro,
    createProError,
    createProLoading,
    createProSuccessful,
    createMultiplePros,
    newPros,
    createMultipleProsError,
    createMultipleProsLoading,
    createMultipleProsSuccessful,
    updatePro,
    updateProError,
    updateProLoading,
    updateProSuccessful,
    updatedPro: updatedPro?.data.pro,

    setUserCommittedPros,
    setUserCommittedProsError,
    setUserCommittedProsLoading,
    setUserCommittedProsProSuccessful,
  };
};
