// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { MUIColors } from "models/StyleModels";

const Footer: React.FC = () => {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      pb={5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color={MUIColors.TEXT}
        fontSize={size.sm}
      >
        Copyright &copy; {new Date().getFullYear()}, Hip and Knee Step by Step. All rights reserved.
      </MDBox>
    </MDBox>
  );
};

export default Footer;
