import { useState } from 'react';

type FormErrors<T> = {
    [K in keyof T]?: string;
}

const useForm = <T>(initialValues: T) => {

    const [values, setValues] = useState<T>(initialValues);
    const [errors, setErrors] = useState<FormErrors<T>>({});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const setFormValue = <K extends keyof T>(key: K, value: T[K]) => {
        setValues({
            ...values,
            [key]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>, callback: () => void) => {
        event.preventDefault();
        if (callback) {
            callback();
        }
    };

    const validate = (name: keyof T, validationFunc: (value: any) => string | null) => {
        if (!validationFunc) return;
        const error = validationFunc(values[name]);
        setErrors({
            ...errors,
            [name]: error,
        });
    };

    return {
        values,
        errors,
        handleChange,
        setFormValue,
        handleSubmit,
        validate,
    };
}

export default useForm;