import colors from "assets/theme/base/colors"
import MDBox from "components/atoms/MDBox/MDBox"
import MDButton from "components/atoms/MDButton/MDButton"

interface PreExistingOrgStepProps {
    affirmative: () => void
    negative: () => void
    back: () => void
}

const PreExistingOrgStep: React.FC<PreExistingOrgStepProps> = ({
    affirmative,
    negative,
    back
}) => {
    return (
        <MDBox display="grid" justifyContent="center">
            <div style={{
                marginBottom: "2rem",
                maxWidth: "15rem",
                fontSize: "1.4rem",
                textAlign: "center"
            }}>
                <span style={{ color: colors.white.main, fontWeight: 400, }}>
                    Is your organization already using Joint Dx?
                </span>
            </div>

            <MDBox display="grid" gap="1rem" marginTop="1.5rem">
                <MDBox display="flex" gap="1rem" justifyContent="center">
                    <MDButton
                        variant='contained'
                        style={{ color: colors.text.main }}
                        onClick={affirmative}>
                        Yes
                    </MDButton>
                    <MDButton
                        variant='contained'
                        style={{ color: colors.text.main }}
                        onClick={negative}>
                        No
                    </MDButton>
                </MDBox>
                <MDButton
                    variant='text'
                    style={{ textDecoration: 'underline', color: colors.white.main }}
                    onClick={back}>
                    Back
                </MDButton>
            </MDBox>
        </MDBox>
    )
}

export default PreExistingOrgStep 