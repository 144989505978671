import { SurveyTypes } from "./SurveyModels";
import { FullJoints } from "./XrayModels";

export enum TreatmentOutcomes {
  PARTIAL = 'partial',
  TOTAL = 'total',
  NON_OP = 'nonOp',
  OP_IF_WORSENS = 'opIfWorsens',
}

export enum ReasonsForVisit {
  INJECTION = 'injection',
  NEW_RETURNING_PATIENT = 'new returning patient',
  PRE_OP = 'Pre-Op testing and education',
  EVALUATION = 'evaluation',
  POST_OP = 'Post-Op',
}

export enum AppointmentTimes {
  _0600AM = '6:00 AM',
  _0630AM = '6:30 AM',
  _0700AM = '7:00 AM',
  _0730AM = '7:30 AM',
  _0800AM = '8:00 AM',
  _0830AM = '8:30 AM',
  _0900AM = '9:00 AM',
  _0930AM = '9:30 AM',
  _1000AM = '10:00 AM',
  _1030AM = '10:30 AM',
  _1100AM = '11:00 AM',
  _1130AM = '11:30 AM',
  _0100PM = '1:00 PM',
  _0130PM = '1:30 PM',
  _0200PM = '2:00 PM',
  _0230PM = '2:30 PM',
  _0300PM = '3:00 PM',
  _0330PM = '3:30 PM',
}

export type AppointmentRankedJoints = { [FJ in FullJoints]?: number | null } | null;

export interface NewAppointment {
  patient_id: string;
  appointment_date: string | null;
  org_id: string;
  survey_type?: SurveyTypes; // TODO: eventually these will not be hardcoded, but instead defined by the user
  reason_for_visit?: ReasonsForVisit;
  physician?: string; // TODO: how to handle
  joints?: AppointmentRankedJoints;
  isTriage?: boolean
}

export interface Appointment extends NewAppointment {
  appointment_id: string;
  created_at: string;
  updated_at: string;
  appointment_date: string; // IMPORTANT: this should always be an ISO date string
  joints?: AppointmentRankedJoints;
}

export enum TriageXrayStatus {
  NO_XRAYS = 'NO_XRAYS',
  XRAYS_MISSING = 'XRAYS_MISSING',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  COMPLETED = 'COMPLETED',
  ANALYZING = 'ANALYZING',
  ANALYSIS_FAILED = 'ANALYSIS_FAILED',
}

export enum TriageProStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}