import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { Patient } from 'models/PatientModels';
import { useMemo } from 'react';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const patientQueryKeys = {
  allPatientsKey: 'allPatients',
  getSinglePatientKey: (patientId: string | undefined) => ['singlePatient', { activePatientId: patientId }],
};

export type PatientsQueryCache = {
  data: {
    patients: Patient[];
  };
  ts: number;
} | undefined;

export type PatientQueryCache = {
  data: {
    patient: Patient;
  };
  ts: number;
} | undefined;

interface UsePatientDataOptions {
  preventRefetch?: boolean;
  patientId?: string;
}

export const usePatientData = (options?: UsePatientDataOptions) => {
  const { accessToken, user } = useAuth();

  const getAllPatientsByOrg = async () => {
    if (!user || !accessToken) return;
    const data = await HttpService.get<{ patients: Patient[] }>({
      url: `${ENDPOINT_BASE_URL}/patients/?org_id=${user.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };

  const getSinglePatientByOrg = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activePatientId }] = queryKey;

    if (!user || !accessToken || !activePatientId) return;
    const data = await HttpService.get<{ patient: Patient }>({
      url: `${ENDPOINT_BASE_URL}/patients/${activePatientId}?org_id=${user.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };
  
  const {
    data: allPatientsData,
    error: allPatientsError,
    isFetching: allPatientsLoading,
    refetch: refetchAllPatients,
  } = useQuery(
    patientQueryKeys.allPatientsKey,
    getAllPatientsByOrg,
    { refetchOnMount: !options?.preventRefetch },
  );

  const {
    data: singlePatientData,
    error: singlePatientError,
    isFetching: singlePatientLoading,
    refetch: refetchSinglePatient,
  } = useQuery(
    patientQueryKeys.getSinglePatientKey(options?.patientId),
    getSinglePatientByOrg,
    {
      refetchOnMount: !options?.preventRefetch,
      enabled: !!options?.patientId,
    },
  );

  const allPatients = allPatientsData?.data.patients;

  // an object where each entry is compries of a key equal to a patient's ID, and
  // the value is the corresponding patient object. used to merge patient, appointment,
  // xray, and PRO information together on various tables in the app, as well as in the
  // useActivePatient hook. This value is memoized to hopefully maximize performance
  // around it, but there is a chance this method becomes untenable in the event that an
  // org manages thousands upon thousands of patients.
  const patientsMappedToIds = useMemo<{[key: string]: Patient} | undefined>(() => allPatients?.reduce((obj, patient) => {
    obj[patient.patient_id] = patient;
    return obj;
  }, {} as {[key: string]: Patient}), [allPatients]) || {};

  const singlePatient = singlePatientData?.data.patient;

  return {
    allPatients,
    allPatientsError,
    allPatientsLoading,
    refetchAllPatients,
    patientsMappedToIds,
    singlePatient,
    singlePatientError,
    singlePatientLoading,
    refetchSinglePatient,
  };
};
