import { CognitoUserPool } from "amazon-cognito-identity-js";

const UserPoolId = process.env.REACT_APP_COGNITO_POOL_ID;
const ClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

if (!UserPoolId || !ClientId) throw new Error('Cognito credentials not found. Check that your .env file is present.');



const poolData = {
    UserPoolId,
    ClientId,
};

export default new CognitoUserPool(poolData);
