import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";
import { MUIColors } from "models/StyleModels";

interface DataTableBodyCellProps {
  noBorder?: boolean;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
}

const DataTableBodyCell: React.FC<DataTableBodyCellProps> = ({ noBorder, align, children }) => {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: any) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        verticalAlign: "top",
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color={MUIColors.TEXT}
        sx={{ verticalAlign: "top" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

export default DataTableBodyCell;
