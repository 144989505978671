import React from 'react';
import { FullJoints } from 'models/XrayModels';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { MUIColors } from 'models/StyleModels';
import { ReactComponent as NoSelection } from 'assets/images/joint_selection_svgs/joint_select_none.svg';
import { ReactComponent as LHipSelected } from 'assets/images/joint_selection_svgs/joint_select_lhip.svg';
import { ReactComponent as RHipSelected } from 'assets/images/joint_selection_svgs/joint_select_rhip.svg';
import { ReactComponent as LKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_lknee.svg';
import { ReactComponent as RKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_rknee.svg';
import { shiftUpLowerRankedJoints } from 'helpers/helpers';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import { Appointment, AppointmentRankedJoints } from 'models/AppointmentModels';

interface SurveyJointSelectionProps {
  appointment: Appointment;
  appointmentJoints: AppointmentRankedJoints | undefined;
  setAppointmentJoints: React.Dispatch<React.SetStateAction<AppointmentRankedJoints | undefined>>;
}

const SurveyJointSelection: React.FC<SurveyJointSelectionProps> = ({
  appointment,
  appointmentJoints,
  setAppointmentJoints,
}) => {
  const handleJointSelection = (
    fullJoint: FullJoints,
  ) => {
    if (appointmentJoints && typeof appointmentJoints[fullJoint] === 'number') {
      let lowerRankedEntriesAdjusted: AppointmentRankedJoints | undefined;
      if (appointmentJoints[fullJoint]) {
        lowerRankedEntriesAdjusted = shiftUpLowerRankedJoints(appointmentJoints[fullJoint], appointmentJoints);
      }
      const newState = {
        ...appointmentJoints,
        ...lowerRankedEntriesAdjusted,
      };
      delete newState[fullJoint];
      setAppointmentJoints(newState);
      return;
    }
    setAppointmentJoints({
      ...appointmentJoints,
      [fullJoint]: appointment.joints && appointment.joints[fullJoint] ? appointment.joints[fullJoint] :  0,
    });
  };

  return (
    <>
      <SurveyQuestionMainText style={{ textAlign: 'center' }}>
        Confirm the joints in pain. You can make changes by clicking the <span style={{ fontWeight: 600 }}>circles:</span>
      </SurveyQuestionMainText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr',
          width: 'fit-content',
          height: '50vh',
          justifySelf: 'center',
          alignSelf: 'center'
        }}
      >
        <NoSelection style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
        {appointmentJoints && (
          <>
            {typeof appointmentJoints[FullJoints.LEFT_HIP] === 'number' && (
              <LHipSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {typeof appointmentJoints[FullJoints.RIGHT_HIP] === 'number' && (
              <RHipSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {typeof appointmentJoints[FullJoints.LEFT_KNEE] === 'number' && (
              <LKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {typeof appointmentJoints[FullJoints.RIGHT_KNEE] === 'number' && (
              <RKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
          </>
        )}
        <MDTypography
          fontWeight='bold'
          color={MUIColors.SECONDARY}
          sx={{
            justifySelf: 'flex-start',
            gridArea: '1 / 1 / 2 / 2',
            transform: 'translateX(1rem)',
          }}
        >
          Right
        </MDTypography>
        <MDTypography
          fontWeight='bold'
          color={MUIColors.SECONDARY}
          sx={{
            justifySelf: 'flex-end',
            gridArea: '1 / 1 / 2 / 2',
            transform: 'translateX(-1rem)',
          }}
        >
          Left
        </MDTypography>
        <MDBox
          sx={{
            gridArea: '1 / 1 / 2 / 2',
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: '.5fr 1fr',
            opacity: 0,
          }}
        >
          <MDButton
            variant='contained'
            color={MUIColors.SECONDARY}
            sx={{ gridArea: '1 / 1 / 2 / 2' }}
            onClick={() => handleJointSelection(FullJoints.RIGHT_HIP)}
          >
            r hip
          </MDButton>
          <MDButton
            variant='contained'
            color={MUIColors.SECONDARY}
            sx={{ gridArea: '1 / 2 / 2 / 3' }}
            onClick={() => handleJointSelection(FullJoints.LEFT_HIP)}
          >
            l hip
          </MDButton>
          <MDButton
            variant='contained'
            color={MUIColors.SECONDARY}
            sx={{ gridArea: '2 / 1 / 3 / 2' }}
            onClick={() => handleJointSelection(FullJoints.RIGHT_KNEE)}
          >
            r knee
          </MDButton>
          <MDButton
            variant='contained'
            color={MUIColors.SECONDARY}
            sx={{ gridArea: '2 / 2 / 3 / 3' }}
            onClick={() => handleJointSelection(FullJoints.LEFT_KNEE)}
          >
            l knee
          </MDButton>
        </MDBox>
      </div>
    </>
)};

export default SurveyJointSelection;
