import { CSSProperties } from "react";
import { keyframes } from "@mui/styled-engine";
import colors from "assets/theme/base/colors";
import { camelToKebab } from "helpers/helpers";

const loadingKeyframes = (cssProperty: keyof CSSProperties, from?: CSSProperties["color"], to?: CSSProperties["color"]) => keyframes(`
  0% {
    ${camelToKebab(cssProperty)}: ${from || colors.grey[200]};
  }

  50% {
    ${camelToKebab(cssProperty)}: ${to || colors.grey[300]};
  }

  100% {
    ${camelToKebab(cssProperty)}: ${from ||colors.grey[200]};
  }
`);

export const loadingAnimation = (
  cssProperty: keyof CSSProperties,
  from?: CSSProperties["color"],
  to?: CSSProperties["color"]) => ({
    animation: `${loadingKeyframes(cssProperty, from, to)} 2s infinite ease`,
  });
