import React from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import { containerStyles, highestValFromArray, shiftUpLowerRankedJoints } from 'helpers/helpers';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { FullJoints } from 'models/XrayModels';
import { useBreakpoints } from 'hooks/useBreakpoints';
import colors from 'assets/theme/base/colors';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import SurveyQuestionSubText from 'components/atoms/Text/SurveyQuestionSubText';
import { AppointmentRankedJoints } from 'models/AppointmentModels';

interface SurveyJointSelectionProps {
  appointmentJoints: AppointmentRankedJoints | undefined;
  setAppointmentJoints: React.Dispatch<React.SetStateAction<AppointmentRankedJoints | undefined>>;
}

const SurveyJointRanking: React.FC<SurveyJointSelectionProps> = ({
  appointmentJoints,
  setAppointmentJoints,
}) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const handleUnrank = (fullJointToUnrank: FullJoints) => {
    if (!appointmentJoints || !appointmentJoints[fullJointToUnrank]) return;
    const rankToRemove = appointmentJoints[fullJointToUnrank];
    if (typeof rankToRemove !== 'number') return;

    const lowerRankedEntriesAdjusted = shiftUpLowerRankedJoints(rankToRemove, appointmentJoints);

    const newState = {
      ...appointmentJoints,
      ...lowerRankedEntriesAdjusted,
      [fullJointToUnrank]: 0,
    };

    setAppointmentJoints(newState);
  };

  return (
    <MDBox
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <SurveyQuestionMainText style={{ textAlign: 'center' }}>
        Confirm joint priority in order of <span style={{ fontWeight: 600 }}>pain:</span>
      </SurveyQuestionMainText>
      <MDBox
        {...containerStyles({
          justifyContent: 'center',
          height: breakpointBreached ? '3.5rem' : '2.5rem',
          marginBottom: '2rem',
        })}
      >
        {appointmentJoints && Object.entries(appointmentJoints)
          .filter(([, rank]) => !rank)
          .map(([fullJoint,]) => {
            const ranks = Object.values(appointmentJoints || []).filter((rank) => typeof rank === 'number').map((rank) => rank);
            const highestRank = highestValFromArray(ranks as number[]);

            return (
              <MDButton
                key={fullJoint}
                variant='contained'
                color={MUIColors.SECONDARY}
                style={{
                  margin: '0 .5rem',
                }}
                onClick={() => setAppointmentJoints({
                  ...appointmentJoints,
                  [fullJoint]: highestRank + 1,
                })}
              >
                {fullJoint}
              </MDButton>
            );
          }
          )}
      </MDBox>
      <MDBox
        {...containerStyles({
          width: '100%',
          height: '18rem',
          marginBottom: '1rem'
        })}
      >
        <MDBox
          {...containerStyles({
            width: '20%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <SurveyQuestionSubText style={{ textAlign: 'center' }}>
            Most Painful
          </SurveyQuestionSubText>
          <div
            style={{
              backgroundColor: colors.secondary.main,
              width: '20px',
              height: '20px',
              borderRadius: '50%',
            }}
          />
          <div
            style={{
              borderLeft: `1px solid ${colors.secondary.main}`,
              height: '70%',
            }}
          />
          <div
            style={{
              backgroundColor: colors.secondary.main,
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              marginBottom: '.25rem',
            }}
          />
          <SurveyQuestionSubText style={{ textAlign: 'center' }}>
            Least Painful
          </SurveyQuestionSubText>
        </MDBox>
        <MDBox
          {...containerStyles({
            width: '80%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          })}
        >
          {appointmentJoints && Array.from(Array(Object.values(appointmentJoints).length).keys()).map((idx) => {
            const label = idx + 1;

            const rankedJoints = Object.values(appointmentJoints).filter((rank) => !!rank);
            const highestRank = rankedJoints.length
              ? rankedJoints.reduce((prev, current) => {
                return prev! > current! ? prev : current;
              }
              )
              : null;

            const matchingJointEntry = Object.entries(appointmentJoints).find(([, rank]) => rank === label);
            const matchingFullJoint = matchingJointEntry && matchingJointEntry[0];
            const matchingRank = matchingJointEntry && matchingJointEntry[1];

            const isActive = highestRank
              ? label === highestRank + 1
              : label === 1

            return (
              <MDBox
                key={label}
                {...containerStyles({
                  width: '100%',
                  height: '5rem',
                  alignItems: 'center',
                  borderRadius: '30px',
                  border: isActive ? `2px solid ${colors.secondary.main}` : 'unset',
                  padding: '.5rem 2rem',
                })}
              >
                <span style={{ fontWeight: 600, marginRight: '2rem' }}>{label}</span>
                {label === matchingRank && (
                  <MDButton
                    variant='contained'
                    color={MUIColors.SECONDARY}
                    onClick={() => handleUnrank(matchingFullJoint as FullJoints)}
                  >
                    {matchingFullJoint}
                    <FontAwesomeIcon icon={faX} style={{ marginLeft: '1rem' }} />
                  </MDButton>
                )}
                {isActive && (
                  <MDBox
                    {...containerStyles({
                      width: 'fit-content',
                      height: '100%',
                      borderRadius: '30px',
                      backgroundColor: colors.grey[300],
                      padding: '0 2.5rem',
                      alignItems: 'center',
                    })}
                  >
                    <span style={{ color: colors.grey[700] }}>Select a Joint</span>
                  </MDBox>
                )}
              </MDBox>
            )
          })}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default SurveyJointRanking;
