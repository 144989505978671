import { Typography } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import { capitalize } from 'helpers/helpers';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { MultiChoiceSelectOneQuestion } from 'models/SurveyModels';
import * as React from 'react';

interface ITriageJointSurveyProps {
    showMissing: boolean;
    updateSurvey: (survey: any) => void;
    jointSurvey: {
        title: string;
        sections: {
            sectionHeader?: string;
            sectionTitle: string;
            questions: (MultiChoiceSelectOneQuestion & { value: string })[];
        }[];
    }
}

const TriageJointSurvey: React.FunctionComponent<ITriageJointSurveyProps> = ({
    jointSurvey,
    updateSurvey,
    showMissing
}) => {
    const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });
    const { breakpointBreached: mdBreakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });
    return (
        <MDBox key={jointSurvey.title} display="grid" gap="2rem">
            {!breakpointBreached && (
                <MDBox display="flex" justifyContent="center" gap="1rem" alignItems="center">
                    <Typography variant='h2' key={jointSurvey.title}>{capitalize(jointSurvey.title)}</Typography>
                    <Typography
                        style={{
                            fontSize: '2rem',
                            textAlign: 'right',
                        }}
                        color={MUIColors.SECONDARY}>
                        Questions
                    </Typography>
                </MDBox>
            )}
            {jointSurvey.sections.map((sect) => (
                <MDBox key={sect.sectionTitle} display="grid" gap="1rem" padding="1rem">
                    <Typography color={MUIColors.PRIMARY} fontWeight="700" key={sect.sectionTitle}>{sect.sectionHeader}</Typography>
                    {sect.questions.map((question) => (
                        <div
                            key={question.label + sect.sectionTitle}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginBottom: '2rem',
                                gap: ".5rem",
                            }}>
                            {question.label && (
                                <MDBox display="flex" gap="1rem" >
                                    <Typography
                                        color={MUIColors.SECONDARY}
                                        fontWeight="400">
                                        {question.label}
                                    </Typography>
                                    {showMissing && !question.value && <Typography color={MUIColors.ERROR}>*</Typography>}
                                </MDBox>
                            )}

                            <div style={{
                                display: mdBreakpointBreached ? 'grid' : 'flex',
                                gap: mdBreakpointBreached ? '1rem' : 'none',
                                flexDirection: 'row',
                            }}>
                                {question.options.map((opt) => {
                                    const { value } = opt;

                                    return (
                                        <MDButton
                                            key={value}
                                            variant={
                                                question?.value === opt.value
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            color={MUIColors.PRIMARY}
                                            style={{ zIndex: 0 }}
                                            onClick={() => {
                                                const updatedSurvey = {
                                                    ...jointSurvey,
                                                    sections: jointSurvey.sections.map((updateSect) => {
                                                        if (updateSect.sectionTitle === sect.sectionTitle) {
                                                            return {
                                                                ...sect,
                                                                questions: sect.questions.map((updateQ) => {
                                                                    if (updateQ.label === question.label) {
                                                                        return {
                                                                            ...question,
                                                                            value: value
                                                                        }
                                                                    }

                                                                    return updateQ
                                                                })
                                                            }
                                                        }
                                                        return updateSect
                                                    })
                                                }

                                                updateSurvey(updatedSurvey)
                                            }}>
                                            {value}
                                        </MDButton>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </MDBox>
            ))}
        </MDBox >
    )
};

export default TriageJointSurvey;

