import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { OrgRole, Roles } from 'models/OrgModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const useOrgRoleMutations = () => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();

  interface UpdateOrgRoleRequest {
    orgRoleId: string | undefined;
    userId: string;
    newValues: { role: Roles },
  }

  const patchOrgRole = async (requestData: UpdateOrgRoleRequest): Promise<void | { data: { role: OrgRole; }; }> => {
    if (!requestData.orgRoleId || !requestData.userId) return;
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/org-roles/${requestData.orgRoleId}?org_id=${user?.orgs[0].org_id}&mp_id=${requestData.userId}`,
      data: requestData.newValues,
      token: accessToken,
    });
    return resp as unknown as { data: { role: OrgRole } };
  };

  const destroyOrgRole = async (orgRoleId: string, userId: string) => {
    await HttpService.destroy({
      url: `${ENDPOINT_BASE_URL}/org-roles/${orgRoleId}?org_id=${user?.orgs[0].org_id}&mp_id=${userId}`,
      token: accessToken,
    });
  };

  const {
    mutateAsync: deleteOrgRole,
    error: deleteOrgRoleError,
    isLoading: deleteOrgRoleLoading,
    isSuccess: deleteOrgRoleSuccessful,
  } = useMutation(
    ({ orgRoleId, userId }: { orgRoleId: string; userId: string }) => destroyOrgRole(orgRoleId, userId),
    { onSuccess: () => queryClient.refetchQueries() },
  );

  const {
    mutateAsync: updateOrgRole,
    error: updateOrgRoleError,
    isLoading: updateOrgRoleLoading,
    isSuccess: updateOrgRoleSuccessful,
  } = useMutation(
    (req: UpdateOrgRoleRequest) => patchOrgRole(req),
    { onSuccess: () => queryClient.refetchQueries() },
  );

  return {
    deleteOrgRole,
    deleteOrgRoleError,
    deleteOrgRoleLoading,
    deleteOrgRoleSuccessful,
    updateOrgRole,
    updateOrgRoleError,
    updateOrgRoleLoading,
    updateOrgRoleSuccessful,
  };
};
