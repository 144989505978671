import colors from "assets/theme/base/colors"

const OnboardingCompleteStep: React.FC = () => {
    return (
        <>
            <span style={{ color: colors.text.main, fontWeight: 700, }}>
                Thanks for filling out information for your clinic. We will be in touch soon in regarding your membership and account setup.
            </span>
        </>
    )
}

export default OnboardingCompleteStep 