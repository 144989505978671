import * as React from 'react';
import { Label, Container, Slider } from './Switch.styles';

interface ISwitchProps {
    isOn?: boolean;
    onSwitch: () => void;
}

const Switch: React.FunctionComponent<ISwitchProps> = ({ onSwitch, isOn }) => {
    return (
        <Container onClick={onSwitch} >
            <Slider isActive={isOn} />
            <Label isActive={isOn}>Yes</Label>
            <Label isActive={!isOn}>No</Label>
        </Container>
    );
};

export default Switch;

