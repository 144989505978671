import colors from "assets/theme/base/colors"
import MDButton from "components/atoms/MDButton/MDButton"
import MDInput from "components/atoms/MDInput/MDInput"
import { MUIBaseColors, MUIColors } from "models/StyleModels"
import { FormValues } from "../OnboardingPage"
import MDBox from "components/atoms/MDBox/MDBox"

interface ClinicNameStepProps {
    next: () => void
    back: () => void
    formValues: FormValues
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const ClinicNameStep: React.FC<ClinicNameStepProps> = ({
    next,
    back,
    formValues,
    handleChange
}) => {
    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <span style={{ color: colors.text.main, fontWeight: 700 }}>
                    What clinic do you work for?
                </span>
            </div>

            <MDInput
                dark
                sx={{ marginBottom: '2rem' }}
                type='name'
                label='Organization Name'
                fullWidth
                name="clinicName"
                onChange={handleChange}
                value={formValues.clinicName}
            />
            <MDBox display="grid">
                <MDButton
                    variant='contained'
                    color={MUIBaseColors.SECONDARY}
                    onClick={next}
                    disabled={!formValues.clinicName}
                >
                    Confirm
                </MDButton>
                <MDButton
                    variant='text'
                    style={{ textDecoration: 'underline' }}
                    color={MUIColors.PRIMARY}
                    onClick={back}
                >
                    Back
                </MDButton>
            </MDBox>
        </>

    )
}

export default ClinicNameStep 