import { useMutation } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { NewOrg, Org, OrgRole } from 'models/OrgModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const useOnboardingMutation = () => {
  const { accessToken } = useAuth();

  const postOrg = async (org: NewOrg): Promise<{ data: { org: Org, role: OrgRole } }> => {
    const newOrgAndRoleData = await HttpService.post<{ org: Org, role: OrgRole }>({
      url: `${ENDPOINT_BASE_URL}/onboarding/`,
      data: org,
      token: accessToken,
    });
    return newOrgAndRoleData;
  };

  const {
    mutateAsync: createOrgAndRole,
    data: newOrgAndRoleData,
    error: createOrgAndRoleError,
    isLoading: createOrgAndRoleLoading,
    isSuccess: createOrgAndRoleSuccessful,
  } = useMutation((org: NewOrg) => postOrg(org));

  const newOrg = newOrgAndRoleData?.data.org;
  const newRole = newOrgAndRoleData?.data.org;

  return {
    createOrgAndRole,
    newOrg,
    newRole,
    createOrgAndRoleError,
    createOrgAndRoleLoading,
    createOrgAndRoleSuccessful,
  };
};
