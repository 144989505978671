import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { DBUser } from 'models/UserModels';
import { UserQueryCache, userQueryKeys } from './useUserData';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

type UserUpdateInput = {
    full_name?: string
    phone?: string
    user_location?: string
    email?: string
    bio?: string
}

interface UploadAvatarRequest { file: File }

export const useUserMutation = () => {
    const { accessToken, user } = useAuth();
    const queryClient = useQueryClient();

    const patchPatient = async (userData: UserUpdateInput): Promise<{ data: { user: UserUpdateInput } }> => {
        const resp = await HttpService.patch({
            url: `${ENDPOINT_BASE_URL}/users/${user!.details.sub}?org_id=${user!.orgs[0].org_id}`,
            data: { ...userData, mp_id: user!.details.sub, },
            token: accessToken,
        });

        return resp as unknown as { data: { user: DBUser } };
    };

    const {
        mutateAsync: updateUser,
        data: updatedUserData,
        error: updateUserError,
        isLoading: updateUserLoading,
        isSuccess: updateUserSuccessful,
    } = useMutation((userData: UserUpdateInput) => patchPatient(userData));

    const updateAvatar = async ({ file }: UploadAvatarRequest): Promise<{ data: { user: DBUser } }> => {
        const resp = await HttpService.post({
            url: `${ENDPOINT_BASE_URL}/users/${user?.details.sub}/avatar?org_id=${user?.orgs[0].org_id}`,
            data: {
                file: file,
                // org_metadata: org_metadata,
            },
            token: accessToken,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return resp as unknown as { data: { user: DBUser } };
    };

    const {
        mutateAsync: uploadAvatar,
        data: uploadAvatarUserData,
        error: uploadAvatarError,
        isLoading: uploadAvatarLoading,
        isSuccess: uploadAvatarSuccessful,
    } = useMutation(
        (req: { file: File }) => updateAvatar(req),
        {
            onSuccess: (resp) => {
                const updatedUser = resp?.data?.user;
                if (!updatedUser) return;
                const prevUser: UserQueryCache = queryClient
                    .getQueryData<UserQueryCache>(userQueryKeys.userById(updatedUser.mp_id));

                if (prevUser) {
                    queryClient.setQueryData<UserQueryCache>(userQueryKeys.userById(updatedUser.mp_id), {
                        ...prevUser,
                        user: {
                            ...prevUser.user,
                            avatar_url: updatedUser.avatar_url
                        }
                    });
                }
            },
        },
    );

    return {
        updateUser,
        updatedUserData,
        updateUserError,
        updateUserLoading,
        updateUserSuccessful,
        uploadAvatar,
        uploadAvatarUserData,
        uploadAvatarError,
        uploadAvatarLoading,
        uploadAvatarSuccessful
    }
};
