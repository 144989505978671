import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { Appointment, TriageProStatus, TriageXrayStatus } from 'models/AppointmentModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const appointmentQueryKeys = {
  getAllAppointmentsKey: (dateISOString: string | undefined | null) => ['allAppointments', { activeDate: dateISOString?.split('T')[0] + 'T' }], // adds a 'T' character to the datestring per backend requirements
  getAllAppointsByPatientKey: (patientId: string | undefined) => ['allAppointmentsByPatient', { activePatientId: patientId }],
  getAppointmentKey: (dateISOString: string | undefined | null, appointmentId: string | undefined | null) => ['allAppointments', { activeDate: dateISOString, activeAppointmentId: appointmentId }],
  getTriageAppointmentStatusByPatientId: (patientIds: string[] | undefined) => ['getTriageAppointmentStatusByPatientId', { patientIds }],
};

export type AppointmentsQueryCache = {
  data: {
    appointments: Appointment[];
  };
  ts: number;
} | undefined;

export type AppointmentQueryCache = {
  data: {
    appointment: Appointment;
  };
  ts: number;
} | undefined;

export type TriageAppointmentStatusesCache = {
  data: {
    appointmentStatuses: TriageAppointmentStatuses
  };
};

export type TriageAppointmentStatuses = {
  [key: string]: {
    appointment_id: string;
    xray_status: TriageXrayStatus;
    appointment_date: string;
    pro_status: TriageProStatus;
  };
}

interface UseAppointmentDataOptions {
  preventRefetch?: boolean;
  selectedDateISOString?: string | null;
  appointmentId?: string | null;
  patientId?: string;
  patientIds?: string[];
  enabled?: boolean;
  polling?: boolean;
}

export const useAppointmentData = (opts?: UseAppointmentDataOptions) => {
  const { user, accessToken } = useAuth();

  const date = opts?.selectedDateISOString;
  const appointmentId = opts?.appointmentId;
  const patientId = opts?.patientId;
  const patientIds = opts?.patientIds;

  const getAppointmentsByDate = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activeDate }] = queryKey;
    if (!activeDate) return;
    const data = await HttpService.get<{ appointments: Appointment[] }>({
      url: `${ENDPOINT_BASE_URL}/appointments/?org_id=${user?.orgs[0].org_id}&appointment_date=${activeDate}`,
      token: accessToken,
    });
    return data;
  };

  // fetch all appointments for a given patient
  const getAppointmentsByPatient = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activePatientId }] = queryKey;
    if (!activePatientId) return;
    const data = await HttpService.get<{ appointments: Appointment[] }>({
      url: `${ENDPOINT_BASE_URL}/appointments/from-patient/${activePatientId}?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return data;
  };

  const getAppointment = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { activeDate, activeAppointmentId }] = queryKey;
    if (!activeDate || !activeAppointmentId) return;
    const data = await HttpService.get<{ appointment: Appointment }>({
      url: `${ENDPOINT_BASE_URL}/appointments/${activeAppointmentId}?org_id=${user?.orgs[0].org_id}&appointment_date=${activeDate}`,
      token: accessToken,
    });
    return data;
  };

  const {
    data: allAppointmentsData,
    error: allAppointmentsError,
    isFetching: allAppointmentsLoading,
    refetch: refetchAllAppointments,
  } = useQuery(
    appointmentQueryKeys.getAllAppointmentsKey(date),
    getAppointmentsByDate,
    {
      refetchOnMount: !opts?.preventRefetch,
      enabled: !!date,
    },
  );

  const {
    data: allAppointmentsByPatientData,
    error: allAppointmentsByPatientError,
    isFetching: allAppointmentsByPatientLoading,
    refetch: refetchAllAppointmentsByPatient,
  } = useQuery(
    appointmentQueryKeys.getAllAppointsByPatientKey(patientId),
    getAppointmentsByPatient,
    {
      refetchOnMount: !opts?.preventRefetch,
      enabled: !!patientId,
    },
  );

  const {
    data: appointmentData,
    error: appointmentError,
    isFetching: appointmentLoading,
    refetch: refetchAppointment,
  } = useQuery(
    appointmentQueryKeys.getAppointmentKey(date, appointmentId),
    getAppointment,
    {
      refetchOnMount: !opts?.preventRefetch,
      enabled: opts?.enabled || (!!date && !!appointmentId),
    },
  );

  const getTriageAppointmentStatusByPatientId = async ({ queryKey }: any) => {
    const [, { patientIds }] = queryKey;

    if (!patientIds) return;

    const patientIdsQueryString = patientIds.join('&patient_ids=');

    const data = await HttpService.get<{ appointmentStatuses: TriageAppointmentStatuses }>({
      url: `${ENDPOINT_BASE_URL}/appointments/patient-triage-appointment-status?org_id=${user?.orgs[0].org_id}&patient_ids=${patientIdsQueryString}`,
      token: accessToken,
    });
    return data;
  };

  const {
    data: triageAppointmentStatusesData,
    error: triageAppointmentStatusesError,
    isLoading: triageAppointmentStatusesLoading,
    refetch: refetchTriageAppointmentStatuses,
  } = useQuery(
    appointmentQueryKeys.getTriageAppointmentStatusByPatientId(patientIds),
    getTriageAppointmentStatusByPatientId,
    {
      ...(opts?.polling && { refetchInterval: 10000 })
    });

  const allAppointments = allAppointmentsData?.data.appointments || [];
  const allAppointmentsByPatient = allAppointmentsByPatientData?.data.appointments || [];
  const appointment = appointmentData?.data.appointment || null;
  const triageAppointmentStatuses = triageAppointmentStatusesData?.data.appointmentStatuses || {};

  const appointmentRequestsLoading = allAppointmentsLoading
    || appointmentLoading
    || allAppointmentsByPatientLoading;

  return {
    allAppointments,
    allAppointmentsError,
    allAppointmentsLoading,
    refetchAllAppointments,
    allAppointmentsByPatient,
    allAppointmentsByPatientError,
    allAppointmentsByPatientLoading,
    refetchAllAppointmentsByPatient,
    appointment,
    appointmentError,
    appointmentLoading,
    refetchAppointment,
    appointmentRequestsLoading,

    triageAppointmentStatuses,
    triageAppointmentStatusesError,
    triageAppointmentStatusesLoading,
    refetchTriageAppointmentStatuses,
  };
};
