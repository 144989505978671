import React from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import { generateQuestionComponent, containerStyles } from 'helpers/helpers';
import { MUIColors } from 'models/StyleModels';
import colors from 'assets/theme/base/colors';
import { SurveyQuestion, SurveySection, SurveySeries } from 'models/SurveyModels';
import { FullJoints } from 'models/XrayModels';

interface SingleScreenSurveyInterfaceProps {
  sections: SurveySection[];
  activeQuestionIndex: number;
  activeSurveys: SurveySeries | null;
  setActiveSurveys: (value: React.SetStateAction<SurveySeries | null>) => void;
  handleBack(): void;
  handleNext(): void;
  breakpointBreached: boolean;
  mutationState?: {
    loading?: boolean;
    error?: boolean;
  };
  readOnlyMode?: boolean;
}

const SingleScreenSurveyInterface: React.FC<SingleScreenSurveyInterfaceProps> = ({
  sections,
  activeQuestionIndex,
  activeSurveys,
  setActiveSurveys,
  handleBack,
  breakpointBreached,
  handleNext,
  mutationState,
  readOnlyMode,
}) => {
  const questions = sections.map((sect) => sect.questions.map((q) => ({ ...q, parentSurveyKey: sect.parentSurveyKey }))).flat();
  const activeQuestion: SurveyQuestion | null = questions[activeQuestionIndex] || null;
  const activeSurvey = activeSurveys && activeSurveys[activeQuestion?.parentSurveyKey as FullJoints];

  return (
    <>
      <MDTypography textAlign='center' variant='h3' mb={2}>
        <span style={{
          fontWeight: 600,
          color: colors.secondary.main,
          textTransform: 'capitalize',
        }}>
          {activeSurvey?.survey.surveyTitle}
        </span>
        {' '}
        Questions
      </MDTypography>
      {sections.map((sect) => (
        <React.Fragment key={sect.sectionKey}>
          {sect.sectionHeader && (
            <SurveyQuestionMainText bold>
              {sect.sectionHeader}
            </SurveyQuestionMainText>
          )}
          {sect.questions.map((q) => (
            <React.Fragment key={q.questionKey}>
              {generateQuestionComponent({
                question: q,
                isPatientInputMode: false,
                activeSurveys,
                setActiveSurveys,
                activeSurvey,
                breakpointBreached,
                readOnlyMode,
              })}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <MDBox {...containerStyles({
        justifyContent: 'space-between',
        padding: '3rem 0',
      })}>
        <div style={{ width: '50%', display: 'flex' }}>
          <MDButton
            variant='outlined'
            color={MUIColors.SECONDARY}
            onClick={handleBack}
          >
            Back
          </MDButton>
        </div>
        <div style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {mutationState?.error && (
            <MDTypography textAlign='right' mr='1rem' variant='body2' color={MUIColors.ERROR}>
              Something went wrong. Please try again.
            </MDTypography>
          )}
          {!readOnlyMode && (
            <MDButton
              variant='contained'
              color={MUIColors.SECONDARY}
              onClick={handleNext}
              disabled={
                !!questions.find((q) => activeSurvey
                  && (
                    !activeSurvey.responses[q.parentSectionKey]
                    || !activeSurvey.responses[q.parentSectionKey][q.questionKey]
                  )
                )
              }
            >
              {
                mutationState?.loading
                  ? <FontAwesomeIcon color='white' icon={faCircleNotch} spin />
                  : 'Save'
              }
            </MDButton>
          )}
        </div>
      </MDBox>
    </>
  );
};

export default SingleScreenSurveyInterface;
