import axios, { ResponseType } from 'axios';

// formats any passed in headers to also include the properly formatted Authorization header
const formatHeadersWithAuth = ({ token, ...rest }: { token: string | null;[key: string]: string | null }) => ({
  Authorization: `Bearer ${token}`,
  ...rest
});

interface RequestProps {
  url: string;
  token: string | null;
  data?: any;
  headers?: { [key: string]: string };
  responseType?: ResponseType;
}

const get = <T>({ url, token }: RequestProps) => axios<T>({
  method: 'get',
  headers: formatHeadersWithAuth({ token }),
  url,
});

const post = <T>({ url, token, data, headers, responseType, ...rest }: RequestProps) => axios<T>({
  method: 'post',
  headers: formatHeadersWithAuth({ token, ...headers }),
  url,
  data,
  responseType: responseType || 'json',
  ...rest
});

const patch = <T>({ url, token, data, headers, responseType, ...rest }: RequestProps) => axios<T>({
  method: 'patch',
  headers: formatHeadersWithAuth({ token, ...headers }),
  url,
  data,
  responseType: responseType || 'json',
  ...rest
});

const destroy = <T>({ url, token }: RequestProps) => axios<T>({
  method: 'delete',
  headers: formatHeadersWithAuth({ token }),
  url,
});

const funcs = {
  get,
  post,
  patch,
  destroy,
};

export default funcs;
