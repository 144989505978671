
import { forwardRef, createContext, useContext, useMemo } from "react";

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";

// Custom styles for MDPagination
import MDPaginationItemRoot from "components/atoms/MDPagination/MDPaginationItemRoot";

// import { PaginationProps } from "@mui/material";
import { MUIColors } from "models/StyleModels";

interface PaginationContextValues {
  size?: 'small' | 'medium' | 'large' | null;
  color?: MUIColors;
  variant?: 'gradient' | 'contained' | 'outlined';
}

// The Pagination main context
const Context = createContext<PaginationContextValues | undefined>(undefined);

export type MDPaginationProps = {
  item?: boolean;
  active?: boolean;
  variant?: 'gradient' | 'contained' | 'outlined';
  color?: MUIColors;
  children?: React.ReactNode;
  size?: "small" | "medium" | "large" | null | undefined;
  onClick?(): any;
};

const MDPagination = forwardRef<HTMLButtonElement, MDPaginationProps>(
  ({ item, variant, color, size, active, children, ...rest }, ref) => {
    const context = useContext(Context);
    const paginationSize = context ? context.size : null;

    const value = useMemo(() => ({ variant, color, size }), [variant, color, size]);

    return (
      <Context.Provider value={value}>
        {item ? (
          <MDPaginationItemRoot
            {...rest}
            ref={ref}
            // variant={active ? context?.variant : "outlined"}
            // color={active ? context?.color : MUIColors.SECONDARY}
            iconOnly
            circular
            ownerState={{ variant, active, paginationSize }}
          >
            {children}
          </MDPaginationItemRoot>
        ) : (
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ listStyle: "none" }}
          >
            {children}
          </MDBox>
        )}
      </Context.Provider>
    );
  }
);

export default MDPagination;
