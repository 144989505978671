import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useState,
  Dispatch,
} from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from 'services/cognitoUserPoolService';
import { User, UserDetails } from "models/UserModels";
import HttpService from 'services/httpService';
import { OrgRole } from "models/OrgModels";
import { ENDPOINT_BASE_URL } from "./constants";
import { clearUnauthorizedInviteWarning } from "services/checkUnauthorizedInviteService";
import { ActionSlug, checkPermissions } from "services/PermissionsService";

// This hook uses the Context API to share the authenticated user data across the app

export type Reducer<S, A> = (state: S, action: A) => S;

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

interface State {
  user: User | null;
  accessToken: string | null;
  loadingAuthState: boolean;
}

enum ActionTypes {
  SET_USER = 'SET_USER',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  LOADING_AUTH_STATE = 'LOADING_AUTH_STATE',
}

type Payloads = {
  [ActionTypes.SET_USER]: User | null;
  [ActionTypes.SET_ACCESS_TOKEN]: string | null;
  [ActionTypes.LOADING_AUTH_STATE]: boolean;
};

type StorageActions = ActionMap<Payloads>[keyof ActionMap<Payloads>];

interface Action<T extends keyof Payloads> {
  type: T;
  payload: Payloads[T];
}

interface AuthContextValues {
  state: State,
  setState: Dispatch<StorageActions>,
}

const AuthContext = createContext<AuthContextValues | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

// TODO: revisit type assertions below
const reducer: Reducer<State, Action<keyof Payloads>> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER: {
      return { ...state, user: action.payload as User | null };
    }
    case ActionTypes.SET_ACCESS_TOKEN: {
      return { ...state, accessToken: action.payload as string | null };
    }
    case ActionTypes.LOADING_AUTH_STATE: {
      return { ...state, loadingAuthState: action.payload as boolean };
    }
    default: {
      throw new Error(`Invalid action type: ${action.type}.`);
    }
  }
};

const stateInit = {
  user: null,
  accessToken: null,
  loadingAuthState: true,
};

// builds the user object returned by the useAuth hook from the Cognito user details, the user's
// OrgRoles, and the can method invoking checkPermissions.
const buildUserObject = (
  cognitoUserAttributesArray: CognitoUserAttribute[] | undefined,
  cognitoUser: CognitoUser,
  orgRoles: OrgRole[],
): User => {
  const userAttributes = cognitoUserAttributesArray?.reduce((obj, { Name, Value }) => {
    obj[Name] = Value;
    return obj;
  }, {} as { [key: string]: any });



  return {
    details: (userAttributes || []) as UserDetails,
    cognitoData: cognitoUser,
    orgs: orgRoles,
    can: (slug: ActionSlug) => checkPermissions(slug, orgRoles[0]),
  };

};

const SIGN_IN_FLAG_KEY = 'pushedFromSignIn';
export const setSignInFlag = () => sessionStorage.setItem(SIGN_IN_FLAG_KEY, 'true');
const removeSignInFlag = () => sessionStorage.removeItem(SIGN_IN_FLAG_KEY);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, dispatch] = useReducer(reducer, stateInit);
  return (
    <AuthContext.Provider value={{ state: store, setState: dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

interface UseAuthOptions {
  supressLoginOnMount: boolean;
}

export const useAuth = (options?: UseAuthOptions) => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth should be called within a AuthProvider');

  const { state, setState } = context;

  const { user, accessToken, loadingAuthState } = state;

  const setUser = useCallback(
    (_user) => setState({ type: ActionTypes.SET_USER, payload: _user }),
    [setState],
  );

  const setAccessToken = useCallback(
    (token) => setState({ type: ActionTypes.SET_ACCESS_TOKEN, payload: token }),
    [setState],
  );

  const toggleLoadingOn = useCallback(
    () => setState({ type: ActionTypes.LOADING_AUTH_STATE, payload: true }),
    [setState],
  );

  const toggleLoadingOff = useCallback(
    () => setState({ type: ActionTypes.LOADING_AUTH_STATE, payload: false }),
    [setState],
  );

  // adds a new role to the user object returned by this hook at index 0 of the
  // array, ensuring that it will be treated as the active org. this is invoked
  // during the onboarding process when a user creates a new org and an admin
  // OrgRole is created on that org for them.
  const addRoleToUserObj = useCallback(
    (role: OrgRole) => {
      if (!user) return;
      setState({
        type: ActionTypes.SET_USER,
        payload: {
          ...user,
          orgs: [role, ...user.orgs],
          can: (slug: ActionSlug) => checkPermissions(slug, role),
        },
      });
    },
    [setState, user],
  );

  const clearUserData = useCallback(
    () => {
      setUser(null);
      setAccessToken(null);
    },
    [setUser, setAccessToken],
  );

  // This is some frankenstein error handling from before I came onto the project, and
  // it doesn't always work as expected. it could probably use some love and improvement.
  const [authError, setAuthError] = useState<string | null>(null);

  const setUserDataFromUserPool = useCallback(
    async () => {
      const cognitoUser = UserPool.getCurrentUser();

      if (cognitoUser) {
        try {
          cognitoUser.getSession((err: Error | null, session: any) => {
            if (err) {
              setAuthError('Something went wrong. Please try again.');
              throw new Error(err.message || JSON.stringify(err));
            }

            if (!session.accessToken.jwtToken) {
              setAuthError('Something went wrong. Please try again.');
              cognitoUser.signOut();
              throw new Error('Error collecting JWT token.');
            }

            setAccessToken(session?.accessToken.jwtToken);

            cognitoUser.getUserAttributes(async (err, attributes) => {
              const rolesData = await HttpService.get<{ roles: OrgRole[] }>({
                url: `${ENDPOINT_BASE_URL}/sign-in/`,
                token: session.accessToken.jwtToken,
              }).catch((e) => {
                setAuthError('Something went wrong. Please try again.');
                cognitoUser.signOut();
                toggleLoadingOff();
                throw new Error(`Error collecting user roles: ${e.message || JSON.stringify(e)}`);
              });

              setUser(buildUserObject(attributes, cognitoUser, rolesData.data.roles));
              toggleLoadingOff();
            });
          });
        } catch (e: any) {
          setAuthError('Something went wrong. Please try again.');
          throw new Error(e.message || JSON.stringify(e));
        }
      } else {
        toggleLoadingOff();
      }
    },
    [setAccessToken, setUser, toggleLoadingOff],
  );

  const signIn = useCallback(
    async ({ username, password }) => {

      clearUnauthorizedInviteWarning();

      if (!username || !password) return;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: UserPool
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async () => {
          setUserDataFromUserPool();
          setAuthError(null);
          setSignInFlag();

          return;
        },
        // TODO: desperately needs refactoring - frankenstien code from previous iterations
        onFailure: (err) => {

          let error = ((err).toString()).split(': ').slice(-1)[0];
          error = error.replace('.', '');

          if (error === 'User is not confirmed') {
            return setAuthError('Account not verified. Please enter the email associated with your account');
          }

          setAuthError(error);
        },
      });
    },
    [setUserDataFromUserPool],
  );

  const signOut = useCallback(
    () => {
      if (!user) return;
      setUser(null);
      setAccessToken(null);
      user.cognitoData.signOut();
    },
    [user, setUser, setAccessToken],
  );

  // sets the logged into cognito user data into state on mount and page refresh.
  useEffect(() => {
    if (options?.supressLoginOnMount) return;

    const pushedFromSignIn = sessionStorage.getItem(SIGN_IN_FLAG_KEY);

    // originally, upon sign in, the `setUserDataFromUserPool` function and all of its contained requests were being called
    // twice - once during the invocation of `signIn` and then immediately again when pushed to the patient dashboard upon a
    // successful login, which triggers this useEffect call. this was causing duplicate and unecessary API calls to the org_role
    // table as well as multiple invocations of the various cognito API methods. this session storage flag is set above in the
    // `signIn` function immediately before the user is navigated to the patient dashboard, and then is removed here after peventing
    // the additional call to `setUserDataFromUserPool` when this side effect is fired. there may be a better way to handle this.
    if (pushedFromSignIn) {
      removeSignInFlag();
      return;
    }

    if (user) return;

    toggleLoadingOn();
    setUserDataFromUserPool();
  }, [
    user,
    toggleLoadingOn,
    setUserDataFromUserPool,
    options,
  ]);

  return {
    user,
    accessToken,
    toggleLoadingOn,
    toggleLoadingOff,
    loadingAuthState,
    signIn,
    signOut,
    addRoleToUserObj,
    clearUserData,
    authError,
  };
};
