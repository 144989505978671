import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { OrgRole } from 'models/OrgModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const userQueryKeys = {
  orgRolesFromOrg: 'orgRolesFromOrg',
};

export type OrgRolesFromOrgQueryCache = {
  data: {
    roles: OrgRole[];
  };
  ts: number;
} | undefined;

interface UseOrgRoleDataOptions {
  preventRefetch?: boolean;
}

export const useOrgRoleData = (options?: UseOrgRoleDataOptions) => {
  const { accessToken, user } = useAuth();

  const getOrgRolesFromOrg = async () => {
    const rolesResp = await HttpService.get<{ roles: OrgRole[] }>({
      url: `${ENDPOINT_BASE_URL}/org-roles/org?org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return rolesResp.data;
  };
  
  const {
    data: orgRolesFromOrgData,
    error: orgRolesFromOrgError,
    isFetching: orgRolesFromOrgLoading,
    refetch: refetchUsersWithRole,
  } = useQuery(
    userQueryKeys.orgRolesFromOrg,
    getOrgRolesFromOrg,
    { refetchOnMount: !options?.preventRefetch }
  );

  return {
    orgRolesFromOrg: orgRolesFromOrgData?.roles,
    orgRolesFromOrgError,
    orgRolesFromOrgLoading,
    refetchUsersWithRole,
  };
};
