import MDBox from "components/atoms/MDBox/MDBox"
import { FormValues } from "../SignUpPage"
import MDTypography from "components/atoms/MDTypography/MDTypography"
import MDInput from "components/atoms/MDInput/MDInput"
import MDButton, { MDButtonVariants } from "components/atoms/MDButton/MDButton"
import { MUIBaseColors, MUIBaseVariants, MUIColors } from "models/StyleModels"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

// AWS Cognito Authentication
import UserPool from 'services/cognitoUserPoolService';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useState } from "react"
import colors from "assets/theme/base/colors"

interface EmailCodeVerifyStepProps {
    next: () => void
    back: () => void
    formValues: FormValues
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    inviteEmail: string | null
    fromInvite: boolean
}

const SignUpStep: React.FC<EmailCodeVerifyStepProps> = ({
    next,
    back,
    formValues,
    handleChange,
    inviteEmail,
    fromInvite
}) => {
    const [validationErrors, setValidationErrors] = useState('');
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const createNewUser = async (event: React.FormEvent) => {
        const { fullName, email: formEmail, password, confirmPassword } = formValues
        const email = fromInvite && inviteEmail ? inviteEmail : formEmail

        const attributeList = [
            new CognitoUserAttribute({
                Name: 'name',
                Value: fullName
            }),
            new CognitoUserAttribute({
                Name: 'preferred_username',
                Value: email
            }),
            new CognitoUserAttribute({
                Name: 'email',
                Value: email
            })
        ];

        event.preventDefault();
        if (fullName && email && password && password === confirmPassword) {
            setValidationErrors('');
            setRequestLoading(true);
            UserPool.signUp(email, password, attributeList, [], (err, data) => {
                if (err) {
                    console.log(err)

                    let error = ((err).toString()).split(': ').slice(-1)[0];
                    error = error.replace('.', '');

                    if (error === 'Username cannot be of email format, since user pool is configured for email alias') {
                        error = 'Username cannot be of email format';
                    }

                    if (error === 'User already exists') {
                        error = 'Username already exists';
                    }
                    setValidationErrors(error);
                } else {
                    setValidationErrors('');
                    next();
                }
            });
            setRequestLoading(false);
        } else {
            setRequestLoading(false);
            setValidationErrors(formValues.password !== formValues.confirmPassword ? 'Passwords do not match' : 'Please fill out all fields');
        }
    };

    return (
        <MDBox>
            <MDBox
                id='signup'
                component='form'
                role='form'
                onSubmit={createNewUser}
            >
                <MDBox lineHeight={0} pb={3} px={3} mb={2} textAlign='center' display={validationErrors === '' ? 'none' : 'block'}>
                    <MDTypography variant='button' color={MUIColors.ERROR}>
                        {validationErrors}
                    </MDTypography>
                </MDBox>

                <MDBox mb={2}>
                    <MDInput
                        className='fnamesignup'
                        type='name'
                        name='fullName'
                        label='Full Name'
                        value={formValues.fullName}
                        fullWidth
                        onChange={(event) => handleChange(event)} />
                </MDBox>

                {!fromInvite && (
                    <MDBox mb={2}>
                        <MDInput
                            name='email'
                            type='email'
                            label='Email'
                            value={formValues.email}
                            fullWidth
                            onChange={(event) => handleChange(event)} />
                    </MDBox>
                )}

                <MDBox mb={2}>
                    <MDInput style={{ outline: 'white' }}
                        name="password"
                        label='Create a Password'
                        type='password'
                        value={formValues.password}
                        fullWidth
                        onChange={(event) => handleChange(event)} />
                </MDBox>

                <MDBox mb={2}>
                    <MDInput style={{ outline: 'white' }}
                        name="confirmPassword"
                        label='Confirm Password'
                        type='password'
                        value={formValues.confirmPassword}
                        fullWidth
                        onChange={(event) => handleChange(event)} />
                </MDBox>

                <MDBox mt={4}>
                    <MDButton
                        variant={MDButtonVariants.GRADIENT as unknown as MUIBaseVariants}
                        color={MUIColors.LIGHT as unknown as MUIBaseColors}
                        type='submit'
                        fullWidth
                    >
                        {requestLoading ? <FontAwesomeIcon icon={faCircleNotch} color='grey' spin /> : 'Sign up'}
                    </MDButton>
                </MDBox>

                <MDBox mt={3} textAlign='center'>
                    <MDTypography variant='button' color={MUIColors.WHITE}>

                        Already have an account?<br />

                        <MDTypography
                            component={Link}
                            to='/authentication/sign-in'
                            variant='button'
                            color={MUIColors.LIGHT}
                            fontWeight='medium'
                            textGradient>

                            Sign in

                        </MDTypography>
                    </MDTypography>
                </MDBox>

                <MDBox mt={3} textAlign='center'>

                    <MDButton
                        variant='text'
                        style={{ textDecoration: 'underline', color: colors.white.main }}
                        onClick={back}
                    >
                        Back
                    </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

export default SignUpStep 