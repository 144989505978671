
// @mui material components
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDButton from "components/atoms/MDButton/MDButton";
import { MDPaginationProps } from "./MDPagination";
import { MUIColors } from "models/StyleModels";

type MDPaginationItemRootOwnerState = MDPaginationProps & {paginationSize?: 'small' | 'medium' | 'large' | null };

type MDPaginationItemRoot = {
  theme?: any,
  ownerState: MDPaginationItemRootOwnerState,
  ref?: React.ForwardedRef<HTMLButtonElement>,
  variant?: 'gradient' | 'contained' | 'outlined';
  color?: MUIColors;
  iconOnly: boolean;
  circular: boolean;
}

export default styled(MDButton)(({ theme, ownerState }: MDPaginationItemRoot) => {
  const { borders, functions, typography, palette } = theme;
  const { /*variant,*/ paginationSize, active } = ownerState;

  const { borderColor } = borders;
  const { pxToRem } = functions;
  const { fontWeightRegular, size: fontSize } = typography;
  const { light } = palette;

  // width, height, minWidth and minHeight values
  let sizeValue = pxToRem(36);

  if (paginationSize === "small") {
    sizeValue = pxToRem(30);
  } else if (paginationSize === "large") {
    sizeValue = pxToRem(46);
  }

  return {
    borderColor,
    margin: `0 ${pxToRem(2)}`,
    pointerEvents: active ? "none" : "auto",
    fontWeight: fontWeightRegular,
    fontSize: fontSize.sm,
    width: sizeValue,
    minWidth: sizeValue,
    height: sizeValue,
    minHeight: sizeValue,

    "&:hover, &:focus, &:active": {
      transform: "none",
      // TODO: debug the commented logic below
      boxShadow: "none !important",
      // boxShadow: (variant !== "gradient" || variant !== "contained") && "none !important",
      opacity: "1 !important",
    },

    "&:hover": {
      backgroundColor: light.main,
      borderColor,
    },
  };
});
