import { useState, useEffect, useCallback } from 'react';
import breakpoints from "assets/theme/base/breakpoints";
import { Breakpoints } from 'models/StyleModels';

// a hook that returns a boolean denoting whether or not the viewport has crossed
// the provided breakpoint. this could probably be refactored out of the app to use
// MUI's sx breakpoint handling, and probably would be more performant.

export const useBreakpoints = ({ breakpoint }: { breakpoint: Breakpoints}) => {
  const { values: breakpointValues } = breakpoints;
  const breakpointValue = breakpointValues[breakpoint];

  if (!breakpointValue) throw new Error('Invalid breakpoint selection. Please choose from among "xs", "sm", "md", "lg", "xl", and "xxl".');

  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const breakpointBreached = windowWidth < breakpointValue;

  return { breakpointBreached, windowWidth };
};
