import React from 'react';
import {
  DegenerationLevels,
  JointSections,
  compartmentOrder,
  normalizedDegenerationLevels,
  normalizedJointSections,
} from 'models/XrayModels';
import MDBox from 'components/atoms/MDBox/MDBox';
import { containerStyles, getDegenColor, getWorstCaseDegeneration } from 'helpers/helpers';
import colors from 'assets/theme/base/colors';
import { MUIColors, Shadows } from 'models/StyleModels';
import { Card, Tooltip } from '@mui/material';
import { CardCover } from '@mui/joy';
import { faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDButton from 'components/atoms/MDButton/MDButton';
import { loadingAnimation } from 'assets/animations/animationStyles';

interface XrayDetailCardLoaderProps {
  displayVertical: boolean;
}

export const XrayDetailCardLoader: React.FC<XrayDetailCardLoaderProps> = ({ displayVertical }) => ((
  <MDBox
    shadow={Shadows.MEDIUM}
    {...containerStyles({
      width: '100%',
      maxWidth: '25rem',
      justifySelf: 'center',
      minHeight: '13rem',
      flexDirection: displayVertical ? 'column' : 'row',
      padding: '1rem',
      borderRadius: '8px',
      position: 'relative',
      ...loadingAnimation('backgroundColor'),
    })}
  >
    {displayVertical && (
      <MDBox
        sx={{
          width: '60%',
          height: '2rem',
          borderRadius: '4px',
          marginBottom: '.5rem',
          ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
        }}
      />
    )}
    <Card {...containerStyles({
      width: '100%',
      height: displayVertical ? '15rem' : '100%',
      borderRadius: '8px',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: displayVertical ? 0 : '.5rem',
      marginBottom: displayVertical ? '.5rem' : 0,
      ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
    })} />
    <MDBox
      {...containerStyles({
        width: '100%',
        height: displayVertical ? 'fit-content' : '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      })}
    >
      {!displayVertical && (
        <MDBox
          sx={{
            width: '60%',
            height: '1.5rem',
            borderRadius: '4px',
            ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
          }}
        />
      )}
      <MDBox
        sx={{
          width: '100%',
          height: '2.75rem',
          borderRadius: '8px',
          ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
        }}
      />
    </MDBox>
  </MDBox>
));

interface XrayDetailCardProps {
  imgSrc: string | undefined;
  imgLoading: boolean;
  label: string;
  compartmentOutcomes: { [JS in JointSections]?: DegenerationLevels };
  handleClickReview(): void;
  confirmed: boolean;
  displayVertical?: boolean;
}

const XrayDetailCard: React.FC<XrayDetailCardProps> = ({
  imgSrc,
  imgLoading,
  label,
  compartmentOutcomes,
  handleClickReview,
  confirmed,
  displayVertical,
}) => (
  <MDBox
    shadow={Shadows.MEDIUM}
    {...containerStyles({
      width: '100%',
      maxWidth: '25rem',
      justifySelf: 'center',
      minHeight: '13rem',
      flexDirection: displayVertical ? 'column' : 'row',
      border: `3px solid ${getDegenColor(getWorstCaseDegeneration(Object.values(compartmentOutcomes)))}`,
      padding: '1rem',
      borderRadius: '8px',
      backgroundColor: colors.grey[100],
      position: 'relative',
    })}
  >
    {confirmed && (
      <Tooltip
        title={<MDTypography sx={{ fontSize: '13px' }} color={MUIColors.WHITE}>Analysis confirmed</MDTypography>}
        placement='right'
      >
        <FontAwesomeIcon
          icon={faCircleCheck}
          color={colors.success.focus}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '.5rem',
          }}
        />
      </Tooltip>
    )}
    {displayVertical && (
      <MDTypography variant='h5' textTransform='capitalize' sx={{ marginBottom: '.5rem' }}>
        {label}
      </MDTypography>
    )}
    <Card {...containerStyles({
      width: '100%',
      height: displayVertical ? '15rem' : '100%',
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundColor: colors.grey[300],
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: displayVertical ? 0 : '.5rem',
      marginBottom: displayVertical ? '.5rem' : 0,
    })}>
      {!imgLoading && imgSrc && (
        <CardCover>
          <img src={imgSrc} alt="X-ray" />
        </CardCover>
      )}
      {imgLoading && <FontAwesomeIcon icon={faCircleNotch} color={colors.grey[500]} spin />}
    </Card>
    <MDBox
      {...containerStyles({
        width: '100%',
        height: displayVertical ? 'fit-content' : '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      })}
    >
      {!displayVertical && (
        <MDTypography sx={{ color: colors.grey[800] }} variant='h5' textTransform='capitalize'>
          {label}
        </MDTypography>
      )}
      <MDBox sx={{ marginBottom: '.25rem' }}>
        {Object.entries(compartmentOutcomes)
          .sort(([compartmentA], [compartmentB]) => (
            compartmentOrder[normalizedJointSections[compartmentA as JointSections]] - compartmentOrder[normalizedJointSections[compartmentB as JointSections]]
          ))
          .map(([compartment, degenLvl], i, a) => (
            a.length === 1
              ? (
                <MDTypography
                  key={compartment}
                  fontWeight='bold'
                  variant='body2'
                  textTransform='capitalize'
                  sx={{
                    color: getDegenColor(degenLvl),
                    marginBottom: displayVertical ? '.5rem' : 0,
                  }}
                >
                  {normalizedDegenerationLevels[degenLvl]}
                </MDTypography>
              )
              : (
                <div key={compartment} style={{ marginBottom: displayVertical ? '.5rem' : 0 }}>
                  <MDTypography variant='body2' textTransform='capitalize'>
                    {normalizedJointSections[compartment as JointSections]}:
                  </MDTypography>
                  <MDTypography
                    variant='body2'
                    fontWeight='bold'
                    textTransform='capitalize'
                    sx={{
                      lineHeight: '8px',
                      color: getDegenColor(degenLvl),
                      marginBottom: i === a.length - 1 ? 0 : '.5rem',
                    }}
                  >
                    {normalizedDegenerationLevels[degenLvl]}
                  </MDTypography>
                </div>
              )
          ))
        }
      </MDBox>
      <MDButton
        onClick={handleClickReview}
        variant='contained'
        color={MUIColors.SECONDARY}
      >
        Review
      </MDButton>
    </MDBox>
  </MDBox>
);

export default XrayDetailCard;
