import MDButton from "components/atoms/MDButton/MDButton";
import { HeaderItems } from "components/templates/MainAppLayout/MainAppLayout";
import { routesMap } from "data/routes";
import { getAppointmentTimeFromISOString } from "helpers/helpers";
import { Appointment } from "models/AppointmentModels";
import { Patient } from "models/PatientModels";
import { RouteKeys } from "models/RouteModels";
import { MUIColors } from "models/StyleModels";
import Moment from "moment";
import { useNavigate } from "react-router-dom";

interface UseAppointmentLayoutProps {
  patient: Patient | null | undefined;
  appointment: Appointment | null | undefined;
  pageTitle?: string;
}

// convenience hook for generating the props required for the dashboard header in MainAppLayout
// across the exam flow

const useAppointmentLayoutProps = ({
  patient,
  appointment,
  pageTitle,
}: UseAppointmentLayoutProps) => {
  const navigate = useNavigate();
  
  const headerItems: HeaderItems = {
    headline: patient ? `${patient.first_name} ${patient.last_name}` : '-',
    pageTitle,
    subItems: [
      { 'Date of Birth': patient?.date_of_birth.replaceAll('-', '/') || '-' },
      {
        'Appointment Date': appointment
          ? Moment(new Date(appointment.appointment_date)).format(`YYYY/MM/DD${getAppointmentTimeFromISOString(appointment.appointment_date) ? ' - hh:mm A' : ''} `)
          : '-'
      },
    ],
    secondaryContent: (
      <MDButton
        variant='outlined'
        color={MUIColors.WHITE as unknown as "inherit"} // this is some jank
        onClick={() => patient ? navigate(`${routesMap[RouteKeys.PATIENT_OVERVIEW].baseRoute}/${patient.patient_id}`) : {}}
      >
        Patient Overview
      </MDButton>
    ),
  };

  return {
    headerItems,
  };
};

export default useAppointmentLayoutProps;
