import {
  QuestionTypes,
  PainLevels,
  MultipleChoiceOption,
  SurveyTypes,
  SurveySection,
} from "models/SurveyModels";

const painLevelOptions: MultipleChoiceOption[] = Object.values(PainLevels).map((lvl) => ({
  value: lvl,
}));

// this file contains the sections and questions for the HOOs & KOOs Jr surveys used on the survey entry screen.

export const hoos: SurveySection[] = [
  {
    sectionKey: 'pain',
    sectionHeader: 'What amount of hip pain have you experienced in the last week during the following activities?',
    questions: [
      {
        parentSectionKey: 'pain',
        questionKey: 'stairs',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Going up or down stairs',
        options: painLevelOptions,
        display: 'buttons',
      },
      {
        parentSectionKey: 'pain',
        questionKey: 'unevenSurfaceWalking',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Walking on an uneven surface',
        options: painLevelOptions,
        display: 'buttons',
      },
    ],
  },
  {
    sectionKey: 'dailyLiving',
    sectionHeader: 'Indicate the degree of difficulty you have experienced in the last week due to your hip:',
    questions: [
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'risingFromSitting',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Rising from sitting',
        options: painLevelOptions,
        display: 'buttons',
      },
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'bendingToFloor',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Bending to the floor/picking up an object',
        options: painLevelOptions,
        display: 'buttons',
      },
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'lyingInBed',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Lying in bed (turning over, maintaining hip position)',
        options: painLevelOptions,
        display: 'buttons',
      },
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'sitting',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        label: 'Sitting',
        options: painLevelOptions,
        display: 'buttons',
      },
    ],
  },
];

export const koos: SurveySection[] = [
  {
    sectionKey: 'stiffness',
    sectionHeader: 'What is the amount of joint stiffness you have experienced during the last week in your knee?',
    sectionDesc: 'Stiffness is a sensation of restriction or slowness in the ease with which you move your knee joint:',
    questions: [
      {
        parentSectionKey: 'stiffness',
        questionKey: 'morningStiffness',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
      },
    ],
  },
  {
    sectionKey: 'pain',
    sectionHeader: 'What amount of knee pain have you experienced the last week during the following activities:',
    questions: [
      {
        parentSectionKey: 'pain',
        questionKey: 'twistingPivoting',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Twisting/Pivoting',
      },
      {
        parentSectionKey: 'pain',
        questionKey: 'straightening',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Straightening knee fully',
      },
      {
        parentSectionKey: 'pain',
        questionKey: 'stairs',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Going up or down stairs',
      },
      {
        parentSectionKey: 'pain',
        questionKey: 'standing',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Standing upright',
      },
    ],
  },
  {
    sectionKey: 'dailyLiving',
    sectionHeader: 'Indicate the degree of difficulty you have experienced in the last week due to your knee:',
    questions: [
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'bendingToFloor',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Rising from sitting',
      },
      {
        parentSectionKey: 'dailyLiving',
        questionKey: 'risingFromSitting',
        type: QuestionTypes.MULTI_CHOICE_SELECT_ONE,
        options: painLevelOptions,
        display: 'buttons',
        label: 'Bending to floor/Picking up object',
      },
    ],
  },
];

type SurveysMap = {
  [K in SurveyTypes]: Record<string, SurveySection[]>;
};

export const surveys: SurveysMap = {
  [SurveyTypes.HOOS_KOOS]: {
    hip: hoos,
    knee: koos,
  },
};
