import React, { useState } from 'react';
import { containerStyles, getXrayUnknownViewTypeStatus, getXrayViewTypeMismatchStatus, getXrayViewTypeValidationStatus } from "helpers/helpers";
import colors from 'assets/theme/base/colors';
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDButton from "components/atoms/MDButton/MDButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestion, faSpinner, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { XraysQueryCache, xrayQueryKeys } from 'hooks/useXrayData';
import { useXrayMutations } from 'hooks/useXrayMutations';
import { useQueryClient } from 'react-query';
import { Card, Modal } from '@mui/material';
import { CardCover } from '@mui/joy';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ViewTypes, ViewTypeData, Xray, ExamTypes } from 'models/XrayModels';
import { Appointment } from 'models/AppointmentModels';
import { Breakpoints, MUIColors } from 'models/StyleModels';

interface XrayUploadPortalProps {
    viewType?: ViewTypeData<ViewTypes> | undefined;
    xray?: Xray | null;
    appointment: Appointment | null;
    disabled?: boolean;
    onDrop?: (e: React.DragEvent<HTMLDivElement>, viewType: ViewTypes, exam: ExamTypes) => void;
    onDragStart?: (e: React.DragEvent<HTMLDivElement>, xrayId: string) => void;
    exam?: ExamTypes;
    loading?: boolean
}

const thumbnailDimensions = '8rem';

const XrayThumb: React.FC<XrayUploadPortalProps> = ({
    viewType,
    xray,
    appointment,
    disabled = false,
    onDrop,
    onDragStart,
    exam,
    loading = false,
}) => {
    const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });

    const queryClient = useQueryClient();

    const [thumbHovered, setThumbHovered] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const {
        deleteXray,
        deleteXrayError
    } = useXrayMutations();

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
        e.dataTransfer.dropEffect = 'move';
    };
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
        e.dataTransfer.dropEffect = 'move';
    };
    const handleDeleteXray = async () => {
        if (!xray || !appointment) return;
        await deleteXray(xray, {
            onSuccess: async (resp) => {
                setShowConfirmDeleteModal(false);
                const key = xrayQueryKeys.allXraysKey(appointment);
                await queryClient.cancelQueries(key);
                const prevXrays: XraysQueryCache = queryClient.getQueryData<XraysQueryCache>(key);

                if (prevXrays) {
                    queryClient.setQueryData(key, {
                        ...prevXrays,
                        data: {
                            ...prevXrays.data,
                            xrays: [...prevXrays.data.xrays.filter((xr) => xr.xray_id !== resp.data.deleted_xray_id)],
                        },
                    });
                }
            },
        });
    };

    const validated = viewType ? getXrayViewTypeValidationStatus({ xray, viewTypeDataObj: viewType }) : false
    const viewTypeMismatch = viewType ? getXrayViewTypeMismatchStatus({ xray, viewTypeDataObj: viewType }) : false
    const unknownViewType = viewType ? getXrayUnknownViewTypeStatus({ xray, viewTypeDataObj: viewType }) : false

    const getValidationStyles = (activeAcceptedDrag: boolean) => {
        if (isDragOver) return {
            border: `5px dashed ${colors.success.main}`,
        }

        if (viewType?.isOptional && !xray) return {
            border: `3px dashed ${colors.grey[500]}`,
        }

        if (viewType && !xray) return {
            border: `3px dashed ${colors.warning.main}`,
        }

        if (validated) return {
            border: `3px solid ${colors.success.main}`,
            label: {
                text: "Validated by JointDx",
                icon: <FontAwesomeIcon icon={faCheck} color={colors.success.main} style={{ marginRight: '.5rem' }} />,
            },
        }
        if (viewTypeMismatch) return {
            border: `3px solid ${colors.warning.main}`,
            label: {
                text: "View mismatch detected",
                icon: <FontAwesomeIcon icon={faQuestion} color={colors.warning.main} style={{ marginRight: '.5rem' }} />,
            },
        }
        if (unknownViewType) return {
            border: `3px solid ${colors.error.main}`,
            label: {
                text: "Invalid Xray",
                icon: <FontAwesomeIcon icon={faX} color={colors.error.main} style={{ marginRight: '.5rem' }} />,
            },
        }

        return {
            border: `3px dashed ${activeAcceptedDrag ? colors.info.main : colors.grey[500]}`,
            label: null,
        }
    };

    const validationStyles = getValidationStyles(true);

    return (
        <>
            <MDBox
                draggable={!!xray}
                onDragStart={(e) => xray && onDragStart && onDragStart(e, xray.xray_id)}
                {...containerStyles({
                    flexDirection: 'column',
                    width: 'fit-content',
                    height: 'fit-content',
                    cursor: xray || disabled
                        ? 'default'
                        : 'pointer'
                })}>
                <Card {...containerStyles({
                    width: thumbnailDimensions,
                    height: thumbnailDimensions,
                    aspectRatio: '1 / 1',
                    border: !viewType
                        ? 'none'
                        : validationStyles.border,
                    backgroundColor: 'none',
                    objectFit: 'cover',
                    overflow: 'hidden',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: '10px',
                    position: 'relative',
                    cursor: !xray ? 'default' : 'pointer',
                    boxShadow: !!xray && thumbHovered ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',

                })}
                    onMouseOver={() => setThumbHovered(true)}
                    onMouseLeave={() => setThumbHovered(false)}
                >
                    {validationStyles.label && (
                        <span style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.358)',
                            padding: '.25rem .5rem',
                            zIndex: 2,
                        }}>
                            {validationStyles.label.icon}
                            <MDTypography color={MUIColors.WHITE} variant="caption">{validationStyles.label.text}</MDTypography>
                        </span>
                    )}
                    {xray && (
                        <>
                            {!disabled && (
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    color={colors.error.focus}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        zIndex: 2,
                                        margin: '.5rem',
                                        cursor: 'pointer',
                                        opacity: thumbHovered ? ".75" : 0,
                                        transition: 'opacity 150ms ease'
                                    }}
                                    onClick={() => setShowConfirmDeleteModal(true)}
                                />
                            )}
                            <CardCover>
                                <img src={xray.img_url} alt={xray.joint} />
                            </CardCover>
                        </>
                    )}
                    {!xray && viewType && onDrop && exam && (
                        <MDBox
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e) => {
                                setIsDragOver(false);
                                onDrop(e, viewType.key, exam)
                            }}
                        >
                            {loading ? (<>
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    color={colors.grey[600]}
                                    style={{ marginBottom: '1rem', fontSize: '30px' }}
                                    spin={true}
                                />
                                Updating...
                            </>
                            ) : (<MDBox style={{ color: viewType.isOptional ? colors.grey[500] : colors.warning.main }}>
                                {viewType.isOptional ? "Optional:" : "MISSING"}
                                < br />
                                {viewType?.label}
                            </MDBox>)
                            }
                        </MDBox>
                    )}
                </Card>
                <Modal open={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
                    <MDBox {...containerStyles({ justifyContent: 'center', alignItems: 'center' })}>
                        <Card {...containerStyles({
                            width: breakpointBreached ? '90vw' : '40%',
                            height: '30vh',
                            maxWidth: '40rem',
                            minWidth: '20rem',
                            padding: '20%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            position: 'relative',
                        })}>
                            <MDTypography sx={{ marginBottom: '.5rem' }} variant='h5' fontWeight='regular'>
                                Are you sure you wish to delete this X-ray?
                            </MDTypography>
                            <MDTypography variant='h5' fontWeight='bold'>
                                This action cannot be undone.
                            </MDTypography>
                            <MDBox {...containerStyles({
                                flexDirection: 'row',
                                height: 'fit-content',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2.5rem',
                            })}>
                                <MDButton onClick={handleDeleteXray} variant='contained' color='error'>Delete</MDButton>
                                <MDButton onClick={() => setShowConfirmDeleteModal(false)}>Cancel</MDButton>
                            </MDBox>
                            <MDBox sx={{ opacity: deleteXrayError ? 1 : 0, position: 'absolute', bottom: '1rem', left: 0, width: '100%' }}>
                                <MDTypography variant="caption" color={MUIColors.ERROR}>Something went wrong.</MDTypography>
                            </MDBox>
                        </Card>
                    </MDBox>
                </Modal>
            </MDBox>
        </>
    );
};

export default XrayThumb;
