import MDButton from "components/atoms/MDButton/MDButton"
import MDInput from "components/atoms/MDInput/MDInput"
import { MUIBaseColors, MUIColors } from "models/StyleModels"
import { validateEmail } from 'helpers/helpers';
import { FormValues } from "../OnboardingPage"
import { useOnboardingMutation } from "hooks/useOnboardingMutation"
import { useAuth } from "hooks/useAuth"
import MDBox from "components/atoms/MDBox/MDBox"
import colors from "assets/theme/base/colors";

interface ClinicInfoStepProps {
    next: () => void
    back: () => void
    formValues: FormValues
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    setFormValue: <Key extends keyof FormValues>(key: Key, value: FormValues[Key]) => void
}

const ClinicInfoStep: React.FC<ClinicInfoStepProps> = ({
    next,
    back,
    formValues,
    handleChange,
    setFormValue,
}) => {
    const { createOrgAndRole, createOrgAndRoleLoading, createOrgAndRoleError } = useOnboardingMutation();
    const { user, addRoleToUserObj } = useAuth();

    const submitOnboarding = async () => {
        if (!user) return;

        const onboardingInput = {
            city: formValues.clinicCity,
            country: formValues.clinicCountry,
            name: formValues.clinicName,
            state: formValues.clinicState,
            phone: formValues.clinicPhone,
            contact_email: formValues.clinicEmail,
            clinic_size: formValues.clinicSize,
        }

        try {
            const newOrgAndRoleData = await createOrgAndRole(onboardingInput)
                .catch((e) => {
                    console.error(`Error creating org: ${e.message || JSON.stringify(e)}`);
                    throw new Error(e);
                });

            const newRole = newOrgAndRoleData.data.role;

            addRoleToUserObj(newRole);

            next();
        } catch (err) {
            console.error(`Something went wrong: ${JSON.stringify(err)}`);
        }
    };

    const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const latestChar = value.split('')[value.length - 1];

        // handle backspace
        if (value.length < (formValues.clinicPhone?.length || 0)) {
            setFormValue('clinicPhone', value);
            return;
        }

        if (isNaN(parseInt(latestChar)) || value.length === 15) return;

        if (value.length === 1) {
            setFormValue('clinicPhone', '(' + value);
            return;
        }

        if (value.length === 4) {
            setFormValue('clinicPhone', value + ') ');
            return;
        }

        if (value.length === 9) {
            setFormValue('clinicPhone', value + '-');
            return;
        }

        setFormValue('clinicPhone', value);
    };

    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <span style={{ color: colors.text.main, fontWeight: 700, }}>
                    Tell us more about your clinic.
                </span>
            </div>

            {createOrgAndRoleError && <div style={{ marginBottom: "2rem" }}>
                <span style={{ textAlign: 'center', display: 'block', color: colors.error.focus, fontWeight: 400, fontSize: '1rem' }}>
                    Something went wrong saving your organization
                </span>
            </div>}

            <MDBox display="grid" gap="1.5rem">
                <MDInput
                    dark
                    label='Number of Clinic Staff'
                    fullWidth
                    name="clinicSize"
                    onChange={handleChange}
                    value={formValues.clinicSize}
                />
                <MDInput
                    dark
                    label='Country'
                    fullWidth
                    name="clinicCountry"
                    onChange={handleChange}
                    value={formValues.clinicCountry}
                />
                <MDInput
                    dark
                    label='State'
                    fullWidth
                    name="clinicState"
                    onChange={handleChange}
                    value={formValues.clinicState}
                />
                <MDInput
                    dark
                    label='City'
                    fullWidth
                    name="clinicCity"
                    onChange={handleChange}
                    value={formValues.clinicCity}
                />
                <MDInput
                    dark
                    error={!!(formValues.clinicEmail && !validateEmail(formValues.clinicEmail))}
                    type='email'
                    label='Patient Facing Email'
                    fullWidth
                    name="clinicEmail"
                    onChange={handleChange}
                    value={formValues.clinicEmail}
                />
                <MDInput
                    dark
                    label='Patient Facing Phone Number'
                    fullWidth
                    name="clinicPhone"
                    onChange={handlePhoneInputChange}
                    value={formValues.clinicPhone}
                />
            </MDBox>

            <MDBox display="grid" marginTop="1.5rem">
                <MDButton
                    variant='contained'
                    color={MUIBaseColors.SECONDARY}
                    disabled={
                        !formValues.clinicPhone
                        || formValues.clinicPhone.length !== 14
                        || !formValues.clinicEmail
                        || !validateEmail(formValues.clinicEmail)
                        || !formValues.clinicCountry
                        || !formValues.clinicCity
                        || !formValues.clinicState
                        || !formValues.clinicSize
                        || createOrgAndRoleLoading
                    }
                    onClick={submitOnboarding}>
                    Confirm
                </MDButton>

                {createOrgAndRoleLoading && <div style={{ margin: "1rem 0rem" }}>
                    <span style={{ display: 'block', fontWeight: 400, fontSize: '1rem' }}>
                        Creating your organization...
                    </span>
                </div>}

                <MDButton
                    variant='text'
                    style={{ textDecoration: 'underline' }}
                    color={MUIColors.PRIMARY}
                    onClick={back}
                    disabled={createOrgAndRoleLoading}
                >
                    Back
                </MDButton>
            </MDBox>
        </>
    )
}

export default ClinicInfoStep 