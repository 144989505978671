import { useEffect, useCallback, useRef } from 'react';

// takes a callback function and returns a ref object which can be attached
// to a DOM node. when any DOM element that is not referenced element or a
// child of the referenced element is clicked, the callback fires

const useOutsideClick = (
  callback: (arg?: any) => void,
) => {
  const elementRef = useRef<any>();

  const handleOutsideClick = useCallback((e: MouseEvent): void => {
    if (elementRef.current && !elementRef.current.contains(e.target as Node)) {
      callback();
    }
  }, [callback, elementRef]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [handleOutsideClick]);

  return { elementRef };
};

export default useOutsideClick;
