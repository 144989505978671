import React, { createRef, useEffect, useState } from 'react';
import { Card, Modal, Typography } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import { capitalize, containerStyles, getJointFromFullJoint } from 'helpers/helpers';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { surveys } from 'data/surveys';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import {
  MultiChoiceSelectOneQuestion,
  PRO,
  SurveyQuestion,
  SurveyTypes,
} from 'models/SurveyModels';
import { FullJoints } from 'models/XrayModels';
import { useActivePatient } from 'hooks/useActivePatient';
import { useBreakpoints } from 'hooks/useBreakpoints';
import colors from 'assets/theme/base/colors';
import { RouteKeys } from 'models/RouteModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faX } from '@fortawesome/free-solid-svg-icons';
import { useProMutations } from 'hooks/useProMutations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProData } from 'hooks/useProData';
import MDButton from 'components/atoms/MDButton/MDButton';
import TriageFlowLayout from 'components/templates/TriageFlowLayout/TriageFlowLayout';
import TriageJointSurvey from 'components/organisms/TriageJointSurvey/TriageJointSurvey';
import theme from 'assets/theme';
import { routesMap } from 'data/routes';
import SidebarIndicator from 'components/atoms/SidebarIndicator/SidebarIndicator';
import Spinner from 'components/molecules/Spinner/Spinner';

type TriageQuestion = (MultiChoiceSelectOneQuestion & { value: string })

function isSurveyQuestion(q: SurveyQuestion): q is TriageQuestion {
  return (q as TriageQuestion).options !== undefined;
}

const TriageSurveyEntryPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const proId = queryParams.get('pro');

  const { breakpointBreached: mediumBreakpoint } = useBreakpoints({ breakpoint: Breakpoints.X_LARGE });
  const { breakpointBreached: mobileBreakpoint } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const queryOpts = { preventRefetch: true };

  const {
    appointment,
    appointmentLoading,
    appointmentError,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    ...queryOpts,
  });

  const {
    createPro,
    updatePro,
    setUserCommittedPros,
    setUserCommittedProsLoading,
  } = useProMutations({ appointment });

  const {
    latestProsMappedToJoints,
    allAppointmentProsLoading,
    proError,
    proLoading,
  } = useProData({ proId, appointment });

  const { activePatient } = useActivePatient();

  const [elementsRef, setElementsRef] = useState<React.RefObject<HTMLDivElement>[]>([]);
  const [jointPros, setJointPros] = useState<string[]>([]);
  const [isActiveSection, setIsActiveSection] = useState<string>();
  const [showMissing, setShowMissing] = useState<boolean>(false);
  const [showConfirmMissingQuestionsModal, setShowConfirmMissingQuestionsModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      jointPros.forEach((key, i) => {
        if (elementsRef[i].current) {
          const element = elementsRef[i].current as HTMLElement;
          const rect = element.getBoundingClientRect();
          if (rect.bottom <= window.innerHeight - (mobileBreakpoint ? - 100 : - 0)) {
            setIsActiveSection(key)
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [jointPros, elementsRef, mobileBreakpoint]);

  useEffect(() => {
    const jointPros = Object.keys(appointment?.joints ? appointment.joints : {})
    const returnToMissing = jointPros
      .some((fullJoint, i) => latestProsMappedToJoints[fullJoint as FullJoints]?.user_committed)

    setJointPros(jointPros)
    setIsActiveSection(jointPros[0])
    setShowMissing(returnToMissing)
    setElementsRef(jointPros.map(() => createRef()))
  }, [appointment?.joints, latestProsMappedToJoints])

  const dataLoading = appointmentLoading || proLoading || allAppointmentProsLoading;
  const appointmentOrSurveyError = (
    !appointment
    || appointmentError
    || !appointment.joints
    || proError
  );

  useEffect(() => {
    const jointPros = Object.keys(appointment?.joints ? appointment.joints : {})
    const returnToMissing = jointPros
      .some((fullJoint, i) => latestProsMappedToJoints[fullJoint as FullJoints]?.user_committed)

    setJointPros(jointPros)
    setIsActiveSection(jointPros[0])
    setShowMissing(returnToMissing)
    setElementsRef(jointPros.map(() => createRef()))
  }, [appointment?.joints, latestProsMappedToJoints])

  const newSections = jointPros.map((fullJoint, i) => {
    let missingQuestionCount = 0
    const latestPRO = latestProsMappedToJoints[fullJoint as FullJoints]
    const surveyQuestions = surveys[SurveyTypes.HOOS_KOOS][getJointFromFullJoint(fullJoint as FullJoints)]

    const combinedSurveyQuestions = surveyQuestions.map((sect) => {
      const proSurvey = latestPRO?.survey[sect.sectionKey]

      return {
        ...sect,
        questions: sect.questions.map((question) => {
          const isAnswered = proSurvey && proSurvey[question.questionKey]

          if (!isAnswered) {
            missingQuestionCount += 1
          }

          return {
            ...question,
            ...(isAnswered && { value: proSurvey[question.questionKey] })
          }
        })
      }
    })

    return {
      title: fullJoint,
      missingQuestionCount,
      sections: combinedSurveyQuestions.map((sect) => ({
        sectionTitle: sect.sectionKey,
        sectionHeader: sect.sectionHeader,
        questions: sect.questions.filter(isSurveyQuestion),
      }))
    }
  })

  const handleCreateOrUpdatePro = async (fullJoint: FullJoints, surveySections: {
    sectionTitle: string;
    questions: (MultiChoiceSelectOneQuestion & { value: string })[];
  }[]) => {
    const formattedSurveySections = surveySections.reduce((sections: any, section: any) => {
      return {
        ...sections,
        [section.sectionTitle]: section.questions.reduce((q: any, question: any) => {
          return {
            ...q,
            [question.questionKey]: question.value
          }
        }, {})
      }
    }, {})

    if (!appointment || !activePatient) return;

    const existingPro = latestProsMappedToJoints[fullJoint];

    if (!existingPro) {
      await createPro({
        appointment_id: appointment.appointment_id,
        patient_id: activePatient.patient_id,
        survey: formattedSurveySections,
        joint: fullJoint,
      });
    } else {
      const updatedPro: PRO = {
        ...existingPro,
        survey: formattedSurveySections
      };
      await updatePro(updatedPro);
    }
  };

  const updateSurvey = (survey: any) => {
    handleCreateOrUpdatePro(survey.title, survey.sections)
  }

  const handleCloseAndSave = async () => {
    if (newSections.some((sect) => sect.missingQuestionCount)) {
      setShowConfirmMissingQuestionsModal(true)
      return
    }

    await setUserCommittedPros(appointmentId!)
    navigate(routesMap[RouteKeys.TRIAGE_LIST].route)
  }

  return (
    <TriageFlowLayout title="PRO Survey" singlePatient={activePatient}>
      {dataLoading && (
        <MDBox display="grid" justifyContent="center" alignItems="center" minHeight="50vh">
          <Spinner />
        </MDBox>

      )}
      {!dataLoading && appointmentOrSurveyError && (
        <div
          style={{
            textAlign: 'center',
            padding: '10%',
          }}
        >
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ marginBottom: '2rem' }}
            size='8x'
            color={colors.error.main}
          />
          <MDTypography
            fontWeight='regular'
            color={MUIColors.ERROR}
            variant='h3'
          >
            There was a problem loading this survey!
          </MDTypography>
        </div>
      )}

      {!dataLoading && !appointmentOrSurveyError && (
        <>
          <MDBox display="grid" gridTemplateColumns={mobileBreakpoint ? "1fr" : "180px 5fr"} style={{ marginTop: '0px', padding: '0px', position: 'relative' }} >
            <MDBox
              style={{
                zIndex: 2,
                marginLeft: '-2.5rem',
                marginTop: '-5rem',
                marginBottom: '-.5rem',
                paddingTop: '8.5rem',
                borderRadius: '20px 0px 0px 20px',
                background: 'linear-gradient(to left, #e4eef7, #FFF)',

                ...(mobileBreakpoint && {
                  position: 'sticky',
                  top: 0,
                  left: 0,
                  marginLeft: '0',
                  marginTop: '-.5rem',
                  marginBottom: '0',
                  paddingTop: '0rem',
                  background: '#E5EEF7',
                  boxShadow: theme.shadows[4],
                  borderRadius: '0px 0px 20px 20px',
                }),
              }}>
              <div style={{
                top: '0px',
                left: '0px',
                position: 'sticky',
                padding: '1rem',
                display: 'grid',
                gap: '3rem',
              }}>
                {!mobileBreakpoint && <Typography
                  style={{
                    marginBottom: '1rem',
                    fontSize: '1.7rem',
                    textAlign: 'right',
                  }}
                  color={MUIColors.SECONDARY}>Joint Type:
                </Typography>}

                {newSections.map((jointSurvey, i) => {
                  if (mobileBreakpoint && isActiveSection !== jointSurvey.title) {
                    return null
                  }

                  return (
                    <MDBox
                      display="flex"
                      position="relative"
                      justifyContent={!mobileBreakpoint ? "flex-end" : "center"}
                      alignItems="center"
                      gap={!mobileBreakpoint ? "1rem" : ".5rem"}
                      key={`${jointSurvey.title}-navItem`}>

                      {!mobileBreakpoint && isActiveSection === jointSurvey.title &&
                        <MDBox style={{ height: '15px', width: '15px', borderRadius: '100%', background: '#000' }} />
                      }

                      <Typography
                        variant='h3'
                        style={{
                          fontWeight: isActiveSection === jointSurvey.title ? '700' : '400',
                          fontSize: '1.4rem',
                          ...(mobileBreakpoint && { fontSize: '2rem' }),
                        }}>
                        {capitalize(jointSurvey.title)}
                      </Typography>

                      {
                        mobileBreakpoint && <Typography style={{
                          fontSize: '1.8rem',
                          textAlign: 'right',
                        }}
                          color={MUIColors.SECONDARY}>Questions</Typography>
                      }

                      {showMissing && mobileBreakpoint && jointSurvey.missingQuestionCount > 0 && (
                        <MDBox
                          style={{
                            height: '1.8rem',
                            width: '1.8rem',
                            borderRadius: '100%',
                            background: colors.error.main,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '.2rem',
                            paddingLeft: '.1rem',
                          }}
                        >
                          <Typography style={{
                            color: colors.white.focus,
                            fontSize: '1.1rem',
                            fontWeight: '700',
                          }}>{jointSurvey.missingQuestionCount}</Typography>
                        </MDBox>
                      )}

                      {showMissing && !mobileBreakpoint && jointSurvey.missingQuestionCount > 0 && (
                        <MDBox style={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignContent: 'center',
                          top: '-.6rem',
                          right: '-2.9rem',
                          paddingRight: '.2rem',
                          borderRadius: '100%',
                          width: '2rem',
                          height: '2rem',
                          paddingTop: '.2rem',
                        }}>
                          <SidebarIndicator />
                          <Typography style={{
                            position: 'absolute',
                            color: colors.white.focus,
                            fontSize: '1.2rem',
                            right: '.8rem',
                            top: '.6rem',
                          }}>{jointSurvey.missingQuestionCount}</Typography>
                        </MDBox>
                      )}
                    </MDBox>
                  )
                })}

                {!mobileBreakpoint && <MDButton
                  color={MUIColors.SECONDARY}
                  onClick={handleCloseAndSave}
                  variant='outlined'>
                  Save & Return
                </MDButton>}
              </div>
            </MDBox>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: '4rem',
              padding: '2rem',
              ...(mobileBreakpoint && { padding: '0rem', })
            }}>
              {newSections.map((jointSurvey, i) => (
                <MDBox key={`${jointSurvey.title}_survey_container`} ref={elementsRef[i] || null}>
                  <TriageJointSurvey key={`${jointSurvey.title}_survey`} jointSurvey={jointSurvey} updateSurvey={updateSurvey} showMissing={showMissing} />
                </MDBox>
              ))}

              <MDButton
                color={MUIColors.SECONDARY}
                onClick={handleCloseAndSave}
                variant='outlined'>
                Save & Return
              </MDButton>
            </div>
          </MDBox>
        </>
      )}

      <Modal open={showConfirmMissingQuestionsModal} onClose={() => setShowConfirmMissingQuestionsModal(false)}>
        <MDBox {...containerStyles({ justifyContent: 'center', alignItems: 'center' })}>
          <Card {...containerStyles({
            width: mediumBreakpoint ? '90vw' : '50%',
            height: '30vh',
            maxWidth: '50rem',
            minWidth: '20rem',
            padding: '20%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
          })}>
            <FontAwesomeIcon
              icon={faX}
              cursor='pointer'
              onClick={() => setShowConfirmMissingQuestionsModal(false)}
              style={{ position: 'absolute', top: '1rem', right: '1rem', color: colors.secondary.main }}
            />
            <MDTypography sx={{ marginBottom: '2rem' }} variant='h3' fontWeight='regular' color={MUIColors.SECONDARY}>
              WARNING: <span style={{ color: colors.error.focus }}>THIS SURVEY IS INCOMPLETE</span>
            </MDTypography>
            <MDTypography variant='h5' color={MUIColors.SECONDARY} fontWeight="regular">
              This may result in an inaccurate diagnosis.
            </MDTypography>
            <MDTypography variant='h5' color={MUIColors.SECONDARY} fontWeight="regular">
              Are you sure you want to proceed with the evaluation?
            </MDTypography>
            <MDBox {...containerStyles({
              flexDirection: 'row',
              height: 'fit-content',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2.5rem',
              gap: '2rem'
            })}>
              <MDButton
                color={MUIColors.SECONDARY}
                variant='contained'
                onClick={async () => {
                  setShowMissing(true)
                  setShowConfirmMissingQuestionsModal(false)
                }}>
                Complete Survey
              </MDButton>
              <MDButton
                variant='contained'
                color={MUIColors.SECONDARY}
                disabled={setUserCommittedProsLoading}
                onClick={
                  async () => {
                    await setUserCommittedPros(appointmentId!)

                    navigate(routesMap[RouteKeys.TRIAGE_LIST].route)
                  }}>
                Save & Return
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      </Modal>
    </TriageFlowLayout >
  );
};

export default TriageSurveyEntryPage;