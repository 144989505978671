// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { useMemo, useState } from "react";
import MDInput from "components/atoms/MDInput/MDInput";
import { DBUser, UpdateUserInput } from "models/UserModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleNotch, faCircleXmark, faEdit } from "@fortawesome/free-solid-svg-icons";
import colors from "assets/theme/base/colors";
import { useUserMutation } from "hooks/useUserMutation";
import Alert from "components/atoms/Alert/Alert";
import { validateEmail } from "helpers/helpers";

type UserProfileInfo = Pick<DBUser,
  | 'full_name'
  | 'user_location'
  | 'phone'
  | 'email'
  | 'bio'>

interface ProfileInfoCardProps {
  title: string;
  info: UserProfileInfo;
}

const ProfileInfoCard: React.FC<ProfileInfoCardProps> = ({
  title,
  info,
}) => {
  const [showMutationOutcomeAlert, setShowMutationOutcomeAlert] = useState(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const userDetailsInit: UserProfileInfo = useMemo(() => ({ ...info }), [info]);
  const [userDetails, setUserDetails] = useState<Partial<UserProfileInfo>>(userDetailsInit);
  const updateUserDetails = (key: keyof UserProfileInfo, val: string) => setUserDetails({
    ...userDetails,
    [key]: val,
  });
  const resetUserDetails = () => setUserDetails(userDetailsInit);

  const {
    updateUser,
    updateUserSuccessful,
    updateUserError,
    updateUserLoading
  } = useUserMutation()

  // autoformatting for typing in the phone input
  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const latestChar = value.split('')[value.length - 1];

    // handle backspace
    if (value.length < (userDetails.phone?.length || 0)) {
      updateUserDetails('phone', value);
      return;
    }

    if (isNaN(parseInt(latestChar)) || value.length === 15) return;

    if (value.length === 1) {
      updateUserDetails('phone', '(' + value);
      return;
    }

    if (value.length === 4) {
      updateUserDetails('phone', value + ') ');
      return;
    }

    if (value.length === 9) {
      updateUserDetails('phone', value + '-');
      return;
    }

    updateUserDetails('phone', value);
  };

  const handleUserDetailsInputOnChange = (key: keyof UpdateUserInput, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (key) {
      case 'phone':
        handlePhoneInputChange(e);
        break;
      default:
        updateUserDetails(key, e.target.value);
        break;
    }
  };

  const handleSaveUserChanges = async () => {
    await updateUser({
      full_name: userDetails.full_name,
      phone: userDetails.phone,
      email: userDetails.email,
      user_location: userDetails.user_location,
      bio: userDetails.bio
    })

    setShowMutationOutcomeAlert(true);
    setIsEditMode(false);
  };

  const handleCancelUserChanges = () => {
    setIsEditMode(false);
    resetUserDetails();
  };

  const formatLabel = (label: string) => {
    if (label === 'user_location') {
      return 'Location'
    }

    return label.replace(/(^\w)/g, g => g[0].toUpperCase()).replace(/([-_]\w)/g, g => " " + g[1].toUpperCase()).trim();
  }

  return (
    <Card sx={{ height: "100%", boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>

      <Alert
        show={showMutationOutcomeAlert && updateUserSuccessful}
        handleClose={() => setShowMutationOutcomeAlert(false)}
      >
        Organization details updated successfully.
      </Alert>
      <Alert
        show={showMutationOutcomeAlert && !!updateUserError}
        handleClose={() => setShowMutationOutcomeAlert(false)}
        type="error"
      >
        Something went wrong. Please try again later.
      </Alert>

      <MDBox display="flex" justifyContent="flex-end">
        {!isEditMode &&
          <FontAwesomeIcon
            onClick={() => setIsEditMode(!isEditMode)}
            cursor='pointer'
            icon={faEdit}
            color={colors.secondary.main} />}

        {isEditMode && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
            {!updateUserLoading && (
              <>
                <FontAwesomeIcon
                  role="button"
                  tabIndex={0}
                  onClick={handleSaveUserChanges}
                  icon={faCircleCheck}
                  color={colors.success.focus}
                />
                <FontAwesomeIcon
                  role="button"
                  tabIndex={0}
                  onClick={handleCancelUserChanges}
                  cursor='pointer'
                  icon={faCircleXmark}
                  color={colors.error.focus}
                />
              </>)}
            {updateUserLoading && <FontAwesomeIcon icon={faCircleNotch} spin color={colors.secondary.main} />}
          </div>
        )}
      </MDBox>

      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          {!isEditMode && (
            <MDTypography variant="button" color="text" fontWeight="light" textTransform="none">
              {userDetails.bio}
            </MDTypography>
          )}

          {isEditMode && (
            <MDInput
              dark
              fullWidth
              value={userDetails.bio}
              onChange={(e) => handleUserDetailsInputOnChange('bio', e)}
            />
          )}
        </MDBox>
        <MDBox sx={{ opacity: '0.3' }}>
          <Divider />
        </MDBox>
        <MDBox>
          {
            Object.entries(userDetails)
              .filter(([key]) => key !== 'bio')
              .map(([key, value]: any) => (
                <MDBox key={key as string} display="grid" pb={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    {formatLabel(key)}:
                  </MDTypography>

                  {!isEditMode && (
                    <MDTypography variant="button" fontWeight="regular" color="text" textTransform="none">
                      {value}
                    </MDTypography>
                  )}

                  {isEditMode && (
                    <MDInput
                      dark
                      type={key === 'email' ? 'email' : 'text'}
                      fullWidth
                      error={!value || (key === 'email' && !validateEmail(value))}
                      value={value}
                      onChange={(e) => handleUserDetailsInputOnChange(key, e)}
                    />
                  )}
                </MDBox>))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ProfileInfoCard;
