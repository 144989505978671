import React, { CSSProperties } from 'react';
import { FullJoints } from 'models/XrayModels';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { MUIColors } from 'models/StyleModels';
import { ReactComponent as NoSelection } from 'assets/images/joint_selection_svgs/joint_select_none.svg';
import { ReactComponent as LHipSelected } from 'assets/images/joint_selection_svgs/joint_select_lhip.svg';
import { ReactComponent as RHipSelected } from 'assets/images/joint_selection_svgs/joint_select_rhip.svg';
import { ReactComponent as LKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_lknee.svg';
import { ReactComponent as RKneeSelected } from 'assets/images/joint_selection_svgs/joint_select_rknee.svg';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import { AppointmentRankedJoints } from 'models/AppointmentModels';
import DropdownSelect from 'components/molecules/DropdownSelect/DropdownSelect';
import colors from 'assets/theme/base/colors';
import { containerStyles } from 'helpers/helpers';

interface AppointmentJointSelectionProps {
  activeJoints: AppointmentRankedJoints;
  setActiveJoints: React.Dispatch<React.SetStateAction<AppointmentRankedJoints>>;
}

const AppointmentJointSelection: React.FC<AppointmentJointSelectionProps> = ({
  activeJoints,
  setActiveJoints,
}) => {
  const handleJointSelection = (
    joint: FullJoints,
    rank: number | null,
  ) => {
    if (!activeJoints) {
      setActiveJoints({
        [joint]: rank,
      })
    } else {
      setActiveJoints({
        ...activeJoints,
        [joint]: rank,
      });
    }
  };

  const validRanks = [null, 1, 2, 3, 4];

  interface GenerateDropdownFnArgs {
    joint: FullJoints,
    containerStyles?: CSSProperties;
  }

  const generateDropdown = ({
    joint,
    containerStyles,
  }: GenerateDropdownFnArgs) => (
    <DropdownSelect
      style={{ ...containerStyles, backgroundColor: colors.white.main }}
      value={activeJoints && activeJoints[joint] ? `Priority ${activeJoints[joint]}` : 'No Pain'}
      options={validRanks.filter((rank) => rank === null || !Object.values(activeJoints || []).includes(rank)).map((rank) => ({
        value: rank ? `Priority ${rank}` : 'No Pain',
        handleClick: () => handleJointSelection(joint, rank),
      }))}
    />
  );

  const sharedStyles: CSSProperties = {
    width: '70%',
    maxWidth: '25vw',
    position: 'relative',
  };

  const jointDropdownStyles: {[FJ in FullJoints]: CSSProperties} = {
    [FullJoints.RIGHT_HIP]: {
      ...sharedStyles,
      gridArea: '1 / 1 / 2 / 2',
      transform: 'translate(-5.25rem, 3rem)',
      justifySelf: 'flex-end',
    },
    [FullJoints.LEFT_HIP]: {
      ...sharedStyles,
      gridArea: '1 / 2 / 2 / 3',
      transform: 'translate(5.5rem, 3rem)',
      justifySelf: 'flex-start',
    },
    [FullJoints.RIGHT_KNEE]: {
      ...sharedStyles,
      gridArea: '2 / 1 / 3 / 2',
      transform: 'translate(-5.25rem, 3.2rem)',
      justifySelf: 'flex-end',
    },
    [FullJoints.LEFT_KNEE]: {
      ...sharedStyles,
      gridArea: '2 / 2 / 3 / 3',
      transform: 'translate(5.5rem, 3.2rem)',
      justifySelf: 'flex-start',
    },
  };

  return (
    <MDBox {...containerStyles({ flexDirection: 'column' })}>
      <SurveyQuestionMainText style={{ textAlign: 'center' }}>
        Select and rank each joint that hurts, 1 being the joint that hurts the most.
      </SurveyQuestionMainText>
      <SurveyQuestionMainText style={{ textAlign: 'center', marginBottom: '2rem' }}>
        If a joint does not hurt, leave it as "No Pain".
      </SurveyQuestionMainText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr',
          width: 'fit-content',
          height: '28rem',
          justifySelf: 'center',
          alignSelf: 'center'
        }}
      >
        <NoSelection style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
        {activeJoints && (
          <>
            {activeJoints[FullJoints.LEFT_HIP] && (
              <LHipSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {activeJoints[FullJoints.RIGHT_HIP] && (
              <RHipSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {activeJoints[FullJoints.LEFT_KNEE] && (
              <LKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
            {activeJoints[FullJoints.RIGHT_KNEE] && (
              <RKneeSelected style={{ gridArea: '1 / 1 / 2 / 2', justifySelf: 'center', height: '100%' }} />
            )}
          </>
        )}
        <MDTypography
          fontWeight='bold'
          color={MUIColors.SECONDARY}
          sx={{
            justifySelf: 'flex-start',
            gridArea: '1 / 1 / 2 / 2',
            transform: 'translateX(1rem)',
          }}
        >
          Right
        </MDTypography>
        <MDTypography
          fontWeight='bold'
          color={MUIColors.SECONDARY}
          sx={{
            justifySelf: 'flex-end',
            gridArea: '1 / 1 / 2 / 2',
            transform: 'translateX(-1rem)',
          }}
        >
          Left
        </MDTypography>
        <MDBox
          sx={{
            gridArea: '1 / 1 / 2 / 2',
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: '.5fr 1fr',
          }}
        >
          {Object.entries(jointDropdownStyles).map(([fullJoint, containerStyles]) => (
            <React.Fragment key={fullJoint}>
              {generateDropdown({ joint: fullJoint as FullJoints, containerStyles })}
            </React.Fragment>
          ))}
        </MDBox>
      </div>
    </MDBox>
)};

export default AppointmentJointSelection;
