import { forwardRef } from "react";

// Custom styles for MDTypography
import MDTypographyRoot from "components/atoms/MDTypography/MDTypographyRoot";

// Material Dashboard 2 React contexts
import { TypographyProps } from "@mui/material";
import { MUIColors } from "models/StyleModels";

export type MDTypographyProps = {
  verticalAlign?: boolean;
  textGradient?: any,
  opacity?: number | string,
  color?: MUIColors | "inherit" | "text",
  component?: any;
  to?: string;
} & TypographyProps;

const MDTypography = forwardRef<HTMLDivElement, MDTypographyProps>(
  ({
    color,
    fontWeight,
    textTransform,
    verticalAlign,
    textGradient,
    opacity,
    children,
    ...rest
  }, ref
  ) => (
    <MDTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
      }}
    >
      {children}
    </MDTypographyRoot>
  )
);

export default MDTypography;
