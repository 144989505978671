import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, Typography } from "@mui/material"
import borders from "assets/theme/base/borders"
import colors from "assets/theme/base/colors"
import MDBox from "components/atoms/MDBox/MDBox"
import MDButton from "components/atoms/MDButton/MDButton"
import MDInput from "components/atoms/MDInput/MDInput"
import { containerStyles } from "helpers/helpers"
import { useAppointmentMutations } from "hooks/useAppointmentMutations"
import { useBreakpoints } from "hooks/useBreakpoints"
import useForm from "hooks/useForm"
import { useOrgData } from "hooks/useOrgData"
import { usePatientMutations } from "hooks/usePatientMutations"
import { Patient } from "models/PatientModels"
import { Breakpoints, MUIColors, Shadows } from "models/StyleModels"

export enum FormKeys {
    PATIENT_FIRST_NAME = 'PATIENT_FIRST_NAME',
    PATIENT_LAST_NAME = 'PATIENT_LAST_NAME',
    PATIENT_ID_NUMBER = 'PATIENT_ID_NUMBER',
    DATE_OF_BIRTH = 'DATE_OF_BIRTH',
    PROVIDER = 'PROVIDER',
}

export type FormValues = {
    [FormKeys.PATIENT_FIRST_NAME]: string;
    [FormKeys.PATIENT_LAST_NAME]: string;
    [FormKeys.PATIENT_ID_NUMBER]: string;
    [FormKeys.DATE_OF_BIRTH]: string;
    [FormKeys.PROVIDER]: string;
}

interface AddPatientModalContentProps {
    closeModal(): void;
    onSuccess(): void;
    patient?: Patient | null;
}

const Content: React.FC<AddPatientModalContentProps> = ({ closeModal, onSuccess, patient }) => {
    const {
        org,
    } = useOrgData({ preventRefetch: true });

    const {
        createPatientError,
        updatePatient,
        updatePatientError,
        updatePatientLoading,
    } = usePatientMutations();

    const {
        createAppointmentError,
        updateAppointmentError,
        updateAppointmentLoading,
    } = useAppointmentMutations();

    const {
        values: formValues,
        handleChange,
        setFormValue,
    } = useForm<FormValues>({
        [FormKeys.PATIENT_FIRST_NAME]: patient?.first_name || '',
        [FormKeys.PATIENT_LAST_NAME]: patient?.last_name || '',
        [FormKeys.PATIENT_ID_NUMBER]: patient?.external_id || '',
        [FormKeys.DATE_OF_BIRTH]: patient?.date_of_birth || '',
        [FormKeys.PROVIDER]: patient?.provider || '',
    });

    const handleDOBInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const latestChar = value.split('')[value.length - 1];

        // handle backspace
        if (value.length < formValues[FormKeys.DATE_OF_BIRTH].length) {
            setFormValue(FormKeys.DATE_OF_BIRTH, value);
            return;
        }

        if (isNaN(parseInt(latestChar)) || value.length === 11) return;

        if (value.length === 4 || value.length === 7) {
            setFormValue(FormKeys.DATE_OF_BIRTH, value + '-');
            return;
        }

        setFormValue(FormKeys.DATE_OF_BIRTH, value);
    };

    const updatePatientHandler = async () => {
        if (!patient) {
            return
        }

        try {
            await updatePatient({
                org_id: org!.org_id,
                patient_id: patient.patient_id,
                first_name: formValues[FormKeys.PATIENT_FIRST_NAME],
                last_name: formValues[FormKeys.PATIENT_LAST_NAME],
                external_id: formValues[FormKeys.PATIENT_ID_NUMBER],
                provider: formValues[FormKeys.PROVIDER],
                date_of_birth: formValues[FormKeys.DATE_OF_BIRTH],
            });

            onSuccess()
        }
        catch (e) {

            console.error(`Something went wrong: ${e}`);
        }
    }

    return (
        <>
            <Typography style={{ color: colors.text.focus, textAlign: 'center' }} fontWeight={700} fontSize={"1.8rem"} >Edit Patient Information</Typography>

            {(createPatientError || updatePatientError || updateAppointmentError || createAppointmentError) && <div style={{ marginBottom: "1rem" }}>
                <span style={{ textAlign: 'center', display: 'block', color: colors.error.focus, fontWeight: 400, fontSize: '1rem' }}>
                    Something went wrong. Please try again.
                </span>
            </div>}

            <FontAwesomeIcon
                icon={faX}
                color={colors.primary.main}
                cursor='pointer'
                onClick={closeModal}
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '1rem',
                }}
            />

            <MDBox display='grid' gridTemplateColumns="1fr 1fr" gap="1rem">
                <MDInput
                    dark
                    label='First name'
                    name={FormKeys.PATIENT_FIRST_NAME}
                    value={formValues[FormKeys.PATIENT_FIRST_NAME]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '.50rem' }}
                />

                <MDInput
                    dark
                    label='Provider'
                    name={FormKeys.PROVIDER}
                    value={formValues[FormKeys.PROVIDER]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '.50rem' }}
                />
                <MDInput
                    dark
                    label='Last Name'
                    name={FormKeys.PATIENT_LAST_NAME}
                    value={formValues[FormKeys.PATIENT_LAST_NAME]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '.50rem' }}
                />

                <MDInput
                    dark
                    label='Patient ID Number'
                    fullWidth
                    name={FormKeys.PATIENT_ID_NUMBER}
                    type='number'
                    value={formValues[FormKeys.PATIENT_ID_NUMBER]}
                    onChange={handleChange}
                />
                <MDInput
                    dark
                    label='Date of Birth'
                    fullWidth
                    placeholder='YYYY-MM-DD'
                    onChange={handleDOBInputChange}
                    name={FormKeys.DATE_OF_BIRTH}
                    value={formValues[FormKeys.DATE_OF_BIRTH]}
                />
            </MDBox>

            <MDButton
                color={MUIColors.SECONDARY}
                variant='contained'
                disabled={
                    updatePatientLoading ||
                    updateAppointmentLoading ||
                    !formValues[FormKeys.PATIENT_FIRST_NAME] ||
                    !formValues[FormKeys.PATIENT_LAST_NAME] ||
                    !formValues[FormKeys.PATIENT_ID_NUMBER]
                }
                onClick={async () => {
                    updatePatientHandler()
                }}
            >
                Update & Save
            </MDButton >
        </>
    )
}

interface AddPatientModalLoaderProps {
    closeModal(): void;
    onSuccess(): void;
    isEdit?: boolean;
    patient?: Patient | null;
}

const Loader: React.FC<AddPatientModalLoaderProps> = ({ closeModal, onSuccess, isEdit, patient }) => {

    const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });
    return <Modal
        {...containerStyles({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        })}
        open={true}
        onClose={closeModal}
    >
        <MDBox
            shadow={Shadows.SMALL}
            borderRadius={borders.borderRadius.lg}
            sx={{
                padding: '3rem',
                backgroundColor: colors.white.main,
                width: breakpointBreached ? '80%' : '60vw',
                display: 'grid',
                gap: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '95vh',
                maxWidth: '700px',
                overflow: 'auto',
                position: 'relative',
            }}
        >

            <Content closeModal={closeModal} onSuccess={onSuccess} patient={patient} />

        </MDBox>
    </Modal >
}

export default Loader