import HttpService from 'services/httpService';
import { base64StringToBlob } from "blob-util";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { ENDPOINT_BASE_URL } from "./constants";
import { useAuth } from "./useAuth";

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

// this hook takes an array of region of interest resource IDs provided by accion after it analyzes each xray.
// these values are stored within the xray.prediction_results field. They correspond to region of interest images
// created by Accion and stored on their servers. The collectRoiIds helper function can be used to generate the
// array of ROI IDs to pass to this hook.

export const roiImageQueryKeys = {
  roiImagesKey: (ids?: string[]) => ['roiImages', { ids }],
};

interface UseRoiImageDataOptions {
  roiIds?: string[] | null;
  options?: { preventRefetch: boolean; };
}

export const useRoiImageData = ({ roiIds, options }: UseRoiImageDataOptions) => {
  const { accessToken, user } = useAuth();

  const getRoiImagesFromAccion = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_key, { ids }] = queryKey;
    const resp = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/xrays/roi-img-collection?org_id=${user?.orgs[0].org_id}`,
      data: ids,
      token: accessToken,
    });
    return resp;
  };

  const {
    data: roiImagesData,
    error: roiImagesError,
    isFetching: roiImagesLoading,
    refetch: refetchRoiImages,
  } = useQuery(
    roiImageQueryKeys.roiImagesKey(roiIds!),
    getRoiImagesFromAccion,
    {
      enabled: !!roiIds,
      refetchOnMount: !options?.preventRefetch,
    },
  );

  const rawRoiImages = roiImagesData?.data;
  
  const [roiImgUrls, setRoiImageUrls] = useState<{[key: string]: string}>({});

  // accion returns the raw image data for each ROI resource. here, we create image
  // URLs for each ID we fetched for, and then return those from the hook for use in the
  // app.
  useEffect(() => {
    if (!rawRoiImages) return;

    Object.entries(rawRoiImages).forEach(([id, dataStr]) => {
      const blob = base64StringToBlob(dataStr, 'image/jpeg');
      setRoiImageUrls((oldUrls) => ({
        ...oldUrls,
        [id]: URL.createObjectURL(blob),
      }));
    });

    return () => Object.values(roiImgUrls).forEach((url) => URL.revokeObjectURL(url));
    // eslint-disable-next-line
  }, [rawRoiImages]);

  return {
    roiImgUrls,
    rawRoiImages,
    roiImagesError,
    roiImagesLoading,
    refetchRoiImages,
  };
};
