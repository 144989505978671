import React, { createContext, useContext, useState } from 'react';

const FeatureFlagsContext = createContext<FeatureFlags>({});

interface FeatureFlagContextProps {
    children: React.ReactNode
    featureFlagValues: FeatureFlags
}

type FeatureFlags = {
    triageFlow?: boolean;
}

export const FeatureFlagsProvider: React.FC<FeatureFlagContextProps> = ({ children, featureFlagValues }) => {
    const [featureFlags] = useState<FeatureFlags>(featureFlagValues);

    return (
        <FeatureFlagsContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export const useFeatureFlags = (): FeatureFlags => {
    return useContext<FeatureFlags>(FeatureFlagsContext);
};