import React from 'react';
import MDBox from "components/atoms/MDBox/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/atoms/MDButton/MDButton";
import { Link } from "react-router-dom";
import colors from 'assets/theme/base/colors';
import { Breakpoints, MUIBaseColors, MUIColors } from 'models/StyleModels';
import { useBreakpoints } from 'hooks/useBreakpoints';

interface NavButton {
  action?: string | (() => void);
  text?: string | React.ReactNode;
  color?: MUIColors;
  variant?: 'contained';
  hide?: boolean;
  disabled?: boolean;
  loading?: {
    isLoading: boolean;
    loadingText: string | React.ReactNode;
  }
  fullWidth?: boolean;
  message?: React.ReactNode;
}

interface LowerNavigationProps {
  buttons: NavButton[];
}

const LowerNavigation: React.FC<LowerNavigationProps> = ({ buttons }) => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  return (
    <Grid
      component='div'
      borderRadius="0 0 0.75rem 0.75rem"
      sx={{ backgroundColor: colors.disabled.focus }}
      pt={2}
      pb={2}
      px={3}
      mx={-3.5}
      my={-1}
    >
      <MDBox m={3}>
        <Grid container spacing={3} justifyContent="space-between" style={{ textAlign:"center" }}>
          {buttons.map((button) => {
            const buttonComponent = (
              <MDButton
                fullWidth={button.fullWidth === undefined ? true : button.fullWidth}
                variant={button.variant || 'contained'}
                color={button.color as unknown as MUIBaseColors || MUIColors.PRIMARY}
                disabled={button.disabled || !!button.loading?.isLoading || button.hide}
                onClick={typeof button.action === 'string' ? () => {} : button.action}
              >
                <span style={{ color: button.disabled ? colors.grey[500] : 'inherit' }}>
                  {button.loading?.isLoading ? button.loading.loadingText : button.text}
                </span>
              </MDButton>
            );
            if (breakpointBreached && button.hide) return null;
            return (
              typeof button.action === 'string'
                ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    key={`${button.text}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      opacity: button.hide ? 0 : 1,
                    }}
                  >
                    <Link to={button.action}>
                      {buttonComponent}
                    </Link>
                    {button.message}
                  </Grid>
                )
                : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    key={`${button.text}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      opacity: button.hide ? 0 : 1,
                    }}
                  >
                    {buttonComponent}
                    {button.message}
                  </Grid>
                )
              );
            }
          )}
        </Grid>
      </MDBox>
    </Grid>
  );
};

export default LowerNavigation;
