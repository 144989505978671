import { useAuth } from "hooks/useAuth";
import { RouteKeys } from "../models/RouteModels";
import { OrgRole, Roles } from "models/OrgModels";
import { useOrgData } from "hooks/useOrgData";

// Action slugs describe the action to be taken (ActionTypes enum) followed by the object
// that action is being applied to (a DB resource or a page). The two are separated by a global
// delimiter (actionSlugDelimiter). All database models minus Users (medical_practioners table)
// are represented by a member of the SlugTypes enum, as all users should have the ability to edit
// their own information regardless of role. The value of each member of the RouteKeys enum also
// acts as a slug for its corresponding page. So, each applicable DB model gets an action slug for
// each action type, and each route indexed by the app gets a VIEW action slug. The slug portion
// of a page's action slug should always end in "_PAGE". Action slugs can be provided to the .can()
// method on the user object provided by the useAuth hook, which will cross reference the user's
// role on the organization against the action slug and return a boolean determining whether or
// not the user has permission to execute the desired action described by the action slug.

// simple usage example:

/*
  import { ActionSlugs } from 'services/PermissionsService';
  import { useAuth } 'hooks/useAuth';

  const AddPatientForm = () => {
    const { user } = useAuth();

    return (
      <form>
        {user?.can(ActionSlugs.CREATE$PATIENT) && <button>Submit New Patient</button>}
        {!user?.can(ActionSlugs.CREATE$PATIENT) && <p>Only admins may intake new patients.</p>}
      </form>
    );
  };
*/

export enum ActionTypes {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum SlugTypes {
  ORG = 'ORG',
  APPOINTMENT = 'APPOINTMENT',
  PATIENT = 'PATIENT',
  PRO = 'PRO',
  XRAY = 'XRAY',
  INVITE = 'INVITE',
  ROLE = 'ROLE',
  USER = 'USER'
}

// This character is used to split the action from the slug in the user.can method.
// Be careful if you decide to change this for any reason - avoid using any alphabet
// characters or a "_" character, as doing so will likely break the method's logic.
// It will also cause existing uses of action slugs deconstructed from the ActionSlugs
// object below to break. You probably shouldn't change this.

export const actionSlugDelimiter = '$';

type ViewActionSlugKeys = `${ActionTypes.VIEW}${typeof actionSlugDelimiter}${RouteKeys}`;
type OtherActionSlugKeys = `${Exclude<ActionTypes, ActionTypes.VIEW>}${typeof actionSlugDelimiter}${SlugTypes}`;
type ActionSlugKey = ViewActionSlugKeys | OtherActionSlugKeys;
type ActionSlugValue<ASK extends ActionSlugKey> = ASK extends `${infer ActionType}${typeof actionSlugDelimiter}${infer SlugType}`
  ? `${ActionType}${typeof actionSlugDelimiter}${SlugType}`
  : never;

const ActionSlugs = Object.freeze(Object.values(ActionTypes))
  .flatMap((action) => [
    ...Object.values(SlugTypes).map((slug) => `${action}${actionSlugDelimiter}${slug}`),
    ...(action === ActionTypes.VIEW ? Object.values(RouteKeys).map((slug) => `${action}${actionSlugDelimiter}${slug}`) : []),
  ])
  .reduce((obj, slug) => {
    obj[slug] = slug;
    return obj;
  }, {} as { [key: string]: string }) as { [K in ActionSlugKey]: ActionSlugValue<K> };

export type ActionSlug = typeof ActionSlugs[keyof typeof ActionSlugs];

// this function is used by the can method on the user object returned by this hook
// to determine whether or not the logged in user has permissions to perform a given
// action or view a given page based on the ActionSlug provided to the function, along
// with the user's role. See PermissionsService.tsx for more details on ActionSlugs and
// client side permissions handling.

// all of this logic should perfectly mirror the logic on the backend permissions_client
// middleware
export const checkPermissions = (actionSlug: ActionSlug, orgRole: OrgRole | undefined): boolean => {
  if (!orgRole) return false;

  const role = orgRole.role;
  // the action being peformed is the first half of the ActionSlug, split by the delimiter
  const action = actionSlug.split(actionSlugDelimiter)[0] as ActionTypes;
  // the resource slug is the second half of the ActionSlug, again split by the delimiter
  const slug = actionSlug.split(actionSlugDelimiter)[1] as SlugTypes | RouteKeys;

  const slugArr = slug.split('_');
  // all page slugs end in "_PAGE"
  const isPageSlug = slugArr[slugArr.length - 1] === 'PAGE';

  const isReadOnlyRole = role === Roles.VIEWER;
  // Roles.JAI_ADMIN is the SuperAdmin role that is internal to JointAi staff only, and
  // is meant to be used to allow internal staff to be able to update information on any
  // org via the JointAi Admin Dashboard at rootdomain.co/jai-dashboard. If you have
  // James or DJ give you the SuperAdmin role after inviting you to the Glen Lake org, you'll
  // see that you can navigate to this route but it is currently a blank page. That functionality
  // is yet to be built. SuperAdmins otherwise act exactly like normal Admins.
  const isAdminRole = role === Roles.JAI_ADMIN || role === Roles.ADMIN;
  const isJAIAdmin = role === Roles.JAI_ADMIN;

  // The actual permissions checking logic
  switch (action) {
    case ActionTypes.VIEW:
      // the only page currently off limits to certain roles is the JointAi Admin Dashboard, which can only
      // be viewed by SuperAdmins as mentioned. 
      if (isPageSlug && slug === RouteKeys.JAI_DASHBOARD) return isJAIAdmin;
      return true;
    case ActionTypes.CREATE:
      // only admins can create invites
      if (slug === SlugTypes.INVITE) return isAdminRole;
      // only SuperAdmins can create orgs or roles outside of the dedicated /onboarding and /accept-invite
      // routes
      if (slug === SlugTypes.ORG || slug === SlugTypes.ROLE) return isJAIAdmin;
      return !isReadOnlyRole;
    case ActionTypes.UPDATE:
      // only JointAi internal SuperAdmins can make changes to existing invites (this is up for debate and serves no function currently)
      if (slug === SlugTypes.INVITE) return isJAIAdmin;
      // only Admins can update orgs and roles
      if (slug === SlugTypes.ORG || slug === SlugTypes.ROLE) return isAdminRole;
      return !isReadOnlyRole;
    case ActionTypes.DELETE:
      // only Admins can delete orgs or invites, though there is currently no method for deleting an invite in the UI
      if (slug === SlugTypes.INVITE || slug === SlugTypes.ROLE) return isAdminRole;
      // only JointAi internal SuperAdmins can delete organizations. again there is no method for this in the UI currently.
      if (slug === SlugTypes.ORG) return isJAIAdmin;
      return !isReadOnlyRole;
    default: return false;
  }
};

// Helper component which will conditionally render the provided children if the user passes
// a permissions check for the action slug provided to the `to` prop. An optional fallback
// node may be provided which will render if the check fails, otherwise no DOM node will be
// rendered at all.

// example usage

/*
  import { ActionSlugs } from 'services/PermissionsService';

  const AddPatientForm = () => {
    const { CREATE$PATIENT } = ActionSlugs;

    return (
      <form>
        <IfUserIsAllowed to={CREATE$PATIENT} fallback={<p>Only admins may intake new patients.</p>}>
          <button>Submit New Patient</button>
        </IfUserIsAllowed>
      </form>
    );
  };
*/

interface IfUserIsAllowedProps {
  children: React.ReactNode;
  to: ActionSlug;
  fallback?: React.ReactNode;
}

export const IfUserIsAllowed: React.FC<IfUserIsAllowedProps> = ({ children, to: actionSlug, fallback }) => {
  const { user } = useAuth();
  const { org } = useOrgData({ preventRefetch: true });

  if (org?.subscribed && user?.can(actionSlug)) return <>{children}</>;

  if (fallback) return <>{fallback}</>;
  return null;
};

export default ActionSlugs;
