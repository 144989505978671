
import { forwardRef } from "react";

// Custom styles for MDInput
import MDInputRoot from "components/atoms/MDInput/MDInputRoot";

import { TextFieldProps } from "@mui/material";
import colors from "assets/theme/base/colors";

export type MDInputProps = {
  success?: boolean;
  dark?: boolean;
  sx?: Record<any, any>;
} & TextFieldProps;

const MDInput = forwardRef<HTMLDivElement, MDInputProps>(({ error, success, disabled, dark, sx, ...rest }, ref) => (
  <MDInputRoot
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled, dark }}
    sx={dark
      ? {
          ...sx,
          "& .MuiOutlinedInput-input": {
            color: `${colors.primary.main} !important`,
          },
          "& .MuiInputLabel-root": {
            color: `${error ? colors.error.main : colors.primary.main} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline, &:after": {
            borderColor: `${error ? colors.error.main : colors.primary.main} !important`,
          },
        }
      : sx
    }
  />
));

export default MDInput;
