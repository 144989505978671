import React, { useEffect, useRef, useState } from 'react';
import MainAppLayout, { MainAppLayoutProps } from 'components/templates/MainAppLayout/MainAppLayout';
import useAppointmentLayoutProps from 'hooks/useAppointmentLayoutProps';
import { useActivePatient } from 'hooks/useActivePatient';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { useProData } from 'hooks/useProData';
import { useXrayData } from 'hooks/useXrayData';
import { useNavigation } from 'hooks/useNavigation';
import { RouteKeys } from 'models/RouteModels';
import { useNavigate } from 'react-router-dom';
import { routesMap } from 'data/routes';
import { containerStyles, isMissingXrayData } from 'helpers/helpers';
import { FullJoints, Xray } from 'models/XrayModels';
import { ENDPOINT_BASE_URL } from 'hooks/constants';
import { Patient } from 'models/PatientModels';
import { PRO } from 'models/SurveyModels';
import HttpService from 'services/httpService';
import { useAuth } from 'hooks/useAuth';
import MDButton from 'components/atoms/MDButton/MDButton';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import MDBox from 'components/atoms/MDBox/MDBox';
import PDF from 'components/molecules/PDF/PDF';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import colors from 'assets/theme/base/colors';
import { useBreakpoints } from 'hooks/useBreakpoints';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Appointment } from 'models/AppointmentModels';
import { useOrgData } from 'hooks/useOrgData';
import { Org } from 'models/OrgModels';
import './ExamReportPage.css';

const ExamReportPage: React.FC = () => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const { accessToken, user } = useAuth();

  const navigate = useNavigate();

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const { activePatient } = useActivePatient();

  const {
    appointment,
    appointmentLoading,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    preventRefetch: true,
  });

  const {
    latestProsMappedToJoints,
    allAppointmentProsLoading,
  } = useProData({ appointment, options: { preventRefetch: true } });

  const {
    xraysMappedToJoints,
    allAppointmentXraysLoading,
  } = useXrayData({ appointment, options: { preventRefetch: true } });

  const {
    org,
    orgLoading,
  } = useOrgData({ preventRefetch: true });

  const initQueriesLoading = appointmentLoading
    || allAppointmentProsLoading
    || allAppointmentXraysLoading
    || orgLoading;

  const { headerItems } = useAppointmentLayoutProps({
    appointment,
    patient: activePatient,
    pageTitle: 'Care Report',
  });

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);

  type PdfRequestDataType = {
    [FJ in FullJoints]?: {
      pro: PRO;
      xrays: Xray[];
      priority: number;
    }
  };

  // TODO: cache this response somewhere, probably with react query
  const generatePdf = async (
    org: Org | undefined,
    patient: Patient | null,
    appointment: Appointment | null,
    prosByJoints: { [FJ in FullJoints]?: PRO },
    xraysByJoints: { [FJ in FullJoints]?: Xray[] },
  ) => {
    try {
      if (
        !patient
        || !org
        || !appointment
        || !appointment.joints
        || !prosByJoints
        || !xraysByJoints
      ) throw new Error('Patient, PRO, or Xray data missing.');

      setPdfError(false);

      const joints = Object.entries(appointment.joints)
        .filter(([, rank]) => !!rank)
        .sort(([, aRank], [, bRank]) => aRank! - bRank!) as [FullJoints, number][];

      const jointData = joints.reduce((obj, [fullJoint, rank]) => {
        const pro = prosByJoints[fullJoint];
        const xrays = xraysByJoints[fullJoint];

        if (!pro || !xrays) return {};

        obj[fullJoint] = {
          pro: prosByJoints[fullJoint]!,
          xrays: xraysByJoints[fullJoint]!,
          priority: rank,
        };

        if (isMissingXrayData(fullJoint, xrays) || !pro.scores) delete obj[fullJoint];

        return obj;
      }, {} as PdfRequestDataType);

      setPdfLoading(true);
      const respData = await HttpService.post<Blob>({
        url: `${ENDPOINT_BASE_URL}/pdf/?org_id=${user?.orgs[0].org_id}`,
        data: { org, patient, joint_data: jointData },
        token: accessToken,
        responseType: 'blob',
      });
      setPdfFile(respData.data);
      setPdfLoading(false);
    } catch (e) {
      console.error(e);
      setPdfError(true);
      setPdfLoading(false);
    }
  };

  const effectSuccessRef = useRef<boolean>(false);

  // this effect fires off the initial request to the POST/pdf endpoint in order
  // to dynamically generate the PDF, once all of the required data has been fetched
  useEffect(() => {
    if (
      initQueriesLoading
      || !appointment
      || !appointment.joints
      || !xraysMappedToJoints
      || !latestProsMappedToJoints
      || pdfFile
      || effectSuccessRef.current
    ) return;

    // prevent the request from being made multiple times due to rerenders
    effectSuccessRef.current = true;

    generatePdf(org, activePatient, appointment, latestProsMappedToJoints, xraysMappedToJoints);

    // eslint-disable-next-line
  }, [
    initQueriesLoading,
    org,
    appointment,
    xraysMappedToJoints,
    latestProsMappedToJoints,
    effectSuccessRef,
  ]);

  // opens up the PDF in the browser's native PDF viewer
  const handlePrintPdf = (blob: Blob | null) => {
    if (!blob) return;
    const url = window.URL.createObjectURL(blob);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.focus();
    window.URL.revokeObjectURL(url);
  };

  // creates a dummy download link on the DOM and clicks it
  // to initiate the PDF download
  const handleDownloadPdf = (
    blob: Blob | null,
    patient: Patient | null,
  ) => {
    if (!blob || !patient) return;
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `patient_report-${patient?.last_name}-${new Date().toISOString()}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  const handleNext = () => navigate(routesMap[RouteKeys.PATIENT_LIST].route);

  const handlePrevious = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.EXAM_ROOM_ALL_JOINTS,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const generatePdfPlaceholder = (mode: 'loading' | 'error') => (
    <MDBox
      shadow={Shadows.MEDIUM}
      {...containerStyles({
        display: 'flex',
        flexDirection: 'column',
        height: breakpointBreached ? '55vh' : '100%',
        width: breakpointBreached ? '100%' : '70%',
        maxWidth: '27rem',
        minWidth: '20rem',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: colors.grey[100],
      })}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: 2,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MDTypography color={mode === 'loading' ? MUIColors.PRIMARY : MUIColors.ERROR} textAlign='center' mb={2} variant='h3'>
          {
            mode === 'loading'
              ? <>Please wait while <br /> we generate your report.</>
              : <>There was a problem <br /> generating your report.</>
          }
        </MDTypography>
        {
          mode === 'loading'
            ? <FontAwesomeIcon icon={faCircleNotch} color={colors.primary.main} spin size="2x" />
            : (
              <MDButton
                variant='contained'
                color={MUIColors.ERROR}
                onClick={() => generatePdf(org, activePatient, appointment, latestProsMappedToJoints, xraysMappedToJoints)}
              >
                Retry
              </MDButton>
            )
        }
      </div>
      <div className='ExamReportPage__LoaderBoxLg' />
      <div style={{ width: '100%', height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        {Array.from(new Array(3), (el, index) => (
          <div
            key={`box_${index + 1}`}
            className='ExamReportPage__LoaderBoxSm'
          />
        ))}
      </div>
      <div style={{ width: '100%', height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        {Array.from(new Array(2), (el, index) => (
          <div
            key={`box_${index + 1}`}
            className='ExamReportPage__LoaderBoxMd'
          />
        ))}
      </div>
    </MDBox>
  );

  const mainAppLayoutProps: MainAppLayoutProps = {
    headerItems,
    navActions: {
      previous: {
        text: 'Back',
        action: handlePrevious,
      },
      next: {
        text: 'Complete Evaluation',
        action: handleNext,
      },
    },
  };

  return (
    <MainAppLayout {...mainAppLayoutProps}>
      <MDBox {...containerStyles({
        display: 'flex',
        flexDirection: breakpointBreached ? 'column' : 'row',
        height: breakpointBreached ? 'auto' : '55vh',
        width: '100%',
      })}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: breakpointBreached ? '100%' : '60%',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: breakpointBreached ? '1rem' : 0,
        }}>
          {(initQueriesLoading || pdfLoading || pdfError) && generatePdfPlaceholder(pdfError ? 'error' : 'loading')}
          {pdfFile && <PDF src={pdfFile} fadeOutBottom />}
        </div>
        <MDBox {...containerStyles({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: breakpointBreached ? '100%' : '40%',
          justifyContent: 'center',
          alignItems: 'center',
        })}>
          <MDButton
            disabled={pdfLoading || !pdfFile}
            color={MUIColors.SECONDARY}
            variant='contained'
            style={{ marginBottom: '1rem' }}
            onClick={() => handleDownloadPdf(pdfFile, activePatient)}
          >
            Download Report
          </MDButton>
          <MDButton
            disabled={pdfLoading || !pdfFile}
            color={MUIColors.SECONDARY}
            variant='contained'
            onClick={() => handlePrintPdf(pdfFile)}
          >
            Print Report
          </MDButton>
        </MDBox>
      </MDBox>
    </MainAppLayout>
  );
};

export default ExamReportPage;
