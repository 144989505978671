// these are all of the valid xray view types that may be required by appointments depending
// on the joints selected for them
export enum ViewTypes {
  FLEX = 'flex',
  NON_FLEX = 'nonFlex',
  KNEECAP = 'kneeCap',
  AP_PELVIS = 'apPelvis',
  LEFT_FROG = 'leftFrog',
  RIGHT_FROG = 'rightFrog',
  LEFT_LATERAL_KNEE = 'leftLateralKnee',
  RIGHT_LATERAL_KNEE = 'rightLateralKnee',
}

// used to render user friendly labels on the UI
export const normalizedViewTypes: { [VT in ViewTypes]: string } = {
  [ViewTypes.FLEX]: 'Flexion',
  [ViewTypes.NON_FLEX]: 'No Flexion',
  [ViewTypes.KNEECAP]: 'Kneecap',
  [ViewTypes.AP_PELVIS]: 'AP',
  [ViewTypes.LEFT_FROG]: 'Lateral',
  [ViewTypes.RIGHT_FROG]: 'Lateral',
  [ViewTypes.LEFT_LATERAL_KNEE]: 'Lateral',
  [ViewTypes.RIGHT_LATERAL_KNEE]: 'Lateral',
};

export interface ViewTypeData<VT> {
  key: VT;
  label: string;
  value: string | string[]; // accion view type values per the documentation
  isOptional?: boolean;
}

export enum DegenerationLevels {
  NORMAL = 'normal',
  MODERATE = 'moderate',
  NEAR_ENDSTAGE = 'nearendstage',
  ENDSTAGE = 'endstage',
}

// used to render user friendly labels on the UI
export const normalizedDegenerationLevels: { [DL in DegenerationLevels]: string } = {
  [DegenerationLevels.NORMAL]: 'Normal/Slight',
  [DegenerationLevels.MODERATE]: 'Moderate',
  [DegenerationLevels.NEAR_ENDSTAGE]: 'Near-End Stage',
  [DegenerationLevels.ENDSTAGE]: 'End Stage',
};

export enum JointCompartments {
  ZONE_1 = 'zone 1',
  ZONE_2 = 'zone 2',
  MEDIAL = 'medial',
  LATERAL = 'lateral',
  KNEECAP = 'kneecap',
}

export const compartmentOrder: { [JC in JointCompartments]: number } = {
  [JointCompartments.ZONE_1]: 1,
  [JointCompartments.ZONE_2]: 2,
  [JointCompartments.MEDIAL]: 1,
  [JointCompartments.LATERAL]: 2,
  [JointCompartments.KNEECAP]: 1,
}

// accion's method of labeling sections
export enum JointSections {
  SINGLE_KNEECAP = 'singleCap',
  SINGLE_LATERAL = 'singleLateral',
  SINGLE_MEDIAL = 'singleMedial', // TODO: confirm single medial + lateral are actual things
  RIGHT_KNEECAP = 'rightCap',
  LEFT_KNEECAP = 'leftCap',
  RIGHT_MEDIAL = 'rightMedial',
  LEFT_MEDIAL = 'leftMedial',
  RIGHT_LATERAL = 'rightLateral',
  LEFT_LATERAL = 'leftLateral',
  RIGHT_ZONE_1 = 'rightZone1',
  LEFT_ZONE_1 = 'leftZone1',
  RIGHT_ZONE_2 = 'rightZone2',
  LEFT_ZONE_2 = 'leftZone2',
}

// used to render user friendly labels on the UI
export const normalizedJointSections: { [JS in JointSections]: JointCompartments } = {
  [JointSections.SINGLE_KNEECAP]: JointCompartments.KNEECAP,
  [JointSections.RIGHT_KNEECAP]: JointCompartments.KNEECAP,
  [JointSections.LEFT_KNEECAP]: JointCompartments.KNEECAP,
  [JointSections.SINGLE_MEDIAL]: JointCompartments.MEDIAL,
  [JointSections.RIGHT_MEDIAL]: JointCompartments.MEDIAL,
  [JointSections.LEFT_MEDIAL]: JointCompartments.MEDIAL,
  [JointSections.RIGHT_LATERAL]: JointCompartments.LATERAL,
  [JointSections.SINGLE_LATERAL]: JointCompartments.LATERAL,
  [JointSections.LEFT_LATERAL]: JointCompartments.LATERAL,
  [JointSections.RIGHT_ZONE_1]: JointCompartments.ZONE_1,
  [JointSections.LEFT_ZONE_1]: JointCompartments.ZONE_1,
  [JointSections.RIGHT_ZONE_2]: JointCompartments.ZONE_2,
  [JointSections.LEFT_ZONE_2]: JointCompartments.ZONE_2,
};


export enum FullJoints {
  LEFT_KNEE = 'left knee',
  RIGHT_KNEE = 'right knee',
  LEFT_HIP = 'left hip',
  RIGHT_HIP = 'right hip',
}

export interface AccionViewTypeCheckResp {
  viewType: string;
  score: string;
}

export interface PredictionSection {
  knee?: 'right' | 'left' | 'single';
  hip?: 'right' | 'left' | 'single';
  joint: 'right' | 'left' | 'single';
  prediction: DegenerationLevels;
  // added by the user, not by accion, if the user decides that
  // the accion prediction is not correct. should always default
  // to this selection, if it exists, before the accion prediction
  clientSelectedPrediction?: DegenerationLevels;
  // added by user, not by accion, when they lock in either accion's
  // prediction or override it with their own selection
  clientConfirmed?: boolean;
  imgId: string;
  section: JointSections;
}

export interface AccionPrediction {
  resourceId: string;
  singleROI?: string;
  singleROIScore?: string;
  leftROI?: string;
  leftROIScore?: string;
  rightROI?: string;
  rightROIScore?: string;
  rightReplaced?: boolean;
  leftReplaced?: boolean;
  sections: PredictionSection[];
}

export type Xray = {
  xray_id: string;
  patient_id: string;
  appointment_id: string;
  view_type_results: AccionViewTypeCheckResp; // accion's view type determination from the view-type API
  prediction_results: AccionPrediction; // accion's analysis results
  analysis_attempted?: boolean;
  analysis_in_progress?: boolean;
  s3_key: string;
  img_url: string;
  created_at: string;
  updated_at: string;
  joint: FullJoints | 'bilateral knee' | 'bilateral hip';
  client_provided_view_type: ViewTypes; // view type assigned by the user upon xray upload
  selected_for_eval: boolean;
};

export enum ExamTypes {
  BILAT_KNEE = 'bilateral knee',
  SINGLE_KNEE_RIGHT = 'right knee',
  SINGLE_KNEE_LEFT = 'left knee',
  BILAT_HIP = 'bilateral hip',
  SINGLE_HIP_RIGHT = 'right hip',
  SINGLE_HIP_LEFT = 'left hip',
}

export const ExamNameNormalizer = {
  [ExamTypes.BILAT_KNEE]: 'Bilateral Knee',
  [ExamTypes.SINGLE_KNEE_RIGHT]: 'Right Knee',
  [ExamTypes.SINGLE_KNEE_LEFT]: 'Left Knee',
  [ExamTypes.BILAT_HIP]: 'Bilateral Hip',
  [ExamTypes.SINGLE_HIP_RIGHT]: 'Right Hip',
  [ExamTypes.SINGLE_HIP_LEFT]: 'Left Hip',
}

export const fullJointNameNormalizer = {
  [FullJoints.RIGHT_HIP]: 'Right Hip',
  [FullJoints.LEFT_HIP]: 'Left Hip',
  [FullJoints.RIGHT_KNEE]: 'Right Knee',
  [FullJoints.LEFT_KNEE]: 'Left Knee',
}