import React from 'react';
import { Alert as MUIAlert } from '@mui/material';
import Portal from '../Portal/Portal';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIColors } from 'models/StyleModels';

interface AlertProps {
  show: boolean;
  children: React.ReactNode;
  type?: "error" | "success" | "info" | "warning";
  variant?: "filled" | "standard" | "outlined";
  handleClose?(): void;
}

const Alert: React.FC<AlertProps> = ({
  children,
  show,
  type,
  variant,
  handleClose,
}) => (
  <>
    {show && (
      <Portal
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1rem',
        }}
      >
        <MUIAlert
          severity={type || 'success'}
          variant={variant || 'filled'}
          action={
            handleClose
              ? (
                <FontAwesomeIcon
                  icon={faX}
                  cursor='pointer'
                  onClick={handleClose}
                  color={MUIColors.WHITE}
                  style={{ alignSelf: 'center', paddingRight: '.5rem' }}
                />
              )
              : null
        }
        >
          {children}
        </MUIAlert>
      </Portal>
    )}
  </>
);

export default Alert;
