import { useLocation, useParams } from "react-router-dom";

// a helper hook for retrieving commonly used query and URL params

export const useAppointmentParamsFromQuery = () => {
  const { appointmentId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentDateISOString = queryParams.get('date');
  const activeJoint = queryParams.get('joint')?.replace('-', ' ');

  return {
    appointmentId,
    appointmentDateISOString,
    activeJoint,
  };
};
