import { faCircleNotch, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "assets/theme/base/colors";
import MDBox from "components/atoms/MDBox/MDBox";
import MDButton from "components/atoms/MDButton/MDButton";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { useOrgMutations } from "hooks/useOrgMutations";
import { MUIBaseColors } from "models/StyleModels";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { containerStyles } from "helpers/helpers";
import { useOrgData } from "hooks/useOrgData";

import { ReactComponent as DownloadIcon } from '../../../../assets/images/icons/download.svg';
import border from '../../../../assets/images/borders/uploadBoxBorder.png';

interface OrgLogoStepProps {
    next: () => void
}

const OrgLogoStep: React.FC<OrgLogoStepProps> = ({ next }) => {
    const [files, setFiles] = useState<any[]>([]);
    const [errorText, setErrorText] = useState<string | undefined>();

    const { org } = useOrgData();

    const {
        uploadLogo,
        uploadLogoLoading,
    } = useOrgMutations();

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropAccepted: async (acceptedFiles) => {
            if (!org) throw new Error('Error gathering org details');

            setFiles(acceptedFiles)
        },
    });

    return (
        <div>
            <div style={{ marginBottom: "2rem" }}>
                <span style={{ textAlign: 'center', display: 'block', color: colors.text.main, fontWeight: 700, }}>
                    Add your clinic's logo:
                </span>
            </div>

            {errorText && <div style={{ marginBottom: "2rem" }}>
                <span style={{ textAlign: 'center', display: 'block', color: colors.error.focus, fontWeight: 400, fontSize: '1rem' }}>
                    {errorText}
                </span>
            </div>}

            <MDBox padding='1rem'
                display="grid"
                justifyContent="center"
            >
                <MDBox
                    sx={{
                        backgroundImage: `url(${border})`,
                        backgroundSize: "cover",
                        border: uploadLogoLoading
                            ? '3px solid transparent'
                            : isDragAccept
                                ? `3px solid ${colors.success.main}`
                                : isDragReject
                                    ? `3px solid ${colors.error.main}`
                                    : 'none',
                        width: '15rem',
                        height: '15rem',
                        aspectRatio: '1 / 1',
                        borderRadius: '0px',
                        objectFit: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {(uploadLogoLoading || !org) && (
                        <MDBox {...containerStyles({
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: colors.grey[600],
                            opacity: .75,
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 10,
                        })}>
                            <FontAwesomeIcon
                                spin
                                icon={faCircleNotch}
                                color={colors.grey[800]}
                                size="4x"
                            />
                        </MDBox>
                    )}

                    {org && <div>
                        <MDBox
                            display="grid"
                            justifyContent="center">
                            {files.length > 0 && (
                                <MDBox display="flex" flexDirection="column" gap="1rem" alignItems="center" justifyContent="center">
                                    <MDBox
                                        sx={{
                                            width: '10rem',
                                            height: '10rem',
                                            backgroundImage: `url(${files[0].preview})`,
                                            backgroundSize: "cover"
                                        }}>
                                    </MDBox>

                                    <FontAwesomeIcon
                                        cursor="pointer"
                                        size="1x"
                                        role="button"

                                        tabIndex={0}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setErrorText(undefined)
                                            setFiles([])
                                        }}
                                        icon={faCircleXmark}
                                        color={colors.error.focus}
                                    />
                                </MDBox>
                            )}

                            {!files.length && (
                                <div>
                                    <MDBox
                                        {...getRootProps()}

                                        display="grid"
                                        justifyContent="center"
                                        gap="1rem"
                                        padding="1rem">

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <DownloadIcon />
                                        </div>

                                        <MDButton
                                            variant='contained'
                                            size='small'
                                            color={MUIBaseColors.SECONDARY}
                                        >
                                            Browse
                                        </MDButton>

                                        <MDTypography textAlign="center" fontSize="10px" sx={{ color: colors.grey[600] }}>
                                            or drag file to upload
                                        </MDTypography>

                                        <MDTypography textAlign="center" fontSize="10px" sx={{ color: colors.grey[600] }}>

                                            <span style={{ color: 'red' }}>*</span>File types supported: .jpeg, .jpg, .png

                                        </MDTypography>

                                    </MDBox>
                                </div>
                            )}

                            {!uploadLogoLoading && <input {...getInputProps()} />}

                        </MDBox>
                    </div>}
                </MDBox >
            </MDBox >

            <MDBox display="grid" marginTop="1.5rem" gap="1rem">
                <MDButton
                    variant='contained'
                    color={MUIBaseColors.SECONDARY}
                    disabled={!files.length || !org}
                    onClick={async () => {
                        if (!org) return

                        try {
                            await uploadLogo({ file: files[0], org })
                            next()
                        } catch (e) {
                            setErrorText("Something went wrong uploading the organization logo.")
                        }
                    }}>
                    Submit
                </MDButton>
                <MDButton
                    variant='outlined'
                    color={MUIBaseColors.SECONDARY}
                    onClick={next}
                >
                    Skip for now and add later
                </MDButton>
            </MDBox>
        </div >
    )
}

export default OrgLogoStep 