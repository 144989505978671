import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import MainAppLayout from 'components/templates/MainAppLayout/MainAppLayout';
import { useParams } from 'react-router-dom';
import { RouteKeys } from 'models/RouteModels';
import ErrorPage from '../ErrorPage/ErrorPage';
import { usePatientData } from 'hooks/usePatientData';
import { DataCardHeader, Surface } from 'components/styled/StyledDashboardComponents';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCirclePlus, faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import colors from 'assets/theme/base/colors';
import {
  filterProsByJointAndSortByCreationDate,
  getActiveExamTypesFromAppointment,
  getAgeFromDOBString,
  getAppointmentTimeFromISOString,
  getAppointmentJointsWithMissingPros,
  getMissingXraysByActiveExams,
  containerStyles,
} from 'helpers/helpers';
import { useAppointmentData } from 'hooks/useAppointmentData';
import Moment from "moment";
import MDBox from 'components/atoms/MDBox/MDBox';
import DataTable, { TableColumnType } from 'components/organisms/DataTable/DataTable';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Appointment, ReasonsForVisit } from 'models/AppointmentModels';
import { useNavigation } from 'hooks/useNavigation';
import { useProData } from 'hooks/useProData';
import { useXrayData } from 'hooks/useXrayData';
import { FullJoints } from 'models/XrayModels';
import JointInfoVisualizer from 'components/organisms/JointInfoVisualizer/JointInfoVisualizer';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { loadingAnimation } from 'assets/animations/animationStyles';
import MDButton from 'components/atoms/MDButton/MDButton';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import { getCurrentISOTimestamp } from 'services/dateService';
import { useAuth } from 'hooks/useAuth';
import { SurveyTypes } from 'models/SurveyModels';
import Alert from 'components/atoms/Alert/Alert';
import ActionSlugs, { IfUserIsAllowed } from 'services/PermissionsService';
import { Button } from '@mui/material';
import EditPatientOverviewModal from 'components/organisms/EditPatientOverviewModal/EditPatientOverviewModal';

// TODO: this page should probably allow users with permission to edit patient information
// to be able to do so here on the patient details card

const PatientOverviewPage: React.FC = () => {
  const { patientId } = useParams();
  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });
  const { user } = useAuth();
  const { CREATE$APPOINTMENT } = ActionSlugs;

  const {
    singlePatient,
    singlePatientError,
    singlePatientLoading,
  } = usePatientData({ patientId });

  const {
    allAppointmentsByPatient,
    allAppointmentsByPatientError,
    allAppointmentsByPatientLoading,
    refetchAllAppointmentsByPatient,
  } = useAppointmentData({ patientId });

  const {
    createAppointment,
    createAppointmentError,
    createAppointmentLoading,
  } = useAppointmentMutations();

  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showEditPatientModal, setShowEditPatientModal] = useState<boolean>(false);

  useEffect(() => {
    if (!createAppointmentError) return;
    setShowErrorAlert(true);
  }, [createAppointmentError])

  const {
    allProsByPatient,
    allProsByPatientMappedToAppointmentIds,
    allProsByPatientError,
    allProsByPatientLoading,
  } = useProData({ patientId });

  const {
    allXraysByPatientMappedToAppointmentIds,
    allXraysByPatientError,
    allXraysByPatientLoading,
  } = useXrayData({ patientId });

  const isLoading = singlePatientLoading
    || allAppointmentsByPatientLoading
    || allProsByPatientLoading
    || allXraysByPatientLoading;

  const isError = !!(
    singlePatientError
    || allAppointmentsByPatientError
    || allProsByPatientError
    || allXraysByPatientError
  );

  const rKneePros = filterProsByJointAndSortByCreationDate(FullJoints.RIGHT_KNEE, allProsByPatient) || [];
  const lKneePros = filterProsByJointAndSortByCreationDate(FullJoints.LEFT_KNEE, allProsByPatient) || [];
  const rHipPros = filterProsByJointAndSortByCreationDate(FullJoints.RIGHT_HIP, allProsByPatient) || [];
  const lHipPros = filterProsByJointAndSortByCreationDate(FullJoints.LEFT_HIP, allProsByPatient) || [];

  const latestRKneePRO = !!rKneePros.length && rKneePros[0];
  const latestLKneePRO = !!lKneePros.length && lKneePros[0];
  const latestRHipPRO = !!rHipPros.length && rHipPros[0];
  const latestLHipPRO = !!lHipPros.length && lHipPros[0];

  const getAppointmentProStatus = useCallback((appointment: Appointment) => {
    const appointmentPros = allProsByPatientMappedToAppointmentIds[appointment.appointment_id];

    const missingPros = getAppointmentJointsWithMissingPros({
      activeExaminedJoints: Object.entries(appointment?.joints || [])
        .filter(([, rank]) => !!rank)
        .map(([fullJoint,]) => fullJoint as FullJoints),
      pros: appointmentPros || [],
    });

    if (!appointment.joints || (!!missingPros && !!missingPros.length)) {
      return <span style={{ color: colors.error.main }}>Incomplete</span>;
    } else {
      return <span style={{ color: colors.success.main }}>Complete</span>;
    }
  }, [allProsByPatientMappedToAppointmentIds]);

  // check if the required Xrays and PROs have been provided for the appointment, based upon
  // the joints assigned to that appointment
  const getAppointmentXrayStatus = useCallback((appointment: Appointment) => {
    const appointmentXrays = allXraysByPatientMappedToAppointmentIds[appointment.appointment_id];
    const activeExamTypes = getActiveExamTypesFromAppointment(appointment);
    const missingXrays = getMissingXraysByActiveExams({ activeExamTypes, xrays: appointmentXrays || [] })

    if (!appointment.joints || (!!missingXrays && !!Object.values(missingXrays).length)) {
      return <span style={{ color: colors.error.main }}>Incomplete</span>;
    } else {
      return <span style={{ color: colors.success.main }}>Complete</span>;
    }
  }, [allXraysByPatientMappedToAppointmentIds]);

  const tableData = useMemo(() => allAppointmentsByPatient?.sort((a, b) => (new Date(b.appointment_date) as any) - (new Date(a.appointment_date) as any))
    .map((appointment) => ({
      json: appointment,
      appointment_date: Moment(new Date(appointment.appointment_date)).format('MM/DD/YYYY'),
      appointment_time: getAppointmentTimeFromISOString(appointment.appointment_date) || '-',
      pro_status: getAppointmentProStatus(appointment),
      xray_status: getAppointmentXrayStatus(appointment),
    })
    ), [allAppointmentsByPatient, getAppointmentProStatus, getAppointmentXrayStatus]);

  const tableColumns: TableColumnType[] = [
    { Header: "Appointment Date", accessor: "appointment_date", align: "left", isSorted: true, isSortedDesc: true, canSort: true },
    { Header: "Appointment Time", accessor: "appointment_time", align: "left", isSorted: false, canSort: false },
    { Header: "Pro Status", accessor: "pro_status", align: "left", isSorted: false, canSort: false },
    { Header: "Xray Status", accessor: "xray_status", align: "left", isSorted: false, canSort: false },
  ];

  const handleAppointmentRowClick = (row: { original: { json: Appointment } }) => navigateToBaseRouteWithAptmtDetails({
    routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
    appointmentId: row.original.json.appointment_id,
    appointmentDateISOString: row.original.json.appointment_date,
  });

  // the New Exam action button on this page acts very similarly to the Quickstart Exam function, creating a
  // new appointment for the patient you're viewing and filling in default values for all of the fields in that exam
  // with that moment's timestamp as the appointment_date. it then navigates the user to that exam's Appointment Overview
  // page
  const handleCreateAppointment = async () => {
    if (!singlePatient || !user || isLoading) return;

    const newExamData = await createAppointment({
      patient_id: singlePatient.patient_id,
      appointment_date: getCurrentISOTimestamp(),
      org_id: user.orgs[0].org_id,
      survey_type: SurveyTypes.HOOS_KOOS,
      reason_for_visit: ReasonsForVisit.EVALUATION,
    }).catch(() => setShowErrorAlert(true));

    const newExam = newExamData?.data.appointment;

    if (!newExam) return;

    navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
      appointmentDateISOString: newExam.appointment_date,
      appointmentId: newExam.appointment_id,
    });
  }

  if (!patientId || (!isLoading && isError)) return <ErrorPage />;

  const detailRowStyles: { style: CSSProperties } = {
    style: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
      paddingTop: '1rem',
    },
  };

  interface DetailStylesType {
    variant: Variant;
    sx: CSSProperties;
  }

  const detailStyles: DetailStylesType = {
    variant: 'body2',
    sx: {
      lineHeight: 'unset',
      padding: '0 .25rem',
      fontSize: '1.1rem',
      textAlign: 'center'
    },
  };

  return (
    <>
      {showEditPatientModal && (<EditPatientOverviewModal
        onSuccess={() => {
          setShowEditPatientModal(false)
        }}
        patient={singlePatient}
        closeModal={() => setShowEditPatientModal(false)} />)}

      <Alert
        type='error'
        show={showErrorAlert}
        handleClose={() => setShowErrorAlert(false)}
      >
        Something went wrong. Please try again later.
      </Alert>
      <MainAppLayout
        headerItems={{
          headline: singlePatient ? `${singlePatient.first_name} ${singlePatient.last_name}` : '-',
          subItems: [{ 'Date of Birth': singlePatient?.date_of_birth || '-' }],
          pageTitle: "Patient Overview"
        }}
        navActions={{
          next: { hide: true },
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem'
          }}
        >
          <div
            style={{
              display: breakpointBreached ? 'flex' : 'grid',
              gridTemplateColumns: '1fr 1fr',
              flexFlow: 'column',
              flexWrap: 'wrap',
              gap: breakpointBreached ? '1rem' : '2rem',
              justifyContent: 'center',
              gridAutoRows: 'max-content',
            }}
          >
            <Surface
              style={{
                height: 'fit-content',
                padding: breakpointBreached ? '.5rem 1rem' : '3rem 3rem',
                marginBottom: breakpointBreached ? '2rem' : '0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{
                display: 'grid',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                paddingBottom: '1rem',
                borderBottom: `2px solid ${colors.grey[300]}`,
              }}>
                {!isLoading && (
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: '.25fr 1fr',
                    justifyContent: 'center',
                  }}>
                    <FontAwesomeIcon color={colors.secondary.main} icon={faUser} size="4x" />
                    <div>
                      <DataCardHeader style={{ fontSize: '2.2rem', textAlign: 'center' }}>
                        {singlePatient?.first_name} {singlePatient?.last_name}
                      </DataCardHeader>
                      <MDTypography {...detailStyles} textAlign='center'>
                        <span style={{ fontWeight: 600 }}>Birthdate: </span>{singlePatient?.date_of_birth}
                      </MDTypography>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '.75rem',
                    padding: '0 2rem',
                  }}>
                    <MDBox
                      sx={{
                        width: '90%',
                        height: '2.35rem',
                        borderRadius: '4px',
                        ...loadingAnimation('backgroundColor'),
                      }}
                    />
                    <MDBox
                      sx={{
                        width: '100%',
                        height: '1rem',
                        borderRadius: '4px',
                        ...loadingAnimation('backgroundColor'),
                      }}
                    />
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  <div {...detailRowStyles}>
                    <MDTypography {...detailStyles}>
                      <span style={{ fontWeight: 600 }}>Age: </span>{singlePatient ? getAgeFromDOBString(singlePatient.date_of_birth) : '-'}
                    </MDTypography>
                  </div>
                  <div {...detailRowStyles}>
                    <MDTypography {...detailStyles}>
                      <span style={{ fontWeight: 600 }}>Provider: </span>{singlePatient?.provider || 'N/A'}
                    </MDTypography>
                    <MDTypography {...detailStyles}>
                      <span style={{ fontWeight: 600 }}>ID Number: </span>{singlePatient?.external_id || 'N/A'}
                    </MDTypography>
                  </div>
                </>
              )}
              {isLoading && (
                <>
                  {Array.from(new Array(2), (e, i) => (
                    <div
                      key={`container-${i}`}
                      style={{ ...detailRowStyles.style, gap: '.5rem' }}
                    >
                      {Array.from(new Array(2), (e2, i2) => (
                        <MDBox
                          key={`child-${i}-${i2}`}
                          sx={{
                            width: '100%',
                            height: '2rem',
                            borderRadius: '4px',
                            ...loadingAnimation('backgroundColor'),
                          }}
                        />
                      ))}
                    </div>
                  ))}
                </>
              )}
              <Button
                startIcon={<FontAwesomeIcon icon={faPen} color={colors.white.main} />}
                variant='contained'
                color={MUIColors.SECONDARY}
                style={{ display: 'flex', flexWrap: 'nowrap', color: colors.white.main, marginTop: '2rem' }}
                disabled={isLoading || createAppointmentLoading}
                onClick={() => setShowEditPatientModal(true)}>
                Edit Info
              </Button>
            </Surface>
            <JointInfoVisualizer
              mode='proScore'
              dataLoading={isLoading}
              jointData={{
                [FullJoints.LEFT_HIP]: {
                  pro: latestLHipPRO || null,
                },
                [FullJoints.RIGHT_HIP]: {
                  pro: latestRHipPRO || null,
                },
                [FullJoints.LEFT_KNEE]: {
                  pro: latestLKneePRO || null,
                },
                [FullJoints.RIGHT_KNEE]: {
                  pro: latestRKneePRO || null,
                },
              }}
              fadeOutBottomToColor='white'
            />

          </div>
          <MDBox {...containerStyles({
            width: '100%',
            marginTop: breakpointBreached ? '2rem' : 0,
            flexDirection: 'column',
          })}>
            <MDBox {...containerStyles({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}>
              <DataCardHeader>
                Exam History
              </DataCardHeader>
              <IfUserIsAllowed to={CREATE$APPOINTMENT}>
                <MDButton
                  variant='contained'
                  color={MUIColors.SECONDARY}
                  style={{ display: 'flex', flexWrap: 'nowrap', gap: '1rem' }}
                  disabled={isLoading || createAppointmentLoading}
                  onClick={handleCreateAppointment}
                >
                  {!createAppointmentLoading && (
                    <>
                      New Exam
                      <FontAwesomeIcon icon={faCirclePlus} color={colors.white.main} fontWeight={600} />
                    </>
                  )}
                  {createAppointmentLoading && <FontAwesomeIcon icon={faCircleNotch} color={colors.white.main} spin />}
                </MDButton>
              </IfUserIsAllowed>
            </MDBox>
            <DataTable
              table={{ columns: tableColumns, rows: tableData }}
              isSorted={[true, false, false, false]}
              showTotalEntries={!isLoading && !isError}
              pagination={{ variant: "contained", color: "secondary" }}
              sx={{ padding: "0 2.25rem", float: "left" }}
              dataLoading={isLoading}
              dataError={isError}
              handleRetry={refetchAllAppointmentsByPatient}
              handleRowClick={handleAppointmentRowClick}
              emptyMessage='No appointments found.'
            />
          </MDBox>
        </div>
      </MainAppLayout>
    </>
  );
};

export default PatientOverviewPage;
