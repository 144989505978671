import { useQuery } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { Org } from 'models/OrgModels';
import { User } from 'models/UserModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const orgQueryKeys = {
  getOrgKey: (user: User | null, token: string | null) => ['org', { activeUser: user, token }],
};

export type OrgQueryCache = {
  data: {
    org: Org;
  };
  ts: number;
} | undefined;

interface UseOrgDataOptions {
  preventRefetch?: boolean;
}

export const useOrgData = (options?: UseOrgDataOptions) => {
  const { accessToken, user } = useAuth();

  const getOrg = async ({ queryKey }: any) => {
    const [, { activeUser, token }] = queryKey;
    if (!activeUser?.orgs.length || !token) return;
    const data = await HttpService.get<{ org: Org }>({
      url: `${ENDPOINT_BASE_URL}/orgs/${activeUser.orgs[0].org_id}?org_id=${user?.orgs[0].org_id}`,
      token: token,
    });
    return data;
  };

  const {
    data: orgData,
    error: orgError,
    isFetching: orgLoading,
    refetch: refetchOrg,
  } = useQuery(
    orgQueryKeys.getOrgKey(user, accessToken),
    getOrg,
    { refetchOnMount: !options?.preventRefetch }
  );

  const org = orgData?.data.org;

  return {
    org,
    orgError,
    orgLoading,
    refetchOrg,
  };
};
