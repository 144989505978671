import React from 'react';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import colors from 'assets/theme/base/colors';

const SurveyComplete: React.FC = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '10% 0',
    }}
  >
    <MDTypography variant='h1' style={{ textAlign: 'center', marginBottom: '2rem' }}>
      Survey Complete!
    </MDTypography>
    <FontAwesomeIcon icon={faCircleCheck} size="10x" color={colors.success.main} />
  </div>
);

export default SurveyComplete