import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProData } from "hooks/useProData";
import { useXrayData } from "hooks/useXrayData";
import { useAppointmentData } from "hooks/useAppointmentData";
import { useBreakpoints } from "hooks/useBreakpoints";
import {
  containerStyles,
  getActiveExamTypesFromAppointment,
  getAppointmentJointsWithMissingPros,
  getMissingXraysByActiveExams,
  mapXraysToViewTypes,
} from "helpers/helpers";
import { fullJointsByExamTypes, reqViewsByExam } from "data/dataConstants";
import MainAppLayout, { MainAppLayoutProps } from "components/templates/MainAppLayout/MainAppLayout";
import XrayUploadInterface from 'components/organisms/XrayUploadInterface/XrayUploadInterface';
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { ExamTypes, FullJoints, ViewTypeData, ViewTypes } from 'models/XrayModels';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import colors from 'assets/theme/base/colors';
import MDButton from 'components/atoms/MDButton/MDButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faEye,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import { useActivePatient } from 'hooks/useActivePatient';
import { RouteKeys } from 'models/RouteModels';
import { useNavigation } from 'hooks/useNavigation';
import {
  ProRowItem,
  XrayRowItem,
  Surface,
  DataCardHeader,
  DataRow,
  Column,
  ColumnContent,
  DataRowHeader,
} from 'components/styled/StyledDashboardComponents';
import { routesMap } from 'data/routes';
import JointInfoVisualizer, { JointDataType } from 'components/organisms/JointInfoVisualizer/JointInfoVisualizer';
import AppointmentJointSelection from 'components/organisms/AppointmentJointSelection/AppointmentJointSelection';
import { AppointmentRankedJoints } from 'models/AppointmentModels';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import { Modal, Tooltip } from '@mui/material';
import borders from 'assets/theme/base/borders';
import QRCode from "react-qr-code";
import useAppointmentLayoutProps from 'hooks/useAppointmentLayoutProps';
import { PRO } from 'models/SurveyModels';
import { loadingAnimation } from 'assets/animations/animationStyles';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionSlugs, { IfUserIsAllowed } from 'services/PermissionsService';
import { useAuth } from 'hooks/useAuth';

const AppointmentOverview: React.FC = () => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const { user } = useAuth();

  const {
    CREATE$PRO,
    CREATE$XRAY,
    UPDATE$PRO,
    UPDATE$APPOINTMENT,
  } = ActionSlugs;

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const { activePatient } = useActivePatient();

  const {
    appointment,
    appointmentLoading,
    appointmentError,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    preventRefetch: true,
  });

  const {
    updateAppointment,
    updateAppointmentLoading,
    updateAppointmentError,
  } = useAppointmentMutations();

  const {
    latestProsMappedToJoints,
    allAppointmentPros,
    allAppointmentProsLoading,
    allAppointmentProsError,
  } = useProData({ appointment, options: { preventRefetch: true } });

  const {
    allAppointmentXrays,
    allAppointmentXraysLoading,
    allAppointmentXraysError,
  } = useXrayData({ appointment, options: { preventRefetch: true } });

  const loading = appointmentLoading || allAppointmentProsLoading || allAppointmentXraysLoading;
  const error = !!(appointmentError || allAppointmentProsError || allAppointmentProsError || allAppointmentXraysError);
  const examStarted = !!appointment && !!appointment.joints && !!Object.values(appointment.joints).find((rank) => !!rank);

  const { headerItems } = useAppointmentLayoutProps({
    appointment,
    patient: activePatient,
  });

  const [showQrModal, setShowQrModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  // this page can toggle between the primary "overview" mode, the xray upload screen,
  // and the appointment joint selection screen.
  const [view, setView] = useState<'overview' | 'upload' | 'jointSelect'>('overview');

  // certain xray views are required based upon which joints have been selected for the
  // appointment. these views are dynamically updated as the user sets or updates the
  // joints for the appointment. this information is passed to the xray upload interface
  // to generate upload fields for each required xray view
  const [requiredViews, setRequiredViews] = useState<[ExamTypes, { [VT in ViewTypes]?: ViewTypeData<VT> }] | null>(null);

  const overviewInterfaceActive = view === 'overview';

  const uploadInterfaceActive = view === 'upload';
  const toggleUploadInterface = () => view === 'overview' ? setView('upload') : setView('overview');

  const jointSelectInterfaceActive = view === 'jointSelect';
  const toggleJointSelectInterface = () => view === 'overview' ? setView('jointSelect') : setView('overview');

  // state variable for setting or updating the joints being examined during the appointment
  const [activeJoints, setActiveJoints] = useState<AppointmentRankedJoints>(appointment?.joints || null);
  const activeJointsArraySortedByPriority = Object.entries(appointment?.joints || [])
    .filter(([, rank]) => !!rank)
    .sort((a, b) => (a[1] || 0) - (b[1] || 0))
    .map(([fullJoint,]) => fullJoint as FullJoints);

  useEffect(() => {
    if (!appointment) return;
    setActiveJoints(appointment?.joints || null);
  }, [appointment]);

  const handleSelectJointsComplete = async () => {
    if (!appointment) return;
    await updateAppointment({
      appointmentDate: appointment.appointment_date,
      appointmentId: appointment.appointment_id,
      newValues: {
        joints: activeJoints,
      },
    });
    toggleJointSelectInterface();
  };

  const handleCancelJointSelection = () => {
    setActiveJoints(appointment?.joints || null);
    toggleJointSelectInterface();
  };

  const activeExamTypes = getActiveExamTypesFromAppointment(appointment);

  // a series of row items for the X-rays section based on the active exam types, as determined
  // by the joints selected for the appointments.
  const xrayRowItems: XrayRowItem[] = activeExamTypes.map((examType) => ({
    type: 'xray',
    id: examType,
    seriesType: examType,
    requiredViews: reqViewsByExam[examType],
    uploadedXraysMappedToViewTypes: mapXraysToViewTypes(allAppointmentXrays?.filter((xray) => !!fullJointsByExamTypes[examType][xray.joint])),
    action: (
      <MDButton
        variant='contained'
        color={MUIColors.SECONDARY}
        size='small'
        onClick={() => {
          setRequiredViews([examType, reqViewsByExam[examType]]);
          toggleUploadInterface();
        }}
      >
        {user?.can(CREATE$XRAY) ? 'View / Edit' : 'View'}
      </MDButton>
    ),
  }));

  // onClick handler for the PRO panel row items
  const handleProRowClick = (joint: FullJoints, editMode?: boolean) => {
    if (!appointment) return;
    const pro = latestProsMappedToJoints[joint];
    // if a PRO already exists for the joint, then we navigate to the desktop view of
    // the survey form with that PRO's responses prepopulated. if the user clicked the
    // edit button, we put the survey in edit mode.
    if (pro) {
      navigateToBaseRouteWithAptmtDetails({
        routeKey: RouteKeys.SURVEY,
        appointmentId: appointment.appointment_id,
        appointmentDateISOString: appointment.appointment_date,
        addtlQueryParams: {
          joint: joint.replace(' ', '-'),
          view: 'desktop',
          pro: pro.pro_id,
          edit: editMode ? '1' : '0',
        },
      });
      return;
    }
    // if a PRO does not exist for the selected joint, we navigate to the desktop
    // for the selected joint with fresh surveys.
    navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.SURVEY,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
      addtlQueryParams: {
        joint: joint.replace(' ', '-'),
        view: 'desktop',
      },
    });
  };

  const handleProQrClick = () => {
    if (!appointment) return;
    setShowQrModal(true);
  };

  // returns the status of a PRO for the Status column of the PRO card.
  // if a PRO does not exist for the joint => "Not Started"
  // if the `scores` field is not present on the PRO (denoting it is incomplete) => "{number}% Complete" based on questions already answered
  // otherwise, the PRO is complete => "Complete"
  const getProStatus = (pro: PRO | undefined): string => {
    if (!pro) return 'Not Started';
    if (!pro.scores) {
      const questions = Object.values(pro.survey).reduce((arr, sect) => {
        const newArr = [...arr, ...Object.values(sect)];
        return newArr;
      }, [] as (string | number | null)[]);
      const answeredQuestions = questions.filter((question) => question !== null);
      return `${Math.floor(answeredQuestions.length / questions.length * 100)}% Complete`
    }
    return 'Complete';
  };

  // PRO row items for the PRO card, one for each joint in the appointment
  const proRowItems: ProRowItem[] = activeJointsArraySortedByPriority.map((fullJoint) => ({
    type: 'pro',
    id: fullJoint,
    joint: fullJoint,
    status: getProStatus(latestProsMappedToJoints[fullJoint]),
    action: latestProsMappedToJoints[fullJoint]?.scores
      ? (
        <div style={{ display: 'flex', gap: '.25rem', width: '100%' }}>
          <MDButton
            variant='contained'
            color={MUIColors.SECONDARY}
            size='small'
            style={{ minWidth: 'unset', width: '100%' }}
            onClick={() => handleProRowClick(fullJoint, user?.can(CREATE$PRO))}
          >
            {user?.can(CREATE$PRO) ? 'View / Edit' : 'View'}
          </MDButton>
        </div>
      )
      : (
        <MDButton
          variant='contained'
          color={MUIColors.SECONDARY}
          size='small'
          onClick={() => handleProRowClick(fullJoint, user?.can(UPDATE$PRO))}
        >
          {user?.can(CREATE$PRO) ? 'Complete' : <FontAwesomeIcon icon={faEye} color='white' />}
        </MDButton>
      ),
  })) || [];

  const missingXrays = getMissingXraysByActiveExams({ activeExamTypes, xrays: allAppointmentXrays || [] })

  const jointsMissingPros = getAppointmentJointsWithMissingPros({
    activeExaminedJoints: activeJointsArraySortedByPriority,
    pros: allAppointmentPros || [],
  });

  const someInfoIsMissing = !!Object.values(missingXrays).length || !!jointsMissingPros.length;

  // a user is only allowed to continue to the next step if at least one joint in the appointment's
  // joints has a complete PRO, and has had all required xrays provided in the corresponding series
  // type. this function checks for that minimum required status.
  const checkForOneReadyExam = () => {
    let readyForExam = false;

    activeExamTypes.forEach((examType) => {
      switch (examType) {
        case ExamTypes.BILAT_KNEE:
          if (
            !jointsMissingPros.find((fj) => fj === FullJoints.RIGHT_KNEE || fj === FullJoints.LEFT_KNEE)
            && !missingXrays['bilateral knee']
          ) {
            readyForExam = true;
          }
          break;
        case ExamTypes.SINGLE_KNEE_RIGHT:
          if (!jointsMissingPros.find((fj) => fj === FullJoints.RIGHT_KNEE) && !missingXrays[FullJoints.RIGHT_KNEE]) {
            readyForExam = true;
          }
          break;
        case ExamTypes.SINGLE_KNEE_LEFT:
          if (!jointsMissingPros.find((fj) => fj === FullJoints.LEFT_KNEE) && !missingXrays[FullJoints.LEFT_KNEE]) {
            readyForExam = true;
          }
          break;
        case ExamTypes.BILAT_HIP:
          if (
            !jointsMissingPros.find((fj) => fj === FullJoints.RIGHT_HIP || fj === FullJoints.LEFT_HIP)
            && !missingXrays['bilateral hip']
          ) {
            readyForExam = true;
          }
          break;
        case ExamTypes.SINGLE_HIP_RIGHT:
          if (!jointsMissingPros.find((fj) => fj === FullJoints.RIGHT_HIP) && !missingXrays[FullJoints.RIGHT_HIP]) {
            readyForExam = true;
          }
          break;
        case ExamTypes.SINGLE_HIP_LEFT:
          if (!jointsMissingPros.find((fj) => fj === FullJoints.LEFT_HIP) && !missingXrays[FullJoints.LEFT_HIP]) {
            readyForExam = true;
          }
          break;
      }
    });

    return readyForExam;
  };

  const generateLoadingPanel = useCallback((rowItemCount = 3) => (
    <Surface style={{
      height: 'fit-content',
      marginBottom: breakpointBreached ? '2rem' : '0',
      display: 'flex',
      flexDirection: 'column',
      gap: '.25rem',
      ...loadingAnimation('backgroundColor'),
    }}>
      <MDBox sx={{
        width: '50%',
        height: '2rem',
        borderRadius: '4px',
        marginBottom: '1rem',
        ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500])
      }} />
      {Array.from(new Array(rowItemCount), (el, idx) => (
        <MDBox
          key={`loadingRowItem-${idx}`}
          sx={{
            width: `${Math.floor(Math.random() * (86 - 30 + 1) + 30)}%`,
            marginBottom: idx !== rowItemCount - 1 && idx !== 0 && (idx + 1) % 3 === 0 ? '.5rem' : '0',
            height: '1rem',
            borderRadius: '4px',
            ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
          }}
        />
      ))}
    </Surface>
  ), [breakpointBreached]);

  const topRightLoadingPanel = useMemo(() => generateLoadingPanel(), [generateLoadingPanel]);
  const botLeftLoadingPanel = useMemo(() => generateLoadingPanel(6), [generateLoadingPanel]);
  const botRightLoadingPanel = useMemo(() => generateLoadingPanel(6), [generateLoadingPanel]);

  const rightColTopContent =
    loading
      ? topRightLoadingPanel
      : examStarted
        ? (
          <Surface style={{
            height: 'fit-content',
            marginBottom: breakpointBreached ? '2rem' : '0',
            border: `1px solid ${someInfoIsMissing ? colors.error.main : colors.success.main}`,
            minHeight: 'none',
          }}>
            <DataCardHeader style={{ borderBottom: `1px solid ${colors.grey[300]}` }}>
              {someInfoIsMissing ? 'Some Information Missing' : 'All Information Found!'}
            </DataCardHeader>
            <DataRow
              item={{
                type: 'static',
                content: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {someInfoIsMissing && !!jointsMissingPros.length && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span style={{ transform: 'translateY(-3px)', marginRight: '.25rem' }}>•</span>
                        <MDTypography variant='body2' sx={{ textTransform: 'capitalize' }}>
                          <span style={{ fontWeight: 600 }}>Incomplete PROs:</span>
                          {' '}
                          {jointsMissingPros.map((fullJoint, i, a) => (
                            <span style={{ textTransform: 'capitalize' }} key={fullJoint}>
                              {fullJoint}{i === a.length - 1 ? '' : ', '}
                            </span>
                          ))}
                        </MDTypography>
                      </div>
                    )}
                    {someInfoIsMissing && Object.entries(missingXrays).map(([examType, viewTypeObjs]) => (
                      <div
                        key={examType}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span style={{ transform: 'translateY(-3px)', marginRight: '.25rem' }}>•</span>
                        <MDTypography variant='body2' sx={{ textTransform: 'capitalize' }}>
                          <span style={{ fontWeight: 600 }}>Missing {examType} X-rays:</span>
                          {' '}
                          {viewTypeObjs.map((viewTypeData, i, a) => (
                            <React.Fragment key={viewTypeData.key}>
                              {viewTypeData.label}{i === a.length - 1 ? '' : ', '}
                            </React.Fragment>
                          ))}
                        </MDTypography>
                      </div>
                    ))}
                    {!someInfoIsMissing && (
                      <MDTypography variant='body2'>
                        All required patient information has been found.
                        You may now continue with the evaluation.
                      </MDTypography>
                    )}
                  </div>
                ),
              }}
            />
          </Surface>
        )
        : (
          <IfUserIsAllowed
            to={UPDATE$APPOINTMENT}
            fallback={(
              <Surface style={{
                height: 'fit-content',
                marginBottom: breakpointBreached ? '2rem' : '0',
                textAlign: breakpointBreached ? 'center' : 'left',
                padding: '5% 10%',
                flexDirection: breakpointBreached ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: breakpointBreached ? '1rem' : '10%',
                border: `1px solid ${colors.info.main}`,
              }}>
                <FontAwesomeIcon color={colors.info.main} icon={faCircleExclamation} size='3x' />
                <MDTypography>
                  A verified practictioner has not yet started this exam. Please check back later for more information.
                </MDTypography>
              </Surface>
            )}
          >
            <MDButton
              variant='contained'
              color={MUIColors.SECONDARY}
              style={{ marginBottom: breakpointBreached ? '2rem' : '0' }}
              onClick={toggleJointSelectInterface}
            >
              Begin Evaluation
            </MDButton>
          </IfUserIsAllowed>
        );

  const columnContentSharedStyles: CSSProperties = {
    height: '32vh',
    minHeight: '15rem',
  };

  const handleNextStep = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.EXAM_ROOM_ALL_JOINTS,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const mainAppLayoutProps: MainAppLayoutProps = {
    headerItems,
    navActions: {
      previous: jointSelectInterfaceActive
        ? {
          text: 'Cancel',
          action: handleCancelJointSelection,
        }
        : null,
      next: uploadInterfaceActive
        ? null
        : {
          disabled: jointSelectInterfaceActive ? updateAppointmentLoading : !examStarted || !checkForOneReadyExam() || loading,
          text: jointSelectInterfaceActive
            ? updateAppointmentLoading ? 'Please Wait...' : 'Save Selections'
            : 'Diagnosis and Treatment',
          action: jointSelectInterfaceActive
            ? handleSelectJointsComplete
            : someInfoIsMissing ? () => setShowConfirmationModal(true) : handleNextStep,
          message: updateAppointmentError
            ? (
              <MDTypography pt={1} textAlign='left' variant='body2' color={MUIColors.ERROR}>
                Something went wrong. Please try again.
              </MDTypography>
            )
            : null,
        },
    },
  };

  if (!loading && !appointment) return (
    <ErrorPage code={404} />
  );

  if (error) return (
    <ErrorPage />
  );

  if (uploadInterfaceActive) return (
    <XrayUploadInterface
      // TODO: right now the "kneeCap" view type encompasses both bilateral and unilateral kneecap
      // views. this means that if an exam starts as a single knee and a unilateral kneecap xray
      // is uploaded, and then the exam is changed to two knees, the upload interface will put the
      // unilateral kneecap xray into the bilateral kneecap upload portal and flag it as a view type
      // mismatch. not a huge deal currently, but should probably update this so that bilateral kneecap
      // and unilateral kneecap are treated as two separate view types
      xrays={
        requiredViews
          ? allAppointmentXrays?.filter((xray) => fullJointsByExamTypes[requiredViews[0]][xray.joint])
          : []
      }
      requiredViewsTuple={requiredViews}
      handleViewToggle={toggleUploadInterface}
      appointment={appointment}
      layoutProps={mainAppLayoutProps}
    />
  );

  return (
    <>
      {showQrModal && (
        <Modal
          {...containerStyles({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
          open={showQrModal}
          onClose={() => setShowQrModal(false)}
        >
          <MDBox
            shadow={Shadows.SMALL}
            borderRadius={borders.borderRadius.lg}
            sx={{
              padding: '3rem',
              backgroundColor: colors.white.main,
              width: breakpointBreached ? '90%' : 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography variant='h4' mb={3}>Scan on tablet to continue:</MDTypography>
            {/* generates a QR code pointing to the tablet view for the survey entry page */}
            <QRCode value={`${new URL(window.location.href).origin}${routesMap[RouteKeys.SURVEY].baseRoute}/${appointmentId}?date=${appointmentDateISOString}&view=tablet`} />
          </MDBox>
        </Modal>
      )}
      {showConfirmationModal && (
        <Modal
          {...containerStyles({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
        >
          <MDBox
            shadow={Shadows.SMALL}
            borderRadius={borders.borderRadius.lg}
            sx={{
              padding: '3rem 5%',
              backgroundColor: colors.white.main,
              width: breakpointBreached ? '90%' : '35rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography fontWeight='bold' textAlign='center' variant='body1' mb={2}>
              Some required information is missing for joints in this evaluation.
            </MDTypography>
            <MDTypography textAlign='center' variant='body1' mb={4}>
              Do you wish to delay the evaluation of joints with missing data?
            </MDTypography>
            <MDBox {...containerStyles({
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-around',
            })}>
              <MDButton
                variant='contained'
                color={MUIColors.RETURN as unknown as MUIColors.PRIMARY}
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </MDButton>
              <MDButton
                onClick={handleNextStep}
                variant='contained'
                color={MUIColors.SECONDARY}
              >
                Yes
              </MDButton>
            </MDBox>
          </MDBox>
        </Modal>
      )}
      <MainAppLayout {...mainAppLayoutProps}>
        {jointSelectInterfaceActive && (
          <AppointmentJointSelection activeJoints={activeJoints} setActiveJoints={setActiveJoints} />
        )}
        {overviewInterfaceActive && (
          <MDBox {...containerStyles({ flexDirection: breakpointBreached ? 'column' : 'row' })}>
            <Column style={{ width: breakpointBreached ? '100%' : '50%' }}>
              {breakpointBreached && rightColTopContent}
              <ColumnContent style={{ ...columnContentSharedStyles, position: 'relative' }}>
                <JointInfoVisualizer
                  mode='hybrid'
                  jointData={activeJointsArraySortedByPriority.reduce((obj, fullJoint) => {
                    obj[fullJoint as FullJoints] = {
                      pro: latestProsMappedToJoints[fullJoint] || null,
                      priority: activeJoints && activeJoints[fullJoint],
                    };
                    return obj;
                  }, {} as JointDataType)}
                  dataLoading={loading}
                />
                {examStarted && (
                  <IfUserIsAllowed to={UPDATE$APPOINTMENT}>
                    <MDButton
                      variant='text'
                      color={MUIColors.SECONDARY}
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        padding: 0,
                      }}
                      onClick={toggleJointSelectInterface}
                    >
                      Edit Joints
                    </MDButton>
                  </IfUserIsAllowed>
                )}
              </ColumnContent>
              <ColumnContent>
                {loading && botLeftLoadingPanel}
                {!loading && (
                  <Surface style={{ minHeight: '12rem' }}>
                    <DataCardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                      PROs
                      {examStarted && (
                        <Tooltip
                          title={<MDTypography sx={{ fontSize: '13px' }} color={MUIColors.WHITE}>Patient entry</MDTypography>}
                          placement='left'
                        >
                          <MDButton
                            variant='outlined'
                            color={MUIColors.SECONDARY}
                            onClick={handleProQrClick}
                          >
                            <FontAwesomeIcon icon={faQrcode} color={colors.secondary.main} />
                          </MDButton>
                        </Tooltip>
                      )}
                    </DataCardHeader>
                    <DataRowHeader col1Header='Joints by Priority' col2Header='Status' />
                    {!proRowItems.length && (
                      <MDTypography
                        variant='body2'
                        sx={{
                          width: '100%',
                          height: '100%',
                          textAlign: 'center',
                        }}
                      >
                        No PROs have been completed for this evaluation.
                      </MDTypography>
                    )}
                    {proRowItems.map((proData, i, a) => (
                      <DataRow
                        key={proData.id}
                        item={proData}
                        isFirst={i === 0}
                        isLast={i === a.length - 1}
                      />
                    ))}
                  </Surface>
                )}
              </ColumnContent>
            </Column>
            <Column style={{ width: breakpointBreached ? '100%' : '50%' }}>
              {!breakpointBreached && (
                <ColumnContent style={{ ...columnContentSharedStyles, justifyContent: 'center' }}>
                  {rightColTopContent}
                </ColumnContent>
              )}
              <ColumnContent>
                {loading && botRightLoadingPanel}
                {!loading && (
                  <Surface style={{ minHeight: '12rem' }}>
                    <DataCardHeader>
                      X-rays
                    </DataCardHeader>
                    <DataRowHeader col1Header='Series Type' col2Header='View Type' />
                    {!xrayRowItems.length && (
                      <MDTypography
                        variant='body2'
                        sx={{
                          width: '100%',
                          height: '100%',
                          textAlign: 'center',
                        }}
                      >
                        No X-rays have been uploaded for this evaluation.
                      </MDTypography>
                    )}
                    {xrayRowItems.map((item, i, a) => (
                      <DataRow
                        key={item.id}
                        item={item}
                        isFirst={i === 0}
                        isLast={i === a.length - 1}
                        showRowDivider
                      />
                    ))}
                  </Surface>
                )}
              </ColumnContent>
            </Column>
          </MDBox>
        )}
      </MainAppLayout>
    </>
  );
};

export default AppointmentOverview;
