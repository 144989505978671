import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";

// Material Dashboard 2 React example components
import Footer from "components/molecules/Footer/Footer";
import ProfileInfoCard from "components/molecules/ProfileInfoCard/ProfileInfoCard";

// Overview page components
import ProfileContainer from "components/molecules/ProfileContainer/ProfileContainer";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import { useAuth } from "hooks/useAuth";
import { useOrgData } from "hooks/useOrgData";
import { useUserData } from "hooks/useUserData";
import { DBUser } from "models/UserModels";
import { Org, Roles } from "models/OrgModels";
import Alert from "components/atoms/Alert/Alert";

// This component is almost completely static and was essentially untouched by me, other than 
// piping the user's name and org details into the header. TODO: this page needs to be fully 
// hooked up to the database with real user information, and allow the user to edit their
// information as well

const ProfilePage: React.FC = () => {
  const { user } = useAuth();
  const { org } = useOrgData({ preventRefetch: true });

  const {
    userById,
    userByIdLoading,
    userByIdError
  } = useUserData();

  if (!userByIdLoading && userById?.user && user?.orgs[0] && org && !userByIdError) {
    return <Content orgRole={user.orgs[0].role} userProfile={userById.user} org={org} />
  }

  return <Fallback error={!!userByIdError} />
}

type ProfilePageProps = {
  org: Org
  userProfile: DBUser
  orgRole: Roles
}

const Fallback: React.FC<{ error: boolean }> = ({ error }) => (
  <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
    <MDAppBar />
    <ProfileContainer
      icon={false}
      header=""
      subHeader=""
    >

      <div>
        Loading...
      </div>

      <Alert
        show={error}
        type="error"
      >
        Something went wrong. Please try again later.
      </Alert>

    </ProfileContainer>
    <Footer />
  </Container>
)

const Content: React.FC<ProfilePageProps> = ({ org, userProfile, orgRole }) => {
  return (
    <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
      <MDAppBar />
      <ProfileContainer
        icon
        header={userProfile.name}
        subHeader={`${org.name} - ${orgRole}`}
        user={userProfile}
      >
        <MDBox mt={1.5} mb={1}>
          <Grid container sx={{ display: "flex" }}>
            <Grid item xs={12}  >

              <ProfileInfoCard
                title="profile information"
                info={{
                  full_name: userProfile.full_name,
                  user_location: userProfile.user_location,
                  phone: userProfile.phone,
                  email: userProfile.email,
                  bio: userProfile.bio
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ProfileContainer>
      <Footer />
    </Container>
  );
}

export default ProfilePage;
