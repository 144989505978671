
// React
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDInput from "components/atoms/MDInput/MDInput";
import MDButton, { MDButtonVariants } from "components/atoms/MDButton/MDButton";

// Authentication layout components
import BasicLayout from 'components/templates/BasicLayout/BasicLayout';

// AWS Cognito Authentication
import UserPool from 'services/cognitoUserPoolService';
import { CognitoUser } from 'amazon-cognito-identity-js';

// Images
import bgImage from 'assets/images/stock/stretching.png';
import { ReactComponent as Logo } from '../../../assets/images/logo/JointAILogo2023.svg';

// CSS
import './SignInPage.css';

import { useAuth } from 'hooks/useAuth';
import { MUIBaseColors, MUIBaseVariants, MUIColors } from 'models/StyleModels';
import { routesMap } from 'data/routes';
import { RouteKeys } from 'models/RouteModels';

// Much of the sign in/sign up funtionality is holdover code from before I came onto the project
// and could probably use some love. You'll definitely find some ugly code around these parts

const SignInPage: React.FC = () => {
  const { signIn, authError } = useAuth();

  // Navigate to Patient Dashboard on Successful Authentication
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successfulSignup = !!(queryParams.get('success'));
  const unauthorizedInvite = !!(queryParams.get('unauthorized'));

  // User Input Initialize States
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [email, setEmail] = useState('');

  // User Submit
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (username && password) {
      try {
        signIn({ username, password });
      } catch (err) {
        setValidationErrors('Something went wrong.');
      }
    } else {
      setValidationErrors('Please fill out all fields.');
    }
  };

  useEffect(() => {
    if (authError) {
      setValidationErrors("Incorrect username or password");

      if (authError === 'Account not verified. Please enter the email associated with your account') {
        setShowEmailConfirmation(true);
      }
    } else {
      setValidationErrors('');
    }
  }, [authError]);

  // Confirm Account Registration with Verification Code
  const confirmEmail = (event: React.FormEvent) => {
    event.preventDefault();
    const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool });
    cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
      if (err) {
        setValidationErrors('Verification Failed. Please try again');
        return;
      }
      if (result === 'SUCCESS') {
        navigate(routesMap[RouteKeys.PATIENT_LIST].route);
      }
    });
  };

  // Resend Verification Code
  const resendCode = (event: React.FormEvent) => {
    event.preventDefault();
    setVerifyCode(true);
    setValidationErrors('Verification code will be sent to the email specified if it exists in our records');
    const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool });
    cognitoUser.resendConfirmationCode(email as any, ((err: any, result: any) => {
      if (err) setValidationErrors('Something went wrong.');
    }) as any)
  };

  return (
    <BasicLayout image={bgImage}>
      <Card style={{
        backgroundColor: 'rgba(34, 69, 159, 0.8)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: "translate(-50%, -50%)", minWidth: '350px', maxWidth: '425px', overflowY: 'auto', maxHeight: '85vh',
      }}
      >

        {/* Logo */}
        <MDBox
          style={{
            width: '100%',
            opacity: 1,
            background: '#ffffff',
            borderRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          variant='gradient'
          bgColor={MUIColors.WHITE}
          borderRadius='lg'
          coloredShadow='light'
          my={2}
          p={3}
          textAlign='center'
        >
          <Logo style={{ height: 'fit-content', width: '100%' }} />
        </MDBox>

        <MDBox py={3} px={2}>

          {/* Validation Errors */}
          <MDBox lineHeight={0} pb={3} px={3} mb={2} textAlign='center' display={validationErrors === '' ? 'none' : 'block'}>
            <MDTypography variant='button' color={MUIColors.ERROR}>
              {validationErrors}
            </MDTypography>
          </MDBox>

          {successfulSignup && (
            <MDBox lineHeight={0} pb={3} px={3} mb={2} textAlign='center' sx={{ display: 'flex', flexDirection: 'column' }}>
              <MDTypography sx={{ textTransform: 'unset' }} variant='button' color={MUIColors.WHITE}>
                Registration successful.
              </MDTypography>
              <MDTypography sx={{ textTransform: 'unset', marginTop: '.5rem' }} variant='button' color={MUIColors.WHITE}>
                Sign in to continue.
              </MDTypography>
            </MDBox>
          )}

          {unauthorizedInvite && (
            <MDBox lineHeight={0} pb={3} px={3} mb={2} textAlign='center' sx={{ display: 'flex', flexDirection: 'column' }}>
              <MDTypography sx={{ textTransform: 'unset' }} variant='button' color={MUIColors.ERROR}>
                There was an issue authenticating your invitation.
              </MDTypography>
              <MDTypography sx={{ textTransform: 'unset', marginTop: '.5rem' }} variant='button' color={MUIColors.ERROR}>
                Please contact your organization administrator.
              </MDTypography>
            </MDBox>
          )}

          {!showEmailConfirmation ?
            <MDBox component='form' role='form' onSubmit={onSubmit}>

              {/* Username */}
              <MDBox mb={2}>
                <MDInput
                  type='email'
                  label='Email'
                  fullWidth
                  onChange={(event) => setUsername(event.target.value)} />
              </MDBox>

              {/* Password */}
              <MDBox mb={2}>
                <MDInput style={{ outline: 'white' }}
                  type='password'
                  label='Password'
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)} />
              </MDBox>

              {/* Sign In Button */}
              <MDBox mt={4} mb={1}>
                <MDButton
                  type='submit'
                  variant={MDButtonVariants.GRADIENT as unknown as MUIBaseVariants}
                  color={MUIColors.LIGHT as unknown as MUIBaseColors}
                  fullWidth
                >

                  Sign in

                </MDButton>
              </MDBox>


              {/* Don't have an account? */}
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color={MUIColors.WHITE}>

                  Don&apos;t have an account?{' '}<br />

                  <MDTypography
                    component={Link}
                    to='/authentication/sign-up'
                    variant='button'
                    color={MUIColors.LIGHT}
                    fontWeight='medium'
                    textGradient >

                    Sign up

                  </MDTypography>
                </MDTypography>
              </MDBox>

            </MDBox>
            :

            <MDBox>

              {!verifyCode ?
                <MDBox component='form' role='form' onSubmit={resendCode}>
                  <MDBox mb={2}>
                    <MDInput
                      className='email'
                      label='Email'
                      type='email'
                      value={email}
                      fullWidth
                      onChange={(event) => setEmail(event.target.value)} />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant={MDButtonVariants.GRADIENT as unknown as MUIBaseVariants}
                      color={MUIColors.LIGHT as unknown as MUIBaseColors}
                      type='submit'
                      fullWidth>

                      Send verification code

                    </MDButton>
                  </MDBox>
                </MDBox>

                :

                <MDBox component='form' role='form' onSubmit={confirmEmail}>
                  <MDBox mb={2}>
                    <MDInput
                      className='verification-code'
                      label='Verification Code'
                      value={verificationCode}
                      fullWidth
                      placeholder='######'
                      onChange={(event) => setVerificationCode(event.target.value)} />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant={MDButtonVariants.GRADIENT as unknown as MUIBaseVariants}
                      color={MUIColors.LIGHT as unknown as MUIBaseColors}
                      type='submit'
                      fullWidth>

                      Verify Account

                    </MDButton>
                  </MDBox>
                </MDBox>
              }
            </MDBox>
          }
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInPage;
