import styled from 'styled-components';

export const Container = styled.div`
    background-color: #E4EEF7;
    display: flex;
    gap: .5rem;
    padding: 0.8rem;
    width: fit-content;
    border-radius: 3.5rem;
    position: relative;
    cursor: pointer;
`;

export const Label = styled.div<{ isActive?: boolean }>`
    width: 4rem;
    font-size: .9rem;
    font-weight: 700;
    text-align: center;
    padding: .5rem 1rem;
    color: ${props => props.isActive ? '#fff' : '#225E9F'};
    z-index: 1;
`;

export const Slider = styled.div<{ isActive?: boolean }>` 
    position: absolute;
    background-color: #225E9F;
    border-radius: 3rem;
    width: 4rem;
    top: 0.75rem;
    height: 2.5rem;
    transform: ${props => props.isActive ? 'translateX(0)' : 'translateX(4.5rem)'};
    transition: transform  .5s ease;
`;