import React from 'react';
import Lottie from "lottie-react";
import loaderAnimation from "assets/animations/loader/loader.json";

const Loader: React.FC = () => (
  <div
    style={{
      width: '100%',
      height: '90vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Lottie
      style={{ height: '100px', width: 'auto' }}
      animationData={loaderAnimation}
      loop={true}
    />
  </div>
);

export default Loader;
