import React, { HTMLAttributes, useState } from 'react';
import { Card, Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import { loadingAnimation } from 'assets/animations/animationStyles';
import { DetailCardContainer, HeaderContainer, HeaderProValue, HeaderWorstCaseValue, ProScore, SecondaryHeaderLabels, SectionContainer, TreatmentReco } from './TriageJointOutcomeSection.styles';
import TriageXrayDetailCard, { TriageMissingXrayDetailCard } from '../TriageXrayDetailCard/TriageXrayDetailCard';
import { reqViewsByJoint } from 'data/dataConstants';
import { useBreakpoints } from 'hooks/useBreakpoints';
import XrayReviewModal from '../XrayReviewModal/XrayReviewModal';
import { Appointment } from 'models/AppointmentModels';
import { PRO } from 'models/SurveyModels';
import MDButton from 'components/atoms/MDButton/MDButton';
import { useXrayMutations } from 'hooks/useXrayMutations';
import {
  capitalize,
  containerStyles,
  filterAccionPredSectsByFullJoint,
  getJointSideFromFullJoint,
  getProPainLevel,
  getWorstCaseDegeneration,
  isAnyJointSurgicalCandidateByXraySections,
  roundProScore,
} from 'helpers/helpers';
import {
  DegenerationLevels,
  FullJoints,
  JointSections,
  ViewTypes,
  Xray,
  normalizedDegenerationLevels,
  normalizedViewTypes,
} from 'models/XrayModels';

interface JointOutcomesSectionLoaderProps {
  displayVertical: boolean;
}

export const JointOutcomesSectionLoader: React.FC<JointOutcomesSectionLoaderProps> = ({ displayVertical }) => (
  <MDBox
    shadow={Shadows.SMALL}
    {...containerStyles({
      flexDirection: 'column',
      height: 'fit-content',
      borderRadius: '8px',
      overflow: 'hidden',
    })}
  >
    <MDBox {...containerStyles({
      width: '100%',
      height: '3.1rem',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.secondary.focus,
      padding: '.5rem 1rem',
      ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
    })} />
    <MDBox
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '1rem',
      }}
    >
      <MDBox
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: displayVertical ? 'column' : 'row',
          justifyContent: 'center',
          padding: '.5rem',
          textAlign: 'center',
          height: '3.06rem',
          ...loadingAnimation('backgroundColor'),
        }}
      />
    </MDBox>
    <MDBox
      sx={{
        width: '100%',
        backgroundColor: colors.white.main,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        gap: '.5rem',
      }}
    >
      <MDBox
        shadow={Shadows.MEDIUM}
        {...containerStyles({
          height: '2.5rem',
          padding: '.25rem',
          display: 'flex',
          flexDirection: displayVertical ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          position: 'relative',
          backgroundColor: colors.grey[100],
          overflow: 'hidden',
          borderRadius: '8px',
          textAlign: 'center',
          ...loadingAnimation('backgroundColor'),
        })}
      />
      <MDBox {...containerStyles({
        width: '100%',
        display: 'flex',
        gap: '.5rem',
        flexDirection: displayVertical ? 'column' : 'row',
        alignItems: displayVertical ? 'flex-start' : 'center',
      })}>
        <Card
          {...containerStyles({
            width: displayVertical ? ' 100%' : '50%',
            height: '15rem',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            color: colors.white.main,
            ...loadingAnimation('backgroundColor'),
          })}
        />
        <Card
          {...containerStyles({
            width: displayVertical ? ' 100%' : '50%',
            height: '15rem',
            display: 'flex',
            borderRadius: '8px',
            border: '5px solid transparent',
            overflow: 'hidden',
            backgroundColor: colors.grey[300],
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            ...loadingAnimation('backgroundColor'),
          })}
        />
      </MDBox>
    </MDBox>
    <MDBox {...containerStyles({
      width: '100%',
      height: '3.75rem',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.disabled.focus,
      padding: '.75rem 1rem',
      ...loadingAnimation('backgroundColor'),
    })} />
  </MDBox>
);

type JointOutcomesSectionProps = {
  appointment: Appointment;
  fullJoint: FullJoints;
  xrays: Xray[];
  thumbnailXray: Xray | undefined;
  jointDegenOutcomes: DegenerationLevels[];
  displayVertical?: boolean;
  allXraysConfirmed: boolean;
  handleConfirmXrays(): void;
  confirmRequestLoading?: boolean;
  roiImgUrls: { [key: string]: string } | undefined;
  roiImagesLoading: boolean;
  hideCTA?: boolean;
  pro?: PRO
} & HTMLAttributes<HTMLDivElement>;

const JointOutcomesSection: React.FC<JointOutcomesSectionProps> = ({
  appointment,
  fullJoint,
  xrays,
  thumbnailXray,
  jointDegenOutcomes,
  displayVertical,
  allXraysConfirmed,
  handleConfirmXrays,
  confirmRequestLoading,
  roiImgUrls,
  roiImagesLoading,
  hideCTA,
  pro,
  ...props
}) => {
  const { batchConfirmXrays, updateXrayLoading } = useXrayMutations({ appointment });
  const jointSide = getJointSideFromFullJoint(fullJoint);
  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);
  const isSurgicalCandidate = isAnyJointSurgicalCandidateByXraySections(xrays, fullJoint);
  const { breakpointBreached: lgBreached } = useBreakpoints({ breakpoint: Breakpoints.X_LARGE });
  const reqViews = Object.values(reqViewsByJoint[fullJoint])

  const existingXrays = (xrays || []).reduce((acc: { [key in ViewTypes]: Xray }, xray) => {
    acc[xray.client_provided_view_type] = xray;
    return acc;
  }, {} as { [key in ViewTypes]: Xray });

  const [activeXrayID, setActiveXrayID] = useState<string | null>(null);
  const activeXray = xrays?.find((xray) => xray.xray_id === activeXrayID);
  const modalOpen = !!activeXray;
  const closeModal = () => setActiveXrayID(null);
  const proPainLevel = getProPainLevel(pro?.scores?.overall)
  const proScore = roundProScore(pro?.scores?.overall)
  const xrayDetailCards = reqViews
    .map((reqView) => {
      const xray = existingXrays[reqView.key]

      return xray ? (<TriageXrayDetailCard
        key={xray.xray_id}
        imgSrc={roiImgUrls![xray.prediction_results[`${jointSide}ROI`] || xray.prediction_results.singleROI || ''] || undefined}
        imgLoading={roiImagesLoading}
        label={normalizedViewTypes[xray.client_provided_view_type || xray.view_type_results.viewType]}
        displayVertical={lgBreached}
        handleClickReview={() => setActiveXrayID(xray.xray_id)}
        confirmed={!filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint).find((sect) => !sect.clientConfirmed)}
        compartmentOutcomes={
          filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint)
            .reduce((obj, sect) => {
              obj[sect.section] = sect.clientSelectedPrediction || sect.prediction;
              return obj;
            }, {} as { [JS in JointSections]?: DegenerationLevels })
        }
      />) : (<TriageMissingXrayDetailCard key={reqView.key} displayVertical={lgBreached} viewLabel={reqView.label} viewKey={reqView.key} />)
    });

  return (
    <SectionContainer key={fullJoint}>

      {modalOpen && (
        <XrayReviewModal
          xray={activeXray}
          appointment={appointment}
          fullJoint={fullJoint}
          modalOpen={modalOpen}
          handleClose={closeModal}
          roiImgUrls={roiImgUrls!}
        />
      )}

      <HeaderContainer $displayVertical={lgBreached}>

        <MDBox display='flex' gap=".5rem" justifyContent='flex-start' alignItems='center'>
          <MDTypography
            textTransform='capitalize'
            align="left"
            variant='h4'
            color={MUIColors.WHITE}
          >
            {fullJoint}
          </MDTypography>

          <TreatmentReco $candidate={isSurgicalCandidate}>

            {/* update with new pathway */}

            {isSurgicalCandidate ? 'Surgical Candidate' : 'Non-Surgical Candidate'}
          </TreatmentReco>
        </MDBox>

        <MDBox display='flex' gap="2rem" justifyContent={lgBreached ? 'flex-start' : 'flex-end'} alignItems='center'>
          {proPainLevel && <MDBox display='flex' gap=".5rem" justifyContent='center' alignItems='center'>
            <SecondaryHeaderLabels>
              Overall {fullJoint.split(" ")[1]} Health
            </SecondaryHeaderLabels>
            <HeaderProValue $proScore={proPainLevel}>
              <ProScore>{proScore}</ProScore>
              <span>{capitalize(proPainLevel)}</span>
            </HeaderProValue>
          </MDBox>}

          {<MDBox display='flex' gap=".5rem" justifyContent='center' alignItems='center' gridTemplateColumns="1fr 1fr">
            <SecondaryHeaderLabels>
              Worst Case DJD:
            </SecondaryHeaderLabels>
            <HeaderWorstCaseValue $degen={worstCaseDegen}>
              {normalizedDegenerationLevels[worstCaseDegen!]}
            </HeaderWorstCaseValue>
          </MDBox>}

        </MDBox>
      </HeaderContainer>

      <DetailCardContainer $displayVertical={lgBreached}>
        {xrayDetailCards}
      </DetailCardContainer>

      <MDBox display='flex' justifyContent='center' alignItems='center' gap='1rem' padding='1rem'>

        {allXraysConfirmed ? (
          <Typography fontWeight="700" style={{ color: colors.success.main }}>All Compartments Confirmed!</Typography>
        ) : (<MDButton
          variant='contained'
          color={MUIColors.SECONDARY}
          onClick={() => { batchConfirmXrays(xrays || [], fullJoint) }}
          disabled={updateXrayLoading}
        >
          Confirm {fullJoint} X-Ray Compartments
        </MDButton>)}
      </MDBox>
    </SectionContainer>
  );
};

export default JointOutcomesSection;
