import { forwardRef, ReactNode } from "react";
import MDBoxRoot from "components/atoms/MDBox/MDBoxRoot";
import { BoxProps } from "@mui/system";
import { GreyValues, MUIColors, Shadows } from "models/StyleModels";

export type MDBoxProps = {
  variant?: string;
  bgColor?: MUIColors | GreyValues;
  color?: MUIColors;
  opacity?: number;
  borderRadius?: string;
  shadow?: Shadows;
  coloredShadow?: string;
  children?: ReactNode;
  type?: string;
} & BoxProps;

const MDBox = forwardRef<HTMLDivElement, MDBoxProps>(
  ({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
    <MDBoxRoot
      {...rest}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
    />
  )
);

export default MDBox;
