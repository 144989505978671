// As you may be able to tell from the below comment block, this file was part of the original fork and heavy modification
// of the generic MUI Dashboard. Some of the items listed in that comment are pretty out of date, so you can infer what the
// actual important fields are given what's actually being used in the routesMap object. I've removed ones that have absolutely
// no relevance.

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  4. The `name` key is used for the name of the route on the Sidenav. **OUTDATED - there is no sidenav**
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  7. The `route` key is used to store the route location which is used for the react router.
  10. The `component` key is used to store the component of its route.
  11. The `unauthedUnonboardedOnly` property, when true, will prevent the route from being indexed in the top level App.js component when a user is not logged in or hasn't onboarded. 
  This can be used to flag off in development features if provided the appropriate flag from data/flags.js, or to hide
  certain routes based on authentication status.
  12. The `permissionSlug` key can be optionally supplied to a route from the ActionSlugs object and used in conjuction
  with logic added to the user.can method supplied by the useAuth hook to gate any page behind certain roles
*/

// Pages
import ProfilePage from "components/pages/ProfilePage/ProfilePage";
import SignInPage from "components/pages/SignInPage/SignInPage";
import SignUpPage from "components/pages/SignUpPage/SignUpPage";
import OrganizationDashboardPage from "components/pages/OrganizationDashboardPage/OrganizationDashboardPage";
import AppointmentOverviewPage from "components/pages/AppointmentOverviewPage/AppointmentOverviewPage";
import OnboardingPage from "components/pages/OnboardingPage/OnboardingPage";
import OrganizationPage from "components/pages/OrganizationPage/OrganizationPage";
import SurveyEntryPage from "components/pages/SurveyEntryPage/SurveyEntryPage";
import PatientOverviewPage from "components/pages/PatientOverviewPage/PatientOverviewPage";
import ExamReportPage from "components/pages/ExamReportPage/ExamReportPage";
import ExamRoomAllJointsPage from "components/pages/ExamRoomAllJointsPage/ExamRoomAllJointsPage";
import ExamRoomSingleJointPage from "components/pages/ExamRoomSingleJointPage/ExamRoomSingleJointPage";

import { RoutesMapInterface, RouteKeys, RouteTypes } from "models/RouteModels";
import { OrgDashboardModes } from "components/pages/OrganizationDashboardPage/OrganizationDashboardPage.types";
import { FullJoints } from "models/XrayModels";
import ActionSlugs from "services/PermissionsService";
import ScheduleCallPage from "components/pages/ScheduleCallPage/ScheduleCallPage";
import TriageListPage from "components/pages/TriagListPage/TriageListPage";
import BatchXrayUploadPage from "components/pages/BatchXrayUploadPage/BatchXrayUploadPage";
import MatchXraysPage from "components/pages/MatchXraysPage/MatchXraysPage";
import TriageExamRoomAllJointsPage from "components/pages/TriageExamRoomAllJointsPage/TriageExamRoomAllJointsPage";
import TriageExamReportPage from "components/pages/TriageExamReportPage/TriageExamReportPage";
import TriageSurveyEntryPage from "components/pages/TriageSurveyEntryPage/TriageSurveyEntryPage";

const examRoomBase = '/exam-room';
const triageExamRoomBase = '/triage-exam-room';

// these base routes are used to build out full routes which include params for fetching
// certain data. you'll see that members of the routesMap object below which have a baseRoute
// field also contain a route field, which combines their corresponding baseRoute with the
// dynamic parameter
const baseRoutes = {
  [RouteKeys.APPOINTMENT_OVERVIEW]: '/appointment',
  [RouteKeys.PATIENT_OVERVIEW]: '/patient',
  [RouteKeys.SURVEY]: '/survey',
  [RouteKeys.TRIAGE_SURVEY]: '/triage-survey',
  [RouteKeys.EXAM_ROOM_ALL_JOINTS]: examRoomBase,
  [RouteKeys.EXAM_ROOM_RIGHT_KNEE]: `${examRoomBase}/right-knee`,
  [RouteKeys.EXAM_ROOM_LEFT_KNEE]: `${examRoomBase}/left-knee`,
  [RouteKeys.EXAM_ROOM_RIGHT_HIP]: `${examRoomBase}/right-hip`,
  [RouteKeys.EXAM_ROOM_LEFT_HIP]: `${examRoomBase}/left-hip`,
  [RouteKeys.REPORT]: '/report',
  [RouteKeys.TRIAGE_REPORT]: '/triage-report',
  [RouteKeys.MATCH_XRAYS]: '/match-xrays',
  [RouteKeys.BATCH_XRAY_UPLOAD]: '/batch-upload',

  [RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS]: triageExamRoomBase,
};

// each member of this object represents an authenticated route indexed by the top level App.tsx component.
// each member of the RouteKeys enum must be reflected by a route object here.
export const routesMap: RoutesMapInterface = {
  [RouteKeys.SIGN_IN]: {
    type: RouteTypes.COLLAPSE,
    name: "Sign In",
    key: RouteKeys.SIGN_IN,
    route: "/authentication/sign-in",
    component: <SignInPage />,
    unauthedUnonboardedOnly: true,
  },
  [RouteKeys.SIGN_UP]: {
    type: RouteTypes.COLLAPSE,
    name: "Sign Up",
    key: RouteKeys.SIGN_UP,
    route: "/authentication/sign-up",
    component: <SignUpPage />,
    unauthedUnonboardedOnly: true,
  },
  [RouteKeys.APPOINTMENT_SCHEDULE]: {
    type: RouteTypes.COLLAPSE,
    name: "Appointment Schedule",
    key: RouteKeys.APPOINTMENT_SCHEDULE,
    route: "/appointment-schedule",
    component: <OrganizationDashboardPage mode={OrgDashboardModes.APPOINTMENT_SCHEDULE} />,
  },
  [RouteKeys.PATIENT_LIST]: {
    type: RouteTypes.COLLAPSE,
    name: "Patient List",
    key: RouteKeys.PATIENT_LIST,
    route: "/patient-list",
    component: <OrganizationDashboardPage mode={OrgDashboardModes.PATIENT_LIST} />,
  },
  [RouteKeys.TRIAGE_LIST]: {
    type: RouteTypes.COLLAPSE,
    name: "Triage List",
    key: RouteKeys.TRIAGE_LIST,
    route: "/triage-list",
    component: <TriageListPage />,
  },
  [RouteKeys.PROFILE]: {
    type: RouteTypes.COLLAPSE,
    name: "Profile",
    key: RouteKeys.PROFILE,
    route: "/profile",
    component: <ProfilePage />,
  },
  [RouteKeys.APPOINTMENT_OVERVIEW]: {
    type: RouteTypes.COLLAPSE,
    name: "Appointment Overview",
    key: RouteKeys.APPOINTMENT_OVERVIEW,
    baseRoute: baseRoutes[RouteKeys.APPOINTMENT_OVERVIEW],
    route: `${baseRoutes[RouteKeys.APPOINTMENT_OVERVIEW]}/:appointmentId`, // base route + dynamic parameter
    component: <AppointmentOverviewPage />,
  },
  [RouteKeys.PATIENT_OVERVIEW]: {
    type: RouteTypes.COLLAPSE,
    name: "Patient Overview",
    key: RouteKeys.PATIENT_OVERVIEW,
    baseRoute: baseRoutes[RouteKeys.PATIENT_OVERVIEW],
    route: `${baseRoutes[RouteKeys.PATIENT_OVERVIEW]}/:patientId`, // base route + dynamic parameter
    component: <PatientOverviewPage />,
  },
  [RouteKeys.ONBOARDING]: {
    type: RouteTypes.COLLAPSE,
    name: "Onboarding",
    key: RouteKeys.ONBOARDING,
    route: "/onboarding",
    component: <OnboardingPage />,
    unauthedUnonboardedOnly: true,
  },
  [RouteKeys.ORG_DETAILS]: {
    type: RouteTypes.COLLAPSE,
    name: "Organization Details",
    key: RouteKeys.ORG_DETAILS,
    route: "/organization",
    component: <OrganizationPage />,
  },
  [RouteKeys.SURVEY]: {
    type: RouteTypes.COLLAPSE,
    name: "Survey Entry",
    key: RouteKeys.SURVEY,
    baseRoute: baseRoutes[RouteKeys.SURVEY],
    route: `${baseRoutes[RouteKeys.SURVEY]}/:appointmentId`, // base route + dynamic parameter
    component: <SurveyEntryPage />,
  },
  [RouteKeys.TRIAGE_SURVEY]: {
    type: RouteTypes.COLLAPSE,
    name: "Survey Entry",
    key: RouteKeys.TRIAGE_SURVEY,
    baseRoute: baseRoutes[RouteKeys.TRIAGE_SURVEY],
    route: `${baseRoutes[RouteKeys.TRIAGE_SURVEY]}/:appointmentId`, // base route + dynamic parameter
    component: <TriageSurveyEntryPage />,
  },
  [RouteKeys.EXAM_ROOM_ALL_JOINTS]: {
    type: RouteTypes.COLLAPSE,
    name: "Exam Room",
    key: RouteKeys.EXAM_ROOM_ALL_JOINTS,
    baseRoute: baseRoutes[RouteKeys.EXAM_ROOM_ALL_JOINTS],
    route: `${baseRoutes[RouteKeys.EXAM_ROOM_ALL_JOINTS]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamRoomAllJointsPage />,
  },
  [RouteKeys.EXAM_ROOM_RIGHT_KNEE]: {
    type: RouteTypes.COLLAPSE,
    name: "Right Knee Details",
    key: RouteKeys.EXAM_ROOM_RIGHT_KNEE,
    baseRoute: baseRoutes[RouteKeys.EXAM_ROOM_RIGHT_KNEE],
    route: `${baseRoutes[RouteKeys.EXAM_ROOM_RIGHT_KNEE]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamRoomSingleJointPage fullJoint={FullJoints.RIGHT_KNEE} />,
  },
  [RouteKeys.EXAM_ROOM_LEFT_KNEE]: {
    type: RouteTypes.COLLAPSE,
    name: "Left Knee Details",
    key: RouteKeys.EXAM_ROOM_LEFT_KNEE,
    baseRoute: baseRoutes[RouteKeys.EXAM_ROOM_LEFT_KNEE],
    route: `${baseRoutes[RouteKeys.EXAM_ROOM_LEFT_KNEE]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamRoomSingleJointPage fullJoint={FullJoints.LEFT_KNEE} />,
  },
  [RouteKeys.EXAM_ROOM_RIGHT_HIP]: {
    type: RouteTypes.COLLAPSE,
    name: "Right Hip Details",
    key: RouteKeys.EXAM_ROOM_RIGHT_HIP,
    baseRoute: baseRoutes[RouteKeys.EXAM_ROOM_RIGHT_HIP],
    route: `${baseRoutes[RouteKeys.EXAM_ROOM_RIGHT_HIP]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamRoomSingleJointPage fullJoint={FullJoints.RIGHT_HIP} />,
  },
  [RouteKeys.EXAM_ROOM_LEFT_HIP]: {
    type: RouteTypes.COLLAPSE,
    name: "Left Hip Details",
    key: RouteKeys.EXAM_ROOM_LEFT_HIP,
    baseRoute: baseRoutes[RouteKeys.EXAM_ROOM_LEFT_HIP],
    route: `${baseRoutes[RouteKeys.EXAM_ROOM_LEFT_HIP]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamRoomSingleJointPage fullJoint={FullJoints.LEFT_HIP} />,
  },
  [RouteKeys.REPORT]: {
    type: RouteTypes.COLLAPSE,
    name: "Exam Report",
    key: RouteKeys.REPORT,
    baseRoute: baseRoutes[RouteKeys.REPORT],
    route: `${baseRoutes[RouteKeys.REPORT]}/:appointmentId`, // base route + dynamic parameter
    component: <ExamReportPage />,
  },
  [RouteKeys.TRIAGE_REPORT]: {
    type: RouteTypes.COLLAPSE,
    name: "Exam Report",
    key: RouteKeys.TRIAGE_REPORT,
    baseRoute: baseRoutes[RouteKeys.TRIAGE_REPORT],
    route: `${baseRoutes[RouteKeys.TRIAGE_REPORT]}/:appointmentId`, // base route + dynamic parameter
    component: <TriageExamReportPage />,
  },
  [RouteKeys.JAI_DASHBOARD]: {
    type: RouteTypes.COLLAPSE,
    name: "JointAi Admin Dashboard",
    key: RouteKeys.JAI_DASHBOARD,
    route: "/jai-dashboard",
    component: <>JointAi Super Admin Dashboard</>, // TODO
    permissionSlug: ActionSlugs.VIEW$JAI_DASHBOARD_PAGE,
  },
  [RouteKeys.SCHEDULE_CALL]: {
    type: RouteTypes.COLLAPSE,
    name: "Schedule Call",
    key: RouteKeys.SCHEDULE_CALL,
    route: "/schedule-call",
    component: <ScheduleCallPage />,
  },
  [RouteKeys.BATCH_XRAY_UPLOAD]: {
    type: RouteTypes.COLLAPSE,
    name: "Batch Xray Upload",
    key: RouteKeys.BATCH_XRAY_UPLOAD,
    baseRoute: baseRoutes[RouteKeys.BATCH_XRAY_UPLOAD],
    route: `${baseRoutes[RouteKeys.BATCH_XRAY_UPLOAD]}/:appointmentId`,
    component: <BatchXrayUploadPage />,
  },
  [RouteKeys.MATCH_XRAYS]: {
    type: RouteTypes.COLLAPSE,
    name: "Match Xrays",
    key: RouteKeys.MATCH_XRAYS,
    baseRoute: baseRoutes[RouteKeys.MATCH_XRAYS],
    route: `${baseRoutes[RouteKeys.MATCH_XRAYS]}/:appointmentId`,
    component: <MatchXraysPage />,
  },
  [RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS]: {
    type: RouteTypes.COLLAPSE,
    name: "Exam Room",
    key: RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS,
    baseRoute: baseRoutes[RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS],
    route: `${baseRoutes[RouteKeys.TRIAGE_EXAM_ROOM_ALL_JOINTS]}/:appointmentId`, // base route + dynamic parameter
    component: <TriageExamRoomAllJointsPage />,
  }
};

const routes = Object.values(routesMap);

export default routes;
