import React, { useState, useEffect } from 'react';
import { containerStyles } from "helpers/helpers";
import MainAppLayout, { MainAppLayoutProps } from "components/templates/MainAppLayout/MainAppLayout";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDBox from "components/atoms/MDBox/MDBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import colors from 'assets/theme/base/colors';
import XrayUploadPortal from 'components/molecules/XrayUploadPortal/XrayUploadPortal';
import { ViewsObject } from 'services/mlService';
import { ExamTypes, FullJoints, ViewTypeData, ViewTypes, Xray } from 'models/XrayModels';
import { Appointment } from 'models/AppointmentModels';
import ActionSlugs from 'services/PermissionsService';
import { useAuth } from 'hooks/useAuth';

interface XrayUploadInterfaceProps {
  requiredViewsTuple: [ExamTypes, { [VT in ViewTypes]?: ViewTypeData<VT> }] | null;
  xrays: Xray[] | undefined;
  handleViewToggle(): void;
  appointment: Appointment | null;
  layoutProps: MainAppLayoutProps;
}

const XrayUploadInterface: React.FC<XrayUploadInterfaceProps> = ({
  requiredViewsTuple,
  xrays = [],
  handleViewToggle,
  appointment,
  layoutProps,
}) => {
  const examType = requiredViewsTuple && requiredViewsTuple[0];
  const requiredViews = requiredViewsTuple && requiredViewsTuple[1];

  const { CREATE$XRAY, DELETE$XRAY } = ActionSlugs;

  const { user } = useAuth();

  const isReadOnly = !user?.can(CREATE$XRAY) || !user.can(DELETE$XRAY);

  const getJointDetailsFromExamType = () => {
    switch (examType) {
      case ExamTypes.SINGLE_KNEE_RIGHT:
        return { label: 'Right Knee', joint: FullJoints.RIGHT_KNEE };
      case ExamTypes.SINGLE_KNEE_LEFT:
        return { label: 'Left Knee', joint: FullJoints.LEFT_KNEE };
      case ExamTypes.BILAT_KNEE:
        return { label: 'Right and Left Knees', joint: 'bilateral knee' };
      case ExamTypes.SINGLE_HIP_RIGHT:
        return { label: 'Right Hip', joint: FullJoints.RIGHT_HIP };
      case ExamTypes.SINGLE_HIP_LEFT:
        return { label: 'Left Hip', joint: FullJoints.LEFT_HIP };
      case ExamTypes.BILAT_HIP:
        return { label: 'Right and Left Hips', joint: 'bilateral hip' };
      default: return { label: '', joint: '' };
    }
  };

  const { label, joint } = getJointDetailsFromExamType();

  const [views, setViews] = useState<ViewsObject>(Object.keys(requiredViews || {}).reduce((obj, view) => {
    obj[view as ViewTypes] = null;
    return obj;
  }, {} as ViewsObject));

  useEffect(() => {
    xrays.forEach((xray) => setViews((oldViews) => ({
      ...oldViews,
      [xray.client_provided_view_type]: xray,
    })));
  }, [xrays]);

  const someViewsMissing = Object.entries(views).find(([viewType, xray]) => {
    const isOptional = !!(requiredViews && requiredViews[viewType as ViewTypes]?.isOptional);
    if (!isOptional && !xray) return true;
    else return false;
  });

  const invalidXrayDetected = Object.entries(views).find(([viewType, xray]) => {
    const isOptional = !!(requiredViews && requiredViews[viewType as ViewTypes]?.isOptional);
    const view = requiredViews && requiredViews[viewType as ViewTypes];
    const validated = viewType === "kneeCap" && xray
      ? view?.value.includes(xray.view_type_results.viewType)
      : xray?.view_type_results?.viewType === view?.value;
    return !isOptional && !validated;
  });

  const notReadyForAnalysis = someViewsMissing || invalidXrayDetected;

  const navActions = {
    previous: {
      text: 'Back',
      action: () => handleViewToggle(),
      hide: !isReadOnly,
    },
    next: {
      text: 'Confirm X-rays',
      action: () => handleViewToggle(),
      hide: isReadOnly,
    },
  };

  const mainAppLayoutProps: MainAppLayoutProps = {
    ...layoutProps,
    navActions,
  };

  return (
    <MainAppLayout {...mainAppLayoutProps}>
      <MDBox {...containerStyles({ flexDirection: 'column' })}>
        <MDTypography variant="h3" fontWeight="regular">
          Confirm X-rays for Use in Evaluation of
          <span style={{ textTransform: 'capitalize' }}> {label}</span>
        </MDTypography>
        <MDBox {...containerStyles({
          margin: '1rem 0',
          backgroundColor: notReadyForAnalysis
            ? colors.badgeColors.error.background
            : colors.badgeColors.success.background,
          color: notReadyForAnalysis
            ? colors.error.main
            : colors.success.dark,
          padding: '1rem',
          alignItems: 'center',
          '& > *': {
            marginRight: '1rem',
          },
        })}>
          <FontAwesomeIcon icon={notReadyForAnalysis ? faCircleXmark : faCheck} />
          <MDTypography
            style={{
              color: notReadyForAnalysis ? colors.error.main : colors.success.dark
            }}
            variant="body2">
            {notReadyForAnalysis
              ? 'A match was not found for all X-rays'
              : 'A match was found for all necessary views'
            }
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox {...containerStyles({
        flexWrap: 'wrap',
        '& > *': {
          marginRight: '2rem',
          marginBottom: '1rem',
        }
      })}>
        {Object.entries(requiredViews || {}).map(([viewTypeLabel, viewTypeValues]) => (
          <XrayUploadPortal
            key={viewTypeLabel}
            viewType={viewTypeValues}
            xray={views[viewTypeLabel as ViewTypes]}
            appointment={appointment}
            joint={joint}
            views={views}
            setViews={setViews}
            disabled={isReadOnly}
          />
        ))}
      </MDBox>
    </MainAppLayout>
  );
};

export default XrayUploadInterface;
