import React, { CSSProperties } from "react";
import MDTypography from "../MDTypography/MDTypography";

interface SurveyQuestionTextProps {
  children: React.ReactNode;
  bold?: boolean;
  style?: CSSProperties;
}

const SurveyQuestionMainText: React.FC<SurveyQuestionTextProps> = ({ children, bold, style }) => (
  <MDTypography fontWeight={bold ? 'bold' : 'regular'} variant='body1' sx={{ marginBottom: '1rem', ...style }}>
    {children}
  </MDTypography>
);

export default SurveyQuestionMainText;
