import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import MainAppLayout from 'components/templates/MainAppLayout/MainAppLayout';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Breakpoints } from 'models/StyleModels';

const ScheduleCallPage: React.FC = () => {
    const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('id', 'calendly-embed-script')
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');

        head!.appendChild(script);

        return () => {
            const element = document.getElementById("calendly-embed-script");
            element?.remove()
        }
    }, [])

    return (
        <MainAppLayout headerItems={{
            headline: "",
            subItems: [],
            pageTitle: "Schedule a Call"
        }}>
            <Container sx={{ height: "80vh" }}>
                <MDBox display="grid" height="100%" justifyContent="center">
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/dj--mlu/introductory-call"
                        style={{
                            width: !breakpointBreached ? '800px' : '300px',
                            overflow: 'hidden'
                        }}></div>
                </MDBox>
            </Container>
        </MainAppLayout>
    );
};

export default ScheduleCallPage;
