import React from 'react';

const Spinner: React.FC = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <svg viewBox="0 0 274 275" width="100" height="100" style={{ animation: 'spinAnimation 3s linear infinite' }}>
                <style>
                    {`
                    @keyframes spinAnimation {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    `}
                </style>
                <path fillRule="evenodd" clipRule="evenodd" d="M137.094 10.6406C207.165 10.6406 263.969 67.4445 263.969 137.516C263.969 207.587 207.165 264.391 137.094 264.391C67.0226 264.391 10.2187 207.587 10.2188 137.516H0.21875C0.218747 213.11 61.4998 274.391 137.094 274.391C212.688 274.391 273.969 213.11 273.969 137.516C273.969 61.9216 212.688 0.640625 137.094 0.640625V10.6406Z" fill="#225E9F" />
            </svg>
        </div>
    );
};

export default Spinner;

