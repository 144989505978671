import colors from "assets/theme/base/colors";

const listItemButton = {
  styleOverrides: {
    root: {
      '&.MuiListItemButton-root.Mui-selected': {
        backgroundColor: `${colors.secondary.main} !important`,
      },
    },
  },
};

export default listItemButton;
