import { Card } from "@mui/material"
import MDBox from "components/atoms/MDBox/MDBox"
import BasicLayout from "components/templates/BasicLayout/BasicLayout"
import { MUIColors } from "models/StyleModels"

// Images
import bgImage from 'assets/images/stock/stretching.png';
import { ReactComponent as Logo } from '../../../assets/images/logo/JointAILogo2023.svg';
import PreExistingOrgStep from "./steps/PreExistingOrgStep"
import { useState } from "react"
import RequestLinkStep from "./steps/RequestLinkStep"
import ConfirmLinkOrgStep from "./steps/ConfirmLinkOrgStep"
import { useLocation, useNavigate } from "react-router-dom"
import EmailCodeVerifyStep from "./steps/EmailCodeVerifyStep"
import useForm from "hooks/useForm"
import SignUpWithLinkStep from "./steps/SignUpStep"
import { routesMap } from "data/routes"
import { RouteKeys } from "models/RouteModels"

enum Steps {
    PreExistingOrgStep,
    RequestLinkStep,
    ConfirmLinkOrgStep,
    SignUpWithLinkStep,
    EmailCodeVerifyStep
}

export interface FormValues {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

const SignUpPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const inviteEmail = queryParams.get('email');
    const inviteId = queryParams.get('invite');
    const fromInvite = !!inviteEmail && !!inviteId;

    const navigate = useNavigate()

    const {
        values,
        handleChange,
    } = useForm<FormValues>({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [currentStep, setCurrentStep] = useState(fromInvite
        ? Steps.ConfirmLinkOrgStep
        : Steps.PreExistingOrgStep);

    const renderStep = () => {
        switch (currentStep) {
            case Steps.PreExistingOrgStep:
                return <PreExistingOrgStep
                    affirmative={() => setCurrentStep(Steps.RequestLinkStep)}
                    negative={() => setCurrentStep(Steps.SignUpWithLinkStep)}
                    back={() => navigate(routesMap[RouteKeys.SIGN_IN].route)}
                />
            case Steps.RequestLinkStep:
                return <RequestLinkStep
                    back={() => setCurrentStep(Steps.PreExistingOrgStep)} />
            case Steps.ConfirmLinkOrgStep:
                return <ConfirmLinkOrgStep
                    affirmative={() => setCurrentStep(Steps.SignUpWithLinkStep)}
                    negative={() => setCurrentStep(Steps.PreExistingOrgStep)} />
            case Steps.SignUpWithLinkStep:
                return <SignUpWithLinkStep
                    formValues={values}
                    handleChange={handleChange}
                    fromInvite={fromInvite}
                    inviteEmail={inviteEmail}
                    next={() => setCurrentStep(Steps.EmailCodeVerifyStep)}
                    back={() => setCurrentStep(fromInvite ? Steps.ConfirmLinkOrgStep : Steps.PreExistingOrgStep)}
                />
            case Steps.EmailCodeVerifyStep:
                return <EmailCodeVerifyStep
                    formValues={values}
                    handleChange={handleChange}
                    inviteEmail={inviteEmail}
                    inviteId={inviteId}
                    fromInvite={fromInvite}
                />
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card style={{
                backgroundColor: 'rgba(34, 69, 159, 0.8)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: "translate(-50%, -50%)",
                minWidth: '350px',
                maxWidth: '425px',
                width: "50vw",
                overflowY: 'auto',
                maxHeight: '85vh',
            }}>
                <MDBox
                    style={{
                        width: '100%',
                        opacity: 1,
                        background: '#ffffff',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    variant='gradient'
                    bgColor={MUIColors.WHITE}
                    borderRadius='lg'
                    coloredShadow='light'
                    my={2}
                    p={3}
                    textAlign='center'
                >
                    <Logo style={{ height: 'fit-content', width: '100%' }} />
                </MDBox>

                <MDBox py={3} px={2}>
                    {renderStep()}
                </MDBox>
            </Card>
        </BasicLayout>
    )
}

export default SignUpPage 