import colors from "assets/theme/base/colors"
import MDBox from "components/atoms/MDBox/MDBox"
import MDButton from "components/atoms/MDButton/MDButton"
import { MUIBaseColors } from "models/StyleModels"

interface GetStartedStepProps {
    next: () => void
    back: () => void
}

const GetStartedStep: React.FC<GetStartedStepProps> = ({ next }) => {
    return (
        <>
            <h3 style={{
                color: colors.text.main,
                fontWeight: 400, fontSize: "1.5rem",
                marginBottom: "1rem"
            }}>
                Welcome to JointDx!
            </h3 >

            <div style={{ marginBottom: "2rem" }}>
                <span style={{ color: colors.text.main, fontWeight: 700 }}>
                    We're going to help you set up your organization. This will only take a moment.
                </span>
            </div>

            <MDBox display="grid" pt="2rem">
                <MDButton
                    variant='contained'
                    color={MUIBaseColors.SECONDARY}
                    onClick={next}>
                    Get Started
                </MDButton>
            </MDBox>
        </>
    )
}

export default GetStartedStep 