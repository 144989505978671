import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { AccionPrediction, ExamTypes, FullJoints, ViewTypes, Xray } from 'models/XrayModels';
import { XraysQueryCache, xrayQueryKeys } from './useXrayData';
import { Appointment } from 'models/AppointmentModels';
import { getJointFromFullJoint, getJointSideFromFullJoint } from 'helpers/helpers';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

interface UseXrayMutationOptions {
  appointment?: Appointment | null;
}

export const useXrayMutations = (opts?: UseXrayMutationOptions) => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();

  const appointment = opts?.appointment;

  // media files must be uploaded as File type objects with the Content-Type: multipart/form-data headers.
  // additional metadata required for the request must be stringified in this format
  const postXray = async ({ file, xray_metadata }: { file: File, xray_metadata: string }): Promise<{ data: { xray: Xray } }> => {
    const resp = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/xrays/?org_id=${user?.orgs[0].org_id}`,
      data: {
        file,
        xray_metadata,
      },
      token: accessToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp as unknown as { data: { xray: Xray } };
  };

  const postBatchXrays = async ({ files, patientId }: { files: File[], patientId: string }): Promise<{ data: any }> => {

    if (!appointment) throw new Error('Appointment data not found or not provided to useXrayMutations hook.');

    const formData = new FormData();

    // Append files to the FormData object
    files.forEach((file, index) => {
      formData.append('files', file);
    });

    formData.append('appointment_id', appointment?.appointment_id);
    formData.append('patient_id', patientId);

    const resp = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/xrays/batch-xrays?org_id=${user?.orgs[0].org_id}`,
      data: formData,
      token: accessToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp as unknown as { data: { xray: Xray } };
  };

  const {
    mutateAsync: createBatchXrays,
    data: batchXrays,
    error: createBatchXrayError,
    isLoading: createBatchXrayLoading,
    isSuccess: createBatchXraySuccessful,
  } = useMutation(
    ({ files, patientId }: { files: File[], patientId: string }) => postBatchXrays({ files, patientId }),
    {
      onSuccess: (resp) => {
        if (!appointment) throw new Error('Appointment data not found or not provided to useXrayMutations hook.');

        const updatedXrays = resp.data?.xrays;

        if (!updatedXrays) return;

        const prevXrays: XraysQueryCache = queryClient
          .getQueryData<XraysQueryCache>(xrayQueryKeys.allXraysKey(appointment));

        if (prevXrays) {
          queryClient.setQueryData<XraysQueryCache>(xrayQueryKeys.allXraysKey(appointment), {
            ...prevXrays,
            data: {
              ...prevXrays.data,
              xrays: [...prevXrays.data.xrays, ...updatedXrays],
            },
          });
        }
      },
    },);

  interface UpdateXrayRequest {
    xray: Xray;
    newValues: {
      joint?: ExamTypes | null,
      client_provided_view_type?: ViewTypes | null,
      prediction_results?: AccionPrediction;
    }
  }

  const patchXray = async (requestData: UpdateXrayRequest): Promise<{ data: { xray: Xray } }> => {
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/xrays/${requestData.xray.xray_id}?appointment_id=${requestData.xray.appointment_id}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
      data: requestData.newValues,
    });
    return resp as unknown as { data: { xray: Xray } };
  };


  const {
    mutateAsync: createXray,
    data: newXray,
    error: createXrayError,
    isLoading: createXrayLoading,
    isSuccess: createXraySuccessful,
  } = useMutation((xrayData: { file: File, xray_metadata: string }) => postXray(xrayData));

  const {
    mutateAsync: updateXray,
    isLoading: updateXrayLoading,
    isSuccess: updateXraySuccessful,
    isError: updateXrayError,
  } = useMutation(
    (requestData: UpdateXrayRequest) => patchXray(requestData),
    {
      onSuccess: (resp) => {
        if (!appointment) throw new Error('Appointment data not found or not provided to useXrayMutations hook.');

        const updatedXray = resp.data?.xray;

        if (!updatedXray) return;

        const prevXrays: XraysQueryCache = queryClient
          .getQueryData<XraysQueryCache>(xrayQueryKeys.allXraysKey(appointment));

        if (prevXrays) {
          queryClient.setQueryData<XraysQueryCache>(xrayQueryKeys.allXraysKey(appointment), {
            ...prevXrays,
            data: {
              ...prevXrays.data,
              xrays: prevXrays.data.xrays.map((xray) => xray.xray_id === updatedXray.xray_id ? updatedXray : xray),
            },
          });
        }
      },
    },
  );

  // passing in fullJoint as an argument here will ensure that only sections that match both the joint and joint side
  // of the parameter will be confirmed. this is necessary when wanting to confirm only a specific joint's evaluation
  // in bilateral xrays, for example only wanting to confirm right knee sections in a bilateral knee xray
  const batchConfirmXrays = async (xrays: Xray[], fullJoint?: FullJoints) => xrays.forEach((xray) => xray.prediction_results
    ? updateXray({
      xray,
      newValues: {
        prediction_results: {
          ...xray.prediction_results,
          sections: xray.prediction_results.sections.map((sect) => {
            if (fullJoint) {
              const joint = getJointFromFullJoint(fullJoint);
              const jointSide = getJointSideFromFullJoint(fullJoint);
              // TODO: abstract this logic to interface with filterAccionPredSectsByFullJoint helper fn
              const sectMatchesJointAndSide = (joint === 'knee' && (sect.knee === jointSide || sect.knee === 'single'))
                || (joint === 'hip' && (sect.hip === jointSide || sect.hip === 'single'))
                || sect.joint === jointSide;

              if (sectMatchesJointAndSide) {
                return {
                  ...sect,
                  clientConfirmed: true,
                }
              } else {
                return sect;
              }
            } else {
              return {
                ...sect,
                clientConfirmed: true,
              };
            }
          }),
        },
      },
    })
    : {}
  );

  const destroyXray = async (xray: Xray): Promise<{ data: { deleted_xray_id: string } }> => {
    const resp = await HttpService.destroy({
      url: `${ENDPOINT_BASE_URL}/xrays/${xray.xray_id}?appointment_id=${xray.appointment_id}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
    });
    return resp as unknown as { data: { deleted_xray_id: string } };
  };


  const {
    mutate: deleteXray,
    error: deleteXrayError,
    isLoading: deleteXrayLoading,
    isSuccess: deleteXraySuccessful,
  } = useMutation((xray: Xray) => destroyXray(xray));


  type AutoAssignXraysInput = { appointmentId: string, appointmentDate: string };

  const patchAutoAssignXrays = async ({ appointmentId, appointmentDate }: AutoAssignXraysInput): Promise<{ data: { success: boolean } }> => {

    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/xrays/auto-assign-xrays?appointment_id=${appointmentId}&org_id=${user?.orgs[0].org_id}`,
      token: accessToken,
      data: {
        appointment_id: appointmentId,
        appointment_date: appointmentDate,
        org_id: user?.orgs[0].org_id
      }
    });
    return resp as unknown as { data: { success: boolean } };
  };


  const {
    mutate: autoAssignXrays,
    error: autoAssignXraysLoading,
    isLoading: autoAssignXraysSuccessful,
    isSuccess: autoAssignXraysError,
  } = useMutation((autoAssignXrayInput: AutoAssignXraysInput) => patchAutoAssignXrays(autoAssignXrayInput));


  return {
    createXray,
    newXray,
    createXrayError,
    createXrayLoading,
    createXraySuccessful,
    deleteXray,
    deleteXrayError,
    deleteXrayLoading,
    deleteXraySuccessful,
    updateXray,
    updateXrayError,
    updateXrayLoading,
    updateXraySuccessful,
    batchConfirmXrays,

    createBatchXrays,
    batchXrays,
    createBatchXrayError,
    createBatchXrayLoading,
    createBatchXraySuccessful,

    autoAssignXrays,
    autoAssignXraysLoading,
    autoAssignXraysSuccessful,
    autoAssignXraysError,
  };
};
