import React, { CSSProperties } from "react";
import MDTypography from "../MDTypography/MDTypography";

interface SurveyQuestionTextProps {
  children: React.ReactNode;
  bold?: boolean;
  style?: CSSProperties;
}

const SurveyQuestionSubText: React.FC<SurveyQuestionTextProps> = ({ children, bold, style }) => (
  <MDTypography fontWeight={bold ? 'bold' : 'regular'} variant='body2' sx={{ ...style }}>
    {children}
  </MDTypography>
);

export default SurveyQuestionSubText;
