import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Card } from "@mui/material";

// MD components
import LowerNavigation from "components/organisms/LowerNavigation/LowerNavigation";
import MDBox from "components/atoms/MDBox/MDBox";
import Footer from "components/molecules/Footer/Footer";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import PatientDashboardHeader from "components/molecules/PatientDashboardHeader/PatientDashboardHeader";
import { MUIColors } from "models/StyleModels";

interface NavAction {
  action?(): void;
  text?: string;
  hide?: boolean;
  message?: React.ReactNode;
  [key: string]: any;
}

interface NavActionsType {
  previous?: NavAction | null;
  next?: NavAction | null;
}

// TODO: duplicated between here and PatientDashboardHeader. abstract out
export interface HeaderItems {
  headline: string;
  subItems?: { [key: string]: string }[];
  secondaryContent?: React.ReactNode;
  pageTitle?: string;
}

export interface MainAppLayoutProps {
  children?: React.ReactNode;
  navActions?: NavActionsType;
  secondaryActionOptions?: NavAction;
  hideHeader?: boolean;
  hideFooter?: boolean;
  headerItems: HeaderItems;
}

const MainAppLayout: React.FC<MainAppLayoutProps> = ({
  children,
  secondaryActionOptions,
  navActions,
  hideHeader,
  hideFooter,
  headerItems,
}) => {
  const buttons = [
    {
      ...navActions?.previous,
      action: navActions?.previous ? navActions?.previous.action : () => { },
      text: navActions?.previous ? navActions?.previous.text || 'Back' : 'Back',
      hide: navActions?.previous ? !navActions?.previous?.action || navActions.previous.hide : !navActions?.previous?.action || true,
      color: MUIColors.RETURN,
    },
    {
      ...secondaryActionOptions,
      color: MUIColors.SECONDARY,
      hide: ((!!secondaryActionOptions?.hide) || (!secondaryActionOptions?.action))
    },
    {
      ...navActions?.next,
      action: navActions?.next ? navActions?.next.action : () => { },
      text: navActions?.next ? navActions?.next.text || 'Next' : 'Next',
      hide: navActions?.next ? !navActions?.next?.action || navActions.next.hide : true,
      color: MUIColors.SECONDARY,
    },
  ];

  return (
    <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
      <MDAppBar />
      <MDBox pb={3}>
        <Grid item xs={12} pt={2}>
          <Card>
            {!hideHeader && (
              <PatientDashboardHeader headerItems={headerItems} />
            )}
            <MDBox m={3} pt={1}>
              {children}
            </MDBox>
            {!hideFooter && <LowerNavigation buttons={buttons} />}
          </Card>
        </Grid>
      </MDBox>
      <Footer />
    </Container>
  );
};

export default MainAppLayout;
