import React from "react";
import { ActionSlug } from "../services/PermissionsService";

// The RouteKeys enum serves as the single source of truth for all routes that
// exist in the app. You'll find that, if you add a route key here, typescript
// will require you to add a corresponding routes map item to the routesMap
// object in routes.tsx, and that item must have a key pointing to the corresponding
// RouteKeys member, as well as a child key called `key` pointing to the same.

// IMPORTANT: Members of the RouteKeys enum are used for several purposes across
// the app, chief of which being that their value serves as the slug that will
// be used to generate their VIEW action slug in the ActionSlugs permissions
// object. The value of each key in this enum should always end in '_PAGE',
// otherwise the user.can method will not properly run any permissions checks
// meant to gate the associated page.

export enum RouteKeys {
  SIGN_IN = 'SIGN_IN_PAGE',
  SIGN_UP = 'SIGN_UP_PAGE',
  PATIENT_LIST = 'PATIENT_LIST_PAGE',
  PATIENT_OVERVIEW = 'PATIENT_OVERVIEW_PAGE',
  PROFILE = 'PROFILE_PAGE',
  APPOINTMENT_OVERVIEW = 'APPOINTMENT_OVERVIEW_PAGE',
  ONBOARDING = 'ONBOARDING_PAGE',
  ORG_DETAILS = 'ORG_DETAILS_PAGE',
  SURVEY = 'SURVEY_PAGE',
  TRIAGE_SURVEY = 'TRIAGE_SURVEY_PAGE',
  APPOINTMENT_SCHEDULE = 'APPOINTMENT_SCHEDULE_PAGE',
  EXAM_ROOM_ALL_JOINTS = 'EXAM_ROOM_ALL_JOINTS_PAGE',
  EXAM_ROOM_RIGHT_KNEE = 'EXAM_ROOM_RIGHT_KNEE_PAGE',
  EXAM_ROOM_LEFT_KNEE = 'EXAM_ROOM_LEFT_KNEE_PAGE',
  EXAM_ROOM_RIGHT_HIP = 'EXAM_ROOM_RIGHT_HIP_PAGE',
  EXAM_ROOM_LEFT_HIP = 'EXAM_ROOM_LEFT_HIP_PAGE',
  REPORT = 'REPORT_PAGE',
  TRIAGE_REPORT = 'TRIAGE_REPORT_PAGE',
  JAI_DASHBOARD = 'JAI_DASHBOARD_PAGE',
  SCHEDULE_CALL = 'SCHEDULE_CALL',
  TRIAGE_LIST = 'TRIAGE_LIST_PAGE',
  BATCH_XRAY_UPLOAD = 'BATCH_XRAY_UPLOAD_PAGE',
  MATCH_XRAYS = 'MATCH_XRAYS',
  TRIAGE_EXAM_ROOM_ALL_JOINTS = 'TRIAGE_EXAM_ROOM_ALL_JOINTS_PAGE',
}

export enum RouteTypes {
  COLLAPSE = 'collapse', // this is leftover from the MUI Dashboard fork
}

export type RouteType<RK> = {
  type: RouteTypes;
  name: string;
  key: RK;
  route: string;
  component: React.ReactNode;
  unauthedUnonboardedOnly?: boolean;
  permissionSlug?: ActionSlug;
  baseRoute?: string;
};

export type Route = RouteType<RouteKeys>;

export type RoutesMapInterface = {
  [K in RouteKeys]: RouteType<K>;
};
