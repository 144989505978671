// The appointment_date is used as the Sort Key for the appointment dynamoDB table, and is used here on the UI
// for scheduling appointments, viewing appointments for a given day, etc. It is important for how appointments
// are handled that all appointment_date values are formatted as ISO datestrings. This service exposes various
// values and helper functions to make working with dates in this format easier across the app.

const today = new Date();
const year = today.getFullYear();
const month = (today.getMonth() + 1).toString().padStart(2, '0');
const day = today.getDate().toString().padStart(2, '0');
const hours = today.getHours().toString().padStart(2, '0');
const minutes = today.getMinutes().toString().padStart(2, '0');
const seconds = today.getSeconds().toString().padStart(2, '0');
const milliseconds = today.getMilliseconds().toString().padStart(3, '0');

export const defaultHour = '01:00:00.000';
// today's date in ISO format with the default starting hour applied
export const todayFormattedForISO = `${year}-${month}-${day}T${defaultHour}`;
// today's date in ISO format with the exact current timestamp applied
export const nowFormattedForISO = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`

// helper function that receives a date object and formats it for how the backend will expect
// to receive it. Importantly we remove the trailing "Z" character denoting UTC timezone if it is
// present, as it can cause weird timezone inconsistencies if it is left on.
export const formatDateForDB = (date: Date) => {
  const dateStringArr = date.toISOString().split('');

  if (dateStringArr[dateStringArr.length - 1] === 'Z') {
    return date.toISOString().slice(0, -1); 
  }

  return date.toISOString();
}; 

// a function that returns the exact ISO formatted timestamp when the function was invoked
export const getCurrentISOTimestamp = () => {
  const _today = new Date();
  const _year = _today.getFullYear();
  const _month = (_today.getMonth() + 1).toString().padStart(2, '0');
  const _day = _today.getDate().toString().padStart(2, '0');
  const _hours = _today.getHours().toString().padStart(2, '0');
  const _minutes = _today.getMinutes().toString().padStart(2, '0');
  const _seconds = _today.getSeconds().toString().padStart(2, '0');
  const _milliseconds = _today.getMilliseconds().toString().padStart(3, '0');
  return `${_year}-${_month}-${_day}T${_hours}:${_minutes}:${_seconds}.${_milliseconds}`;
};
