const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },

  "& .MuiButtonBase-root & .MuiTab-root & .Mui-selected": {
    color: "green !important",
  },

};

export default buttonBase;
