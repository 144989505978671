import React, { useState } from 'react';
import { containerStyles, getXrayUnknownViewTypeStatus, getXrayViewTypeMismatchStatus, getXrayViewTypeValidationStatus } from "helpers/helpers";
import colors from 'assets/theme/base/colors';
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDButton from "components/atoms/MDButton/MDButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestion, faSpinner, faTrash, faUpload, faX } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import { XraysQueryCache, xrayQueryKeys } from 'hooks/useXrayData';
import { useXrayMutations } from 'hooks/useXrayMutations';
import { useQueryClient } from 'react-query';
import { Card, Modal } from '@mui/material';
import { CardCover } from '@mui/joy';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { FullJoints, ViewTypes, ViewTypeData, Xray } from 'models/XrayModels';
import { Appointment } from 'models/AppointmentModels';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { ViewsObject, sendXraysForAnalysis } from 'services/mlService';
import { useAuth } from 'hooks/useAuth';

interface XrayUploadPortalProps {
  viewType: ViewTypeData<ViewTypes>;
  xray?: Xray | null;
  appointment: Appointment | null;
  joint: FullJoints | string;
  views: { [K in ViewTypes]?: Xray | null };
  setViews: React.Dispatch<React.SetStateAction<ViewsObject>>;
  disabled?: boolean;
}

const thumbnailDemensions = '13rem';

const XrayUploadPortal: React.FC<XrayUploadPortalProps> = ({
  viewType,
  xray,
  appointment,
  joint,
  views,
  setViews,
  disabled,
}) => {
  const { accessToken, user } = useAuth();
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });

  const validated = getXrayViewTypeValidationStatus({ xray, viewTypeDataObj: viewType });
  const viewTypeMismatch = getXrayViewTypeMismatchStatus({ xray, viewTypeDataObj: viewType });
  const unknownViewType = getXrayUnknownViewTypeStatus({ xray, viewTypeDataObj: viewType });

  const getValidationStyles = (activeAcceptedDrag: boolean) => {
    if (validated) return {
      border: `3px solid ${colors.success.main}`,
      label: {
        text: "Validated by JointDx",
        icon: <FontAwesomeIcon icon={faCheck} color={colors.success.main} style={{ marginRight: '.5rem' }} />,
      },
    }
    if (viewTypeMismatch) return {
      border: `3px solid ${colors.warning.main}`,
      label: {
        text: "View mismatch detected",
        icon: <FontAwesomeIcon icon={faQuestion} color={colors.warning.main} style={{ marginRight: '.5rem' }} />,
      },
    }
    if (unknownViewType) return {
      border: `3px solid ${colors.error.main}`,
      label: {
        text: "Invalid Xray",
        icon: <FontAwesomeIcon icon={faX} color={colors.error.main} style={{ marginRight: '.5rem' }} />,
      },
    }
    return {
      border: `3px dashed ${activeAcceptedDrag ? colors.info.main : colors.grey[500]}`,
      label: null,
    }
  };

  const {
    createXray,
    createXrayLoading,
    createXrayError,
    deleteXray,
    deleteXrayError,
  } = useXrayMutations();

  const queryClient = useQueryClient();

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    disabled,
    onDropAccepted: async (acceptedFiles) => {
      if (!appointment) throw new Error('Error gathering appointment details');
      await createXray({
        file: acceptedFiles[0],
        xray_metadata: JSON.stringify({
          "appointment_id": appointment.appointment_id,
          "patient_id": appointment.patient_id,
          "joint": joint,
          "client_provided_view_type": viewType.key,
        }),
      }, {
        onSuccess: async (resp) => {
          const newXray = resp.data.xray;
          const key = xrayQueryKeys.allXraysKey(appointment);
          await queryClient.cancelQueries(key);
          const prevXrays: XraysQueryCache = queryClient.getQueryData<XraysQueryCache>(key);

          if (prevXrays) {
            queryClient.setQueryData(key, {
              ...prevXrays,
              data: {
                ...prevXrays.data,
                xrays: [
                  ...prevXrays.data.xrays,
                  newXray,
                ],
              },
            });
          }

          // if type is not of unknown, or type is not lateral knee, run accion analysis.
          // lateral knee analysis is not currently supported by the accion algorithm.
          const canBeAnalyzed = !getXrayUnknownViewTypeStatus({ xray: newXray, viewTypeDataObj: viewType })
            && newXray.view_type_results.viewType !== 'lateral_knee';

          if (canBeAnalyzed) {
            sendXraysForAnalysis({
              [newXray.client_provided_view_type]: newXray,
            }, accessToken, user);
          }
        },
      });
    },
  });

  const validationStyles = getValidationStyles(isDragAccept);

  const [thumbHovered, setThumbHovered] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const closeModal = () => setShowConfirmDeleteModal(false);

  const handleDeleteXray = async () => {
    if (!xray || !appointment) return;
    await deleteXray(xray, {
      onSuccess: async (resp) => {
        const key = xrayQueryKeys.allXraysKey(appointment);
        await queryClient.cancelQueries(key);
        const prevXrays: XraysQueryCache = queryClient.getQueryData<XraysQueryCache>(key);

        if (prevXrays) {
          queryClient.setQueryData(key, {
            ...prevXrays,
            data: {
              ...prevXrays.data,
              xrays: [...prevXrays.data.xrays.filter((xr) => xr.xray_id !== resp.data.deleted_xray_id)],
            },
          });
        }

        setViews({
          ...views,
          [viewType.key]: null,
        })
        closeModal();
      },
    });
  };

  return (
    <>
      <Modal open={showConfirmDeleteModal} onClose={closeModal}>
        <MDBox {...containerStyles({ justifyContent: 'center', alignItems: 'center' })}>
          <Card {...containerStyles({
            width: breakpointBreached ? '90vw' : '40%',
            height: '30vh',
            maxWidth: '40rem',
            minWidth: '20rem',
            padding: '20%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
          })}>
            <MDTypography sx={{ marginBottom: '.5rem' }} variant='h5' fontWeight='regular'>
              Are you sure you wish to delete this X-ray?
            </MDTypography>
            <MDTypography variant='h5' fontWeight='bold'>
              This action cannot be undone.
            </MDTypography>
            <MDBox {...containerStyles({
              flexDirection: 'row',
              height: 'fit-content',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2.5rem',
            })}>
              <MDButton onClick={handleDeleteXray} variant='contained' color='error'>Delete</MDButton>
              <MDButton onClick={closeModal}>Cancel</MDButton>
            </MDBox>
            <MDBox sx={{ opacity: deleteXrayError ? 1 : 0, position: 'absolute', bottom: '1rem', left: 0, width: '100%' }}>
              <MDTypography variant="caption" color={MUIColors.ERROR}>Something went wrong.</MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </Modal>

      <MDBox {...containerStyles({ flexDirection: 'column', width: 'fit-content', cursor: xray || disabled ? 'default' : 'pointer' })}>
        <MDTypography variant="h4" fontWeight="regular">{viewType.label} {viewType.isOptional && '(Optional)'}</MDTypography>
        <Card {...containerStyles({
          width: thumbnailDemensions,
          height: thumbnailDemensions,
          aspectRatio: '1 / 1',
          border: validationStyles.border,
          backgroundColor: colors.grey[300],
          objectFit: 'cover',
          overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: '10px',
          position: 'relative',
        })}
          {...(!xray ? getRootProps() : {})}
          onMouseOver={() => setThumbHovered(true)}
          onMouseLeave={() => setThumbHovered(false)}
        >
          {!xray && !createXrayLoading && <input {...getInputProps()} />}
          {validationStyles.label && (
            <span style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.358)',
              padding: '.25rem .5rem',
              zIndex: 2,
            }}>
              {validationStyles.label.icon}
              <MDTypography color={MUIColors.WHITE} variant="caption">{validationStyles.label.text}</MDTypography>
            </span>
          )}
          {xray && (
            <>
              {!disabled && (
                <FontAwesomeIcon
                  icon={faTrash}
                  color={colors.error.focus}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    margin: '.5rem',
                    cursor: 'pointer',
                    opacity: thumbHovered ? ".75" : 0,
                    transition: 'opacity 150ms ease'
                  }}
                  onClick={() => setShowConfirmDeleteModal(true)}
                  onKeyDown={() => setShowConfirmDeleteModal(true)}
                />
              )}
              <CardCover>
                <img src={xray.img_url} alt={xray.joint} />
              </CardCover>
            </>
          )}
          {!xray && (
            <div>
              {!disabled && (
                <FontAwesomeIcon
                  icon={createXrayLoading ? faSpinner : faUpload}
                  color={colors.grey[600]}
                  style={{ marginBottom: '1rem', fontSize: '30px' }}
                  spin={createXrayLoading}
                />
              )}
              <MDTypography
                variant="body2"
                fontWeight={disabled ? 'bold' : 'regular'}
                sx={{ color: createXrayError ? colors.error.main : colors.grey[600], padding: '0 1rem' }}
              >
                {createXrayError
                  ? 'Something went wrong.' : createXrayLoading
                    ? 'Upload in progress...' : disabled
                      ? 'This view has not been provided.' : 'Drag & Drop or click to browse'}
              </MDTypography>
            </div>
          )}
        </Card>
      </MDBox>
    </>
  );
};

export default XrayUploadPortal;
