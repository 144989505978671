import { forwardRef } from "react";

// Custom styles for MDAvatar
import MDAvatarRoot from "components/atoms/MDAvatar/MDAvatarRoot";

import { AvatarProps } from "@mui/material";
import { MUIColors, Sizes } from "models/StyleModels";
import { Shadows } from "@mui/material/styles/shadows";

type MDAvatarProps = {
  bgColor?: MUIColors;
  size?: Sizes;
  shadow?: Shadows | string;
  src: string;
} & AvatarProps;

const MDAvatar: React.FC<MDAvatarProps> = forwardRef<HTMLDivElement, MDAvatarProps>(({
  bgColor,
  size,
  shadow,
  ...rest
}, ref) => (
  <MDAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

export default MDAvatar;
