import React from 'react';
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDButton from "components/atoms/MDButton/MDButton";
import { MUIColors } from 'models/StyleModels';

interface ErrorRetryProps {
  message?: string;
  handleRetry(): void;
}

const ErrorRetry: React.FC<ErrorRetryProps> = ({ message, handleRetry }) => (
  <MDBox sx={{
    width: '100%',
    height: '8rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <MDTypography sx={{ marginBottom: '1rem' }} variant="button" color={MUIColors.PRIMARY} fontWeight="regular">
      {message || 'Something went wrong.' }
    </MDTypography>
    {handleRetry && <MDButton color={MUIColors.SECONDARY} onClick={handleRetry}>Try again.</MDButton>}
  </MDBox>
);

export default ErrorRetry;
