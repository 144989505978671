import { usePatientData } from "./usePatientData";
import { useAppointmentData } from "./useAppointmentData";
import { useAppointmentParamsFromQuery } from "./useAppointmentParamsFromQuery";

// uses the patientsMappedToIds object returned by usePatientData to find the patient
// that owns the appointment for which the user is currently viewing, or null.

export const useActivePatient = () => {
  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const { patientsMappedToIds } = usePatientData({ preventRefetch: true });

  const { appointment } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    preventRefetch: true,
  });

  const activePatient = appointment && patientsMappedToIds[appointment.patient_id];

  return { activePatient };
};
